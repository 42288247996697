const VALIDATION = {
  moveMoney: {
    currencyInput: {
      required: "Enter an amount to continue.",
      moreThanMinBalanceDestinationAccount:
        "The minimum balance of the account you're transferring to is {{minBalance}}. Enter at least {{remainingToMin}} to continue."
    }
  },
  userMigrationJourney: {
    customerDetails: {
      email: {
        required: "Enter your email address to continue.",
        atSymbolRequired: "Your email address must include the @ symbol.",
        validEmailAddress: "Enter a valid email address."
      },
      mobile: {
        mobileFormatIncorrect: "Your mobile number must contain 10 or 11 digits and start with 07.",
        required: "Enter your mobile number to continue."
      },
      otp: {
        limitReached: "You've entered an incorrect passcode too many times. Please try again later.",
        required: "Enter a passcode to continue.",
        validOtp: "This passcode is incorrect. Try again, or resend passcode to get a new one.",
        genericError:
          "Sorry, we're having trouble with your passcode. Please try again in a few minutes. If the problem continues, call us on {{HELP_CENTER_NUMBER}}. We're open from 9am to 5pm, Monday to Friday, excluding  bank holidays."
      }
    },
    accessibilitySupport: {
      required: "Make a selection to continue."
    },
    accessibilityOptions: { required: "Make a selection to continue." }
  }
};

export { VALIDATION };
