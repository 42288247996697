import { Button, Flex, Heading, Modal, Text } from "@shawbrook/sds-component-library";

import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";

export const ErrorModal = ({
  open = false,
  allPages = false,
  customCallback = () => {}
}: {
  open?: boolean;
  allPages?: boolean;
  customCallback?: () => void;
}) => {
  const { heading: allPagesHeading, content: allPagesContent } = strings.general.modals.allPagesError;
  const {
    heading: generalHeading,
    text: { option1, option2, optionsTitle, paragraph },
    button1,
    button2
  } = strings.general.modals.error;

  const handleTryAgain = () => {
    window.location.assign(links.inbox.link);
  };

  const handleBackToHome = () => {
    customCallback();
    window.location.assign(links.landing.link);
  };

  const enhancedParagraph = formatTextWithPhoneHours(allPages ? allPagesContent : paragraph);
  const heading = allPages ? allPagesHeading : generalHeading;

  const paragraphMargin = allPages ? "$0" : "$12 $0";

  return (
    <Modal.Root open={open}>
      <Modal.Content css={{ padding: "$6", maxWidth: "786px", borderRadius: "8px" }}>
        <Heading
          level={2}
          fontWeight="semiBold"
          id="modal-title"
          css={{ marginBottom: "$2" }}
          aria-label="Error message">
          {heading}
        </Heading>
        <Flex direction="column" css={{ margin: "$6 0" }}>
          {!allPages && (
            <>
              <Text textStyle="medium" css={{ marginBottom: "$0" }}>
                {optionsTitle}
              </Text>
              <ul style={{ paddingLeft: "2.5rem" }}>
                <li style={{ listStyleType: "disc" }}>
                  <Text textStyle="medium">{option1}</Text>
                </li>
                <li style={{ listStyleType: "disc" }}>
                  <Text textStyle="medium">{option2}</Text>
                </li>
              </ul>
            </>
          )}
          <Text textStyle="medium" css={{ margin: paragraphMargin }}>
            {enhancedParagraph}
          </Text>
        </Flex>

        <Flex justify={allPages ? "end" : "between"}>
          <Button variant={allPages ? "primary" : "secondary"} onClick={handleBackToHome}>
            {button1}
          </Button>
          {!allPages && (
            <Button variant="primary" onClick={handleTryAgain} autoFocus>
              {button2}
            </Button>
          )}
        </Flex>
      </Modal.Content>
    </Modal.Root>
  );
};
