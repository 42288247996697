import { isAxiosError } from "axios";
import { useCallback, useEffect, useRef, useState } from "react";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/src/components/CurrencyInputProps";
import { useForm } from "react-hook-form";

import { CURRENCIES, REG_EXPS } from "assets/strings/api";
import { links } from "config/navLinks";
import { UseMoveMoneyFormProps } from "hooks/useMoveMoneyForm/interfaces";
import { FormValues } from "pages/MoveMoney/types";
import { IAccountSummary, IPaymentsErrorResponse } from "services/data/types";
import { moveMoneyValidationSchema } from "services/validationSchemas";
import { IMoveMoneyContext } from "state/moveMoneyState";
import { getAvailableBalance } from "utils";
import formatTranferValidation from "utils/formatTransferValidation";
import getOutboundPaymentWarning from "utils/getOutboundPaymentWarning";
import { inputFormatter, inputFormatterPreviousValue } from "utils/inputFormatter";

const useMoveMoneyForm = ({
  moveMoneyContext: { state, setState } = {} as IMoveMoneyContext,
  navigate,
  isSubmitTransferError,
  submitTransferError,
  isSubmitTransferSuccess
}: UseMoveMoneyFormProps) => {
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const currencyInputRef = useRef<HTMLInputElement | null>(null);
  const referenceInputRef = useRef<HTMLInputElement | null>(null);
  const [isCurrencyInputRef, setIsCurrencyInputRef] = useState(false);
  const [isReferenceInputRef, setIsReferenceInputRef] = useState(false);
  const [previousReference, setPreviousReference] = useState<string | undefined>();
  const resolvedSchema = moveMoneyValidationSchema(state);
  const outboundPaymentWarning = getOutboundPaymentWarning({
    amount: state.amount.value ?? 0,
    sourceCategory: state.fromAccount?.product?.category,
    sourceAvailableBalance: getAvailableBalance(state.fromAccount as IAccountSummary),
    destinationInternal: state.toAccount?.internal,
    interestRates: state.fromAccount?.product.interestRates || [],
    currentRate: state.fromAccount?.product.interestRate
  });
  const { handleSubmit, control, formState, setError } = useForm<FormValues>({
    defaultValues: {
      moveMoney: {
        currencyInput: state.amount.value,
        reference: state.reference
      }
    },
    resolver: resolvedSchema
  });

  const onCurrencyInputRefChange = useCallback((node: HTMLInputElement | null) => {
    if (node) {
      currencyInputRef.current = node;
      setIsCurrencyInputRef(true);
    }
  }, []);

  const onReferenceInputRefChange = useCallback((node: HTMLInputElement | null) => {
    if (node) {
      referenceInputRef.current = node;
      setIsReferenceInputRef(true);
    }
  }, []);

  useEffect(() => {
    if (isSubmitTransferError) {
      if (isAxiosError<IPaymentsErrorResponse>(submitTransferError)) {
        const errorMessage = formatTranferValidation(submitTransferError.response?.data?.errors);
        setError("moveMoney.currencyInput", {
          type: "server",
          message: errorMessage
        });
        if (currencyInputRef.current) {
          currencyInputRef.current.focus();
        }
      }
    } else if (isSubmitTransferSuccess && navigate) {
      navigate(links.moveMoneyReview.link);
    }
  }, [isSubmitTransferError, submitTransferError, isSubmitTransferSuccess, navigate, setError]);

  useEffect(() => {
    if (isCurrencyInputRef && currencyInputRef.current) {
      inputFormatter(
        currencyInputRef.current,
        currencyInputRef.current.value,
        `${CURRENCIES.GBP} `,
        REG_EXPS.MOVE_MONEY_AMOUNT_PREFIX
      );
    }
  }, [isCurrencyInputRef]);

  useEffect(() => {
    if (isReferenceInputRef && referenceInputRef.current) {
      inputFormatterPreviousValue(
        referenceInputRef.current,
        referenceInputRef.current.value,
        previousReference,
        REG_EXPS.MOVE_MONEY_REFERENCE_INPUT
      );
    }
  }, [isReferenceInputRef, previousReference]);

  useEffect(() => {
    const { errors } = formState;
    if (errors.moveMoney?.currencyInput && currencyInputRef.current) {
      inputFormatter(
        currencyInputRef.current,
        currencyInputRef.current.value,
        `${CURRENCIES.GBP} `,
        REG_EXPS.MOVE_MONEY_AMOUNT_PREFIX
      );
      if (document.activeElement === btnRef.current) {
        currencyInputRef.current.focus();
      }
    }
  }, [formState]);

  const onValueChange = (newValue?: string, _?: string, values?: CurrencyInputOnChangeValues) => {
    const setValue = Number(newValue ?? 0);
    setState({
      amount: {
        ...state.amount,
        value: setValue
      }
    });

    const formattedValue = values?.formatted || "";
    inputFormatter(currencyInputRef.current, formattedValue, `${CURRENCIES.GBP} `, REG_EXPS.MOVE_MONEY_AMOUNT_PREFIX);
  };

  const onReferenceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (REG_EXPS.MOVE_MONEY_REFERENCE_INPUT.test(e.target.value)) {
      setState({
        reference: e.target.value
      });
      setPreviousReference(e.target.value);
    }
    inputFormatterPreviousValue(
      referenceInputRef.current,
      referenceInputRef.current?.value,
      previousReference,
      REG_EXPS.MOVE_MONEY_REFERENCE_INPUT
    );
    inputFormatter(
      currencyInputRef.current,
      currencyInputRef.current?.value || "",
      `${CURRENCIES.GBP} `,
      REG_EXPS.MOVE_MONEY_AMOUNT_PREFIX
    );
  };

  return {
    handleSubmit,
    control,
    errors: formState.errors,
    onValueChange,
    onCurrencyInputRefChange,
    onReferenceInputRefChange,
    btnRef,
    outboundPaymentWarning,
    onReferenceChange
  };
};

export default useMoveMoneyForm;
