import { AuthenticatedTemplate } from "@azure/msal-react";
import isNoStrictAuth from "utils/isNoStrictAuth";
import ConditionalWrapper from "components/ConditionalWrapper";
import Footer from "layouts/Footer/Footer";
import Navigation from "layouts/Navigation/Navigation";
import { FC, ReactElement } from "react";

interface IDefaultLayout {
  children: ReactElement;
  className?: string;
}

export const DefaultLayout: FC<IDefaultLayout> = ({ children, ...props }) => {
  const renderLayout = (childrenArg: ReactElement) => {
    let wrapper: ReactElement | null = null;

    if (isNoStrictAuth()) {
      wrapper = childrenArg;
    } else {
      wrapper = <AuthenticatedTemplate>{childrenArg}</AuthenticatedTemplate>;
    }

    return wrapper;
  };

  return (
    <ConditionalWrapper wrapper={renderLayout} condition>
      <div {...props}>
        <Navigation />

        <main id="wrapper">{children}</main>
        <Footer />
      </div>
    </ConditionalWrapper>
  );
};

export default DefaultLayout;
