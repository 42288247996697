import { Text } from "@shawbrook/sds-component-library";

interface IDescriptionProps {
  description: string;
  identifier: string;
  value: string;
}

export const Description = ({ description, identifier, value }: IDescriptionProps) => (
  <>
    <Text as="span">{description.split(identifier)?.[0]}</Text>
    <Text as="span" fontWeight="bold">
      {value}
    </Text>
    <Text as="span">{description.split(identifier)?.[1]}</Text>
  </>
);
