import { Button, Heading } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import classnames from "classnames";
import Card from "components/Card/Card";
import cardStyles from "components/Card/Card.module.scss";
import WhiteBox from "components/WhiteBox/WhiteBox";
import { links } from "config/navLinks";
import MoveMoneyInfo from "pages/MoveMoney/components/MoveMoneyInfo";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { FromAccountProp, IToAccount } from "state/moveMoneyState";
import { getAvailableBalance, isISAAccountCategory } from "utils";
import getNonWithdrawableInfo from "utils/getNonWithdrawableInfo";
import styles from "../MoveMoney.module.scss";
import inlineStyles from "../MoveMoney.styles";

interface ISelectedAccounts {
  fromAccount: FromAccountProp;
  toAccount: IToAccount | null;
  isSingleAccount: boolean;
}

export const MoveMoney: FC<ISelectedAccounts> = ({ fromAccount, toAccount = null, isSingleAccount }) => {
  const navigate = useNavigate();

  const {
    product: { title: fromAccountTitle, issueNumber: fromAccountIssueNumber, interestRate: fromAccountInterestRate }
  } = fromAccount;

  const isNonWithdrawable =
    (fromAccount.withdrawability.enabled === false && fromAccount.outboundWithdrawability?.enabled === false) ||
    fromAccount.linkedAccount?.verified === false;

  const cardCustomClasses = classnames(cardStyles["card__move-money"], {
    [cardStyles["card__move-money-single"]]: isSingleAccount,
    [cardStyles["card__move-money-ineligible"]]: isNonWithdrawable
  });

  const moveMoneyFromHandler = () => {
    navigate(links.moveMoneyFrom.link);
  };

  const moveMoneyToHandler = () => {
    navigate(links.moveMoneyTo.link);
  };

  let nonWithdrawableInfo = null;
  if (isNonWithdrawable) {
    nonWithdrawableInfo = getNonWithdrawableInfo({
      maturityDate: fromAccount.maturity?.maturityDate,
      reason: fromAccount.withdrawability.reason,
      openToFundingReason: fromAccount.openToFunding.reason,
      category: fromAccount.product.category,
      isLinkedAccVerified: fromAccount.linkedAccount?.verified
    });
  }

  return (
    <section>
      <WhiteBox>
        <div>
          <Heading level="5" as="h3" className={styles.moveMoneyHeadings}>
            {strings.moveMoney.mmFrom}
          </Heading>
          {!isSingleAccount && (
            <Button
              variant="tertiary"
              onClick={moveMoneyFromHandler}
              role="link"
              css={inlineStyles.chooseAccountBtn}
              className={styles["move-money__chooseAccount-button"]}>
              {strings.moveMoney.changeAccount}
            </Button>
          )}
          <div>
            <Card
              blueBorder
              title={fromAccountTitle}
              issueNumber={`${strings.general.issueNumber} ${fromAccountIssueNumber}`}
              balance={getAvailableBalance(fromAccount)}
              rate={fromAccountInterestRate}
              accountNumber={fromAccount.accountNumber}
              sortCode={fromAccount.sortCode}
              accountId={fromAccount.id}
              customClasses={cardCustomClasses}
            />
          </div>
          {isNonWithdrawable && (
            <MoveMoneyInfo
              customStyles={{ marginTop: 0 }}
              heading={nonWithdrawableInfo?.heading}
              text={nonWithdrawableInfo?.text}
              variant={nonWithdrawableInfo?.variant}
              actionMode={nonWithdrawableInfo?.actionMode}
              accountId={fromAccount.accountId}
              bankName={fromAccount.linkedAccount?.bankName}
            />
          )}
          {(fromAccount.withdrawability.enabled || fromAccount.outboundWithdrawability?.enabled) &&
            isISAAccountCategory(fromAccount.product.category) && (
              <MoveMoneyInfo customStyles={{ marginTop: 0 }} text={strings.moveMoney.outbondTransfer.text} />
            )}
        </div>
      </WhiteBox>
      {(fromAccount.withdrawability.enabled || fromAccount.outboundWithdrawability?.enabled) &&
        (fromAccount.linkedAccount?.verified ||
          !fromAccount.linkedAccount ||
          !fromAccount.linkedAccount?.accountNumber) && (
          <WhiteBox>
            <Heading level="5" as="h3" className={styles.moveMoneyHeadings}>
              {strings.moveMoney.mmTo}
            </Heading>
            {!isSingleAccount && (
              <Button
                variant="tertiary"
                onClick={moveMoneyToHandler}
                role="link"
                css={inlineStyles.chooseAccountBtn}
                className={styles["move-money__chooseAccount-button"]}>
                {toAccount === null ? strings.moveMoney.chooseAccount : strings.moveMoney.changeAccount}
              </Button>
            )}
            {toAccount !== null && (
              <div>
                <Card
                  blueBorder
                  title={toAccount.accountTitle}
                  issueNumber={toAccount.issueNumber ?? ""}
                  balance={toAccount.internal ? toAccount.availableBalance ?? 0 : undefined}
                  bankName={toAccount.bankName ? toAccount.bankName : undefined}
                  personName={!toAccount.internal ? toAccount.customerName : undefined}
                  accountNumber={toAccount.accountNumber}
                  sortCode={toAccount.sortCode}
                  customClasses={cardCustomClasses}
                />
              </div>
            )}
          </WhiteBox>
        )}
    </section>
  );
};

export default MoveMoney;
