export const contentConfig = {
  latestWithdrawalTime: "9:30pm",
  minimumWithdrawAmount: 500,
  taxSaving: 20000,
  isaTransferDays: {
    from: "four",
    to: "five"
  },
  accessPeriodAfterClosure: 18,
  maxValueAmountToPaidISA: 20000,
  minValueAmountToPaidISA: 1
};
