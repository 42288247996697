import { useMutation } from "@tanstack/react-query";
import { UseCancelInstructionProps } from "hooks/useCancelMaturityInstruction/interfaces";
import { deleteMaturityInstruction } from "services/data/api";

const useCancelInstruction = ({ sourceAccountIdArg }: UseCancelInstructionProps) => {
  const sourceAccountId = sourceAccountIdArg ?? "";

  const {
    mutate: cancelInstruction,
    isError: isCancelInstructionError,
    error: cancelInstructionError,
    isSuccess: isCancelInstructionSuccess,
    isLoading: isCancelInstructionLoading
  } = useMutation({
    mutationFn: () => deleteMaturityInstruction(sourceAccountId)
  });

  return {
    cancelInstruction,
    isCancelInstructionError,
    cancelInstructionError,
    isCancelInstructionSuccess,
    isCancelInstructionLoading
  };
};

export default useCancelInstruction;
