import { IGlobalContextAction } from "types/interfaces";
import { GlobalContextActionType, GlobalContextType } from "types/types";

export function GlobalContextReducer(state: GlobalContextType, action: IGlobalContextAction) {
  switch (action.type) {
    case GlobalContextActionType.setSelectedProfile:
      return { ...state, selectedProfile: action.payload };
    case GlobalContextActionType.setAvailableProfiles:
      return { ...state, availableProfiles: action.payload };
    case GlobalContextActionType.setCustomerId:
      return { ...state, customerId: action.payload };
    case GlobalContextActionType.setHasAccounts:
      return { ...state, hasAccounts: action.payload };
    default:
      return state;
  }
}
