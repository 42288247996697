import strings from "assets/strings/strings.json";
import { ProductCategory } from "services/data/types";
import { GenericObject } from "types/interfaces";
import { formatBalance } from "utils";
import findInterestRate from "utils/findInterestRate";
import { IGetOutboundPaymentWarning } from "utils/getOutboundPaymentWarning/interfaces";

const getOutboundPaymentWarning = ({
  sourceCategory,
  destinationInternal,
  amount,
  sourceAvailableBalance,
  interestRates,
  currentRate
}: IGetOutboundPaymentWarning) => {
  let warning = "";

  if (
    sourceAvailableBalance != null &&
    (sourceCategory === ProductCategory.EASY_ACCESS || sourceCategory === ProductCategory.EASY_ACCESS_ISA) &&
    destinationInternal === false
  ) {
    let futureBalance = sourceAvailableBalance - amount;
    if (futureBalance < 0) {
      futureBalance = 0;
    }

    const interestRate = findInterestRate(interestRates, futureBalance);
    if (interestRate && interestRate.lowestInterestRate !== currentRate) {
      const bindings: GenericObject = {
        "{{lowerRateBound}}": formatBalance(interestRate.nextBalanceBandFrom),
        "{{outboundRate}}": interestRate.lowestInterestRate
      };

      warning = strings.moveMoney.outbondTransfer.lowBalanceWarning.replaceAll(
        /{{lowerRateBound}}|{{outboundRate}}/g,
        match => bindings[match]
      );
    }
  }

  return warning;
};

export default getOutboundPaymentWarning;
