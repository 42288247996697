import classnames from "classnames";
import { useReducer } from "react";
import { Outlet } from "react-router-dom";
import { Flex, Loader } from "@shawbrook/sds-component-library";

import strings from "assets/strings/strings.json";
import IdleModal from "components/IdleModal";
import FEATURE_FLAGS from "config/featureFlags";
import useNavigateToBankfastMigration from "hooks/useNavigateToBankfastMigration";
import usePageTrack from "hooks/usePageTrack";
import { useServiceTick } from "hooks/useServiceTick/useServiceTick";
import DefaultLayout from "layouts/DefaultLayout";
import ComingSoon from "pages/ComingSoon";
import "pages/Landing/Landing.module.scss";
import { initialReinvestmentState, IReinvestmentState, IReinvestmentStateDispatcher } from "state/reinvestmentState";

const MaturityInstructionLayout = () => {
  usePageTrack();
  useServiceTick();
  const { isCustomerLoading, errorOccured } = useNavigateToBankfastMigration();

  const [state, setState] = useReducer((data: IReinvestmentState, action: IReinvestmentStateDispatcher) => {
    const { accountId, partialData } = action;

    return {
      ...data,
      [accountId]: {
        ...data[accountId],
        ...partialData
      }
    };
  }, initialReinvestmentState);

  const { title } = strings.reinvestment;

  const renderReinvestments = () => {
    if (isCustomerLoading && !errorOccured) {
      return (
        <Flex justify="center" align="center" css={{ height: "100vh" }}>
          <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
        </Flex>
      );
    }

    if (FEATURE_FLAGS.hideReinvestments) {
      return (
        <>
          <IdleModal />
          <ComingSoon headingText={title} />
        </>
      );
    }

    return (
      <>
        <IdleModal />
        <Outlet context={{ state, setState }} />
      </>
    );
  };

  const renderLayout = () => {
    const cxLayout = classnames("layoutWrapper--landing", {
      "layoutWrapper--landing--shortest": !FEATURE_FLAGS.hideReinvestments
    });

    return <DefaultLayout className={cxLayout}>{renderReinvestments()}</DefaultLayout>;
  };

  return renderLayout();
};

export default MaturityInstructionLayout;
