import { FC } from "react";

import strings from "assets/strings/strings.json";
import SystemInfoCard from "components/SystemInfoCard/SystemInfoCard";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";

export const HighTraffic: FC = () => {
  const { title, description, action } = strings.highTraffic;

  const descriptionAdjusted = formatTextWithPhoneHours(description);

  return (
    <SystemInfoCard
      title={title}
      description={descriptionAdjusted}
      actionTitle={action}
      withLayout
      action={() => window.location.reload()}
    />
  );
};
