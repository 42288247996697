import { Box, Flex, Heading, Loader } from "@shawbrook/sds-component-library";
import { ArrowLeft } from "assets/images";
import strings from "assets/strings/strings.json";
import classnames from "classnames";
import IdleModal from "components/IdleModal";
import MessageThreadList from "components/MessageThreadList/MessageThreadList";
import FEATURE_FLAGS from "config/featureFlags";
import usePageTrack from "hooks/usePageTrack";
import DefaultLayout from "layouts/DefaultLayout";
import ComingSoon from "pages/ComingSoon";
import { HighTraffic } from "pages/HighTraffic/HighTraffic";

import { IconButton } from "components/IconButton/IconButton";
import { links } from "config/navLinks";
import useNavigateToBankfastMigration from "hooks/useNavigateToBankfastMigration";
import { useServiceTick } from "hooks/useServiceTick/useServiceTick";
import { FC, useReducer } from "react";
import { Outlet, ScrollRestoration, useLocation, useNavigate } from "react-router-dom";
import { IState, initialState } from "state/inboxState";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import styles from "./InboxLayout.module.scss";
import InlineStyles from "./InboxLayout.styles";

export const InboxLayout: FC = () => {
  usePageTrack();
  useServiceTick();
  const navigate = useNavigate();
  const { isCustomerLoading, errorOccured } = useNavigateToBankfastMigration();

  // A simple reducer function that just has one function.
  const [state, setState] = useReducer(
    (data: IState, partialData: Partial<IState>) => ({
      ...data,
      ...partialData
    }),
    initialState
  );
  const { pathname, state: locationState } = useLocation();
  // we are checking for the pathname in links.inboxNew instead of links.inbox because
  // that allows for us to show only accounts on messages-and-help/new for mobile;
  // on desktop, this will show both.
  const isNewMessagePath = pathname === links.inboxNew.link;
  const isThreadPath = pathname.startsWith(links.inboxThread.link.replace(":id", ""));

  const { title, comingSoonContent } = strings.inbox;

  const renderInbox = () => {
    if (FEATURE_FLAGS.hideMessages) {
      const comingSoonContentProcessed = formatTextWithPhoneHours(comingSoonContent);

      return (
        <>
          <IdleModal />
          <ComingSoon headingText={title} pageContent={comingSoonContentProcessed} />
        </>
      );
    }

    const shouldAllowBackNavigation = locationState ? locationState.allowBackNavigation : true;

    return (
      <div className={styles.inbox__wrapper}>
        <IdleModal />
        <Box as="header" css={InlineStyles.header}>
          {shouldAllowBackNavigation && (
            <IconButton onClick={() => navigate(-1)} icon={<ArrowLeft />} text={strings.general.back} />
          )}
          <Heading css={{ marginTop: shouldAllowBackNavigation ? "$0" : "$14" }} color="white">
            {title}
          </Heading>
        </Box>

        <section
          className={`${styles.page__content} ${
            isNewMessagePath || isThreadPath ? styles["page__content--mobile"] : ""
          }`}>
          <aside>
            <ScrollRestoration />
            <MessageThreadList context={{ state, setState }} />
          </aside>
          <section>
            <Outlet context={{ state, setState }} />
          </section>
        </section>
      </div>
    );
  };

  const renderLayout = () => {
    if (isCustomerLoading && !errorOccured) {
      return (
        <Flex justify="center" align="center" css={{ height: "100vh" }}>
          <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
        </Flex>
      );
    }

    if (state.isHighTraffic) {
      return <HighTraffic />;
    }

    const cxLayout = classnames("layoutWrapper--landing", {
      "layoutWrapper--landing--shortest": !FEATURE_FLAGS.hideMessages
    });

    return <DefaultLayout className={cxLayout}>{renderInbox()}</DefaultLayout>;
  };

  return renderLayout();
};

export default InboxLayout;
