import { Card, Heading, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import styles from "./TermsExplained.module.scss";

export type ITermsExplained = {
  className?: string;
};

export const TermsExplained = ({ className }: ITermsExplained) => {
  const {
    title,
    aer: { title: aerTitle, content: aerContent },
    gross: { title: grossTitle, content: grossContent }
  } = strings.termsExplained;
  return (
    <Card variant="filled" className={`${styles.card} ${className}`}>
      <Heading as="h4" level={3}>
        {title}
      </Heading>
      <div className={styles.card__definition}>
        <Space gap="2">
          <Text fontWeight="semiBold">{aerTitle}</Text>
        </Space>
        <Text>{aerContent}</Text>
      </div>
      <div className={styles.card__definition}>
        <Space gap="2">
          <Text fontWeight="semiBold">{grossTitle}</Text>
        </Space>
        <Text>{grossContent}</Text>
      </div>
    </Card>
  );
};
