const isMaintenance = () => {
  const maintenanceEnd = new Date(process.env.REACT_APP_MAINTENANCE_END as string);
  const currentTime = new Date().getTime();

  return (
    process.env.REACT_APP_MAINTENANCE_END &&
    maintenanceEnd.toString() !== "Invalid Date" &&
    maintenanceEnd.getTime() > currentTime
  );
};

export default isMaintenance;
