import { ReactElement, useReducer } from "react";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { Box } from "@shawbrook/sds-component-library";
import { Outlet } from "react-router-dom";

import usePageTrack from "hooks/usePageTrack";
import isNoStrictAuth from "utils/isNoStrictAuth";
import Header from "journeys/components/Header";
import ConditionalWrapper from "components/ConditionalWrapper";
import IdleModal from "components/IdleModal";
import { HighTraffic } from "pages/HighTraffic/HighTraffic";
import "./BankfastUserMigrationLayout.module.scss";

import { useServiceTick } from "hooks/useServiceTick/useServiceTick";
import { IBankfastUserMigrationState, initialState } from "state/bankfastUserMigrationState";
import MigrationFooter from "./migrationFooter";

export const BankfastUserMigrationLayout = () => {
  usePageTrack();
  useServiceTick();

  const [state, setState] = useReducer(
    (data: IBankfastUserMigrationState, partialData: Partial<IBankfastUserMigrationState>) => ({
      ...data,
      ...partialData
    }),
    initialState
  );

  const renderTemplate = (childrenArg: ReactElement) => {
    let wrapper: ReactElement | null = null;

    if (isNoStrictAuth()) {
      wrapper = childrenArg;
    } else {
      wrapper = <AuthenticatedTemplate>{childrenArg}</AuthenticatedTemplate>;
    }

    return wrapper;
  };

  const renderLayout = () => {
    if (state.isHighTraffic) {
      return <HighTraffic />;
    }

    return (
      <ConditionalWrapper wrapper={renderTemplate} condition>
        <Box className="bankfastUserMigrationLayoutWrapper">
          <IdleModal />
          <Header />
          <Outlet context={{ state, setState }} />
          <MigrationFooter />
        </Box>
      </ConditionalWrapper>
    );
  };

  return renderLayout();
};
