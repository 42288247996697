import { Space, Text } from "@shawbrook/sds-component-library";
import { FC } from "react";
import { ICustomerAddress } from "services/data/types";

const ProfileLineItem: FC<{ text: string }> = ({ text }) => (
  <Space gap="0">
    <Text as="span" font="silka">
      {text}
    </Text>
  </Space>
);

interface IProfileInfo {
  title: string;
  data: string | string[];
  lastBlock?: boolean;
}

export const ProfileInfo: FC<IProfileInfo> = ({ title, data, lastBlock = false }) => (
  <>
    <Space gap="1">
      <Text fontWeight="bold">{title}</Text>
    </Space>
    <Space gap={lastBlock ? 0 : 4}>
      {Array.isArray(data) ? data.map(x => <ProfileLineItem text={x} />) : <ProfileLineItem text={data} />}
    </Space>
  </>
);

interface IProfileInfoAddress {
  title: string;
  address?: ICustomerAddress;
  lastBlock?: boolean;
}
export const ProfileInfoAddress: FC<IProfileInfoAddress> = ({ title, address, lastBlock = false }) => (
  <ProfileInfo
    {...{ lastBlock, title }}
    data={[...(address?.lines || []), address?.city || "", address?.postalCode || "", address?.country || ""]}
  />
);
