/* eslint-disable react/no-unused-prop-types */
import { Heading, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import classnames from "classnames";
import { FC } from "react";
import styles from "./LinkedAccount.module.scss";
import { LinkedAccountData } from "./LinkedAccountData/LinkedAccountData";
import { LinkedAccountMissing } from "./LinkedAccountMissing/LinkedAccountMissing";
import { LinkedAccountUnverified } from "./LinkedAccountUnverified/LinkedAccountUnverified";

interface ILinkedAccount {
  bank: string;
  name: string;
  acNumber: string;
  sortCode: string;
  isLinkedAccountVerified?: boolean;
  hasTitle?: boolean;
  hasLongCard?: boolean;
  isLinkedAccountMissing?: boolean;
  customClasses?: string;
}

const LinkedAccount: FC<ILinkedAccount> = ({
  bank,
  name,
  acNumber,
  sortCode,
  hasTitle,
  hasLongCard,
  isLinkedAccountVerified,
  isLinkedAccountMissing = false,
  customClasses = ""
}) => {
  const { title } = strings.linkedAccountCard;

  const cx = classnames(`${styles.linkedAccount__container} ${customClasses}`, {
    [styles.linkedAccount__longCard]: hasLongCard
  });
  return (
    <section className={cx}>
      {hasTitle && (
        <Space gap="3">
          <Heading level="3" className={styles.linkedAccount__title}>
            {title}
          </Heading>
        </Space>
      )}
      {isLinkedAccountMissing && <LinkedAccountMissing />}
      {!isLinkedAccountMissing && !isLinkedAccountVerified && <LinkedAccountUnverified bank={bank} />}
      {!isLinkedAccountMissing && isLinkedAccountVerified && (
        <LinkedAccountData bank={bank} name={name} hasLongCard={hasLongCard} acNumber={acNumber} sortCode={sortCode} />
      )}
    </section>
  );
};
export default LinkedAccount;
