import { Text } from "@shawbrook/sds-component-library";
import { API_LIMITS } from "assets/strings/api";
import { ReactNode } from "react";
import rreplace from "react-string-replace";

import { DATES } from "assets/strings/formats";
import strings from "assets/strings/strings.json";
import { convertToISOLocalDate, formatBalance, getHour, getMinutes } from "utils";

const getTransferSuccessMessage = (amount: number, accountTitle: string, internal: boolean, date?: string) => {
  const { p1, p2 } = strings.moveMoney.modals.success;
  let p1Formatted: Iterable<ReactNode> | ReactNode | null = null;
  let p2Formatted: Iterable<ReactNode> | ReactNode | null = null;
  const p1Margin = internal ? 0 : "$12";

  const accountSignature = internal ? "" : ` ${p1.linkedAccount}`;
  p1Formatted = rreplace(p1.message, "{{amount}}", (_, i) => (
    <Text as="span" font="silka" textStyle="medium" css={{ fontWeight: "$medium" }} key={`amount${i}`}>
      {formatBalance(amount)}
    </Text>
  ));
  p1Formatted = rreplace(p1Formatted as string, "{{title}}", (_, i) => (
    <Text as="span" font="silka" textStyle="medium" css={{ fontWeight: "$medium" }} key={`title${i}`}>
      {`${accountTitle}${accountSignature}`}
    </Text>
  ));

  if (!internal && date) {
    const localDate = convertToISOLocalDate(date, DATES.isoWithLongTimezone);
    const localDateObj = new Date(localDate);
    if (localDateObj.toString() !== "Invalid Date") {
      const hour = localStorage.getItem("outboundPaymentHour")
        ? Number(localStorage.getItem("outboundPaymentHour"))
        : Number(getHour(localDateObj));
      const minutes = localStorage.getItem("outboundPaymentMinute")
        ? Number(localStorage.getItem("outboundPaymentMinute"))
        : Number(getMinutes(localDateObj));
      if (!Number.isNaN(hour) && !Number.isNaN(minutes)) {
        const { before, after } = p2;
        let timeSignature = before;

        if (
          hour > API_LIMITS.transferDeadlineHour ||
          (hour === API_LIMITS.transferDeadlineHour && minutes >= API_LIMITS.transferDeadlineMinute)
        ) {
          timeSignature = after;
        }

        p2Formatted = p2.message.replace("{{transferTime}}", timeSignature);
      }
    }
  }

  p1Formatted = (
    <Text textStyle="medium" font="silka" css={{ marginBottom: p1Margin }}>
      {p1Formatted}
    </Text>
  );

  if (p2Formatted) {
    p2Formatted = (
      <Text textStyle="medium" font="silka">
        {p2Formatted}
      </Text>
    );
  }

  return (
    <>
      {p1Formatted}
      {p2Formatted}
    </>
  );
};

export default getTransferSuccessMessage;
