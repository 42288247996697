import { IHasLinkedAccountsMissed } from "hooks/useHasLinkedAccountsMissed/interfaces";
import hasLinkedAccountsMissed from "utils/hasLinkedAccountsMissed";

const useHasLinkedAccountsMissed = ({ accountDetails, accounts, accountResolved }: IHasLinkedAccountsMissed) => {
  let isLinkedAccountMissed = false;

  if (accountResolved) {
    if (accounts) {
      isLinkedAccountMissed = hasLinkedAccountsMissed(accounts);
    }
    if (accountDetails) {
      isLinkedAccountMissed = !accountDetails.linkedAccount || !accountDetails.linkedAccount.accountNumber;
    }
  }

  return { isLinkedAccountMissed };
};

export default useHasLinkedAccountsMissed;
