import { IBankfastUserMigrationAppContext } from "state/bankfastUserMigrationState";
import { IInboxContext } from "state/inboxState";
import { IMainContext } from "state/mainState";
import { IMoveMoneyContext } from "state/moveMoneyState";

export type Contexts = IMoveMoneyContext | IMainContext | IInboxContext | IBankfastUserMigrationAppContext;

export type Roles = "link" | "button" | "";

export type CheckedState = boolean | "indeterminate";

export type HeadingLevel = 1 | 2 | 3 | 4 | 5 | 6;

export type HeadingType = "h1" | "h2" | "h3" | "h4" | "h5" | "h6";

export type FontWeight = "light" | "normal" | "medium" | "semiBold" | "bold";

export type ScrollRestoration = "auto" | "manual";

export const QUERY_STATUS = {
  error: "error",
  success: "success",
  loading: "loading"
} as const;

export type QueryStatus = typeof QUERY_STATUS[keyof typeof QUERY_STATUS];

export const FETCH_STATUS = {
  fetching: "fetching",
  paused: "paused",
  idle: "idle"
} as const;

export type FetchStatus = typeof FETCH_STATUS[keyof typeof FETCH_STATUS];

export const ALERT_VARIANTS = {
  success: "success",
  error: "error",
  warning: "warning",
  info: "info"
} as const;

export type AlertVariants = typeof ALERT_VARIANTS[keyof typeof ALERT_VARIANTS];

export const ALERT_ACTION_MODE = {
  sendMessage: "sendMessage",
  compareAccounts: "compareSavingsAccounts",
  verifyLinkedAccount: "verifyLinkedAccount",
  confirmLinkedAccount: "confirmLinkedAccount"
} as const;

export type AlertActionMode = typeof ALERT_ACTION_MODE[keyof typeof ALERT_ACTION_MODE];

export const LOGGED_OUT_TYPE = {
  manual: "manual",
  auto: "auto"
} as const;

export type LoggedOutType = typeof LOGGED_OUT_TYPE[keyof typeof LOGGED_OUT_TYPE];

export type PostSubmitPopupType =
  | "waiting"
  | "error"
  | "showFundingModal"
  | "showNearlyThereModal"
  | "showElectronicModal"
  | "showManualModal";

export interface IAccountDetails {
  name: string;
  accountNumber: string;
  sortCode: string;
}

export interface IAccountDetailItem {
  text: string;
  variable: string;
  replacement: string;
}

export type Instructions = {
  key: string;
  p: string;
};

export interface IModalInstructions {
  message: string;
  isVerified?: boolean;
  instructions: Instructions[];
  subMessage?: string;
  subInstructions?: Instructions[];
}

export enum ProfileType {
  personal,
  business
}

export type Profile = {
  profileId: string;
  profileName: string;
  profileType: ProfileType;
};

export type GlobalContextType = {
  selectedProfile?: Profile;
  availableProfiles: Profile[];
  customerId: string;
  hasAccounts: boolean;
};

export enum GlobalContextActionType {
  setSelectedProfile = "setSelectedProfile",
  setAvailableProfiles = "setAvailableProfiles",
  setCustomerId = "setCustomerId",
  setHasAccounts = "setHasAccounts"
}
