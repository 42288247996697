import strings from "assets/strings/strings.json";
import { FC } from "react";

import RequestInProgressModal from "components/RequestInProgressModal";
import WarningModal from "components/WarningModal/WarningModal";
import { links } from "config/navLinks";
import { useNavigate } from "react-router-dom";
import { PostSubmitPopupType } from "types/types";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import { FundingModal, IHasEa, IPostSubmitModal, NearlyThereModal } from "./PostSubmitModals";
import { IIsVerified } from "./PostSubmitModals/interface";
import { SuccessModal } from "./PostSubmitModals/SuccessModal";

const Waiting: FC = () => <RequestInProgressModal open infoText={strings.newAccount.inProgress.infoText} />;

const Error: FC = () => {
  const navigate = useNavigate();
  const { title, text: originalText, accept, decline } = strings.newAccount.errorOpeningAccount;
  const text = formatTextWithPhoneHours(originalText);
  const handleSendMessage = () => {
    navigate(links.inbox.link);
  };
  const handleBackToHome = () => {
    navigate(links.landing.link);
  };

  return (
    <WarningModal
      handleClose={handleBackToHome}
      handleConfirm={handleSendMessage}
      open
      {...{ title, text, accept, decline }}
    />
  );
};

interface IPostSubmitPopupType {
  type: PostSubmitPopupType;
}

export type PostSubmitPopupProps = IPostSubmitPopupType & IPostSubmitModal & IHasEa & IIsVerified;

const PostSubmitPopup: FC<PostSubmitPopupProps> = ({
  type,
  name,
  linkedAccountNumber,
  newAccountId,
  accountDetails,
  hasEa,
  isVerified
}) => {
  if (type === "waiting") return <Waiting />;
  if (type === "error") return <Error />;
  if (type === "showFundingModal")
    return <FundingModal {...{ name, linkedAccountNumber, newAccountId, accountDetails, hasEa }} />;

  if (type === "showElectronicModal")
    return (
      <SuccessModal {...{ name, linkedAccountNumber, newAccountId, accountDetails, isVerified, isElectronic: true }} />
    );

  if (type === "showManualModal")
    return (
      <SuccessModal {...{ name, linkedAccountNumber, newAccountId, accountDetails, isVerified, isElectronic: false }} />
    );

  return <NearlyThereModal {...{ name, newAccountId, hasEa }} />;
};

export { PostSubmitPopup };
