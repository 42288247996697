import { Text } from "@shawbrook/sds-component-library";
import { FC } from "react";
import { Link } from "react-router-dom";
import styles from "./IconLink.module.scss";

interface IIconLinkProps {
  text?: string;
  to: string;
  icon?: any;
}

const IconLink: FC<IIconLinkProps> = ({ text, icon, to }) => (
  <Link className={styles.iconLink__container} to={to}>
    {icon}
    {text && (
      <Text color="white" font="silka" fontWeight="medium" as="span">
        {text}
      </Text>
    )}
  </Link>
);
export default IconLink;
