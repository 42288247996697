import { useMutation } from "@tanstack/react-query";
import { UseSubmitInstruction } from "hooks/useSubmitInstruction/interfaces";
import { submitInstruction } from "services/data/api";

const useSubmitInstruction = ({ sourceAccountId, instructions }: UseSubmitInstruction) => {
  const {
    mutate: postInstruction,
    isError: isSubmitInstructionError,
    error: submitInstructionError,
    isSuccess: isSubmitInstructionSuccess,
    isLoading: isSubmitInstructionLoading,
    data: submitInstructionData
  } = useMutation({
    mutationFn: () => submitInstruction(sourceAccountId, { instructions, instructionReason: "MATURITY" })
  });

  return {
    postInstruction,
    isSubmitInstructionError,
    submitInstructionError,
    isSubmitInstructionSuccess,
    isSubmitInstructionLoading,
    submitInstructionData
  };
};

export default useSubmitInstruction;
