import { ChangeEmail, Email, EmailOTP, GDPRAndA11y } from ".";

/* eslint-disable */
// This is required because of warnings about missing keys in the pages array, it will be fixed when we have the full form structure
export const pages = [<Email />, <EmailOTP />, <ChangeEmail />, <GDPRAndA11y />];

export const BankfastUserMigrationFormStructure = {
  steps: pages.length,
  pages
};

export default BankfastUserMigrationFormStructure;
