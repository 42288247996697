import { useEffect } from "react";
import { IUseSrollToTop } from "hooks/useScrollToTop/interfaces";

const useScrollToTop = ({ condition = true, scrollRestoration = "manual" }: IUseSrollToTop = {}) => {
  useEffect(() => {
    if ("scrollRestoration" in window.history) {
      window.history.scrollRestoration = scrollRestoration;
    }
  }, [scrollRestoration]);

  useEffect(() => {
    if (condition) {
      try {
        window.scroll({ top: 0, left: 0 });
      } catch (e) {
        window.scrollTo(0, 0);
      }
    }
  }, [condition]);
};

export default useScrollToTop;
