export enum QueryKeys {
  ACCOUNT_SUMMARY = "accountSummary",
  ACCOUNT_DETAIL = "accountDetail",
  AVAILABLE_PRODUCTS = "availableProducts",
  NEW_ACCOUNT_PRODUCTS = "newAccountProducts",
  TRANSFER_HISTORY = "transferHistory",
  TRANSFER_DETAIL = "transferDetail",
  MATURITY_INSTRUCTION = "maturityInstruction",
  CUSTOMER_DATA = "customerData",
  MESSAGES = "messages",
  DOCUMENTS = "documents",
  DOCUMENTS_STATUS = "documentsStatus",
  THREADS = "threads",
  ELIGIBILITY_DETAILS = "eligibilityDetails",
  LINKED_ACCOUNTS = "linkedAccounts",
  CUSTOMER_PROFILES = "customerProfiles",
  PROVIDERS_DATA = "providersData"
}
