import { useState, useEffect, useMemo, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import getAccessToken from "utils/getAccessToken";
import tokenTimerFunction from "workers/tokenTimer";
import workerImporter from "utils/workerImporter";
import { IUseAccessToken } from "hooks/useAccessToken/interfaces";
import { TOKEN_TIMER_TYPE, ITokenWorker } from "workers/tokenTimer/interfaces";
import { GenericObject } from "types/interfaces";

const useAccessToken = ({ firstTokenData }: IUseAccessToken) => {
  const { instance, accounts } = useMsal();
  const isLoggedIn = accounts?.length > 0;
  const [exp, setExp] = useState(accounts?.[0]?.idTokenClaims?.exp ? accounts[0].idTokenClaims.exp * 1000 : 0);
  const [msToRefresh, setMsToRefresh] = useState<number | null>(null);

  const tokenTimer: Worker = useMemo(() => workerImporter(tokenTimerFunction), []);

  const setExpFromClaims = (idTokenClaims: object) => {
    const currentTokenClaims = idTokenClaims as GenericObject;
    setExp(currentTokenClaims?.exp ? currentTokenClaims.exp * 1000 : 0);
  };

  const onRefresh = useCallback(async () => {
    const { idTokenClaims } = await getAccessToken(instance);
    setExpFromClaims(idTokenClaims);
  }, [instance]);

  useEffect(() => {
    if (window.Worker) {
      tokenTimer.addEventListener("message", (e: MessageEvent<ITokenWorker>) => {
        const { type } = e.data;

        switch (type) {
          case TOKEN_TIMER_TYPE.refreshToken:
            onRefresh();
            break;
          default:
            break;
        }
      });
    }
  }, [tokenTimer, onRefresh]);

  useEffect(() => {
    if (window.Worker) {
      tokenTimer.postMessage({
        type: TOKEN_TIMER_TYPE.start,
        exp,
        msToRefresh,
        isLoggedIn
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [msToRefresh, tokenTimer]);

  useEffect(() => {
    if (exp) {
      setMsToRefresh(exp - new Date().getTime());
    }
  }, [exp]);

  useEffect(() => {
    if (firstTokenData) {
      const { idTokenClaims } = firstTokenData;
      setExpFromClaims(idTokenClaims);
    }
  }, [firstTokenData]);
};

export default useAccessToken;
