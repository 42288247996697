export default {
  header: {
    marginLeft: "20px",
    marginTop: "18px",

    "@md": {
      mt: "0",
      pb: "12px",
      marginLeft: "0px"
    },

    bg: "$shawbrookCharcoal",
    pos: "relative",
    "&::before, &::after": {
      content: "",
      bg: "$shawbrookCharcoal",
      width: "2000px",
      height: "100%",
      d: "block",
      pos: "absolute",
      top: 0,
      bottom: 0,
      right: "100%"
    },
    "&::after": {
      left: "100%"
    }
  }
};
