import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Heading, Text, LeftArrow, Card } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { IconButton } from "components/IconButton/IconButton";
import styles from "./ComingSoon.module.scss";

interface IComingSoon {
  headingText: string;
  pageContent?: string;
}

export const ComingSoon: FC<IComingSoon> = ({ headingText, pageContent }) => {
  const navigate = useNavigate();

  return (
    <div className="page__wrapper ">
      <div className="page__container">
        <header>
          <IconButton onClick={() => navigate(-1)} icon={<LeftArrow />} text={strings.general.back} />
          <Heading color="white">{headingText}</Heading>
        </header>
        <div className="page__content">
          <Text color="white">{strings.general.comingSoon}</Text>
        </div>
        {pageContent && (
          <section className={styles.page__content}>
            <Card css={{ width: "100%", height: "fit-content" }}>
              <Text fontWeight="semiBold" font="silka" color="grey">
                {pageContent}
              </Text>
            </Card>
          </section>
        )}
      </div>
    </div>
  );
};

export default ComingSoon;
