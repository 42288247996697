import { Button, Card, Container, Flex, Heading, Loader, Space, Text } from "@shawbrook/sds-component-library";
import { FC, Fragment } from "react";

import { ArrowDown } from "assets/images/index";
import { API_LIMITS } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import inlineStyles from "components/TransactionHistory/TransactionHistory.style";
import TransactionHistoryItem from "components/TransactionHistoryItem";
import { FETCH_STATUS, QUERY_STATUS } from "types/types";
import formatTransfers from "utils/formatTransfers";
import getPrevTransferInfo from "utils/getPrevTransferInfo";
import { ITransactionHistoryProps } from "./interfaces";
import styles from "./TransactionHistory.module.scss";

export const TransactionHistory: FC<ITransactionHistoryProps> = ({
  title,
  targetAccountId,
  transactions,
  nextPageAvailable,
  offset,
  status,
  fetchStatus,
  loadNextPage
}) => {
  const formattedTransfers = formatTransfers(targetAccountId, transactions);

  const handleShowMore = () => {
    loadNextPage();
  };

  const renderTransactions = () => (
    <Card variant="white" css={inlineStyles.card} className={styles["transaction-history__container"]}>
      <Heading css={inlineStyles.heading} level="2">
        {title}
      </Heading>
      <section>
        {!formattedTransfers.length && offset === 0 ? (
          <Container
            space="10"
            className={styles["transaction-history__wrapper-empty"]}
            aria-label="Empty Transaction History Block">
            <Flex justify="center" align="center">
              <Text fontWeight="bold">{strings.accountInformation.emptyHistory}</Text>
            </Flex>
          </Container>
        ) : (
          <>
            <Container space="8" css={inlineStyles.container} aria-label="Transaction History Block">
              {formattedTransfers.map((transfer, i) => {
                const { startOfMonth, prevTransfer } = getPrevTransferInfo(formattedTransfers, transfer, i);

                return (
                  <Fragment key={transfer.id}>
                    {startOfMonth && (
                      <Flex
                        justify="start"
                        className={
                          prevTransfer
                            ? styles["transaction-history__month"]
                            : styles["transaction-history__month--first"]
                        }>
                        <Text as="span" fontWeight="bold">
                          {transfer.dateInitiatedFormattedShort}
                        </Text>
                      </Flex>
                    )}
                    <TransactionHistoryItem
                      date={transfer.dateInitiatedFormatted}
                      details={transfer.transferDescription}
                      amount={transfer.amount.amount}
                      tagText={transfer.tagText}
                      isIncomingTransfer={transfer.isIncomingTransfer}
                      amountPrefix={transfer.amountPrefix}
                      reference={transfer.reference}
                      status={transfer.status.value}
                      isStrikedThrough={transfer.isStrikedThrough}
                    />
                  </Fragment>
                );
              })}
            </Container>
            {offset > 0 && fetchStatus === FETCH_STATUS.fetching && (
              <Loader className={styles["transaction-history__loading"]} showRing size="small" spacing="small" />
            )}
            {nextPageAvailable && status === QUERY_STATUS.success && fetchStatus !== FETCH_STATUS.fetching && (
              <Space gap="1-5" className={styles["transaction-history__buttonWrapper"]}>
                <Button
                  variant="tertiary"
                  onClick={handleShowMore}
                  role="link"
                  css={{ width: "100%", lineHeight: "$tight" }}
                  className={styles["transaction-history__buttonWrapper__button"]}>
                  <span>
                    {strings.accountInformation.transactionHistoryButton.replace(
                      "{{limit}}",
                      API_LIMITS.PAYMENTS_DEFAULT_LIMIT.toString()
                    )}
                  </span>
                  <ArrowDown />
                </Button>
              </Space>
            )}
          </>
        )}
      </section>
    </Card>
  );

  return renderTransactions();
};

export default TransactionHistory;
