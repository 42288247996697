import { CURRENCIES } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import { useNewAccountContext } from "state/newAccountState";
import { formatBalance } from "utils";
import { WrappedCurrencyInput } from "../InputComponents/WrappedCurrencyInput";

export const PartialTransferNoTopped = () => {
  const {
    amountInput: {
      label,
      validations: { maximumError, minimumError }
    }
  } = strings.newAccount.transferAmount;
  const {
    state: { selectedProductSet, accountType }
  } = useNewAccountContext();

  const minValue = 1;
  const { accountTypes } = strings.newAccount.accountType;
  const { soleAccount } = accountTypes;
  const maxValue =
    (accountType === soleAccount.id ? selectedProductSet?.soleMaxBalance : selectedProductSet?.jointMaxBalance) || 0;

  const rules = {
    required: minimumError.replace("{{MIN_AMOUNT}}", `${formatBalance(minValue)}`),
    min: {
      value: minValue,
      message: minimumError.replace("{{MIN_AMOUNT}}", `${formatBalance(minValue)}`)
    },
    max: {
      value: maxValue,
      message: maximumError.replace("{{MAX_BALANCE}}", `${formatBalance(maxValue)}`)
    }
  };

  return <WrappedCurrencyInput label={{ main: label }} name="amountPaidToISA" prefix={CURRENCIES.GBP} rules={rules} />;
};
