import { Button, Flex } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import Modal from "components/Modal";
import { FC } from "react";
import { Location } from "react-router-dom";
import ReactRouterPrompt from "react-router-prompt";
import styles from "../../MoveMoney.module.scss";

interface ICloseModal {
  show: boolean;
  pathBoundaries: string;
  isReadyToShow: boolean;
}

export const CloseModal: FC<ICloseModal> = ({ show, pathBoundaries, isReadyToShow }) => {
  const checkDirty = ({ nextLocation }: { nextLocation: Location }) => {
    const nextPath = nextLocation.pathname;
    return show && isReadyToShow && !nextPath.includes(pathBoundaries);
  };

  return (
    <ReactRouterPrompt when={checkDirty}>
      {({ isActive, onConfirm, onCancel }) =>
        isActive && (
          <Modal title={strings.moveMoney.modals.close.confirm}>
            <Flex gap="2" className={styles.modalButtonList}>
              <Button variant="secondary" onClick={onCancel}>
                {strings.moveMoney.modals.close.no}
              </Button>
              <Button onClick={onConfirm}>{strings.moveMoney.modals.close.yes}</Button>
            </Flex>
          </Modal>
        )
      }
    </ReactRouterPrompt>
  );
};
