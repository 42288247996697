import useSubmitThread from "hooks/useSubmitThread";
import { useEffect, useState } from "react";
import { CreateThreadRequest } from "services/data/types";
import { UseCloseAccountProps } from "./interfaces";

export const useCloseAccount = ({ accountId, subject, messageContent }: UseCloseAccountProps) => {
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showFailureModal, setShowFailureModal] = useState(false);

  const submitThreadContent: CreateThreadRequest = {
    sender: "create-thread",
    content: messageContent,
    subject,
    accountId
  };

  const { postThread, isSubmitThreadSuccess, isSubmitThreadLoading, isSubmitThreadError } =
    useSubmitThread(submitThreadContent);

  useEffect(() => {
    if (isSubmitThreadSuccess) {
      setShowSuccessModal(true);
    } else if (isSubmitThreadError) {
      setShowFailureModal(true);
    }
  }, [isSubmitThreadSuccess, isSubmitThreadError]);

  return {
    postThread,
    isSubmitThreadLoading,
    isSubmitThreadError,
    isSubmitThreadSuccess,
    showSuccessModal,
    showFailureModal
  };
};
