import { useMutation } from "@tanstack/react-query";
import { submitThread } from "services/data/api";
import { CreateThreadRequest } from "services/data/types";

const useSubmitThread = (content: CreateThreadRequest) => {
  const {
    mutate: postThread,
    isError: isSubmitThreadError,
    error: submitThreadError,
    isSuccess: isSubmitThreadSuccess,
    isLoading: isSubmitThreadLoading,
    data: submitThreadData
  } = useMutation({
    mutationFn: () => submitThread(content)
  });

  return {
    postThread,
    isSubmitThreadError,
    submitThreadError,
    isSubmitThreadSuccess,
    isSubmitThreadLoading,
    submitThreadData
  };
};

export default useSubmitThread;
