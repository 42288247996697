import {
  AccountStatus,
  AccountType,
  ILinkedAccount,
  InterestRate,
  ProductCategory,
  ProductType
} from "services/data/types";

import { Roles } from "types/types";

export enum CardSection {
  LANDING = "landing",
  ACCOUNT = "account",
  MOVE_MONEY_FROM = "moveMoneyFrom",
  MOVE_MONEY_TO = "moveMoneyTo"
}
export interface ICardProps {
  accountId?: string;
  title?: string;
  status?: AccountStatus;
  issueNumber?: string;
  balance?: number;
  availableBalance?: number;
  maxBalance?: number;
  interestRates?: Array<InterestRate>;
  rate?: number;
  endOfTerm?: string;
  endOfTermForNonDisplay?: string;
  fromISA?: boolean;
  noticePeriodDays?: number;
  category?: ProductCategory;
  openToFunding?: boolean;
  openToFundingReason?: string;
  withdrawable?: boolean;
  outboundWithdrawable?: boolean;
  withdrawableReason?: string;
  fromAccountWithdrawable?: boolean;
  fromAccountWithdrawableReason?: string;
  section?: CardSection;
  transfer?: boolean;
  viewAccount?: boolean;
  frequency?: string;
  accountNumber: string;
  bankName?: string;
  personName?: string;
  sortCode: string;
  single?: boolean;
  accountDetails?: boolean;
  greyBox?: boolean;
  blueBorder?: boolean;
  customClasses?: string;
  onClickHandler?: () => void;
  role?: Roles;
  preMaturing?: boolean;
  maturityInstructionsSet?: boolean;
  linkedAccount?: ILinkedAccount;
  accountType?: AccountType;
  productType?: ProductType;
  unauthorisedAccountCredit?: boolean;
}
