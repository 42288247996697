import { Flex, Text } from "@shawbrook/sds-component-library";
import { FC } from "react";

import { FscsLogo } from "assets/images";
import strings from "assets/strings/strings.json";
import styles from "./FooterShort.module.scss";
import inlineStyles from "./FooterShort.styles";

const FooterShort: FC = () => {
  const year = new Date().getFullYear();

  return (
    <footer className={styles.footer}>
      <Text css={{ mt: "auto", mb: "0" }}>{strings.footerShort.text.replace("{{year}}", `${year}`)}</Text>
      <Flex css={{ alignItems: "center", mb: "0.625rem" }}>
        <FscsLogo />
        <Text css={inlineStyles.fscs}>{strings.footerShort.fscs}</Text>
      </Flex>
    </footer>
  );
};

export default FooterShort;
