import { SESSION_STORAGE_KEY } from "assets/strings/api";
import { jwtDecode } from "jwt-decode";
import { TokenPayload } from "./types";

export const getCustomerId = () => {
  if (process.env.REACT_APP_NO_STRICT_AUTH === "true") {
    return localStorage.getItem("savingsCustomerId") || "";
  }
  const token = sessionStorage.getItem(SESSION_STORAGE_KEY.ACCESS_TOKEN);
  if (!token) return "";
  const decoded = jwtDecode(token) as TokenPayload;
  return decoded.savingsCustomerId;
};
