import { CURRENCIES } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import { RadioGroupInputForForm } from "components/RadioGroupInputForForm/RadioGroupInputForForm";
import { contentConfig } from "config/contentConfig";
import { formatBalance } from "utils";
import { WrappedCurrencyInput } from "../InputComponents/WrappedCurrencyInput";

export const FullTransferYesTopped = () => {
  const { amountPaidToISA, isaContainsSavingFromPY } = strings.newAccount.transferAmount;
  const { minValueAmountToPaidISA, maxValueAmountToPaidISA } = contentConfig;

  const rulesAmountPaidToISA = {
    required: amountPaidToISA.validations.minimumError.replace(
      "{{MIN_AMOUNT}}",
      `${formatBalance(minValueAmountToPaidISA)}`
    ),
    min: {
      value: minValueAmountToPaidISA,
      message: amountPaidToISA.validations.minimumError.replace(
        "{{MIN_AMOUNT}}",
        `${formatBalance(minValueAmountToPaidISA)}`
      )
    },
    max: {
      value: maxValueAmountToPaidISA,
      message: amountPaidToISA.validations.maximumError.replace(
        "{{MAX_AMOUNT}}",
        `${formatBalance(maxValueAmountToPaidISA)}`
      )
    }
  };

  return (
    <>
      <WrappedCurrencyInput
        label={{ main: amountPaidToISA.label, sub: amountPaidToISA.subLabel }}
        name="amountPaidToISA"
        prefix={CURRENCIES.GBP}
        rules={rulesAmountPaidToISA}
      />
      <RadioGroupInputForForm
        label={{ main: isaContainsSavingFromPY.label }}
        name="isaContainsSavingFromPY"
        options={isaContainsSavingFromPY.options}
        rules={{ required: true }}
      />
    </>
  );
};
