import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import { IUseAmountToReinvest } from "hooks/useAmountToReinvest/interfaces";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Instruction, InstructionType } from "services/data/types";
import { useReinvestmentContext } from "state/reinvestmentState";
import getReinvestmentAccountData from "utils/getReinvestmentAccountData";
import getSwitchInstructionType from "utils/getSwitchInstructionType";

const useAmountToReinvest = ({
  accountId,
  setAmountToReinvest,
  setAmountToWithdraw,
  amountToReinvest,
  amountToWithdraw
}: IUseAmountToReinvest) => {
  const { state, setState } = useReinvestmentContext();
  const navigate = useNavigate();
  const { accountDetailData } = useAccount({ accountId });
  const [showWarningModel, setShowWarningModel] = useState<boolean | null>(null);
  const [switchInstructionType, setSwitchInstructionType] = useState<InstructionType | undefined>();

  const productDisplayName = state[accountId]?.selectedProductSetName || "";
  const productIssueNumber = state[accountId]?.selectedProductSet?.issueNumber
    ? `${strings.general.issueNumber} ${state[accountId]?.selectedProductSet?.issueNumber}`
    : "";
  const {
    accountDisplayName,
    accountType,
    accountIssueNumberText: accountIssueNumber,
    accountBalance
  } = getReinvestmentAccountData({ accountDetailData, state, accountId });

  const productMaxBalance =
    accountType === "Single"
      ? state[accountId]?.selectedProductSet?.soleMaxBalance
      : state[accountId]?.selectedProductSet?.jointMaxBalance;

  const { title } = strings.reinvestment;
  const {
    question,
    description,
    amountToReinvestLabel,
    amountToWithdrawLabel,
    maxBalanceError,
    switchModal: {
      title: { content: switchTitleContent, action: switchTitleAction },
      text: switchTextObject,
      accept: { content: switchAcceptContent, action: switchAcceptAction },
      decline: { content: switchDeclineContent, action: switchDeclineAction }
    }
  } = strings.amountToReinvest;
  const { continueButton } = strings.general;
  const switchTitle = switchTitleContent.replace(
    "{{action}}",
    switchInstructionType ? switchTitleAction[switchInstructionType] : ""
  );
  const switchText = switchInstructionType ? switchTextObject[switchInstructionType] : "";
  const switchAccept = switchAcceptContent.replace(
    "{{action}}",
    switchInstructionType ? switchAcceptAction[switchInstructionType] : ""
  );
  const switchDecline = switchDeclineContent.replace(
    "{{action}}",
    switchInstructionType ? switchDeclineAction[switchInstructionType] : ""
  );

  useEffect(() => {
    if (typeof accountId !== "string" || typeof state[accountId]?.selectedProductSetName === "undefined") {
      navigate(links.selectAccount.link.replace(":id", accountId));
    }
  }, [state, accountId, navigate]);

  useEffect(() => {
    if (switchInstructionType) {
      setShowWarningModel(true);
    }
  }, [switchInstructionType]);

  useEffect(() => {
    if (showWarningModel === false) {
      setAmountToReinvest("");
      setAmountToWithdraw("");
    }
  }, [showWarningModel, setAmountToReinvest, setAmountToWithdraw]);

  const isMaxBalanceError = parseFloat(amountToReinvest || "0") > (productMaxBalance || 0);

  const shouldDisableContinue =
    (!amountToReinvest && !amountToWithdraw) ||
    (parseFloat(amountToReinvest) === 0 && parseFloat(amountToWithdraw) === 0) ||
    isMaxBalanceError;

  const handleEdit = () => {
    navigate(links.selectAccount.link.replace(":id", accountId));
  };

  const handleContinue = () => {
    const switchInstruction = getSwitchInstructionType(amountToReinvest, amountToWithdraw, accountBalance);
    setSwitchInstructionType(switchInstruction);

    if (!switchInstruction) {
      setState({
        accountId,
        partialData: {
          instructions: [
            {
              type: InstructionType.REINVEST_NEW,
              useRemainingBalance: false,
              amount: {
                amount: parseFloat(amountToReinvest || "0"),
                currency: "GBP"
              },
              priority: 1
            },
            {
              type: InstructionType.WITHDRAW,
              useRemainingBalance: true,
              amount: {
                amount: parseFloat(amountToWithdraw || "0"),
                currency: "GBP"
              },
              priority: 2
            }
          ]
        }
      });
      navigate(links.chooseInterest.link.replace(":id", accountId));
    }
  };

  const handleCloseWarningModal = () => {
    setSwitchInstructionType(undefined);
    setShowWarningModel(false);
  };

  const handleFlowSwitch = () => {
    let instructions: Array<Instruction> = [];
    let navigateUrl = "";

    if (switchInstructionType === InstructionType.REINVEST_NEW) {
      instructions = [{ type: InstructionType.REINVEST_NEW, useRemainingBalance: true, priority: 1 }];
      navigateUrl = links.chooseInterest.link.replace(":id", accountId);
    } else if (switchInstructionType === InstructionType.WITHDRAW) {
      instructions = [{ type: InstructionType.WITHDRAW, useRemainingBalance: true, priority: 1 }];
      navigateUrl = links.withdrawFull.link.replace(":id", accountId);
    }

    setState({
      accountId,
      partialData: {
        instructions
      }
    });
    setShowWarningModel(null);
    navigate(navigateUrl);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  return {
    productDisplayName,
    productIssueNumber,
    accountDisplayName,
    accountIssueNumber,
    accountBalance,
    productMaxBalance,
    title,
    question,
    description,
    amountToReinvestLabel,
    amountToWithdrawLabel,
    maxBalanceError,
    continueButton,
    switchTitle,
    switchText,
    switchAccept,
    switchDecline,
    handleEdit,
    handleContinue,
    handleCloseWarningModal,
    handleFlowSwitch,
    handleBackButton,
    isMaxBalanceError,
    shouldDisableContinue,
    showWarningModel
  };
};

export default useAmountToReinvest;
