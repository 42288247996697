import { Heading, TabsResponsive } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { CompareAccountsTab } from "components/CompareAccountsTab/CompareAccountsTab";
import { ErrorModal } from "components/ErrorModal";
import { LoadingSection } from "components/LoadingSection/LoadingSection";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { TermsExplained } from "components/TermsExplained/TermsExplained";
import { links } from "config/navLinks";
import useNewAccountProducts from "hooks/useNewAccountProducts/useNewAccountProducts";
import useSrollToTop from "hooks/useScrollToTop";
import { useEffect, useState } from "react";
import styles from "./CompareAccounts.module.scss";
import { formatNewAccountProducts, generateNewAccountTabs, NewAccountTab, TABS_VALUES } from "./tabs";

export const CompareAccounts = () => {
  useSrollToTop();
  const { availableProductsData, isAvailableProductsLoading, isAvailableProductsError } = useNewAccountProducts();
  const [tabs, setTabs] = useState<NewAccountTab[]>();

  const { title, description, tabsSublabel } = strings.newAccount.compareAccounts;

  useEffect(() => {
    if (availableProductsData) {
      const { categories, newAccountProducts } = formatNewAccountProducts(availableProductsData);
      const tabsContent = generateNewAccountTabs(newAccountProducts, categories);
      setTabs(tabsContent);
    }
  }, [availableProductsData]);

  if (isAvailableProductsError) {
    return <ErrorModal open allPages />;
  }

  return (
    <div className={styles.title}>
      <SecondaryHeader title={title} backLink={links.landing.link} />

      <section className={styles.page__content}>
        <section>
          <div>
            <Heading level={2}>{description}</Heading>
          </div>
          {isAvailableProductsLoading && <LoadingSection />}
          {tabs && (
            <TabsResponsive
              defaultValue={TABS_VALUES.allAccounts}
              items={tabs
                .filter(el => Object.values(el.content).some(value => !!value))
                .map(el => ({
                  title: el.title,
                  value: el.value,
                  content: <CompareAccountsTab tabProducts={el.content} />
                }))}
              selectId="tabs-responsive-available-products"
              tabStyleTrigger="widthAligned"
              tabStyleContent="widthAligned"
              backgroundColorContent="transparent"
              backgroundColorTrigger="transparent"
              label={tabsSublabel}
              gap="5"
            />
          )}
          <TermsExplained className={styles.termsExplained} />
        </section>
        <aside>
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};
