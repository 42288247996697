import { FormattedTransaction } from "utils/formatTransfers/interfaces";

const getPrevTransferInfo = (
  transfers: Array<FormattedTransaction>,
  currentTransfer: FormattedTransaction,
  index: number
) => {
  const prevTransfer = index > 0 ? transfers[index - 1] : null;
  const startOfMonth =
    index === 0 ||
    (prevTransfer &&
      currentTransfer.dateInitiatedFormattedShort &&
      currentTransfer.dateInitiatedFormattedShort !== prevTransfer.dateInitiatedFormattedShort);
  const isPrevTransferWithTag = Boolean(!startOfMonth && prevTransfer?.tagText);

  return {
    startOfMonth,
    isPrevTransferWithTag,
    prevTransfer
  };
};

export default getPrevTransferInfo;
