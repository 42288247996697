import { BankfastUserMigrationFlow } from "pages/BankfastUserMigration/Steps";
import { APIReturnResult, ComponentState, OtpType } from "services/data/types/bankfastUserMigration/";
import { ICustomer } from "services/data/types";

export enum ValidateOTPComponentState {
  CALL_UPDATE_CONTACT_DETAILS = "callUpdateContactDetails",
  CALL_VERIFY_CONTACT_PROCESS = "callVerifyContactProcess"
}

export interface IVerificationOTPProcess {
  otpType: OtpType;
  currentState?: ComponentState;
  currentStepIndex?: number;
  setPostResult?: React.Dispatch<
    React.SetStateAction<
      | {
          result: APIReturnResult | null;
        }
      | undefined
    >
  >;
  setCurrentState?: React.Dispatch<React.SetStateAction<ComponentState>>;
  flowId?: BankfastUserMigrationFlow;
  customerData?: ICustomer;
}
