import { FC, ReactElement } from "react";

import { NavLogo } from "components/NavLogo/NavLogo";
import FooterShort from "layouts/FooterShort/FooterShort";

interface IInfoLayout {
  children: ReactElement;
  className?: string;
  infoCompleteLayout?: boolean;
}

export const InfoLayout: FC<IInfoLayout> = ({ children, infoCompleteLayout, ...props }) => (
  <div {...props}>
    <NavLogo infoCompleteLayout={infoCompleteLayout} />
    <main id="wrapper">{children}</main>
    <FooterShort />
  </div>
);

export default InfoLayout;
