import { Button, Icon } from "@shawbrook/sds-component-library";
import { FC } from "react";
import styles from "./ActionTrigger.module.scss";

interface IActionTrigger {
  actionText: string;
  action: () => void;
}

export const ActionTrigger: FC<IActionTrigger> = ({ actionText, action }) => (
  <Button className={styles.actionTrigger} variant="tertiary" onClick={action} iconRight>
    {actionText}
    <Icon name="rightArrow" className={styles.actionTrigger__icon} width="21" height="21" />
  </Button>
);
