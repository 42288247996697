const DATES: Readonly<Record<string, string>> = Object.freeze({
  longDate: "EEE LLL dd yyyy",
  isoWithNoTimezone: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSS",
  isoWithLongTimezone: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSSXXX",
  isoWithNoTimezoneShort: "yyyy-MM-dd'T'HH:mm:ss",
  isoWithTimezoneShort: "yyyy-MM-dd'T'HH:mm:ssXXX",
  day: "dd",
  fullMonth: "MMMM",
  year: "yyyy",
  hour: "HH",
  minutes: "mm",
  seconds: "ss"
});

export const possibleDateFormats = [
  DATES.isoWithLongTimezone,
  DATES.isoWithTimezoneShort,
  DATES.isoWithNoTimezone,
  DATES.longDate,
  DATES.isoWithNoTimezoneShort
];

const TIMEZONES: Readonly<Record<string, string>> = Object.freeze({
  London: "Europe/London",
  UTC: "UTC"
});

export { DATES, TIMEZONES };
