import { Button } from "@shawbrook/sds-component-library";
import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import useSubmitMessages from "hooks/useSubmitMessages";
import { useInboxContext } from "state/inboxState";
import styles from "./InboxActionBarButtons.module.scss";

interface IInboxActionBarButtons {
  options?: string[];
  threadId: string;
  isDisabled: boolean;
}

export const InboxActionBarButtons: FC<IInboxActionBarButtons> = ({ threadId, options, isDisabled }) => {
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const context = useInboxContext();

  const { postMessages, isSubmitMessagesError, submitMessagesError } = useSubmitMessages({
    threadId,
    message: newMessage,
    inboxContext: context
  });

  if (isSubmitMessagesError) {
    return (
      <ErrorRequestView requestErrors={[submitMessagesError]} context={context} errorModal={<ErrorModal open />} />
    );
  }

  const handleSendMessage = (value: string) => {
    setLoading(true);
    setNewMessage(value);
    postMessages();
    setLoading(false);
  };

  return (
    <section className={styles["inbox-actionbar-buttons"]}>
      {options &&
        options.map((o, i) => (
          <Button
            disabled={loading || isDisabled}
            onClick={() => handleSendMessage(o)}
            size="medium"
            key={uuidv4()}
            variant={i === 0 ? "primary" : "secondary"}
            css={{ width: "100%", mb: "$2", "--animation-order": i + 1 }}>
            {o}
          </Button>
        ))}
    </section>
  );
};

export default InboxActionBarButtons;
