import { VALIDATION } from "assets/strings/validation";
import * as yup from "yup";

export const customerDetailsGDPRAndA11ySchema =
  // Need check for 'do you need support with letters and phone calls' to prevent user from
  // continuing if not selected
  // 'Make a selection to continue.'

  // If yes, additional check needs to happen on a11yExtraOptions to make sure at least one option
  // has been picked
  // 'Make a selection to continue.'

  yup.object().shape({
    customer: yup.object().shape({
      /* marketingPreferences: yup.object().shape({
        email: yup.boolean(),
        textMessage: yup.boolean(),
        phoneCall: yup.boolean()
      }), */
      accessibilityOptions: yup
        .object()
        .shape({
          localization: yup.string(),
          supportNeeded: yup.string().required(VALIDATION.userMigrationJourney.accessibilitySupport.required),
          details: yup.object().shape({
            translator: yup.boolean(),
            relayUKService: yup.boolean(),
            writingOnly: yup.boolean(),
            largePrint: yup.boolean(),
            braille: yup.boolean()
          })
        })
        .test("namesRequired", VALIDATION.userMigrationJourney.accessibilityOptions.required, value => {
          if (value.supportNeeded !== "yes") return true;
          const accessibilityOptions = value.details;
          if (Object.values(accessibilityOptions).some(item => item)) return true;
          return false;
        })
    })
  });
