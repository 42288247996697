import { EventsType } from "react-idle-timer";

const ACCOUNT_PROPERTIES: Readonly<Record<string, string>> = Object.freeze({
  sourceAccount: "SourceAccount",
  destinationAccount: "DestinationAccount"
});

const HEADERS = {
  Authorization: {
    noStrict: {
      type: "Bearer",
      value: "C=savingsCustomerId:{{savingsCustomerId}}",
      defaultSavingsCustomerId: "639016"
    }
  }
};

const SESSION_STORAGE_KEY: Readonly<Record<string, string>> = Object.freeze({
  ACCESS_TOKEN: "accessToken",
  AUTH_POPUP_OPEN: "authPopupOpen"
});

const STATUS_CODE: Readonly<Record<string, number>> = Object.freeze({
  UNAUTHORIZED: 401,
  REQUEST_TIMEOUT: 408,
  GATEWAY_TIMEOUT: 504,
  SERVICE_UNAVAILABLE: 503,
  NOT_FOUND: 404,
  BAD_REQUEST: 400
});

const SYSTEM_MESSAGE: Readonly<Record<string, string>> = Object.freeze({
  NETWORK_ERROR: "Network Error"
});

const API_LIMITS: Readonly<Record<string, number>> = Object.freeze({
  ALLOWANCE: 20000,
  RETRY_TIME_STEP: 1000,
  RETRY_MAX_TIME: 30000,
  RETRY_LIMIT: 6,
  MIN_BALANCE: 1000,
  MIN_WITHDRAW_LIMIT: 500,
  transferDeadlineHour: 21,
  transferDeadlineMinute: 30,
  REFETCH_TIMEOUT: 120000,
  PAYMENTS_DEFAULT_LIMIT: 10,
  MESSAGES_INTERVAL_LIMIT_INITAL: Number(process.env.REACT_APP_MESSAGES_INTERVAL_LIMIT_INITAL || 3),
  MESSAGES_INTERVAL_LIMIT: Number(process.env.REACT_APP_MESSAGES_INTERVAL_LIMIT || 4),
  MESSAGES_TIME_MAX_INITIAL: Number(process.env.REACT_APP_MESSAGES_TIME_MAX_INITIAL || 15),
  MESSAGES_TIME_MAX: Number(process.env.REACT_APP_MESSAGES_TIME_MAX || 30)
});

const IDLE_PARAMS: Readonly<Record<string, number | Array<EventsType>>> = Object.freeze({
  timeout: 300_000,
  promptBeforeIdle: 30_000,
  throttle: 200,
  events: [
    "mousemove",
    "keydown",
    "wheel",
    "DOMMouseScroll",
    "mousewheel",
    "mousedown",
    "touchstart",
    "touchmove",
    "MSPointerDown",
    "MSPointerMove"
  ] as Array<EventsType>
});

const CURRENCIES: Readonly<Record<string, string>> = Object.freeze({
  GBP: "£"
});

const REG_EXPS: Readonly<Record<string, RegExp>> = Object.freeze({
  MOVE_MONEY_AMOUNT_PREFIX: /^£\s/,
  AMOUNT_TO_REINVEST_PREFIX: /^£/,
  MOVE_MONEY_REFERENCE_INPUT: /^([a-zA-Z0-9 ]*)$/
});

const SERVICE_TICK: Readonly<Record<string, string>> = Object.freeze({
  LINK_URL: "servicetick",
  CUSTOM_NAME: "c1",
  CUSTOM_VALUE: "",
  BUTTON_CLASS: "st-survey-launch-button"
});

const ERROR_CODES: Readonly<Record<string, string>> = Object.freeze({
  APPLICATION_MISSING: "APPLICATION_MISSING",
  PERSONAL_DETAILS_MISSING: "PERSONAL_DETAILS_MISSING",
  CONTACT_DETAILS_MISSING: "CONTACT_DETAILS_MISSING",
  EMPLOYMENT_DETAILS_MISSING: "EMPLOYMENT_DETAILS_MISSING",
  CONFIRMATIONS_MISSING: "CONFIRMATIONS_MISSING",
  WHAT_YOU_WILL_NEED_MISSING: "WHAT_YOU_WILL_NEED_MISSING",
  ELIGIBILITY_MISSING: "ELIGIBILITY_MISSING",
  PAYMENT_MISSING: "PAYMENT_MISSING",
  MISSING_SOURCE_ACCOUNT_ID: "MISSING_SOURCE_ACCOUNT_ID",
  MISSING_DESTINATION_ACCOUNT_ID: "MISSING_DESTINATION_ACCOUNT_ID",
  ITEM_MISSING: "ITEM_MISSING",
  INVALID_ACCOUNT: "INVALID_ACCOUNT",
  ACCOUNT_ID_REQUIRED: "ACCOUNT_ID_REQUIRED",
  ACCOUNT_NOT_FOUND: "ACCOUNT_NOT_FOUND",
  PRODUCT_MISSING: "PRODUCT_MISSING",
  PRODUCT_ID_MISSING: "PRODUCT_ID_MISSING",
  SOURCE_ACCOUNT_NOT_FOUND: "SOURCE_ACCOUNT_NOT_FOUND",
  DESTINATION_ACCOUNT_NOT_FOUND: "DESTINATION_ACCOUNT_NOT_FOUND",
  PAYMENT_ID_REQUIRED: "PAYMENT_ID_REQUIRED",
  PAYMENT_NOT_FOUND: "PAYMENT_NOT_FOUND",
  GENERIC_UNKNOWN_LOGIN_ERROR: "GENERIC_UNKNOWN_LOGIN_ERROR",
  ACCOUNT_TYPE_MISSING: "ACCOUNT_TYPE_MISSING",
  EMAIL_INVALID_EXTENSION: "EMAIL_INVALID_EXTENSION",
  EMAIL_FORMAT_INCORRECT: "EMAIL_FORMAT_INCORRECT",
  EMAIL_MISSING: "EMAIL_MISSING",
  PASSCODE_BLANK: "PASSCODE_BLANK",
  PASSCODE_RESEND_WARNING: "PASSCODE_RESEND_WARNING",
  PASSCODE_RESEND_TIMEOUT: "PASSCODE_RESEND_TIMEOUT",
  AMOUNT_INVALID: "AMOUNT_INVALID",
  AMOUNT_GREATER_CURRENT_BALANCE: "AMOUNT_GREATER_CURRENT_BALANCE",
  NOT_WITHDRAWABLE_MULTIPLE_ACCOUNTS: "NOT_WITHDRAWABLE_MULTIPLE_ACCOUNTS",
  MOVE_MONEY_FROM_NOTICE: "MOVE_MONEY_FROM_NOTICE",
  INVALID_AVAILABLE_BALANCE: "INVALID_AVAILABLE_BALANCE",
  PRODUCT_TYPE_INVALID: "PRODUCT_TYPE_INVALID",
  ACCOUNT_NOT_FUNDABLE: "ACCOUNT_NOT_FUNDABLE",
  ACTIVE_SOURCE_ACCOUNT: "ACTIVE_SOURCE_ACCOUNT",
  ACTIVE_DESTINATION_ACCOUNT: "ACTIVE_DESTINATION_ACCOUNT",
  ZERO_BALANCE: "ZERO_BALANCE",
  NOT_WITHDRAWABLE_FIXED_RATE: "NOT_WITHDRAWABLE_FIXED_RATE",
  NOT_WITHDRAWABLE_ISA_FIXED_RATE: "NOT_WITHDRAWABLE_ISA_FIXED_RATE",
  NOT_WITHDRAWABLE_NOTICE: "NOT_WITHDRAWABLE_NOTICE",
  NOT_WITHDRAWABLE_ISA_NOTICE: "NOT_WITHDRAWABLE_ISA_NOTICE",
  NOT_WITHDRAWABLE_ISA: "NOT_WITHDRAWABLE_ISA",
  NOT_WITHDRAWABLE_POST_MATURITY: "NOT_WITHDRAWABLE_POST_MATURITY",
  PEGA_OVERRIDE: "Pega Override",
  ISA_CLOSED_FOR_FUNDING: "ISA_CLOSED_FOR_FUNDING",
  POST_MATURITY_CLOSED_FOR_FUNDING: "POST_MATURITY_CLOSED_FOR_FUNDING",
  PRODUCT_TYPE_MATURED_FUNDS: "PRODUCT_TYPE_MATURED_FUNDS",
  ACCOUNT_HAS_MATURITY_INSTRUCTIONS: "ACCOUNT_HAS_MATURITY_INSTRUCTIONS",
  MOVE_MONEY_MAX_BALANCE_REACHED: "MOVE_MONEY_MAX_BALANCE_REACHED",
  MOVE_MONEY_MAX_SUBSCRIPTION_REACHED: "MOVE_MONEY_MAX_SUBSCRIPTION_REACHED",
  AMOUNT_MUST_BE_GREATER_THAN_CUSTOMER_MIN_LIMIT: "AMOUNT_MUST_BE_GREATER_THAN_CUSTOMER_MIN_LIMIT",
  MOVE_MONEY_UNAUTHORIZED_ACCOUNT_CREDIT: "MOVE_MONEY_UNAUTHORIZED_ACCOUNT_CREDIT"
});

export {
  ACCOUNT_PROPERTIES,
  API_LIMITS,
  CURRENCIES,
  ERROR_CODES,
  HEADERS,
  IDLE_PARAMS,
  REG_EXPS,
  SESSION_STORAGE_KEY,
  STATUS_CODE,
  SYSTEM_MESSAGE,
  SERVICE_TICK
};
