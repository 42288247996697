export default {
  card: {
    width: "100%",
    height: "fit-content",
    maxWidth: "var(--large-screen)",
    padding: "$4"
  },
  linkedAccountContainer: {
    paddingRight: 0,
    marginLeft: "$2"
  },
  accountContainer: {
    paddingLeft: 0,
    paddingRight: 0
  },
  p: {
    marginBottom: 0
  },
  paymentInfo: {
    padding: "$2 $4"
  }
};
