import strings from "assets/strings/strings.json";
import { AxiosError, AxiosResponse } from "axios";
import { IAllBankfastUserMigrationStepTypes } from "pages/BankfastUserMigration/Steps";
import {
  APIBankfastJourneyResponse,
  APIContactResponse,
  APICustomerResponse,
  ApiError,
  APIReturnResult,
  IStep
} from "services/data/types/bankfastUserMigration/";
import { formatTextWithPhone } from "utils/formatTextWithPhone/FormatTextWithPhone";
import { doesStepIncludeFieldName } from "./StepManagement";

export const getGeneralError = (): ApiError[] | null => [
  // TO DO: REPLACE THE FOLLOWING LINES WITH NEXT GENERAL ERROR MESSAGE COMING FROM PRODUCT
  {
    property: "",
    message: formatTextWithPhone(strings.general.generalApiErrorMessage),
    code: "GENERAL_API_ERROR"
  } as ApiError
];

export const filterOnlyErrorsFromStep = (
  currentStep: IStep<IAllBankfastUserMigrationStepTypes>,
  apiErrors?: ApiError[] | null
) => apiErrors?.filter(error => doesStepIncludeFieldName(currentStep, error.property));

const knownErrorsFromResult = (result?: APIReturnResult | null): ApiError[] | null => {
  if (Array.isArray((result as AxiosError<APICustomerResponse>)?.response?.data?.errors as ApiError[])) {
    return (result as AxiosError<APICustomerResponse>)?.response?.data?.errors ?? null;
  }
  if (
    ((result as AxiosError<APIContactResponse>)?.response?.data as APIContactResponse)?.errors ||
    ((result as AxiosError<APIContactResponse>)?.response?.data as APIContactResponse)?.status === 401
  ) {
    return [] as ApiError[];
  }

  return null;
};

export const isResultValidError = (result?: APIReturnResult | null): boolean =>
  !!result && (result as AxiosError<APIBankfastJourneyResponse>)?.name === "AxiosError";

export const ACCEPTED_STATUS_CODES = [200, 202, 204]; // TO BE CHANGED TO 202
export const WITH_DETAILS_STATUS_CODE = [429];

export const isResultSuccessful = (result?: APIReturnResult | null): boolean =>
  !!result && ACCEPTED_STATUS_CODES.includes((result as AxiosResponse<APIBankfastJourneyResponse>)?.status);

export const isStatusCodeWithDetails = (status?: number | string): boolean =>
  !!status && !Number.isNaN(Number(status)) && WITH_DETAILS_STATUS_CODE.includes(Number(status));

export const errorsOnTheScreen = (
  currentStep: IStep<IAllBankfastUserMigrationStepTypes>,
  apiErrors?: ApiError[] | null
) => apiErrors?.some(error => doesStepIncludeFieldName(currentStep, error.property));

export const getKnownErrorsFromResult = (result?: APIReturnResult | null): ApiError[] | null =>
  isResultValidError(result) ? knownErrorsFromResult(result) : null;
