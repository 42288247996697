import { PERIOD_INDICATOR } from "config/periodIndicator";
import { Term } from "services/data/types";

export const transformTerm = (term: Term | undefined) => {
  if (!term) return 0;
  let daysInTerm = 0;
  switch (term.periodIndicator) {
    case PERIOD_INDICATOR.DAYS:
      daysInTerm = term.number;
      break;
    case PERIOD_INDICATOR.MONTHS:
      daysInTerm = term.number * 30;
      break;
    case PERIOD_INDICATOR.YEARS:
      daysInTerm = term.number * 365;
      break;
    default:
      break;
  }
  return daysInTerm;
};
