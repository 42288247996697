export enum Step {
  EMAIL = "email",
  EMAIL_OTP = "email-otp",
  CHANGE_EMAIL = "change-email",
  MOBILE = "mobile",
  MOBILE_OTP = "mobile-otp",
  CHANGE_MOBILE = "change-mobile",
  GDPR_AND_A11Y = "communication"
}
export default Step;
