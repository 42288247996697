import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { UseMoveMoneyReviewSubmitProps } from "hooks/useMoveMoneyReviewSubmit/interfaces";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { TransferStatus } from "services/data/types";
import { IMoveMoneyContext } from "state/moveMoneyState";
import { QUERY_STATUS } from "types/types";
import { formatBalance } from "utils";
import { clearStorage } from "utils/sessionStorage";

const useMoveMoneyReviewSubmit = ({
  isSubmitTransferError,
  isSubmitTransferSuccess,
  submitTransferData,
  isGetTransfer,
  setIsGetTransfer,
  transferData,
  transferStatus,
  moveMoneyContext: { state, setState } = {} as IMoveMoneyContext
}: UseMoveMoneyReviewSubmitProps) => {
  const navigate = useNavigate();
  const whenDescription: "descriptionInternal" | "descriptionExternal" = `description${
    state.toAccount?.internal ? "Internal" : "External"
  }`;
  let successAccountTitle = "";
  if (state.toAccount != null) {
    successAccountTitle = state.toAccount.internal ? state.toAccount.accountTitle : state.toAccount.bankName || "";
  }

  let accountFromName = state.fromAccount?.product?.title || state.fromAccount?.product?.displayName;
  accountFromName += state.fromAccount?.product?.issueNumber
    ? ` ${strings.general.issueNumber} ${state.fromAccount.product.issueNumber}`
    : "";

  let accountToName = "";
  if (state.toAccount?.internal) {
    accountToName = state.toAccount?.accountTitle || "";
    accountToName += state.toAccount?.issueNumber ? ` ${state.toAccount.issueNumber}` : "";
  } else {
    accountToName = `${state.toAccount?.customerName || ""} - ${state.toAccount?.bankName || ""}`;
  }

  useEffect(() => {
    if (!state.fromAccount || !state.toAccount || !state.amount.value) {
      navigate(links.moveMoney.link);
    }
  }, [state.fromAccount, state.toAccount, state.amount.value, navigate]);

  useEffect(() => {
    const testErr =
      localStorage.getItem("postTransferError") === "true" && (isSubmitTransferError || isSubmitTransferSuccess);

    if (testErr || isSubmitTransferError) {
      setState({
        displayLeaveModal: false,
        displayErrorModal: true
      });
    } else if (isSubmitTransferSuccess) {
      if (state.toAccount?.internal) {
        clearStorage("moveMoneyState");
        setState({
          displayLeaveModal: false,
          displaySuccessModal: true
        });
      } else if (isGetTransfer == null && (!state.paymentId || state.paymentId !== submitTransferData?.id)) {
        setState({
          paymentId: submitTransferData?.id
        });
        setIsGetTransfer(true);
      }
    }
  }, [
    isSubmitTransferError,
    isSubmitTransferSuccess,
    submitTransferData,
    state,
    isGetTransfer,
    setIsGetTransfer,
    setState
  ]);

  useEffect(() => {
    if (
      isGetTransfer &&
      (transferStatus === QUERY_STATUS.error ||
        (transferStatus === QUERY_STATUS.success &&
          transferData?.status?.value &&
          (transferData.status.value === TransferStatus.COMPLETE ||
            transferData.status.value === TransferStatus.FAILED ||
            transferData.status.value === TransferStatus.MANUAL_REVIEW)))
    ) {
      setIsGetTransfer(false);
    }
  }, [transferStatus, transferData, isGetTransfer, setIsGetTransfer]);

  useEffect(() => {
    if (isGetTransfer === false) {
      if (
        transferStatus === QUERY_STATUS.error ||
        (transferStatus === QUERY_STATUS.success &&
          (transferData?.status?.value === TransferStatus.FAILED ||
            transferData?.status?.value === TransferStatus.PENDING ||
            transferData?.status?.value === TransferStatus.MANUAL_REVIEW))
      ) {
        setIsGetTransfer(null);
        setState({
          displayLeaveModal: false,
          displayErrorModal: true
        });
      } else if (transferStatus === QUERY_STATUS.success) {
        clearStorage("moveMoneyState");
        setState({
          displayLeaveModal: false,
          displaySuccessModal: true
        });
      }
    }
  }, [transferStatus, transferData, isGetTransfer, setState, setIsGetTransfer]);

  const successCallback = () => {
    setState({
      displaySuccessModal: false
    });
  };

  const errorCallback = () => {
    setState({
      displayErrorModal: false
    });
  };

  const cancelHandler = () => {
    setState({
      displayErrorModal: false,
      displaySuccessModal: false,
      displayLeaveModal: true
    });

    navigate(links.landing.link);
  };

  const calculateBalance = (balance: number, withdrawAmount: number): string | undefined => {
    if (typeof balance === "number" && typeof withdrawAmount === "number") {
      return formatBalance(balance - withdrawAmount);
    }

    return undefined;
  };

  return {
    successCallback,
    errorCallback,
    cancelHandler,
    calculateBalance,
    whenDescription,
    successAccountTitle,
    accountFromName,
    accountToName
  };
};

export default useMoveMoneyReviewSubmit;
