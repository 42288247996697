import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { getAllProviders } from "services/data/api";
import { IsaProviders, QueryKeys } from "services/data/types";

const commonQueryOptions = {
  cacheTime: 0,
  staleTime: 0,
  retry: 3,
  retryDelay: (attemptIndex: number) => Math.min(1000 * 2 ** attemptIndex, 30000),
  refetchOnWindowFocus: false
};

const useProviders = (enabled = true, customKeys = []) => {
  const [hasModal, setHasModal] = useState(false);
  const queryKey = [QueryKeys.PROVIDERS_DATA, ...customKeys];

  const {
    data: originalProvidersData = [],
    isLoading: isProvidersLoading,
    isError: isProvidersError,
    error: providersError,
    refetch: refetchProviders
  } = useQuery({
    queryKey,
    queryFn: getAllProviders,
    ...commonQueryOptions,
    enabled,
    onError: () => {
      setHasModal(true);
    }
  });

  const providersData = [...originalProvidersData, { id: "other", companyName: "Other" }] as IsaProviders[];

  return {
    providersData,
    isProvidersLoading,
    isProvidersError,
    providersError,
    refetchProviders,
    hasModal,
    setHasModal
  };
};

export default useProviders;
