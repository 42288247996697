import { media } from "config/breakpoints";

const { md } = media;

export const inlineStyles = {
  balanceCard: {
    flexDirection: "column",
    "> div": {
      marginBottom: "0.625rem"
    },
    "> :last-child": {
      marginBottom: "0"
    },
    [md]: {
      flexDirection: "row",
      "> div": {
        marginBottom: "0"
      }
    }
  }
};
