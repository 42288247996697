import { Button, Text } from "@shawbrook/sds-component-library";

import { CloseIcon } from "assets/images";
import strings from "assets/strings/strings.json";
import inlineStyles from "components/WhyWeAskThisLink/WhyWeAskThisLink.styles";
import { useState } from "react";
import styles from "./whyWeAskThisLink.module.scss";

interface IWhyWeAskThisProps {
  content: string;
  subContent?: string;
  label?: string;
  alternativeFontStyle?: boolean;
}

const WhyWeAskThis = ({ content, subContent, label, alternativeFontStyle = false }: IWhyWeAskThisProps) => {
  const [clicked, setIsClicked] = useState(false);
  const textType = alternativeFontStyle ? "p" : "small";
  const fontWeight = alternativeFontStyle ? "medium" : "semiBold";
  return (
    <>
      <Button variant="tertiary" css={inlineStyles.button} onClick={() => setIsClicked(!clicked)}>
        {label || strings.userMigrationJourney.income.infoButton}
      </Button>
      {clicked && (
        <div className={styles.container}>
          <Text fontWeight={fontWeight} as={textType}>
            {content}
          </Text>
          {subContent && (
            <Text css={{ marginTop: "1.5rem" }} fontWeight={fontWeight} as={textType}>
              {subContent}
            </Text>
          )}
          <CloseIcon className={styles.closeIcon} onClick={() => setIsClicked(!clicked)} />
        </div>
      )}
    </>
  );
};

export default WhyWeAskThis;
