import { useInfiniteQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import { getDocuments } from "services/data/api";
import { QueryKeys } from "services/data/types";

interface IGetDocumentsParams {
  offset?: number;
  limit?: number;
}

const useDocuments = ({ limit }: { limit: number }) => {
  const [offset, setOffset] = useState(0);

  const documentsParams: IGetDocumentsParams = { offset, limit: limit + 1 };

  const { hasNextPage, ...result } = useInfiniteQuery({
    queryKey: [QueryKeys.DOCUMENTS],
    queryFn: () => getDocuments(documentsParams),
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    retry: false,
    cacheTime: 0,
    staleTime: 1000 * 60 * 60 * 24, // 24 hours
    getNextPageParam: lastPage => (lastPage?.length === limit + 1 ? true : undefined)
  });

  useEffect(() => {
    result.fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const loadNextPage = () => {
    setOffset(prevOffset => prevOffset + limit);
  };

  return {
    filesQuery: result,
    hasNextPage,
    loadNextPage
  };
};

export { useDocuments };

export default useDocuments;
