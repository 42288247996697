import { DATES } from "assets/strings/formats";
import strings from "assets/strings/strings.json";
import SystemInfoCard from "components/SystemInfoCard/SystemInfoCard";
import { convertTo12HTime, convertToISOLocalDate, formatToDisplayDate, getHour, getMinutes, getSeconds } from "utils";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";

export const Maintenance = () => {
  const { title: titleWithinHours, description: descriptionWithinHours } = strings.maintenance;
  const { title: titleOutOfHours, description: descriptionOutOfHours } = strings.maintenanceOutOfHours;
  const currentDate = new Date();
  const workStart = +(process.env.REACT_APP_WORK_START || 0);
  const workEnd = +(process.env.REACT_APP_WORK_END || 0);

  const maintenanceEndDate = formatToDisplayDate(process.env.REACT_APP_MAINTENANCE_END || "");
  const maintenanceLocalObj = new Date(
    convertToISOLocalDate(process.env.REACT_APP_MAINTENANCE_END || "", DATES.isoWithTimezoneShort)
  );
  const maintenanceEndHours = +getHour(maintenanceLocalObj);
  const maintenanceEndMinutes = +getMinutes(maintenanceLocalObj);
  const maintenanceEndFormattedTime = convertTo12HTime(maintenanceEndHours, maintenanceEndMinutes, true, true);

  const currentHour = +getHour(currentDate);
  const currentMinutes = +getMinutes(currentDate);
  const currentSeconds = +getSeconds(currentDate);

  const isWorkingTime =
    currentHour >= workStart &&
    (currentHour < workEnd || (currentHour === workEnd && currentMinutes === 0 && currentSeconds === 0));

  const titleVariant = isWorkingTime ? titleWithinHours : titleOutOfHours;
  const descriptionVariant = isWorkingTime ? descriptionWithinHours : descriptionOutOfHours;
  const descriptionAdjusted = formatTextWithPhoneHours(descriptionVariant)
    .replace("{{DATE}}", maintenanceEndDate)
    .replace("{{TIME}}", maintenanceEndFormattedTime);

  return <SystemInfoCard title={titleVariant} withLayout description={descriptionAdjusted} />;
};
