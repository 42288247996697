import { Button, Space, Tag, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import WarningModal from "components/WarningModal/WarningModal";
import { links } from "config/navLinks";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SelectedAccount.module.scss";

interface ISelectedAccountProps {
  productName: string;
  issueNumber: number;
  clearState: () => void;
}

export const SelectedAccount: FC<ISelectedAccountProps> = ({ productName, issueNumber, clearState }) => {
  const [showEditModal, setShowEditModal] = useState(false);
  const navigate = useNavigate();

  const { title } = strings.selectedAccountBox;
  const { title: modalTitle, description } = strings.newAccount.editModal.editProduct;
  const { editButton } = strings.general;

  const handleCloseWarningModal = () => {
    setShowEditModal(false);
  };

  const handleEdit = () => {
    clearState();
    navigate(links.compareAccounts.link);
  };

  return (
    <Space gap="5" className={styles.page__selectedAccount}>
      <WarningModal
        handleClose={handleCloseWarningModal}
        handleConfirm={handleEdit}
        open={showEditModal}
        title={modalTitle}
        text={description}
      />
      <div className={styles.page__selectedAccount__content}>
        <Space gap="2">
          <Text fontWeight="bold">{title}</Text>
        </Space>
        <div>
          <Tag isLarge css={{ padding: "$0-5 $1" }} className={styles.page__selectedAccount__box}>
            <Text as="span" fontWeight="bold">
              {productName}{" "}
              <span className={styles.page__selectedAccount__content__issueNumber}>
                {`${strings.general.issueNumber} ${issueNumber}`}{" "}
              </span>
            </Text>
          </Tag>
        </div>
        <Button
          css={{ border: "none", textDecoration: "underline" }}
          onClick={() => setShowEditModal(true)}
          variant="tertiary">
          {editButton}
        </Button>
      </div>
    </Space>
  );
};
