import strings from "assets/strings/strings.json";
import { FormattedAvailableProducts } from "utils/formatAvailableProducts/interfaces";
import { ProductCategory } from "services/data/types";

const TABS_VALUES: Readonly<Record<string, string>> = Object.freeze({
  allAccounts: "allAccounts",
  [ProductCategory.EASY_ACCESS]: "easyAccess",
  [ProductCategory.EASY_ACCESS_ISA]: "easyAccessISA",
  [ProductCategory.FIXED_RATE]: "fixedRateBonds",
  [ProductCategory.FIXED_RATE_ISA]: "fixedRateISA",
  [ProductCategory.NOTICE]: "noticeAccount"
});

const tabsTitles = strings.selectAccount.accountsTabs;

interface AvailableProductsSelection {
  title: string;
  value: string;
  content: FormattedAvailableProducts;
}

export type AvailableProductsSelectionArr = Array<AvailableProductsSelection>;

const generateSelection = (
  availableProducts: FormattedAvailableProducts,
  categories: Set<ProductCategory>
): AvailableProductsSelectionArr => {
  let tabs: AvailableProductsSelectionArr = [];

  if (categories.size) {
    const allTab = {
      title: tabsTitles[TABS_VALUES.allAccounts as keyof typeof tabsTitles],
      value: TABS_VALUES.allAccounts,
      content: availableProducts
    };

    tabs = [...tabs, allTab];

    categories.forEach(currentCategory => {
      let currentContent: FormattedAvailableProducts = {};

      Object.keys(availableProducts).forEach(setName => {
        if (availableProducts[setName].category === currentCategory) {
          currentContent = {
            ...currentContent,
            [setName]: {
              ...availableProducts[setName]
            }
          };
        }
      });

      if (Object.keys(currentContent).length) {
        const currentTab = {
          title: tabsTitles[TABS_VALUES[currentCategory] as keyof typeof tabsTitles],
          value: TABS_VALUES[currentCategory],
          content: currentContent
        };

        tabs = [...tabs, currentTab];
      }
    });
  }

  return tabs;
};

export { TABS_VALUES, generateSelection };
