import { useMsal } from "@azure/msal-react";
import { FC, useEffect } from "react";

import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { resetCustomer } from "utils/trackerLib";
import { LOGGED_OUT_TYPE } from "types/types";

export const Logout: FC = () => {
  const { instance } = useMsal();
  useEffect(() => {
    localStorage.clear();
    sessionStorage.clear();
    resetCustomer();
    localStorage.setItem("loggedOut", LOGGED_OUT_TYPE.manual);
    instance.logoutRedirect({ postLogoutRedirectUri: links.loggedOut.link });
  }, [instance]);

  return <div className="logout">{strings.logout.title}</div>;
};

export default Logout;
