import { Alert, Box, Button, Card, Flex, Heading, InfoIcon, Loader, Text } from "@shawbrook/sds-component-library";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import rreplace from "react-string-replace";

import { ArrowLeft, ArrowRight } from "assets/images";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import { IconButton } from "components/IconButton/IconButton";
import SuccessModal from "components/SuccessModal";
import WarningModal from "components/WarningModal/WarningModal";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import useCancelInstruction from "hooks/useCancelMaturityInstruction";
import useSrollToTop from "hooks/useScrollToTop";
import useSubmitInstruction from "hooks/useSubmitInstruction";
import { InstructionType, ProductCategory } from "services/data/types";
import { useReinvestmentContext } from "state/reinvestmentState";
import { formatBalance, formatToDisplayDate, getCancelModalText, getInstructionsSetInfoText } from "utils";
import getReinvestmentAccountData from "utils/getReinvestmentAccountData";
import getReinvestmentSuccessMessage from "utils/getReinvestmentSuccessMessage";
import { headerStyles } from "../HeaderStyles.styles";
import styles from "./WithdrawFull.module.scss";
import inlineStyles from "./WithdrawFull.style";

export const WithdrawFull = () => {
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const [putInstructionStarted, setPutInstruction] = useState(false);
  const [cancelInstructionStarted, setCancelInstruction] = useState(false);
  const [skipRefreshingAccounts, setSkipRefresingAccounts] = useState(true);
  const [displaySubmitError, setDisplaySubmitError] = useState(false);
  useSrollToTop();
  const params = useParams();
  const accountId = params.id || "";
  const navigate = useNavigate();
  const { state, setState } = useReinvestmentContext();
  const { isSubmitInstructionSuccess, isSubmitInstructionLoading, postInstruction, isSubmitInstructionError } =
    useSubmitInstruction({
      sourceAccountId: accountId,
      instructions: state[accountId]?.instructions || []
    });
  const { accountDetailData, accountErrored } = useAccount({ accountId });
  const { isAccountsLoading } = useAccount({ skipRequest: skipRefreshingAccounts });

  const { isCancelInstructionSuccess, isCancelInstructionLoading, cancelInstruction, isCancelInstructionError } =
    useCancelInstruction({
      sourceAccountIdArg: accountId
    });

  const [searchParams] = useSearchParams();
  const edit = searchParams.get("edit");
  const editMode = state[accountId]?.editMode;

  const instructionActionInProgress = isSubmitInstructionLoading || (!skipRefreshingAccounts && isAccountsLoading);

  useEffect(() => {
    if (edit !== "true" || accountDetailData) {
      setShowInstruction(true);
    }
  }, [edit, setShowInstruction, accountDetailData]);

  useEffect(() => {
    if (isSubmitInstructionError || isCancelInstructionError) {
      setDisplaySubmitError(true);
    }
  }, [isSubmitInstructionError, isCancelInstructionError]);

  useEffect(() => {
    if (edit === "true" && !state[accountId]?.editMode) {
      setState({
        accountId,
        partialData: {
          editMode: true
        }
      });
    }
  }, [accountId, edit, setState, state]);

  useEffect(() => {
    if (!edit && (typeof accountId !== "string" || !state[accountId])) {
      navigate(`${links.maturityInstruction.link}/select-account/${accountId}`);
    }
  }, [state, accountId, navigate, edit]);

  useEffect(() => {
    const reinvestWithdrawState = state[accountId]?.instructions?.find(el => el.type === InstructionType.WITHDRAW);

    if (state[accountId]?.instructions?.length === 1 && reinvestWithdrawState && putInstructionStarted) {
      postInstruction();
    }
  }, [state, accountId, postInstruction, putInstructionStarted]);

  useEffect(() => {
    if (isSubmitInstructionSuccess || isCancelInstructionSuccess) {
      setSkipRefresingAccounts(false);
    }
  }, [isSubmitInstructionSuccess, isCancelInstructionSuccess]);

  useEffect(() => {
    if (!skipRefreshingAccounts && isAccountsLoading === false) {
      if (putInstructionStarted) {
        setShowSuccessModal(true);
      }
      if (cancelInstructionStarted) {
        setShowCancelModal(false);
        navigate(links.landing.link);
      }
    }
  }, [skipRefreshingAccounts, isAccountsLoading, putInstructionStarted, cancelInstructionStarted, navigate]);

  const {
    accountMaturityDate: maturityDate,
    accountBalance,
    linkedAccountBank,
    accountProductCategory
  } = getReinvestmentAccountData({ accountDetailData, state, accountId });

  const endOfTerm = formatToDisplayDate(maturityDate || "");

  const {
    title,
    modals: {
      success: { heading, btnText },
      cancel: { title: cancel, text, description: cancelDescription }
    }
  } = strings.reinvestment;
  const {
    subtitle,
    subtitleEdit,
    description,
    descriptionEdit,
    amount,
    infoText,
    cancelInstruction: cancelInstructionBtn,
    ISAInfo
  } = strings.withdrawFull;
  const { submitBtn, cancelBtn, backToHomeButton, back } = strings.general;

  const editInfoText = getInstructionsSetInfoText(maturityDate || "", infoText, linkedAccountBank);

  const acceptButtonText = !editMode ? submitBtn : backToHomeButton;
  const cancelButtonText = !editMode ? cancelBtn : cancelInstructionBtn;
  const subtitleText = !editMode ? subtitle : subtitleEdit;
  const descriptionText = !editMode ? description : descriptionEdit;

  if (edit === "true" && accountErrored) {
    return <ErrorModal open allPages />;
  }

  const cancelModalText = getCancelModalText(editMode || false, !editMode, accountProductCategory, text);

  const handleOpenCancelModal = () => {
    setShowCancelModal(true);
  };

  const handleSubmit = () => {
    setPutInstruction(true);
    setState({
      accountId,
      partialData: {
        instructions: [{ type: InstructionType.WITHDRAW, useRemainingBalance: true, priority: 1 }]
      }
    });
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleCancel = () => {
    if (editMode) {
      setCancelInstruction(true);
      cancelInstruction();
    } else {
      navigate(links.landing.link);
    }
  };

  const handleBackToHome = () => {
    navigate(links.landing.link);
  };

  const successCallback = () => {
    setShowSuccessModal(false);
  };

  const errorCallback = () => {
    setDisplaySubmitError(false);
  };

  const handleAccept = !editMode ? handleSubmit : handleBackToHome;
  return (
    <div className={styles.withdrawFull}>
      <ErrorModal customCallback={errorCallback} open={displaySubmitError} allPages />
      <WarningModal
        handleClose={handleCloseCancelModal}
        handleConfirm={handleCancel}
        open={showCancelModal}
        title={cancel}
        text={cancelModalText}
        description={(editMode && cancelDescription) || undefined}
        maturityDate={endOfTerm}
        loading={isCancelInstructionLoading || instructionActionInProgress}
      />
      <SuccessModal
        customCallback={successCallback}
        open={showSuccessModal}
        url={links.landing.link}
        content={getReinvestmentSuccessMessage({
          instructionsArg: state[accountId]?.instructions,
          accountMaturityDate: maturityDate,
          linkedAccountBank
        })}
        header={heading}
        btnContent={btnText}
        headerMarginBottom="$6"
      />
      <Box as="header" css={headerStyles.header}>
        <IconButton onClick={() => navigate(-1)} icon={<ArrowLeft />} text={back} />
        <Heading color="white">{title}</Heading>
      </Box>
      <section className={styles.page__content}>
        <section>
          {!showInstruction && (
            <Card css={{ width: "100%" }} className={styles.withdrawFull__card}>
              <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
            </Card>
          )}
          {showInstruction && (
            <div>
              <Heading level={2}>{subtitleText.replace("{{BANK}}", linkedAccountBank || "")}</Heading>
              <Text textStyle="body" fontWeight="medium" css={{ marginBottom: "2rem" }}>
                {rreplace(descriptionText, "{{DATE}}", (_, i) => (
                  <Text as="span" textStyle="body" css={{ fontWeight: "$semiBold" }} key={`DATEPartial${i}`}>
                    {endOfTerm}
                  </Text>
                ))}
              </Text>
              <Card css={{ width: "100%" }} className={styles.withdrawFull__card}>
                {editMode && (
                  <Alert
                    variant="info"
                    icon={<InfoIcon />}
                    css={{ width: "fit-content", marginBottom: "$8", padding: "$2 $2-5" }}>
                    <Text fontWeight="medium">{editInfoText}</Text>
                  </Alert>
                )}
                {!editMode && accountProductCategory === ProductCategory.FIXED_RATE_ISA && (
                  <Alert
                    variant="info"
                    icon={<InfoIcon />}
                    css={{ width: "fit-content", marginBottom: "$10", padding: "$2 $2-5" }}>
                    <Text fontWeight="medium">{ISAInfo}</Text>
                  </Alert>
                )}
                <Text textStyle="body" fontWeight="semiBold">
                  {amount}
                </Text>
                <div className={styles.withdrawFull__card__amount}>
                  <Text textStyle="large">{formatBalance(accountBalance || 0)}</Text>
                </div>
                <Flex
                  direction="columnReverse"
                  justify="between"
                  className={styles.withdrawFull__buttons}
                  css={inlineStyles.submissionButtons}>
                  <Button
                    variant="tertiary"
                    onClick={handleOpenCancelModal}
                    className={styles["withdrawFull__buttons--cancel"]}
                    css={inlineStyles.cancelButton}
                    role="link">
                    {cancelButtonText}
                  </Button>
                  <Button
                    iconRight
                    size="large"
                    className={styles.submitButton}
                    css={inlineStyles.submitButton}
                    variant="primary"
                    disabled={instructionActionInProgress}
                    onClick={handleAccept}>
                    {instructionActionInProgress ? (
                      <Loader size="small" />
                    ) : (
                      <>
                        {acceptButtonText}
                        {!editMode && <ArrowRight />}
                      </>
                    )}
                  </Button>
                </Flex>
              </Card>
            </div>
          )}
        </section>
        <aside>
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};

export default WithdrawFull;
