import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Container, Heading, RightArrow } from "@shawbrook/sds-component-library";
import { links } from "config/navLinks";
import { HASH_PARAMS } from "assets/strings/queryParams";
import strings from "assets/strings/strings.json";
import styles from "pages/ResetPasswordSuccessful/ResetPasswordSuccessful.module.scss";

const ResetPasswordSuccessful = () => {
  const navigate = useNavigate();
  const { hash } = useLocation();

  useEffect(() => {
    if (!hash || hash.indexOf(HASH_PARAMS.ID_TOKEN) === -1) {
      navigate(links.landing.link);
    }
  }, [hash, navigate]);

  return (
    <div className={styles.resetPasswordWrapper}>
      <div className={styles.resetPasswordContainer}>
        <Heading css={{ marginBottom: 0 }}>{strings.resetPassword.title}</Heading>
        <Container className={styles.contentBox} css={{ ml: 0, pl: 0, mr: 0, mb: "$6", pr: 0 }}>
          <Heading level="4" as="h2" fontWeight="normal">
            {strings.resetPassword.content}
          </Heading>
        </Container>

        <div className={styles.button}>
          <Button
            variant="primary"
            role="link"
            as="a"
            css={{
              width: "100%",
              gap: "$1-5",
              svg: { width: "$3-5" },
              "& > [aria-busy]": {
                padding: 0
              }
            }}
            href={process.env.REACT_APP_REDIRECT_URI || links.landing.link}>
            {strings.resetPassword.loginButton}
            <RightArrow aria-hidden="true" width="14" />
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ResetPasswordSuccessful;
