import { VALIDATION } from "assets/strings/validation";
import * as yup from "yup";

export const OTPSchema = yup
  .string()
  .required(VALIDATION.userMigrationJourney.customerDetails.otp.required)
  // check is not 6 blank spaces
  .test(
    "containsNotOnlySpaces",
    VALIDATION.userMigrationJourney.customerDetails.otp.required,
    value => /^([^\s])+$/.test(value) || /\d/.test(value)
  )
  .matches(/^[0-9]{6}$/, VALIDATION.userMigrationJourney.customerDetails.otp.validOtp);
