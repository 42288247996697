import { Flex, Loader } from "@shawbrook/sds-component-library";
import { FC, ReactElement } from "react";

import useAccessToken from "hooks/useAccessToken";
import useAuthInitialized from "hooks/useAuthInitialized";

interface IDefaultNoAuth {
  children: ReactElement;
}

export const AuthInitialized: FC<IDefaultNoAuth> = ({ children }) => {
  const { firstTokenData } = useAuthInitialized();
  useAccessToken({ firstTokenData });

  const renderAuthInitialized = () => {
    if (!firstTokenData) {
      return (
        <Flex justify="center" align="center" css={{ height: "100vh" }}>
          <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
        </Flex>
      );
    }

    return children;
  };

  return renderAuthInitialized();
};

export default AuthInitialized;
