import strings from "assets/strings/strings.json";

export const links = {
  profile: {
    title: strings.navigation.profile,
    link: "/choose-profile"
  },
  signOut: {
    title: strings.navigation.signout,
    link: "/logout"
  },
  loggedOut: {
    title: strings.navigation.loggedOut,
    link: "/logged-out"
  },
  resetPasswordCompleted: {
    title: strings.navigation.resetPasswordCompleted,
    link: "/reset-password-completed"
  },
  documents: {
    title: strings.navigation.documents,
    link: "/statements-and-documents"
  },
  myProfile: {
    title: strings.navigation.myProfile,
    link: "/profile"
  },
  moveMoney: {
    title: strings.navigation.moveMoney,
    link: "/move-money"
  },
  account: {
    title: strings.navigation.account,
    link: "/account/:id"
  },
  closeAccount: {
    title: strings.navigation.account,
    link: "/account/:id/close"
  },
  linkedAccountIssue: {
    title: strings.navigation.account,
    link: "/account/:id/close/linked-account-issue"
  },
  moveMoneyTo: {
    title: strings.navigation.moveMoneyTo,
    link: "/move-money/to"
  },
  moveMoneyFrom: {
    title: strings.navigation.moveMoneyFrom,
    link: "/move-money/from"
  },
  moveMoneyReview: {
    title: strings.navigation.moveMoneyReview,
    link: "/move-money/review"
  },
  maturityInstruction: {
    title: strings.navigation.maturityInstruction,
    link: "/maturity-instruction"
  },
  selectAccount: {
    title: strings.navigation.selectAccount,
    link: "/maturity-instruction/select-account/:id"
  },
  chooseReinvestment: {
    title: strings.navigation.maturityInstruction,
    link: "/maturity-instruction/choose-reinvestment/:id"
  },
  amountToReinvest: {
    title: strings.navigation.maturityInstruction,
    link: "/maturity-instruction/amount-to-reinvest/:id"
  },
  chooseInterest: {
    title: strings.navigation.maturityInstruction,
    link: "/maturity-instruction/choose-interest/:id"
  },
  reviewSubmission: {
    title: strings.navigation.maturityInstruction,
    link: "/maturity-instruction/review-submission/:id"
  },
  fundingInstructions: {
    title: strings.navigation.fundingInstructions,
    link: "/funding-instructions/:id"
  },
  withdrawFull: {
    title: strings.navigation.maturityInstruction,
    link: "/maturity-instruction/withdraw-full/:id"
  },
  newAccount: {
    title: strings.navigation.newAccount,
    link: "/new-account"
  },
  compareAccounts: {
    title: strings.navigation.newAccount,
    link: "/new-account/compare-accounts"
  },
  newAccountDetails: {
    title: strings.navigation.newAccount,
    link: "/new-account/account-details"
  },
  newAccountType: {
    title: strings.navigation.newAccount,
    link: "/new-account/type"
  },
  annualSavings: {
    title: strings.navigation.annualSavingsGoal,
    link: "/new-account/savings-goal"
  },
  newAccountLinkedAccount: {
    title: strings.navigation.newAccount,
    link: "/new-account/linked-account"
  },
  newAccountLinkedAccountForm: {
    title: strings.navigation.newAccount,
    link: "/new-account/linked-account-form"
  },
  newAccountInterest: {
    title: strings.navigation.newAccount,
    link: "/new-account/interest"
  },
  newAccountSummary: {
    title: strings.navigation.newAccount,
    link: "/new-account/summary"
  },
  isaTransfer: {
    title: strings.navigation.isaTransfer,
    link: "/new-account/isa-transfer"
  },
  isaDetails: {
    title: strings.navigation.isaDetails,
    link: "/new-account/isa-details"
  },
  signpost: {
    title: strings.navigation.contactUs,
    link: "/new-account/isa-transfer-start"
  },
  taxYearWaypoint: {
    title: strings.navigation.taxYearWaypoint,
    link: "/new-account/tax-year-waypoint"
  },
  fullPartialTransfer: {
    title: strings.navigation.fullPartialTransfer,
    link: "/new-account/full-partial-transfer"
  },
  transferAmount: {
    title: strings.navigation.transferAmount,
    link: "/new-account/transfer-amount"
  },
  notice: {
    title: strings.navigation.notice,
    link: "/new-account/notice"
  },
  nationalInsurance: {
    title: strings.navigation.nationalInsurance,
    link: "/new-account/national-insurance"
  },
  postSubmit: {
    title: strings.navigation.postSubmit,
    link: "/new-account/post-submit"
  },
  landing: {
    title: strings.navigation.landing,
    link: "/"
  },
  inbox: {
    title: strings.navigation.inbox,
    link: "/messages-and-help"
  },
  inboxNew: {
    title: strings.navigation.inbox,
    link: "/messages-and-help/new"
  },
  inboxAccounts: {
    title: strings.navigation.inbox,
    link: "/messages-and-help/account/:id"
  },
  inboxThread: {
    title: strings.navigation.inbox,
    link: "/messages-and-help/thread/:id"
  },
  bankfastUserMigrationJourneys: {
    title: strings.navigation.bankfastUserMigration,
    link: "/login/email-check"
  },
  bankfastUserMigrationJourneySteps: {
    title: strings.navigation.bankfastUserMigration,
    link: "/login/email-check/:stepId"
  },
  bankfastUserMigrationMobileJourneys: {
    title: strings.navigation.bankfastUserMigration,
    link: "/login/mobile-check"
  },
  bankfastUserMigrationMobileJourneySteps: {
    title: strings.navigation.bankfastUserMigration,
    link: "/login/mobile-check/:stepId"
  },

  bankfastUserMigrationJourneySuccess: {
    title: strings.navigation.bankfastUserMigrationSuccess,
    link: "/login/check-complete"
  },

  documentMissing: {
    title: strings.navigation.landing,
    link: "/document-missing"
  }
};
export const MENU_SECTIONS: Readonly<Record<string, string>> = Object.freeze({
  GENERAL: "general",
  PROFILE: "profile"
});

export const mainNav = [
  { nav: links.landing, section: MENU_SECTIONS.GENERAL },
  { nav: links.moveMoney, section: MENU_SECTIONS.GENERAL },
  { nav: links.documents, section: MENU_SECTIONS.GENERAL },
  { nav: links.inbox, section: MENU_SECTIONS.GENERAL },
  { nav: links.myProfile, section: MENU_SECTIONS.PROFILE },
  { nav: links.profile, section: MENU_SECTIONS.PROFILE },
  { nav: links.signOut, section: MENU_SECTIONS.PROFILE }
];
