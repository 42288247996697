import { Card, Heading, Space } from "@shawbrook/sds-component-library";
import { FC, ReactNode } from "react";

interface IProfileSection {
  title?: string;
  children?: ReactNode;
  lastSection?: boolean;
}

export const ProfileSection: FC<IProfileSection> = ({ title, children = null, lastSection = false }) => (
  <Space gap={lastSection ? 10 : 4}>
    <Card variant="raised" width="auto">
      {title && (
        <Space gap="2">
          <Heading level="4">{title}</Heading>
        </Space>
      )}
      {children}
    </Card>
  </Space>
);
