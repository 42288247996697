import { Badge, Flex, Menu, Text, UserCircleIcon } from "@shawbrook/sds-component-library";
import { useQuery } from "@tanstack/react-query";
import { debounce } from "lodash";
import { FC, useEffect, useState } from "react";
import { Link, matchPath, NavLink, useLocation } from "react-router-dom";

import { Burger, CircleCross, Close, LabelWhite, LogoWhite } from "assets/images/index";
import strings from "assets/strings/strings.json";
import classnames from "classnames";
import FEATURE_FLAGS from "config/featureFlags";
import { links, mainNav, MENU_SECTIONS } from "config/navLinks";
import { getNotificationCount } from "services/data/api";
import { QueryKeys } from "services/data/types";
import { useGlobalContext } from "state/globalContext";
import { ProfileType } from "types/types";
import styles from "./Navigation.module.scss";
import inlineStyles from "./Navigation.styles";

const Navigation: FC = () => {
  const [mobileMenuClosed, setMobileMenuClosed] = useState(true);
  const [profileMenuClosed, setProfileMenuClosed] = useState(true);
  const state = useGlobalContext();

  const location = useLocation();

  useEffect(() => {
    const onResize = () => {
      setMobileMenuClosed(true);
      setProfileMenuClosed(true);
    };

    window.addEventListener("resize", debounce(onResize, 250));
    return () => {
      window.removeEventListener("resize", debounce(onResize, 250));
    };
  }, [state.selectedProfile?.profileType]);

  const handleModal = () => {
    setMobileMenuClosed(isClosed => !isClosed);
  };

  const cxLogo = classnames(`${styles["nav__block-logo"]}`, "hide-mobile");
  const cxClosed = "nav__list-item";
  const cxActive = "nav__list-item nav__list-item--active";

  const cxProfileItem = `${styles.profileMenu__item}`;
  const cxProfileItemActive = `${styles.profileMenu__item} ${styles["profileMenu__item--active"]}`;

  const cxNav = classnames(`${styles.nav}`, {
    [styles["nav--fixed"]]: !mobileMenuClosed
  });

  const getProfileItemClassName = (link: string) => (link === location.pathname ? cxProfileItemActive : cxProfileItem);

  const isInbox = (link: string) => link === links.inbox.link;
  const isMoveMoney = (link: string) => link === links.moveMoney.link;
  const { data: unreadData } = useQuery({
    queryKey: [QueryKeys.MESSAGES],
    queryFn: getNotificationCount
  });
  const useLimitStyle = unreadData && unreadData.count > 9;

  const handleProfileMenuOpenChange = () => {
    setProfileMenuClosed(prev => !prev);
  };

  const getFilteredMenuItems = (menuItems: typeof mainNav) => {
    let myFilteredMenu = menuItems.filter(el => state.hasAccounts || !isMoveMoney(el.nav.link));

    if (state.selectedProfile?.profileType.toString().toLowerCase() === ProfileType[ProfileType.business]) {
      myFilteredMenu = myFilteredMenu.filter(el => el.nav !== links.moveMoney);
    }

    if (state.availableProfiles.length <= 1) {
      myFilteredMenu = myFilteredMenu.filter(el => el.nav.link !== links.profile.link);
    }

    if (!FEATURE_FLAGS.allowBusinessBanking) {
      myFilteredMenu = myFilteredMenu.filter(el => el.nav.link !== links.profile.link);
    }

    return myFilteredMenu;
  };

  const isNewAccountPage = matchPath(`${links.newAccount.link}/*`, location.pathname);
  const isFundingInstructionsPage = matchPath(`${links.fundingInstructions.link}/*`, location.pathname);

  const getLinkClassname = (isActive: boolean, navTitle: string) =>
    isActive ||
    (isNewAccountPage && navTitle === links.landing.title) ||
    (isFundingInstructionsPage && navTitle === links.landing.title)
      ? cxActive
      : cxClosed;

  const mainNavFilteredMobile = getFilteredMenuItems(mainNav);
  const mainNavFilteredDesktop = mainNavFilteredMobile.filter(el => el.section === MENU_SECTIONS.GENERAL);

  return (
    <div className="navs__container">
      <a className={styles["skip-link"]} href="#wrapper">
        {strings.navigation.skip}
      </a>

      <Flex justify="between" className={cxNav}>
        <nav style={{ display: "contents" }}>
          <div className={cxLogo} style={{ marginRight: "2.5rem" }}>
            <Link to="/" aria-label="Click logo to go to homepage">
              <div className={styles["nav__block-logo--white"]}>
                <LogoWhite className={styles["nav__block-logo__icon"]} />
                <LabelWhite className={styles["nav__block-logo__label"]} />
              </div>
            </Link>
          </div>

          <Flex as="ul" css={inlineStyles.navBar} className={styles.nav__block__list} aria-label="Navigation">
            {mainNavFilteredDesktop.map(({ nav }) => (
              <li style={{ whiteSpace: "nowrap" }} key={`${nav.title}-${nav.link}`}>
                <NavLink
                  className={({ isActive }) => getLinkClassname(isActive, nav.title)}
                  to={nav.link}
                  role="button">
                  <Text color="white" as="span" font="silka" fontWeight="medium" css={{ whiteSpace: "nowrap" }}>
                    {nav.title}
                  </Text>
                  {isInbox(nav.link) && unreadData?.count && (
                    <Badge
                      value={unreadData.count}
                      limit={9}
                      css={{
                        minWidth: "0",
                        margin: "auto 0 auto $2",
                        width: "1.5rem",
                        height: "1.5rem",
                        fontSize: useLimitStyle ? "0.75rem" : "1rem",
                        position: "relative",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        alignContent: "center"
                      }}
                    />
                  )}
                </NavLink>
              </li>
            ))}
            <li className={styles.profileMenu}>
              <Menu onOpenChange={handleProfileMenuOpenChange}>
                <Menu.Trigger className={styles.profileMenu__icon} aria-label="profile_menu">
                  {profileMenuClosed ? (
                    <UserCircleIcon className={styles.profileMenu__icon__profile} />
                  ) : (
                    <CircleCross className={styles.profileMenu__icon__cross} />
                  )}
                </Menu.Trigger>
                <Menu.Content align="start" className={styles.profileMenu__content}>
                  {getFilteredMenuItems(mainNav)
                    .filter(el => el.section === MENU_SECTIONS.PROFILE)
                    .map(({ nav }) => (
                      <Menu.Item
                        css={{
                          ...inlineStyles.profileMenuItem,
                          ...(nav.link === location.pathname && inlineStyles.profileMenuItem.active)
                        }}
                        className={getProfileItemClassName(nav.link)}
                        href={nav.link}
                        key={`${nav.title}-${nav.link}`}>
                        <Text font="silka" css={{ px: "1rem" }}>
                          {nav.title}
                        </Text>
                      </Menu.Item>
                    ))}
                </Menu.Content>
              </Menu>
            </li>
          </Flex>
          <button
            type="button"
            title="Open mobile navigation"
            onClick={() => handleModal()}
            className={styles["nav__block-burger"]}>
            {mobileMenuClosed ? (
              <div style={{ position: "relative" }}>
                <Burger className="icon-burger" color="white" />
                {unreadData?.count && (
                  <Badge
                    value={unreadData.count}
                    limit={9}
                    css={{
                      fontSize: useLimitStyle ? "0.6rem" : "0.75rem",
                      position: "absolute",
                      top: "-0.625rem",
                      right: "-0.625rem",
                      height: "18px",
                      width: "18px",
                      minWidth: "18px",
                      display: "flex",
                      flexWrap: "wrap",
                      justifyContent: "center",
                      alignContent: "center"
                    }}
                  />
                )}
              </div>
            ) : (
              <Close className="icon-close" />
            )}
          </button>
        </nav>
      </Flex>
      {!mobileMenuClosed && (
        <nav className={styles.nav__mobile}>
          <ul role="navigation" aria-label="Navigation" className={` ${styles.nav__block__list__mobile}`}>
            {mainNavFilteredMobile.map(({ nav }) => (
              <li key={`${nav.title}-${nav.link}--mobile`}>
                <NavLink
                  onClick={() => handleModal()}
                  className={({ isActive }) => `${isActive ? cxActive : cxClosed} ${styles["nav__list-item--mobile"]}`}
                  to={nav.link}
                  role="button">
                  <Text color="white" font="silka">
                    {nav.title}
                  </Text>
                  {isInbox(nav.link) && unreadData?.count && (
                    <Badge
                      limit={9}
                      value={unreadData.count}
                      css={{
                        fontSize: useLimitStyle ? "0.6rem" : "0.75rem",
                        display: "flex",
                        flexWrap: "wrap",
                        justifyContent: "center",
                        placeContent: "center",
                        alignContent: "center",
                        alignSelf: "center",
                        width: "18px",
                        minWidth: "18px",
                        height: "18px",
                        marginLeft: "0.6rem",
                        top: "none",
                        right: "none",
                        transform: "none",
                        position: "static"
                      }}
                    />
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
        </nav>
      )}
    </div>
  );
};

export default Navigation;
