import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { trackPage, enableLinkTracking } from "utils/trackerLib";

const usePageTrack = () => {
  const { pathname, search } = useLocation();

  useEffect(() => {
    trackPage();
    enableLinkTracking();
  }, [pathname, search]);
};

export default usePageTrack;
