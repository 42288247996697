import { media } from "config/breakpoints";

const { lg } = media;
export const inlineStyles = {
  list: {
    justifyContent: "space-around",
    flexFlow: "column wrap",
    [lg]: {
      alignItems: "center",
      flexFlow: "row nowrap"
    }
  }
};
