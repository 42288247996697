import useCustomer from "hooks/useCustomer";
import StepsDefault from "pages/BankfastUserMigration/StepIds";
import { useNavigateToStep } from "pages/BankfastUserMigration/StepManagement";
import { BankfastUserMigrationFlow } from "pages/BankfastUserMigration/Steps";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

const useNavigateToBankfastMigration = () => {
  const { pathname, search } = useLocation();
  const [errorOccured, setErrorOccured] = useState(false);

  const { customerData, isCustomerLoading, isCustomerError } = useCustomer(true, !errorOccured);

  const mobilePhoneNumberVerified = customerData?.contactDetails?.mobilePhoneNumberVerified;
  const navigateToStep = useNavigateToStep(
    !mobilePhoneNumberVerified ? BankfastUserMigrationFlow.MOBILE : BankfastUserMigrationFlow.DEFAULT
  );
  const allDataVerified =
    customerData?.contactDetails?.commPreferencesProvided &&
    customerData?.contactDetails?.emailVerified &&
    mobilePhoneNumberVerified;

  useEffect(() => {
    if (!!customerData && !allDataVerified) navigateToStep(StepsDefault.EMAIL);
  }, [customerData, navigateToStep, pathname, search, allDataVerified]);

  useEffect(() => {
    if (isCustomerError && !errorOccured) {
      setErrorOccured(true);
    }
  }, [isCustomerError, errorOccured, setErrorOccured]);

  return { isCustomerLoading, errorOccured };
};

export default useNavigateToBankfastMigration;
