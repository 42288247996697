import strings from "assets/strings/strings.json";
import { serverValidationTexts } from "assets/strings/serverValidationTexts";
import { IPaymentsError } from "services/data/types";
import { GenericObject } from "types/interfaces";
import { getPhoneFormat } from "utils";
import { ACCOUNT_PROPERTIES, API_LIMITS } from "assets/strings/api";

const formatTranferValidation = (input?: Array<IPaymentsError>): string => {
  const bindings: GenericObject = {
    "{{HELP_CENTER_NUMBER}}": getPhoneFormat(strings.general.helpCenterNumber),
    "{{minWithdrawLimit}}": API_LIMITS.MIN_WITHDRAW_LIMIT
  };

  let outputMessage = strings.moveMoney.defaultErrorMessage.replace(
    /{{HELP_CENTER_NUMBER}}/g,
    match => bindings[match]
  );

  if (input?.length) {
    let firstError: Array<IPaymentsError> | IPaymentsError = [
      ...input.filter(error => error.property === ACCOUNT_PROPERTIES.sourceAccount),
      ...input.filter(error => error.property === ACCOUNT_PROPERTIES.destinationAccount),
      ...input.filter(
        error =>
          error.property !== ACCOUNT_PROPERTIES.sourceAccount &&
          error.property !== ACCOUNT_PROPERTIES.destinationAccount
      )
    ];
    [firstError] = firstError;

    outputMessage = firstError.message;

    const error = firstError as IPaymentsError;
    let messageReset: boolean = false;

    serverValidationTexts.forEach(validationText => {
      if (
        !messageReset &&
        ((typeof validationText.code === "string" && error.code === validationText.code) ||
          (Array.isArray(validationText.code) && validationText.code.includes(error.code)))
      ) {
        outputMessage = validationText.message.replace(
          /{{HELP_CENTER_NUMBER}}|{{minWithdrawLimit}}/g,
          match => bindings[match]
        );
        messageReset = true;
      }
    });
  }

  return outputMessage;
};

export default formatTranferValidation;
