import { FC, ReactElement } from "react";

import ConditionalWrapper from "components/ConditionalWrapper";
import isNoStrictAuth from "utils/isNoStrictAuth";
import useScrollToTop from "hooks/useScrollToTop";
import AuthInitialized from "layouts/AuthInitialized";
import Initialized from "layouts/Initialized";
import { Maintenance } from "pages/Maintenance/Maintenance";
import isMaintenance from "utils/isMaintenance";
import { ByPassedAuthUrls } from "./BypassedAuthUrls";

interface IDefaultWrapper {
  children: ReactElement;
}

const DefaultWrapper: FC<IDefaultWrapper> = ({ children }) => {
  useScrollToTop({ condition: false, scrollRestoration: "auto" });

  const renderLayout = (childrenElement: ReactElement) => {
    let wrapper: ReactElement | null = null;

    if (
      isNoStrictAuth() ||
      ByPassedAuthUrls.some(bypassedAuthUrl => window.location.href.indexOf(bypassedAuthUrl.link.slice(0, -1)) !== -1)
    ) {
      wrapper = <Initialized>{childrenElement}</Initialized>;
    } else {
      wrapper = <AuthInitialized>{childrenElement}</AuthInitialized>;
    }

    return wrapper;
  };

  if (isMaintenance()) {
    return <Maintenance />;
  }

  return (
    <ConditionalWrapper wrapper={renderLayout} condition>
      {children}
    </ConditionalWrapper>
  );
};

export default DefaultWrapper;
