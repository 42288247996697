import { contentConfig } from "config/contentConfig";
import { formatBalance } from "utils";

type FormatProductDetailsContent = {
  content: string;
  lowestInterestRate?: number;
  noticePeriodDays?: number;
  maxBalanceSole?: number;
  maxBalanceJoint?: number;
  minBalance?: number;
};

export const formatProductDetailsContent = ({
  content,
  lowestInterestRate,
  noticePeriodDays,
  maxBalanceSole,
  maxBalanceJoint,
  minBalance
}: FormatProductDetailsContent) =>
  content
    .replace("{{LOWEST_INTEREST_RATE}}", lowestInterestRate?.toString() || "")
    .replace("{{LATEST_WITHDRAWAL_TIME}}", contentConfig.latestWithdrawalTime)
    .replace("{{NOTICE_PERIOD_DAYS}}", noticePeriodDays?.toString() || "")
    .replace("{{MAX_BALANCE_SOLE}}", formatBalance(maxBalanceSole || 0, true))
    .replace("{{MAX_BALANCE_JOINT}}", formatBalance(maxBalanceJoint || 0, true))
    .replace("{{LATEST_WITHDRAWAL_TIME}}", contentConfig.latestWithdrawalTime)
    .replace("{{MINIMUM_WITHDRAW_AMOUNT}}", formatBalance(contentConfig.minimumWithdrawAmount, true))
    .replace("{{MIN_BALANCE}}", formatBalance(minBalance || 0, true))
    .replace("{{TAX_SAVING}}", formatBalance(contentConfig.taxSaving, true));
