import { GenericObject } from "types/interfaces";

const fromObjectToParams = (paramsObj: GenericObject = {}): string => {
  let paramsStr = "";
  Object.keys(paramsObj).forEach(key => {
    paramsStr += `${encodeURIComponent(key)}=${encodeURIComponent(paramsObj[key])}&`;
  });

  if (paramsStr.length) {
    paramsStr = `?${paramsStr}`;
  }

  return paramsStr.substring(0, paramsStr.length - 1);
};

export default fromObjectToParams;
