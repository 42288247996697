import { Button, Flex, Heading, Modal, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { AccountDetails } from "pages/NewAccount/PostSubmit/components/AccountDetails/AccountDetails";
import { ModalInstructions } from "pages/NewAccount/PostSubmit/components/ModalInstructions/ModalInstructions";
import { PaymentInfo } from "pages/NewAccount/PostSubmit/components/PaymentInfo/PaymentInfo";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { IIsElectronic, IIsVerified, IPostSubmitModal } from "./interface";
import styles from "./PostSubmitModals.module.scss";

const SuccessModal: FC<IPostSubmitModal & IIsVerified & IIsElectronic> = ({
  name,
  accountDetails,
  isVerified,
  isElectronic
}) => {
  const navigate = useNavigate();

  const handleBackToHome = () => navigate(links.landing.link);

  const { verifiedTitle, unverifiedTitle, verifiedInfoText, unverifiedInfoText, electronicModal, manualModal } =
    strings.newAccount.fundingModal;

  const modalTitle = isVerified ? verifiedTitle.replace("{{name}}", name) : unverifiedTitle.replace("{{name}}", name);
  const electronicInstructions = isVerified ? electronicModal.verified : electronicModal.unverified;
  const manualInstructions = isVerified ? manualModal.verified : manualModal.unverified;

  const modalInstructions = isElectronic ? electronicInstructions : manualInstructions;
  const infoText = isVerified ? verifiedInfoText : unverifiedInfoText;

  return (
    <Modal.Root open>
      <Modal.Content className={styles.modalStyles} onEscapeKeyDown={undefined} onPointerDownOutside={undefined}>
        <Space gap={6}>
          <Heading level={2} fontWeight="semiBold" id="funding-modal-title">
            {modalTitle}
          </Heading>
        </Space>
        <ModalInstructions {...modalInstructions} isVerified={isVerified} />
        {isElectronic && (
          <Space gap={6}>
            <Text as="span">{infoText}</Text>
          </Space>
        )}
        {isVerified && (
          <>
            {!isElectronic && (
              <Space gap={6}>
                <Text as="span">{verifiedInfoText}</Text>
              </Space>
            )}
            <Space gap={6}>
              <AccountDetails {...accountDetails} />
            </Space>
            <Space gap={6}>
              <PaymentInfo />
            </Space>
          </>
        )}
        <Flex className={styles.buttonConatiner} gap="4" justify="end">
          <Button onClick={handleBackToHome} size="large" className={styles.buttonConatiner__buttons}>
            {strings.general.backToHomeButton}
          </Button>
        </Flex>
      </Modal.Content>
    </Modal.Root>
  );
};

export { SuccessModal };
