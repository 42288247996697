import isNoStrictAuth from "utils/isNoStrictAuth";
import { HEADERS } from "assets/strings/api";

const setDefaultAuthHeader = (): string => {
  let value = "";

  if (isNoStrictAuth()) {
    const savingsCustomerId =
      localStorage.getItem("savingsCustomerId") || HEADERS.Authorization.noStrict.defaultSavingsCustomerId;
    value = `${HEADERS.Authorization.noStrict.type} ${HEADERS.Authorization.noStrict.value}`.replace(
      "{{savingsCustomerId}}",
      savingsCustomerId
    );
  }

  return value;
};

export default setDefaultAuthHeader;
