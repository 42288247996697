import { Alert, Card as CardComponent, Icon, Space, Tag, Text } from "@shawbrook/sds-component-library";
import classnames from "classnames";
import { MouseEvent, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import strings from "assets/strings/strings.json";
import { ActionTrigger } from "components/ActionTrigger/ActionTrigger";
import Card from "components/Card/Card";
import cardStyles from "components/Card/Card.module.scss";
import { CardSection } from "components/Card/interfaces";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import useCustomer from "hooks/useCustomer";
import { useLinkedAccountMessage } from "hooks/useLinkedAccountMessage/useLinkedAccountMessage";
import useSrollToTop from "hooks/useScrollToTop";
import { IAllAccounts } from "services/data/types";
import { useMainContext } from "state/mainState";
import { useMoveMoneyContext } from "state/moveMoneyState";
import { getAvailableBalance, getCapitalBalance } from "utils";
import hasLinkedAccountsMissed from "utils/hasLinkedAccountsMissed";
import { getMoveMoneyStateFromStorage } from "utils/sessionStorage";
import styles from "./MoveMoney.module.scss";
import MoveMoneyWrapper from "./MoveMoneyWrapper";

export const MoveMoneyTo = () => {
  const { state, setState } = useMoveMoneyContext();
  const context = useMainContext();
  const navigate = useNavigate();
  useSrollToTop();
  const [selectedAccountId, setSelectedAccountId] = useState<string | null>(null);
  const [isLinkedAccount, setIsLinkedAccount] = useState<boolean | null>(null);
  const [loadingOnReload, setLoadingOnReload] = useState(false);
  const { subjectWithTransfer: subject } = strings.linkedAccountIssue.missing;
  const fromAccountId = state.fromAccount?.accountId;

  const { postThread, isSubmitThreadError } = useLinkedAccountMessage({
    accountId: fromAccountId,
    subject
  });
  const { accountsData, accountDetailData, accountResolved, accountErrored, accountsError, accountError } = useAccount({
    accountId: selectedAccountId
  });
  const {
    customerData,
    isCustomerError: isCustomerDataError,
    isCustomerLoading: isCustomerDataLoading,
    customerError
  } = useCustomer();

  useEffect(() => {
    if (!state.fromAccount) {
      setLoadingOnReload(true);
      const moveMoneyState = getMoveMoneyStateFromStorage();

      if (moveMoneyState?.fromAccount) {
        setState(moveMoneyState);
      } else {
        navigate(links.moveMoney.link);
      }
      setLoadingOnReload(false);
    }
  }, [state.fromAccount, setState, setLoadingOnReload, navigate]);

  useEffect(() => {
    if (accountDetailData && customerData && isLinkedAccount != null && accountErrored === false) {
      if (isLinkedAccount) {
        setState({
          toAccount: {
            id: accountDetailData?.linkedAccount?.id || "",
            accountNumber: accountDetailData?.linkedAccount?.accountNumber || "",
            sortCode: accountDetailData?.linkedAccount?.sortCode || "",
            internal: false,
            customerName: accountDetailData?.linkedAccount?.holderName || "",
            balance: 0,
            availableBalance: 0,
            bankName: accountDetailData?.linkedAccount?.bankName || "",
            accountTitle: strings.moveMoney.linkedAccount
          }
        });
      } else {
        setState({
          toAccount: {
            id: accountDetailData.id,
            accountNumber: accountDetailData.accountNumber,
            sortCode: accountDetailData.sortCode,
            internal: true,
            customerName: `${customerData.name?.title} ${customerData.name?.givenNames?.[0]} ${customerData.name?.familyName}`,
            balance: getCapitalBalance(accountDetailData),
            availableBalance: getAvailableBalance(accountDetailData),
            minBalance: accountDetailData.product.minBalance,
            accountTitle: accountDetailData.product.title || accountDetailData.product.displayName,
            issueNumber: `${strings.general.issueNumber} ${accountDetailData.product.issueNumber}`
          }
        });
      }

      navigate(links.moveMoney.link);
    }
  }, [accountDetailData, accountErrored, customerData, isLinkedAccount, navigate, setState]);

  // show loading screen until page is loaded
  if (!accountResolved || isCustomerDataLoading || loadingOnReload) {
    return (
      <MoveMoneyWrapper
        description={strings.moveMoney.moveMoneyTo.description}
        backUrl={links.moveMoney.link}
        isLoaded={Boolean(accountResolved && isCustomerDataLoading === false)}
      />
    );
  }

  if (accountErrored || isCustomerDataError) {
    return (
      <ErrorRequestView context={{ state, setState }} requestErrors={[customerError, accountsError, accountError]} />
    );
  }

  const setToAccountData = (accountId: string, isLinked: boolean, e: MouseEvent) => {
    e.preventDefault();
    setSelectedAccountId(accountId);
    setIsLinkedAccount(isLinked);
  };

  const handleSendMessage = () => {
    postThread();
  };

  let depositableAccounts: IAllAccounts = [];
  let nonDepositableAccounts: IAllAccounts = [];

  if (accountsData?.length) {
    depositableAccounts = accountsData.filter(
      account =>
        account.id !== state?.fromAccount?.id &&
        state.fromAccount?.withdrawability?.enabled &&
        account.openToFunding.enabled &&
        (account.linkedAccount?.verified || !account.linkedAccount || !account.linkedAccount?.accountNumber) &&
        !account.unauthorisedAccountCredit
    );
    nonDepositableAccounts = accountsData.filter(
      account =>
        (account.id !== state?.fromAccount?.id &&
          (state.fromAccount?.withdrawability?.enabled === false || account.openToFunding.enabled === false)) ||
        account.linkedAccount?.verified === false
    );
  }

  const nonDepositableCx = classnames({
    [styles["page__content-accounts--next"]]: depositableAccounts.length || state.fromAccount
  });

  const isLinkedAccountMissing = state.fromAccount && hasLinkedAccountsMissed([state.fromAccount]);

  if (isSubmitThreadError) {
    return (
      <ErrorRequestView
        requestErrors={[isSubmitThreadError]}
        context={context}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }
  const hasDepositableAccounts =
    depositableAccounts.length > 0 ||
    (state.fromAccount?.linkedAccount?.verified && state.fromAccount?.linkedAccount?.accountNumber);

  const subTitle = hasDepositableAccounts
    ? strings.moveMoney.moveMoneyTo.description
    : strings.moveMoney.moveMoneyTo.noAvailableDescription;

  return (
    <MoveMoneyWrapper
      description={subTitle}
      backUrl={links.moveMoney.link}
      isLoaded={Boolean(accountResolved && isCustomerDataLoading === false)}>
      <section>
        {depositableAccounts.length > 0 && (
          <section>
            {depositableAccounts.map(el => (
              <Link to={links.moveMoney.link} onClick={e => setToAccountData(el.id, false, e)} key={el.id}>
                <Card
                  title={el.product.title || el.product.displayName}
                  issueNumber={`${strings.general.issueNumber} ${el.product.issueNumber}`}
                  balance={getAvailableBalance(el)}
                  sortCode={el.sortCode}
                  accountNumber={el.accountNumber}
                  accountId={el.id}
                  customClasses={cardStyles["card__move-money-account-element"]}
                />
              </Link>
            ))}
          </section>
        )}
        {state.fromAccount != null && state.fromAccount.linkedAccount && state.fromAccount.linkedAccount.accountNumber && (
          <section>
            <Link to={links.moveMoney.link} onClick={e => setToAccountData(state.fromAccount?.id || "", true, e)}>
              <Card
                title={strings.moveMoney.linkedAccount}
                personName={state.fromAccount?.linkedAccount?.holderName || ""}
                accountNumber={state.fromAccount?.linkedAccount?.accountNumber || ""}
                sortCode={state.fromAccount?.linkedAccount?.sortCode || ""}
                bankName={state.fromAccount?.linkedAccount?.bankName || ""}
                customClasses={cardStyles["card__move-money-account-element"]}
              />
            </Link>
          </section>
        )}
        {(nonDepositableAccounts.length > 0 || isLinkedAccountMissing) && (
          <section className={nonDepositableCx}>
            {hasDepositableAccounts && (
              <Text fontWeight="medium" font="silka" css={{ fontSize: "$4", mb: "$3" }}>
                {strings.moveMoney.moveMoneyTo.nonDepositableTitle}
              </Text>
            )}
            {nonDepositableAccounts.map(el => (
              <Card
                key={el.id}
                title={el.product.title || el.product.displayName}
                issueNumber={`${strings.general.issueNumber} ${el.product.issueNumber}`}
                balance={getAvailableBalance(el)}
                sortCode={el.sortCode}
                accountNumber={el.accountNumber}
                accountId={el.id}
                openToFunding={el.openToFunding.enabled}
                openToFundingReason={el.openToFunding.reason}
                category={el.product.category}
                fromAccountWithdrawable={state.fromAccount?.withdrawability?.enabled}
                fromAccountWithdrawableReason={state.fromAccount?.withdrawability?.reason}
                maxBalance={el.product.maxBalance}
                section={CardSection.MOVE_MONEY_TO}
                customClasses={`${cardStyles["card__move-money-account-element"]} ${cardStyles["card__move-money-account-element--inactive"]}`}
                linkedAccount={el.linkedAccount}
                unauthorisedAccountCredit={el.unauthorisedAccountCredit}
              />
            ))}
          </section>
        )}
        {state.fromAccount != null && isLinkedAccountMissing && (
          <section>
            <CardComponent variant="white" width="full">
              <Space gap="2">
                <Tag isLarge>
                  <Text as="span" fontWeight="bold">
                    {strings.moveMoney.linkedAccount}
                  </Text>
                </Tag>
              </Space>
              <Alert
                variant="warning"
                className={styles.alert}
                action={<ActionTrigger actionText={strings.general.sendMessage} action={handleSendMessage} />}
                icon={<Icon name="warning" color="charcoal" />}
                title={strings.moveMoney.nonDepositable.linkedAccountHeading}>
                <Text>{strings.moveMoney.nonDepositable.linkedAccountText}</Text>
              </Alert>
            </CardComponent>
          </section>
        )}
      </section>
    </MoveMoneyWrapper>
  );
};

export default MoveMoneyTo;
