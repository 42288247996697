import {
  Button,
  Card,
  Flex,
  Grid,
  Heading,
  RightArrow,
  Space,
  Text,
  TextInput
} from "@shawbrook/sds-component-library";
import { STATUS_CODE } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import { isAxiosError } from "axios";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import LoadingSection from "components/LoadingSection/LoadingSection";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import { newAccountConfig } from "config/newAccountConfig";
import useCustomer from "hooks/useCustomer";
import useNINumber from "hooks/useNINumber/useNINumber";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useMainContext } from "state/mainState";
import { useNewAccountContext } from "state/newAccountState";
import styles from "./NationalInsurance.module.scss";

export const NationalInsurance = () => {
  const {
    state: { selectedProductSet, niNumber: niNumberFromState, transferISASavingsToCurrentAccount, editMode },
    setState,
    clearState
  } = useNewAccountContext();
  const [niNumber, setNINumber] = useState(niNumberFromState || "");
  const context = useMainContext();
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { customerData, isCustomerError, isCustomerLoading, customerError } = useCustomer();
  const { mutateNINumber, isNINumberLoading, isNINumberError, isPatchNINumberSuccess, niNumberError } = useNINumber(
    niNumber.replaceAll(" ", "").toUpperCase()
  );

  const {
    title,
    niNumber: { heading, secondaryText, niDescription, niTitle, niFormatError, niCheckError }
  } = strings.newAccount;
  const { continueButton } = strings.general;
  const { niNumberRegex, niInputMask, formatChars } = newAccountConfig;

  useEffect(() => {
    if (!selectedProductSet) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet]);

  // client already has an NI number, no need to ask to provide it
  useEffect(() => {
    if (customerData && customerData.personalDetails?.nationalInsuranceNumber?.number) {
      navigate(links.annualSavings.link);
    }
  }, [customerData, navigate]);

  useEffect(() => {
    if (isNINumberError) {
      if (isAxiosError(niNumberError) && niNumberError?.response?.status === STATUS_CODE.BAD_REQUEST)
        setError(niCheckError);
    }
  }, [isNINumberError, niCheckError, niNumberError]);

  useEffect(() => {
    if (isPatchNINumberSuccess) {
      navigate(editMode ? links.newAccountSummary.link : links.annualSavings.link);
    }
  }, [editMode, isPatchNINumberSuccess, navigate]);

  const handleContinue = () => {
    if (!niNumberRegex.test(niNumber)) {
      setError(niFormatError);
      return;
    }
    setState({ niNumber });
    mutateNINumber();
  };

  const handleNIChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError("");
    setNINumber(e.target.value);
  };

  if (
    isCustomerError ||
    (isNINumberError && isAxiosError(niNumberError) && niNumberError?.response?.status !== STATUS_CODE.BAD_REQUEST)
  ) {
    return (
      <ErrorRequestView
        requestErrors={[customerError, niNumberError]}
        context={context}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }

  return (
    <div className={styles.page}>
      <SecondaryHeader
        title={title}
        backLink={transferISASavingsToCurrentAccount === "Yes" ? links.notice.link : links.isaTransfer.link}
        isEdit={editMode}
      />
      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" width="full" className={styles.page__content__card}>
            {(isCustomerLoading || isNINumberLoading) && <LoadingSection />}
            {!isNINumberLoading && customerData && customerData?.personalDetails?.nationalInsuranceNumber && (
              <>
                <Space gap="2">
                  <Heading level={2}>{heading}</Heading>
                </Space>
                <Space gap="2">
                  <Text>{secondaryText}</Text>
                </Space>
                <Space gap="10">
                  <TextInput
                    data-testid="niNumber"
                    id="niNumber"
                    spellCheck={false}
                    value={niNumber}
                    className={styles.page__content__input}
                    onChange={handleNIChange}
                    infoMessage={error}
                    status={error ? "error" : "default"}
                    mask={niInputMask}
                    formatChars={formatChars}
                    maskChar={null}
                    labelText={{
                      main: niTitle,
                      sub: niDescription
                    }}
                    fieldSize="6"
                  />
                </Space>
                <Flex justify="end">
                  <Button
                    iconRight
                    size="large"
                    className={styles.button}
                    disabled={niNumber.length !== 13}
                    onClick={handleContinue}>
                    {continueButton}
                    <RightArrow width="24" height="24" />
                  </Button>
                </Flex>
              </>
            )}
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
