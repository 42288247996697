import {
  Alert,
  Button,
  Card,
  Grid,
  Heading,
  InfoIcon,
  RadioGroup,
  RadioItem,
  RightArrow,
  SelectInput,
  Space,
  Text,
  TextInput
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { IsaDetails, IsaProviders } from "services/data/types";
import { useNewAccountContext } from "state/newAccountState";
import FailureModal from "../../../components/FailureModal/FailureModal";
import { SecondaryHeader } from "../../../components/SecondaryHeader/SecondaryHeader";
import useProviders from "../../../hooks/useProviders";
import { getInitialValue } from "../utils";
import styles from "./ISADetails.module.scss";
import { emptyProvider } from "./utils/mockData";
import { buttonDisableHandler } from "./utils/validations";

export const ISADetails = () => {
  const navigate = useNavigate();
  const { headingText, secondaryText, isaFormDetailsText, isaInvestmentType } = strings.newAccount.ISADetails;
  const {
    providerText,
    providerSubText,
    providerNameText,
    providerNoteText,
    accountNumberText,
    sortCodeText,
    buildingSocietyRollNumberText,
    investmentTypeText
  } = isaFormDetailsText;
  const { title } = strings.newAccount;
  const {
    state,
    state: { selectedProductSet, editMode, editSelection, isaProvidersList },
    setState,
    clearState
  } = useNewAccountContext();
  const isaDetailsInit = getInitialValue(state, editMode, "isaDetails") as IsaDetails;
  const isaDetails = useMemo(() => isaDetailsInit, [isaDetailsInit]);

  const { providersData: initialProvidersData, ...rest } = useProviders(false);

  let providersData = useMemo(() => {
    if (!isaProvidersList) {
      return initialProvidersData;
    }
    return isaProvidersList;
  }, [initialProvidersData, isaProvidersList]);

  useEffect(() => {
    if (providersData && providersData.length > 1) {
      setState({ isaProvidersList: providersData });
    }
  }, [setState, providersData]);

  const { refetchProviders, hasModal, setHasModal } = rest;

  const [selectedIsaProvider, setSelectedIsaProvider] = useState<IsaProviders>(
    isaDetails?.isaProvider || emptyProvider
  );
  const [showIsaProviderNameInput, setShowIsaProviderNameInput] = useState<boolean>(false);
  const [isaProviderName, setIsaProviderName] = useState<string | undefined>(isaDetails?.isaProviderNameIfOther);
  const [accountNumber, setAccountNumber] = useState<string | undefined>(isaDetails?.accountNumber);
  const [sortCode, setSortCode] = useState<string | undefined>(isaDetails?.sortCode);
  const [rollNumber, setRollNumber] = useState<string | undefined>(isaDetails?.buildingSocietyRollNumber);
  const [investmentType, setInvestmentType] = useState<string | undefined>(isaDetails?.investmentType);

  const [providerNameError, setProviderNameError] = useState<string>("");
  const [sortCodeError, setSortCodeError] = useState<string>("");
  const [accountNumberError, setAccountNumberError] = useState<string>("");
  const [buildingSocietyRollNumberError, setBuildingSocietyRollNumberError] = useState<string>("");

  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  useEffect(() => {
    if (selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet]);

  const handleContinue = () => {
    const isaDetailsToSubmit = {
      isaProvider: selectedIsaProvider,
      isaProviderNameIfOther: isaProviderName,
      accountNumber,
      sortCode: sortCode || "",
      buildingSocietyRollNumber: rollNumber,
      investmentType: investmentType || ""
    };

    if (editMode) {
      setState({ editSelection: { ...editSelection, isaDetails: isaDetailsToSubmit } });
    } else {
      setState({ isaDetails: isaDetailsToSubmit });
    }

    navigate(links.fullPartialTransfer.link);
  };

  const handleProviderChange = (providerName: string) => {
    const selectedProvider = providersData?.find((p: { companyName: string }) => p.companyName === providerName);
    if (selectedProvider) {
      setSelectedIsaProvider(selectedProvider);
    }

    if (showIsaProviderNameInput) {
      setIsaProviderName(undefined);
      setProviderNameError("");
    }
  };

  const onChangeHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    if (id === "ISAProviderName") {
      const inputText = event.target.value.slice(0, 60);
      const onlyLetters = value.replace(/[^A-Za-z]/g, "");
      const letterValidationRegex = /^[A-Za-z]+$/;
      const noLeadingSpaces = inputText.replace(/^\s+/, "");
      const isLengthValid = noLeadingSpaces.trim().length >= 2 && noLeadingSpaces.trim().length <= 60;

      if (letterValidationRegex.test(onlyLetters)) {
        setProviderNameError("");
      }

      if (inputText.length === 0) {
        setProviderNameError("");
      }

      if (noLeadingSpaces.trim().length >= 2) {
        setProviderNameError("");
      }
      setIsaProviderName(noLeadingSpaces);
      if (!isLengthValid) {
        setIsDisabled(true);
      }
    }

    if (id === "SortCode") {
      let inputSortCode = value.replace(/\D/g, "");
      inputSortCode = inputSortCode.slice(0, 6);

      if (inputSortCode.length === 6) {
        setSortCodeError("");
      }

      if (inputSortCode.length === 0) {
        setSortCodeError("");
      }

      let formattedSortCode = "";
      for (let i = 0; i < inputSortCode.length; i += 2) {
        formattedSortCode += inputSortCode.slice(i, i + 2);
        if (i < inputSortCode.length - 2) {
          formattedSortCode += "-";
        }
      }
      setSortCode(formattedSortCode);
    }

    if (id === "AccountNumber") {
      const inputAccountNumber = value.slice(0, 15);
      const numericValidationRegex = /^[0-9]*$/;
      setAccountNumber(inputAccountNumber);
      if (numericValidationRegex.test(inputAccountNumber) && inputAccountNumber.length >= 5) {
        setAccountNumberError("");
      }

      if (inputAccountNumber.length === 0) {
        setAccountNumberError("");
      }
    }

    if (id === "BuildingSocietyRollNumber") {
      const inputRollNumber = value.slice(0, 20).toUpperCase();

      // Regular expression to match uppercase letters, digits, hyphens, forward slashes, and full stops
      const validationRegex = /^[A-Z0-9\-./]*$/;
      const numericValidationRegex = inputRollNumber.replace(/[^0-9]/g, "");

      if (inputRollNumber.length === 0) {
        setBuildingSocietyRollNumberError("");
      }

      if (inputRollNumber.length >= 3) {
        setBuildingSocietyRollNumberError("");
      } else if (numericValidationRegex.length >= 3) {
        setBuildingSocietyRollNumberError("");
      }

      if (validationRegex.test(inputRollNumber)) {
        setRollNumber(inputRollNumber);
      }
    }
  };

  const onBlurValidationHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.target;

    if (value.length > 0) {
      if (id === "ISAProviderName") {
        const onlyLetters = value.replace(/[^A-Za-z]/g, "");
        const letterValidationRegex = /^[A-Za-z]+$/;

        if (value.length < 2) {
          setProviderNameError(strings.newAccount.ISADetails.errorMessages.providerNameErrorLength);
        } else if (!letterValidationRegex.test(onlyLetters)) {
          setProviderNameError(strings.newAccount.ISADetails.errorMessages.providerNameErrorLetter);
        }
      }

      if (id === "SortCode") {
        let inputSortCode = value.replace(/\D/g, "");
        inputSortCode = inputSortCode.slice(0, 6);

        if (inputSortCode.length < 6) {
          setSortCodeError(strings.newAccount.ISADetails.errorMessages.sortCodeError);
        }
      }

      if (id === "AccountNumber") {
        const inputAccountNumber = value.slice(0, 15);
        const numericValidationRegex = /^[0-9]*$/;
        if (!numericValidationRegex.test(inputAccountNumber)) {
          setAccountNumberError(strings.newAccount.ISADetails.errorMessages.accountNumberError);
        } else if (inputAccountNumber.length < 5) {
          setAccountNumberError(strings.newAccount.ISADetails.errorMessages.accountLengthError);
        }
      }

      if (id === "BuildingSocietyRollNumber") {
        const inputRollNumber = value.slice(0, 20).toUpperCase();
        const numericValidationRegex = inputRollNumber.replace(/[^0-9]/g, "");
        const noLeadingSlashRegex = /^\/+/;

        if (inputRollNumber.length < 3 && !noLeadingSlashRegex.test(inputRollNumber)) {
          setBuildingSocietyRollNumberError(strings.newAccount.ISADetails.errorMessages.bsrNumberLengthError);
        } else if (numericValidationRegex.length < 3) {
          setBuildingSocietyRollNumberError(strings.newAccount.ISADetails.errorMessages.bsrNumberNumberError);
        }
      }
    }
  };

  useEffect(() => {
    if (selectedIsaProvider?.companyName === "Other") {
      setShowIsaProviderNameInput(true);
    } else {
      setShowIsaProviderNameInput(false);
    }
  }, [selectedIsaProvider]);

  useEffect(() => {
    setIsDisabled(
      buttonDisableHandler(
        selectedIsaProvider,
        showIsaProviderNameInput,
        isaProviderName,
        accountNumber,
        sortCode,
        rollNumber,
        investmentType
      )
    );
  }, [
    selectedIsaProvider,
    showIsaProviderNameInput,
    isaProviderName,
    accountNumber,
    sortCode,
    rollNumber,
    investmentType
  ]);

  useEffect(() => {
    if (!hasModal && !isaProvidersList) {
      refetchProviders();
    }
  }, [isaProvidersList, hasModal, refetchProviders]);

  const modal = hasModal ? (
    <FailureModal
      header={strings.newAccount.ISADetails.failureModal.header}
      content={strings.newAccount.ISADetails.failureModal.content}
      btnContent={strings.newAccount.ISADetails.failureModal.btn}
      open
      customCallback={() => {
        setHasModal(false);
      }}
      url=""
    />
  ) : null;

  if (!Array.isArray(providersData)) {
    providersData = Object.entries(providersData).map(([, value]: [string, any]) => value.companyName);
  }

  return (
    <div className={styles.page}>
      {modal}
      <SecondaryHeader title={title} backLink={links.signpost.link} />
      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" className={styles.page__cardContainer}>
            <Heading level={2}>{headingText}</Heading>
            <Text fontWeight="medium">{secondaryText}</Text>

            <SelectInput
              id="ISAProvider"
              testid="provider-select"
              value={selectedIsaProvider.companyName}
              labelText={{ main: providerText, sub: providerSubText }}
              optionsList={providersData?.map((item: { companyName: string }) => item.companyName)}
              onValueChange={handleProviderChange}
              className={styles.isaDetailsProviderSelect}
              fieldSize="full"
            />
            {showIsaProviderNameInput && (
              <TextInput
                id="ISAProviderName"
                value={isaProviderName}
                labelText={{ main: providerNameText }}
                onChange={onChangeHandler}
                onBlur={onBlurValidationHandler}
                status={providerNameError ? "error" : "default"}
                infoMessage={providerNameError}
              />
            )}
            <Alert title={providerNoteText} icon={<InfoIcon />} />

            <TextInput
              id="AccountNumber"
              labelText={{ main: accountNumberText }}
              value={accountNumber}
              fieldSize="6"
              onChange={onChangeHandler}
              onBlur={onBlurValidationHandler}
              status={accountNumberError ? "error" : "default"}
              infoMessage={accountNumberError}
            />
            <TextInput
              id="SortCode"
              labelText={{ main: sortCodeText }}
              value={sortCode}
              fieldSize="6"
              onChange={onChangeHandler}
              onBlur={onBlurValidationHandler}
              status={sortCodeError ? "error" : "default"}
              infoMessage={sortCodeError}
            />
            <TextInput
              id="BuildingSocietyRollNumber"
              labelText={{ main: buildingSocietyRollNumberText }}
              value={rollNumber}
              fieldSize="6"
              onChange={onChangeHandler}
              onBlur={onBlurValidationHandler}
              status={buildingSocietyRollNumberError ? "error" : "default"}
              infoMessage={buildingSocietyRollNumberError}
            />
            <Space gap={1}>
              <RadioGroup
                id="InvestmentTypeRadio"
                labelText={{ main: investmentTypeText }}
                orientation="vertical"
                value={investmentType}>
                {isaInvestmentType.map(type => (
                  <RadioItem
                    id={type.value}
                    key={type.value}
                    value={type.value}
                    label={type.label}
                    onClick={() => setInvestmentType(type.value)}
                  />
                ))}
              </RadioGroup>
            </Space>

            <Button
              className={styles.page__cardContainer__continueButton}
              iconRight
              disabled={isDisabled}
              type="button"
              size="large"
              onClick={handleContinue}>
              {strings.general.continueButton}
              <RightArrow width="24" height="24" />
            </Button>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
