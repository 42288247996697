import { Alert, Button, RightArrow, Text, WarningIcon } from "@shawbrook/sds-component-library";
import { FC } from "react";
import { IAlertWithActionButtonProps } from "./AlertWithActionButton.interface";
import styles from "./AlertWithActionButton.module.scss";

export const AlertWithActionButton: FC<IAlertWithActionButtonProps> = ({
  icon,
  actionHandler,
  buttonText,
  title,
  body
}) => (
  <Alert
    variant="warning"
    icon={icon || <WarningIcon height="20" width="20" />}
    className={styles.alert}
    title={title}
    action={
      <Button className={styles.alert__button} iconRight onClick={actionHandler} role="link" variant="plain">
        <Text font="silka" className={styles.alert__button__text}>
          {buttonText}
        </Text>
        <RightArrow className={styles.alert__button__icon} height="21" width="21" />
      </Button>
    }>
    <Text>{body}</Text>
  </Alert>
);
