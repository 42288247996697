import { FC } from "react";
import { Text } from "@shawbrook/sds-component-library";
import { FontWeight } from "types/types";
import styles from "../MoveMoney.module.scss";

interface ITransferCard {
  title: string;
  subTitle?: string;
  value?: string;
  subTitleWeight?: FontWeight;
  valueWeight?: FontWeight;
}

export const TransferCard: FC<ITransferCard> = ({
  title,
  subTitle,
  value,
  subTitleWeight = "medium",
  valueWeight = "medium"
}) => (
  <>
    <dt>
      <Text as="span" font="silka" fontWeight="medium" color="grey">
        {title}
      </Text>
    </dt>
    <dd className={styles["move-money__review-body"]}>
      {subTitle && (
        <Text as="span" font="silka" fontWeight={subTitleWeight}>
          {subTitle}
        </Text>
      )}
      {value && (
        <Text as="span" font="silka" fontWeight={valueWeight}>
          {value}
        </Text>
      )}
    </dd>
  </>
);

export default TransferCard;
