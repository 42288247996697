import { useEffect, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { links } from "config/navLinks";
import StepsDefault from "pages/BankfastUserMigration/StepIds";
import { useNavigateToStep } from "pages/BankfastUserMigration/StepManagement";
import { ICustomer } from "services/data/types/entities";
import StepMobile from "../BankfastUserMigrationMobile/StepIds";
import StepDefault from "./StepIds";
import { BankfastUserMigrationFlow } from "./Steps";

const useEnforceUserJourney = (customerData?: ICustomer, formEmail?: string, formMobilePhoneNumber?: string) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { stepId } = useParams();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const emailVerified = customerData?.contactDetails?.emailVerified;
  const mobilePhoneNumberVerified = customerData?.contactDetails?.mobilePhoneNumberVerified;
  const commPreferencesProvided = customerData?.contactDetails?.commPreferencesProvided;

  const currentFlow =
    pathname.indexOf("email-check") !== -1 ? BankfastUserMigrationFlow.DEFAULT : BankfastUserMigrationFlow.MOBILE;
  const allowedFlow =
    !mobilePhoneNumberVerified || formMobilePhoneNumber // to secure staying on mobile flow after complete phone verification
      ? BankfastUserMigrationFlow.MOBILE
      : BankfastUserMigrationFlow.DEFAULT;

  const navigateToStep = useNavigateToStep(allowedFlow);

  const setNextRender = () => {
    if (isFirstRender) {
      setIsFirstRender(false);
    }
  };

  useEffect(() => {
    // ---------------->>> All data filled <<<----------------
    if (customerData) {
      const isWrongFlow = isFirstRender && currentFlow !== allowedFlow;

      if (emailVerified && mobilePhoneNumberVerified && commPreferencesProvided) {
        setNextRender();
        navigate(links.landing.link);

        // ---------------->>> commPreferences verification <<<----------------
      } else if (
        emailVerified &&
        mobilePhoneNumberVerified &&
        !commPreferencesProvided &&
        (stepId !== StepsDefault.GDPR_AND_A11Y || isWrongFlow)
      ) {
        setNextRender();
        navigateToStep(StepsDefault.GDPR_AND_A11Y);

        // ---------------->>> Email verification <<<----------------
      } else if (
        // The Form doesn't contain an email
        !emailVerified &&
        !formEmail && // No email in form
        (stepId !== StepsDefault.EMAIL || isWrongFlow)
      ) {
        setNextRender();
        navigateToStep(StepsDefault.EMAIL);
      } else if (
        // Email exist in form (allow otp page and change email page)
        !emailVerified &&
        !!formEmail && // Email exist in form
        ((stepId !== StepDefault.EMAIL_OTP && stepId !== StepDefault.CHANGE_EMAIL && stepId !== StepDefault.EMAIL) ||
          isWrongFlow) // All steps except Email OTP + Change email + Email
      ) {
        setNextRender();
        navigateToStep(StepsDefault.EMAIL);

        // ---------------->>> MobilePhone verification <<<----------------
      } else if (
        // The Form doesn't contain a phone number
        emailVerified &&
        allowedFlow === BankfastUserMigrationFlow.MOBILE && // only for mobile flow
        !mobilePhoneNumberVerified &&
        !formMobilePhoneNumber && // No phone in form
        (stepId !== StepMobile.MOBILE || isWrongFlow)
      ) {
        setNextRender();
        navigateToStep(StepMobile.MOBILE);
      } else if (
        // Phone exist in form (allow otp page and change email page)
        emailVerified &&
        allowedFlow === BankfastUserMigrationFlow.MOBILE && // only for mobile flow
        !mobilePhoneNumberVerified &&
        formMobilePhoneNumber && // Phone exist in form
        ((stepId !== StepMobile.MOBILE_OTP && stepId !== StepMobile.CHANGE_MOBILE && stepId !== StepMobile.MOBILE) ||
          isWrongFlow) // All steps except phone OTP + change phone + phone
      ) {
        setNextRender();
        navigateToStep(StepMobile.MOBILE);
      }

      setNextRender();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerData]);
};

export default useEnforceUserJourney;
