import { Dispatch } from "react";
import { useOutletContext } from "react-router-dom";

export interface IMainContext {
  state: IState;
  setState: Dispatch<Partial<IState>>;
}

export interface IState {
  isHighTraffic: boolean;
  is404Page: boolean;
}

export const initialState: IState = {
  isHighTraffic: false,
  is404Page: false
};

export const useMainContext = () => useOutletContext<IMainContext>();
