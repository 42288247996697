import { useMsal } from "@azure/msal-react";
import { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOGGED_OUT_TYPE, LoggedOutType } from "types/types";

import strings from "assets/strings/strings.json";
import SystemInfoCard from "components/SystemInfoCard/SystemInfoCard";
import { links } from "config/navLinks";

export const LoggedOut: FC = () => {
  const [mode, setMode] = useState<LoggedOutType | null>(null);
  const { accounts } = useMsal();
  const navigate = useNavigate();

  useEffect(() => {
    const currentMode = localStorage.getItem("loggedOut");

    if (
      accounts?.length > 0 ||
      (!mode && currentMode !== LOGGED_OUT_TYPE.auto && currentMode !== LOGGED_OUT_TYPE.manual)
    ) {
      navigate(links.landing.link);
    }

    if (currentMode === LOGGED_OUT_TYPE.auto || currentMode === LOGGED_OUT_TYPE.manual) {
      setMode(currentMode);
    }

    if (mode) {
      localStorage.removeItem("loggedOut");
    }
  }, [accounts, navigate, mode]);

  const renderPage = () => {
    if (mode) {
      const { title, description, action } = strings.loggedOut[mode];

      return (
        <SystemInfoCard
          title={title}
          description={description}
          actionTitle={action}
          actionHref={process.env.REACT_APP_REDIRECT_URI || "/"}
        />
      );
    }

    return null;
  };

  return renderPage();
};

export default LoggedOut;
