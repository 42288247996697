import useIdleModal from "hooks/useIdleModal";
import strings from "assets/strings/strings.json";
import WarningModal from "components/WarningModal/WarningModal";

const IdleModal = () => {
  const { isIdleModalOpen, handleLogout, handleStayLoggedIn } = useIdleModal();
  const { title, text, decline, accept } = strings.general.modals.idle;

  return (
    <WarningModal
      open={isIdleModalOpen}
      handleClose={handleLogout}
      handleConfirm={handleStayLoggedIn}
      title={title}
      text={text}
      decline={decline}
      accept={accept}
      zIndex={99999}
    />
  );
};

export default IdleModal;
