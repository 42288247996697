import { Label, LabelTextProps, Space, Text } from "@shawbrook/sds-component-library";
import classNames from "classnames";
import { useEffect, useMemo } from "react";
import CurrencyInput from "react-currency-input-field";
import { FieldError, FieldValues, UseControllerProps, useController, useFormContext } from "react-hook-form";
import styles from "../TransferAmount.module.scss";

export type WrappedCurrencyInputProps = {
  prefix?: string;
  label?: LabelTextProps;
  toolTip?: React.ReactNode;
  requiredSign?: boolean;
} & UseControllerProps<FieldValues, string>;

export const WrappedCurrencyInput = ({
  prefix = "",
  label = {},
  name,
  toolTip,
  requiredSign,
  ...rest
}: WrappedCurrencyInputProps) => {
  const { trigger, control, setError } = useFormContext();
  const {
    field: { value, onChange },
    fieldState: { error }
  } = useController({ control, shouldUnregister: true, name, ...rest });

  // Flag to show on UI validation message
  const fieldError = useMemo(() => !!error && error.type !== "custom", [error]);

  useEffect(() => {
    // Set custom error if field is required and has no value to disable continue button on form on load, but don't show error
    if (rest.rules?.required && !value) {
      setError(name, { type: "custom", message: "Initial empty" }, { shouldFocus: true });
    }
    // Trigger validation on load when some values are prepopulated
    if (value) {
      trigger(name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Space gap={10}>
      <div className={styles.page__cardContainer__textInputBlock}>
        <Space gap={fieldError ? "1" : "2"}>
          <Label htmlFor={name} labelText={label} toolTip={toolTip} required={requiredSign} />
        </Space>
        <div
          className={classNames({
            [styles["page__cardContainer__textInputBlock--error"]]: fieldError
          })}>
          {fieldError && (
            <Space gap="2">
              <Text fontWeight="normal" className={styles.page__cardContainer__textInputBlock__inputError}>
                {(error as FieldError).message}
              </Text>
            </Space>
          )}
          <div className={styles.page__cardContainer__textInputBlock__wrapper}>
            <CurrencyInput
              id={name}
              data-testid={name}
              allowNegativeValue={false}
              decimalsLimit={2}
              autoComplete="off"
              prefix={prefix}
              placeholder={prefix}
              aria-labelledby={name}
              defaultValue={value}
              onValueChange={(newValue, fieldName) => {
                onChange(newValue);
                trigger(fieldName);
              }}
            />
          </div>
        </div>
      </div>
    </Space>
  );
};
