import {
  Box,
  Button,
  Card,
  DownloadIcon,
  Flex,
  Heading,
  RightArrow,
  Space,
  Text
} from "@shawbrook/sds-component-library";
import { FC } from "react";

import strings from "assets/strings/strings.json";
import classnames from "classnames";
import { formatBalance } from "utils";
import styles from "./ProductCard.module.scss";
import { inlineStyles } from "./ProductCard.styles";

export const PRODUCT_CARD_SECTION: Readonly<Record<string, string>> = Object.freeze({
  REINVESTMENT: "reinvestment",
  NEW_ACCOUNT: "newAccount",
  NEW_ACCOUNT_DETAILS: "newAccountDetails"
});

interface IProductCardProps {
  displayName: string;
  variableProductType: boolean;
  maxInterestRate: number;
  issueNumber?: number;
  interestRateAnnual?: number;
  interestRateMonthly?: number;
  maxBalanceSole: number | null;
  maxBalanceJoint?: number | null;
  pdf: string;
  onSelect: () => void;
  interestRateText?: string;
  section?: string;
}

export const ProductCard: FC<IProductCardProps> = ({
  displayName,
  variableProductType,
  interestRateAnnual,
  interestRateMonthly,
  maxInterestRate,
  maxBalanceSole,
  maxBalanceJoint,
  issueNumber,
  pdf,
  onSelect,
  interestRateText,
  section = PRODUCT_CARD_SECTION.REINVESTMENT
}) => {
  const {
    interest: { interestRate, variable, fixed },
    gross: { gross, monthly, annually },
    maxBalance: { maxBalance, sole, joint },
    summaryPdf
  } = strings.selectAccount.productCard;
  const { viewDetails } = strings.general;
  const { apply } = strings.newAccount.accountDetails;

  const cxHeading = classnames(styles.productCard__header, {
    [styles["productCard__header--shortMargin"]]: section === PRODUCT_CARD_SECTION.NEW_ACCOUNT,
    [styles["productCard__header--accDetails"]]: section === PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS
  });

  const cxCard = classnames({
    [styles.productCard__cardContainer__card]: section === PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS
  });

  const cxContainerCard = classnames(styles.productCard__cardContainer, {
    [styles["productCard__cardContainer--accDetails"]]: section === PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS
  });

  let actionName = viewDetails;
  if (section === PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS) {
    actionName = apply;
  }
  const interestRateTextToDisplay = interestRateText || (variableProductType ? variable : fixed);
  return (
    <Card className={cxContainerCard}>
      <Heading as="h3" level={5} className={cxHeading}>
        <Flex className={styles.productCard__header__content} css={inlineStyles.title}>
          <Box className={styles.productCard__header__content__productTitle}>
            <Text>{displayName}</Text>
            {issueNumber && (
              <Text as="span" font="silka" fontWeight="medium">
                {` ${strings.general.issueNumber} ${issueNumber}`}
              </Text>
            )}
          </Box>
          {section !== PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS && (
            <Button
              as="a"
              iconRight
              variant="plain"
              target="_blank"
              className={styles.productCard__header__content__download}
              css={inlineStyles.download}
              href={pdf}>
              {summaryPdf}
              <DownloadIcon />
            </Button>
          )}
          {section === PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS && (
            <Box className={styles["productCard__selectButton--accDetails"]} css={{ marginRight: "0" }}>
              <Button iconRight type="button" size="medium" onClick={onSelect}>
                {actionName}
                <RightArrow width="24" height="24" />
              </Button>
            </Box>
          )}
        </Flex>
      </Heading>
      <Card
        width="full"
        variant={section === PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS ? "filled" : "raised"}
        className={cxCard}>
        <Flex justify="between" gap="3" className={styles.productCard__content}>
          <div className={styles.productCard__mobileGroup2}>
            <Flex direction="column" className={styles.productCard__interest}>
              <Space gap="2">
                <Text fontWeight="medium" color="grey">
                  {interestRate}
                </Text>
              </Space>
              <div className={styles.productCard__interest__info}>
                <Text as="span" textStyle="xlarge" className={styles.productCard__interest__info__rate}>
                  {maxInterestRate.toFixed(2)}%{" "}
                </Text>
                <Text
                  as="span"
                  textStyle="body"
                  fontWeight="medium"
                  className={styles.productCard__interest__info__interestRateText}>
                  {interestRateTextToDisplay}
                </Text>
              </div>
            </Flex>

            <div className={styles.productCard__mobileGroup}>
              <Flex direction="column" className={styles.productCard__information}>
                <Space gap="2">
                  <Text fontWeight="medium" color="grey">
                    {gross}
                  </Text>
                </Space>
                {typeof interestRateAnnual !== "undefined" && (
                  <Space gap="1">
                    <Text fontWeight="semiBold" className={styles.productCard__information__detail}>
                      {`${interestRateAnnual.toFixed(2)}% `}
                      <span>{annually}</span>
                    </Text>
                  </Space>
                )}
                {typeof interestRateMonthly !== "undefined" && (
                  <Text fontWeight="semiBold" className={styles.productCard__information__detail}>
                    {`${interestRateMonthly.toFixed(2)}% `}
                    <span>{monthly}</span>
                  </Text>
                )}
              </Flex>
              <Flex
                direction="column"
                className={`${styles.productCard__information} ${styles.productCard__maxBalance}`}>
                <Space gap="2">
                  <Text fontWeight="medium" color="grey">
                    {maxBalance}
                  </Text>
                </Space>
                {maxBalanceSole && maxBalanceJoint ? (
                  <>
                    <Space gap="1">
                      <Text fontWeight="semiBold" className={styles.productCard__information__detail}>
                        {`${formatBalance(maxBalanceSole, true)} `}
                        <span>{sole}</span>
                      </Text>
                    </Space>
                    <Text fontWeight="semiBold" className={styles.productCard__information__detail}>
                      {`${formatBalance(maxBalanceJoint, true)} `}
                      <span>{joint}</span>
                    </Text>
                  </>
                ) : (
                  <Text fontWeight="semiBold" className={styles.productCard__information__detail}>
                    {`${formatBalance(maxBalanceSole || 0, true)}`}
                  </Text>
                )}
              </Flex>
            </div>
          </div>
          {section !== PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS && (
            <div className={styles.productCard__selectButton}>
              <Button iconRight type="button" size="medium" onClick={onSelect}>
                {actionName}
                <RightArrow width="24" height="24" />
              </Button>
            </div>
          )}
        </Flex>
      </Card>
    </Card>
  );
};
