import useAvailableProducts from "hooks/useAvailableProducts";
import { IUsePopulateProductSet } from "hooks/usePopulateProductSet/interfaces";
import { useEffect } from "react";
import { useReinvestmentContext } from "state/reinvestmentState";
import { formatProductsGroup } from "utils/formatAvailableProducts/formatProductsGroup";

const usePopulateProductSet = ({ accountId, condition, setCondition }: IUsePopulateProductSet) => {
  const { state, setState } = useReinvestmentContext();

  const { availableProductsData, isAvailableProductsError } = useAvailableProducts({
    accountId,
    enabled: condition
  });

  useEffect(() => {
    const selectedProductSetName = state[accountId]?.selectedProductSetName || "";
    const selectedProductGroup = availableProductsData?.find(
      productsGroup => productsGroup.products[0]?.title === selectedProductSetName
    );

    if (condition && availableProductsData && selectedProductGroup) {
      const selectedProductSet = formatProductsGroup(selectedProductGroup);
      setState({
        accountId,
        partialData: {
          selectedProductSet: selectedProductSet || undefined
        }
      });

      if (setCondition) {
        setCondition(false);
      }
    }
  }, [accountId, availableProductsData, setState, state, condition, setCondition]);

  const noSelectedProductSet = !state[accountId]?.selectedProductSet;

  return {
    isAvailableProductsError,
    noSelectedProductSet
  };
};

export default usePopulateProductSet;
