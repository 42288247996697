import { useCallback, useEffect, useRef, useState } from "react";

import { CURRENCIES, REG_EXPS } from "assets/strings/api";
import { getOppositeBalanceInput } from "utils";
import { inputFormatter } from "utils/inputFormatter";

const useCurrencyInput = (initialBalance: string) => {
  const [amount, setAmount] = useState<string>(initialBalance);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [isInputRef, setIsInputRef] = useState(false);

  const onInputRefChange = useCallback((node: HTMLInputElement | null) => {
    if (node) {
      inputRef.current = node;
      setIsInputRef(true);
    }
  }, []);

  useEffect(() => {
    if (isInputRef && inputRef.current) {
      inputFormatter(inputRef.current, inputRef.current.value, CURRENCIES.GBP, REG_EXPS.AMOUNT_TO_REINVEST_PREFIX);
    }
  }, [isInputRef]);

  useEffect(() => {
    if (!amount) {
      inputFormatter(inputRef.current, amount, CURRENCIES.GBP, REG_EXPS.AMOUNT_TO_REINVEST_PREFIX);
    }
  }, [amount]);

  const handleChange = (
    value: string,
    balance?: number,
    setOppositeValue?: React.Dispatch<React.SetStateAction<string>>,
    formattedValueArg?: string
  ) => {
    if (balance !== undefined && parseFloat(value) > balance) return;

    setAmount(value);
    if (setOppositeValue && balance) {
      setOppositeValue(getOppositeBalanceInput(value, balance));
    }

    const formattedValue = formattedValueArg || "";
    inputFormatter(inputRef.current, formattedValue, CURRENCIES.GBP, REG_EXPS.AMOUNT_TO_REINVEST_PREFIX);
  };

  return { amount, setAmount, handleChange, onInputRefChange, inputRef };
};

export default useCurrencyInput;
