import {
  Alert,
  Button,
  Card,
  CashIcon,
  CreditCardIcon,
  CurrencyPoundIcon,
  Grid,
  Heading,
  InfoIcon,
  List,
  RightArrow,
  Space,
  Text
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { contentConfig } from "config/contentConfig";
import FEATURE_FLAGS from "config/featureFlags";
import { links } from "config/navLinks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import rreplace from "react-string-replace";
import { useNewAccountContext } from "state/newAccountState";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import styles from "./Signpost.module.scss";

export const SignPost = () => {
  const navigate = useNavigate();
  const { headingText, secondaryHeading, detailHeading, detailsNeed, description, hideIsaTransfers } =
    strings.newAccount.signpost;
  const { title } = strings.newAccount;
  const {
    state: { selectedProductSet },
    clearState
  } = useNewAccountContext();

  const formattedSecondaryHeading = rreplace(
    formatTextWithPhoneHours(FEATURE_FLAGS.hideIsaTransfers ? hideIsaTransfers.secondaryHeading : secondaryHeading),
    "{{SEND_US_A_MESSAGE}}",
    () => (
      <Button
        key="sendUsMessage"
        data-testid="sendUsMessage-link"
        onClick={() => navigate(links.inbox.link)}
        style={{ fontFamily: "inter", lineHeight: "0.75rem" }}
        variant="tertiary"
        isSlim>
        {strings.general.ask.toLowerCase()}
      </Button>
    )
  );

  useEffect(() => {
    if (selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet]);

  const handleContinue = () => {
    if (FEATURE_FLAGS.hideIsaTransfers) {
      return navigate(links.landing.link);
    }

    return navigate(links.isaDetails.link);
  };

  const getIcon = (name: string) => {
    switch (name) {
      case "creditCard":
        return <CreditCardIcon width="48" height="48" />;
      case "cash":
        return <CashIcon width="48" height="48" />;
      case "currencyPound":
        return <CurrencyPoundIcon width="48" height="48" />;
      default:
        return null;
    }
  };

  const HideIsaTransfersItemsWithIcon = hideIsaTransfers.detailsNeed.map(item => {
    const key = Object.keys(item)[0] as keyof typeof item;
    return (
      <List.Item className={styles.page__cardContainer__listItem} key={key}>
        <List.ItemIcon color="primary">{getIcon(key)}</List.ItemIcon>
        {item[key]}
      </List.Item>
    );
  });

  const ListItemsWithIcon = detailsNeed.map(item => {
    const key = Object.keys(item)[0] as keyof typeof item;
    return (
      <List.Item className={styles.page__cardContainer__listItem} key={key}>
        <List.ItemIcon color="primary">{getIcon(key)}</List.ItemIcon>
        {item[key]}
      </List.Item>
    );
  });

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={links.isaTransfer.link} />

      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" className={styles.page__cardContainer}>
            <Heading level={2}>{FEATURE_FLAGS.hideIsaTransfers ? hideIsaTransfers.headingText : headingText}</Heading>
            <Space gap="2" />
            <Text>{formattedSecondaryHeading}</Text>

            <Heading level={3}>{detailHeading}</Heading>
            <Space gap="6">
              <List noMargin noPadding>
                {FEATURE_FLAGS.hideIsaTransfers ? HideIsaTransfersItemsWithIcon : ListItemsWithIcon}
              </List>
            </Space>

            <Text>
              {description
                .replace("{{FROM_DAY}}", contentConfig.isaTransferDays.from.toString())
                .replace("{{TO_DAY}}", contentConfig.isaTransferDays.to.toString())}
            </Text>

            {FEATURE_FLAGS.hideIsaTransfers && (
              <>
                <Space gap="6" />
                <Alert icon={<InfoIcon />} title={hideIsaTransfers.alertText} />
              </>
            )}
            <Space gap="10" />

            <Button
              className={styles.page__cardContainer__backToHomeButton}
              iconRight
              type="button"
              size="large"
              onClick={handleContinue}>
              {FEATURE_FLAGS.hideIsaTransfers ? strings.general.backToHomeButton : strings.general.continueButton}
              <RightArrow width="24" height="24" />
            </Button>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
