export const inputFormatter = (inputArg: HTMLInputElement | null, value: string, prefix: string, regexp: RegExp) => {
  const input = inputArg;
  const changedValue = regexp.test(value) ? value : `${prefix}${value}`;
  if (changedValue !== value || changedValue === "£ ") {
    Promise.resolve().then(() => {
      if (input) {
        input.value = changedValue;
      }
    });
  }
};

export const inputFormatterPreviousValue = (
  inputArg: HTMLInputElement | null,
  value: string | undefined,
  previousValue: string | undefined,
  regexp: RegExp
) => {
  const input = inputArg;
  const newValue = regexp.test(value || "") ? value : previousValue;
  if (newValue !== value) {
    Promise.resolve().then(() => {
      if (input) {
        input.value = newValue || "";
      }
    });
  }
};
