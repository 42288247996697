import { Button, Flex, Loader, TextAreaInput } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { FC, useEffect, useState } from "react";

import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import useSubmitMessages from "hooks/useSubmitMessages";
import { useInboxContext } from "state/inboxState";

interface IInboxActionBarTextarea {
  threadId: string;
  isDisabled: boolean;
}

export const InboxActionBarTextarea: FC<IInboxActionBarTextarea> = ({ threadId, isDisabled }) => {
  const context = useInboxContext();
  const [loading, setLoading] = useState(false);
  const [newMessage, setNewMessage] = useState("");
  const { postMessages, isSubmitMessagesLoading, isSubmitMessagesError, submitMessagesError } = useSubmitMessages({
    threadId,
    message: newMessage,
    inboxContext: context
  });

  useEffect(() => {
    if (isSubmitMessagesLoading) {
      // As we're displaying the sent message immediately, even before it's confirmed
      // to be sent successfully, I also made the loading state to be set to false
      // together with the new message clearing.
      setLoading(false);
      // clear out the message
      setNewMessage("");
    }
  }, [isSubmitMessagesLoading]);

  if (isSubmitMessagesError) {
    return (
      <ErrorRequestView requestErrors={[submitMessagesError]} context={context} errorModal={<ErrorModal open />} />
    );
  }

  const handleSendMessage = () => {
    if (newMessage.trim() === "") return;
    setLoading(true);
    postMessages();
  };

  return (
    <section>
      <TextAreaInput
        value={newMessage}
        onChange={e => setNewMessage(e.target.value)}
        labelText={{ main: strings.inbox.replyLabel }}
        aria-label={strings.inbox.replyLabel}
        css={{ "> textarea": { fontFamily: "inherit" }, pb: "$5" }}
        resize="vertical"
      />
      <Flex
        justify="end"
        css={{
          pb: "$5"
        }}>
        <Button
          disabled={loading || isDisabled}
          onClick={handleSendMessage}
          size="medium"
          css={{ width: "100%", "@lg": { width: "10rem" } }}>
          {loading ? <Loader size="small" /> : strings.inbox.send}
        </Button>
      </Flex>
    </section>
  );
};
export default InboxActionBarTextarea;
