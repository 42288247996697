import { GlobalContextActionType, Profile } from "types/types";

export function GlobalContextSetSelectedProfile(selectedProfile: Profile) {
  return { type: GlobalContextActionType.setSelectedProfile, payload: selectedProfile };
}

export function GlobalContextSetAvailableProfiles(availableProfiles: Profile[]) {
  return { type: GlobalContextActionType.setAvailableProfiles, payload: availableProfiles };
}

export function GlobalContextSetCustomerId(customerId: string | undefined) {
  return { type: GlobalContextActionType.setCustomerId, payload: customerId };
}

export function GlobalContextSetHasAccounts(hasAccounts: boolean) {
  return { type: GlobalContextActionType.setHasAccounts, payload: hasAccounts };
}
