import { Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { TransferStatus } from "services/data/types";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import { IErrorMessageInfo } from "utils/getErrorMessageInfo/interfaces";

const getErrorMessageInfo = (transferStatus?: TransferStatus): IErrorMessageInfo => {
  const transferStatusStr = transferStatus as keyof typeof strings.moveMoney.modals.error.transfers;
  let {
    heading,
    text: { p1, p2 }
  } = strings.moveMoney.modals.error;

  if (transferStatusStr && strings.moveMoney.modals.error.transfers[transferStatusStr]) {
    ({
      heading,
      text: { p1, p2 }
    } = strings.moveMoney.modals.error.transfers[transferStatusStr]);
  }

  p1 = formatTextWithPhoneHours(p1);
  if (p2) {
    p2 = formatTextWithPhoneHours(p2);
  }

  const p1Margin = p2 ? "$12" : 0;

  return {
    heading,
    content: (
      <>
        <Text textStyle="medium" font="silka" css={{ marginBottom: p1Margin }}>
          {p1}
        </Text>
        {p2 && (
          <Text textStyle="medium" font="silka">
            {p2}
          </Text>
        )}
      </>
    )
  };
};

export default getErrorMessageInfo;
