import {
  Alert,
  Box,
  Button,
  Card,
  ErrorIcon,
  Heading,
  InfoIcon,
  Loader,
  RadioGroup,
  RadioItem,
  Text
} from "@shawbrook/sds-component-library";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ArrowLeft, ArrowRight } from "assets/images";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import { IconButton } from "components/IconButton/IconButton";
import { ReinvestmentAccounts } from "components/ReinvestmentAccounts/ReinvestmentAccounts";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import usePopulateProductSet from "hooks/usePopulateProductSet";
import useSrollToTop from "hooks/useScrollToTop";
import { InstructionType, ProductCategory } from "services/data/types";
import { REINVESTEMENT_TYPES, ReinvestmentType, useReinvestmentContext } from "state/reinvestmentState";
import { formatBalance, getReinvestmnentType } from "utils";
import getReinvestmentAccountData from "utils/getReinvestmentAccountData";
import { headerStyles } from "../HeaderStyles.styles";
import styles from "./ChooseReinvestment.module.scss";

export const ChooseReinvestment: FC = () => {
  const { state, setState } = useReinvestmentContext();
  useSrollToTop();
  const params = useParams();
  const accountId = params.id || "";
  const reinvestmentType = getReinvestmnentType(state[accountId]?.instructions);
  const [value, setValue] = useState<ReinvestmentType | undefined>(reinvestmentType);
  const navigate = useNavigate();
  const { accountDetailData } = useAccount({ accountId });
  const { noSelectedProductSet, isAvailableProductsError } = usePopulateProductSet({
    accountId,
    condition: !state[accountId]?.selectedProductSet
  });

  const { title } = strings.reinvestment;
  const { question, options, maxBalanceErr, partialISAInfo } = strings.chooseReinvestment;
  const { continueButton } = strings.general;

  useEffect(() => {
    if (typeof accountId !== "string" || typeof state[accountId]?.selectedProductSetName === "undefined") {
      navigate(`${links.maturityInstruction.link}/select-account/${accountId}`);
    }
  }, [state, accountId, navigate]);

  const productDisplayName = state[accountId]?.selectedProductSetName || "";
  const productIssueNumber = state[accountId]?.selectedProductSet?.products[0].issueNumber
    ? `${strings.general.issueNumber} ${state[accountId]?.selectedProductSet?.products[0].issueNumber}`
    : "";
  const {
    accountDisplayName,
    accountIssueNumberText: accountIssueNumber,
    accountBalance,
    accountType,
    accountProductCategory
  } = getReinvestmentAccountData({ accountDetailData, state, accountId });

  const productMaxBalance =
    accountType === "Single"
      ? state[accountId]?.selectedProductSet?.soleMaxBalance
      : state[accountId]?.selectedProductSet?.jointMaxBalance;
  const shouldDisplayErr = !!(
    productMaxBalance &&
    accountBalance &&
    productMaxBalance < accountBalance &&
    value === REINVESTEMENT_TYPES.full
  );
  const showPartialISAInfo =
    accountProductCategory === ProductCategory.FIXED_RATE_ISA && value === REINVESTEMENT_TYPES.partial;

  if (isAvailableProductsError) {
    return <ErrorModal open allPages />;
  }

  const handleValueChange = (e: ReinvestmentType) => {
    setValue(e);
  };

  const handleEdit = () => {
    navigate(links.selectAccount.link.replace(":id", accountId));
  };

  const handleContinue = () => {
    const reinvestNewState = state[accountId]?.instructions?.find(el => el.type === InstructionType.REINVEST_NEW);
    const reinvestWithdrawState = state[accountId]?.instructions?.find(el => el.type === InstructionType.WITHDRAW);
    const amountToReinvestValue =
      (reinvestmentType === REINVESTEMENT_TYPES.full ? accountBalance : reinvestNewState?.amount?.amount) || 0;

    if (value === REINVESTEMENT_TYPES.full) {
      setState({
        accountId,
        partialData: {
          instructions: [
            {
              type: InstructionType.REINVEST_NEW,
              useRemainingBalance: true,
              priority: 1
            }
          ]
        }
      });
      navigate(links.chooseInterest.link.replace(":id", accountId));
    } else {
      setState({
        accountId,
        partialData: {
          instructions: [
            {
              type: InstructionType.REINVEST_NEW,
              useRemainingBalance: false,
              priority: 1,
              ...(reinvestNewState?.toProduct && { toProduct: { ...reinvestNewState.toProduct } }),
              ...(reinvestNewState &&
                amountToReinvestValue != null && { amount: { amount: amountToReinvestValue, currency: "GBP" } })
            },
            {
              type: InstructionType.WITHDRAW,
              useRemainingBalance: true,
              priority: 2,
              ...(reinvestWithdrawState?.amount && { amount: { ...reinvestWithdrawState.amount } })
            }
          ]
        }
      });
      navigate(links.amountToReinvest.link.replace(":id", accountId));
    }
  };

  return (
    <div className="chooseReinvestment">
      <Box as="header" css={headerStyles.header}>
        <IconButton onClick={() => navigate(-1)} icon={<ArrowLeft />} text={strings.general.back} />
        <Heading color="white">{title}</Heading>
      </Box>
      <section className={styles.page__content}>
        <Card css={{ width: "100%", height: "fit-content" }}>
          {noSelectedProductSet && (
            <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
          )}
          {!noSelectedProductSet && (
            <>
              <div className="radioLabel">
                <RadioGroup
                  value={value}
                  css={{ marginTop: "1.25rem" }}
                  onValueChange={handleValueChange}
                  id="choose-reinvestment"
                  labelText={{ main: question }}
                  ariaLabel="choose reinvestment"
                  orientation="vertical">
                  {options.map(({ label, value: optionValue }) => (
                    <div key={optionValue}>
                      <RadioItem value={optionValue} label={label} id={optionValue} aria-label={label} />
                      {optionValue === REINVESTEMENT_TYPES.full && shouldDisplayErr && (
                        <Alert
                          icon={<ErrorIcon />}
                          variant="error"
                          css={{ marginTop: "1.25rem", marginBottom: "0.25rem" }}>
                          <Text fontWeight="medium">
                            {maxBalanceErr.replace("{{BALANCE}}", formatBalance(productMaxBalance))}
                          </Text>
                        </Alert>
                      )}
                      {optionValue === REINVESTEMENT_TYPES.partial && showPartialISAInfo && (
                        <Alert icon={<InfoIcon />} css={{ marginTop: "$3", padding: "$2 $2-5" }}>
                          <Text>{partialISAInfo}</Text>
                        </Alert>
                      )}
                    </div>
                  ))}
                </RadioGroup>
              </div>
              <div className={styles.continueButton}>
                <Button
                  iconRight
                  type="button"
                  disabled={!value || shouldDisplayErr}
                  size="medium"
                  onClick={handleContinue}>
                  {continueButton}
                  <ArrowRight />
                </Button>
              </div>
            </>
          )}
        </Card>
        <aside>
          <ReinvestmentAccounts
            accountDisplayName={accountDisplayName}
            accountIssueNumber={accountIssueNumber}
            accountBalance={accountBalance || 0}
            productDisplayName={productDisplayName}
            productIssueNumber={productIssueNumber}
            handleEdit={handleEdit}
          />
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};

export default ChooseReinvestment;
