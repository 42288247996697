import { useQuery } from "@tanstack/react-query";
import { getLinkedAccounts } from "services/data/api";
import { QueryKeys } from "services/data/types";

const useNewAccountLinkedAccounts = () => {
  const {
    data: linkedAccountsData,
    isLoading: linkedAccountsDataLoading,
    isError: linkedAccountsDataError,
    isFetched: linkedAccountsDataFetched
  } = useQuery({
    queryKey: [QueryKeys.LINKED_ACCOUNTS],
    queryFn: () => getLinkedAccounts()
  });
  const hasVerifiedLinkedAccounts =
    linkedAccountsDataFetched && !linkedAccountsDataError && linkedAccountsData && linkedAccountsData.length > 0;

  return {
    linkedAccountsData,
    linkedAccountsDataLoading,
    linkedAccountsDataError,
    hasVerifiedLinkedAccounts,
    linkedAccountsDataFetched
  };
};

export default useNewAccountLinkedAccounts;
