import { useMsal } from "@azure/msal-react";
import { AuthenticationResult } from "@azure/msal-browser";
import { useEffect, useState } from "react";

import { QUERY_PARAMS } from "assets/strings/queryParams";
import getAccessToken from "utils/getAccessToken";
import sendUserToLoginPage from "utils/sendUserToLoginPage";
import { initialize } from "utils/trackerLib";

const useAuthInitialized = () => {
  const { instance, inProgress } = useMsal();
  const [firstTokenData, setFirstTokenData] = useState<AuthenticationResult | null>(null);

  useEffect(() => {
    (async () => {
      if (!firstTokenData) {
        const customerNumber = new URLSearchParams(window.location.search).get(QUERY_PARAMS.CUSTOMER_NUMBER);

        sendUserToLoginPage(instance, inProgress, customerNumber);
        const tokenData = await getAccessToken(instance);

        if (tokenData.idToken) {
          setFirstTokenData(tokenData);
        }
      }
    })();
  }, [instance, inProgress, firstTokenData, setFirstTokenData]);

  useEffect(() => {
    if (firstTokenData) {
      initialize();
    }
  }, [firstTokenData]);

  return { firstTokenData };
};

export default useAuthInitialized;
