export default {
  card: {
    padding: "$10",
    margin: "3.75rem auto $6",
    width: "70%",
    "& p": {
      fontFamily: "var(--fonts-display)",
      whiteSpace: "pre-wrap"
    }
  }
};
