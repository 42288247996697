import { FC } from "react";

import { Alert, Button, Flex, Heading, InfoIcon, Modal, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { useNavigate } from "react-router-dom";
import { IHasEa, INearlyThereModal } from "./interface";
import styles from "./PostSubmitModals.module.scss";

const MoveMoneyInfoText: FC = () => (
  <Alert icon={<InfoIcon />} css={{ padding: "$2 $4", marginBottom: "$6" }}>
    <Text font="inter" fontWeight="bold">
      {strings.newAccount.nearlyThereModal.infoText}
    </Text>
  </Alert>
);
const NearlyThereModal: FC<INearlyThereModal & IHasEa> = ({
  name,
  // Account id is not in use for now, will be required when we resolve the queries in the ticket SDD-3496
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  newAccountId,
  hasEa
}) => {
  const navigate = useNavigate();

  const handleBackToHome = () => navigate(links.landing.link);
  const handleMoveMoney = () => navigate(links.moveMoney.link);

  const { title, message, fundingInstructions } = strings.newAccount.nearlyThereModal;

  return (
    <Modal.Root open>
      <Modal.Content className={styles.modalStyles} onEscapeKeyDown={undefined} onPointerDownOutside={undefined}>
        <Space gap={6}>
          <Heading level={2} fontWeight="semiBold" id="funding-modal-title">
            {title.replace("{{name}}", name)}
          </Heading>
        </Space>
        <Space gap={6}>
          <Text textStyle="medium" font="silka">
            {message}
          </Text>
        </Space>
        <Space gap={6}>
          <Text as="span" font="inter">
            {fundingInstructions}
          </Text>
        </Space>
        {hasEa && <MoveMoneyInfoText />}
        <Flex className={styles.buttonConatiner} gap="4" justify="end">
          <Button
            variant={hasEa ? "secondary" : "primary"}
            onClick={handleBackToHome}
            size="large"
            className={styles.buttonConatiner__buttons}
            css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
            {strings.general.backToHomeButton}
          </Button>
          {hasEa && (
            <Button
              onClick={handleMoveMoney}
              size="large"
              className={styles.buttonConatiner__buttons}
              css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
              {strings.navigation.moveMoney}
            </Button>
          )}
        </Flex>
      </Modal.Content>
    </Modal.Root>
  );
};

export { NearlyThereModal };
