import { Flex, Loader } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import classnames from "classnames";
import IdleModal from "components/IdleModal";
import FEATURE_FLAGS from "config/featureFlags";
import useNavigateToBankfastMigration from "hooks/useNavigateToBankfastMigration";
import usePageTrack from "hooks/usePageTrack";
import { useServiceTick } from "hooks/useServiceTick/useServiceTick";
import DefaultLayout from "layouts/DefaultLayout";
import ComingSoon from "pages/ComingSoon";
import "pages/Landing/Landing.module.scss";
import { useReducer } from "react";
import { Outlet } from "react-router-dom";
import { INewAccountState, initialNewAccountState } from "state/newAccountState";

export const NewAccountLayout = () => {
  usePageTrack();
  useServiceTick();
  const { isCustomerLoading, errorOccured } = useNavigateToBankfastMigration();

  const [state, setState] = useReducer(
    (data: INewAccountState, partialData: Partial<INewAccountState>) => ({
      ...data,
      ...partialData
    }),
    initialNewAccountState
  );

  const clearState = () => {
    setState({
      editMode: false,
      selectedProductSet: undefined,
      selectedFrequency: undefined,
      selectedProductId: undefined,
      annualSavings: undefined,
      linkedAccount: undefined,
      accountType: undefined,
      interestPaymentOption: undefined,
      transferISASavingsToCurrentAccount: undefined,
      niNumber: undefined,
      isaDetails: undefined,
      fullTransfer: undefined,
      toppedUpAccountInCY: undefined,
      transferAmount: undefined,
      isaProvidersList: undefined
    });
  };

  const { title } = strings.newAccount;

  const renderNewAccount = () => {
    if (isCustomerLoading && !errorOccured) {
      return (
        <Flex justify="center" align="center" css={{ height: "100vh" }}>
          <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
        </Flex>
      );
    }

    if (FEATURE_FLAGS.hideNewAccount) {
      return (
        <>
          <IdleModal />
          <ComingSoon headingText={title} />
        </>
      );
    }

    return (
      <>
        <IdleModal />
        <Outlet context={{ state, setState, clearState }} />
      </>
    );
  };

  const renderLayout = () => {
    const cxLayout = classnames("layoutWrapper--landing", {
      "layoutWrapper--landing--shortest": !FEATURE_FLAGS.hideNewAccount
    });

    return <DefaultLayout className={cxLayout}>{renderNewAccount()}</DefaultLayout>;
  };

  return renderLayout();
};
