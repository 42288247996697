import { Box, CheckCircleIcon, ErrorIcon, Text } from "@shawbrook/sds-component-library";
import classnames from "classnames";
import { FC } from "react";
import { ProsConsItemType } from "./ProsCons";
import styles from "./ProsConsItem.module.scss";

export interface IProsConsItem {
  itemType: ProsConsItemType;
  content: string;
}

export const ProsConsItem: FC<IProsConsItem> = ({ itemType, content }) => {
  const cxIcon = classnames(styles.prosConsItem__icon, {
    [styles["prosConsItem__icon--pro"]]: itemType === ProsConsItemType.PRO,
    [styles["prosConsItem__icon--con"]]: itemType === ProsConsItemType.CON
  });

  return (
    <Box className={styles.prosConsItem}>
      {itemType === ProsConsItemType.PRO ? <CheckCircleIcon className={cxIcon} /> : <ErrorIcon className={cxIcon} />}
      <Text className={styles.prosConsItem__content}>{content}</Text>
    </Box>
  );
};
