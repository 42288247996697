import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { UseMoveMoneyLandingProps } from "hooks/useMoveMoneyLanding/interfaces";
import useSrollToTop from "hooks/useScrollToTop";
import { IMoveMoneyContext } from "state/moveMoneyState";
import { saveMoveMoneyStateToStorage } from "utils/sessionStorage";

/**
 * A hook for Move Money entry page:
 * based on received accounts and accounts ineligibility
 * information manages how accounts should be displayed on a page,
 * either should be preselected, allowed for selection, eligible for transaction or not
 * @returns {object}
 * - fromAccountValid {boolean} - defines if the move money page
 * state of account is set
 * - isPageResolved {boolean} - defines if the view of the
 * page could be displayed based on received data
 * - isPageErrored {boolean} - defines if error case should be shown
 */
const useMoveMoneyLanding = ({
  accountResolved,
  accountErrored,
  accountDetailData,
  accountsData,
  accountId,
  moveMoneyContext: { state, setState } = {} as IMoveMoneyContext
}: UseMoveMoneyLandingProps) => {
  const navigate = useNavigate();
  const fromAccountValid = state.fromAccount !== null;
  const isSingleAccount = accountsData?.length === 1;
  const isPageResolved = accountResolved && ((!accountDetailData && !isSingleAccount) || fromAccountValid);
  const isPageErrored = accountErrored;

  useSrollToTop({
    condition: Boolean(
      fromAccountValid ||
        (!accountErrored && ((accountId == null && accountsData && isSingleAccount) || accountDetailData))
    )
  });

  useEffect(() => {
    if (accountId == null && !accountErrored && accountsData && !fromAccountValid) {
      if (!isSingleAccount) {
        navigate(links.moveMoneyFrom.link, { replace: true });
      } else {
        const moveMoneyState = {
          fromAccount: accountsData[0],
          toAccount: {
            id: accountsData[0]?.linkedAccount?.id || "",
            accountNumber: accountsData[0]?.linkedAccount?.accountNumber || "",
            sortCode: accountsData[0]?.linkedAccount?.sortCode || "",
            internal: false,
            customerName: accountsData[0]?.linkedAccount?.holderName || "",
            balance: 0,
            availableBalance: 0,
            bankName: accountsData[0]?.linkedAccount?.bankName || "",
            accountTitle: strings.moveMoney.linkedAccount
          }
        };

        saveMoveMoneyStateToStorage(moveMoneyState);
        setState(moveMoneyState);
      }
    }
  }, [accountId, accountErrored, isSingleAccount, fromAccountValid, setState, accountsData, navigate]);

  useEffect(() => {
    if (accountErrored === false && accountDetailData) {
      const moveMoneyState = {
        fromAccount: accountDetailData
      };

      saveMoveMoneyStateToStorage(moveMoneyState);
      setState(moveMoneyState);
    }
  }, [accountErrored, accountDetailData, setState]);

  const moveMoneyFromHandler = () => {
    navigate(links.moveMoneyFrom.link);
  };

  return {
    fromAccountValid,
    isPageResolved,
    isPageErrored,
    moveMoneyFromHandler
  };
};

export default useMoveMoneyLanding;
