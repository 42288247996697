import { media } from "config/breakpoints";

const { md } = media;

export const inlineStyles = {
  item: {
    download: {
      color: "var(--shawbrook-primary)",

      margin: "0.75rem 0 0",
      "&:hover, &:active": {
        color: "var(--active-color)",

        path: {
          fill: "var(--active-color)"
        }
      },
      [md]: {
        margin: "auto 0 auto auto"
      }
    },
    flexDirection: "column",
    [md]: {
      flexDirection: "row"
    }
  }
};
