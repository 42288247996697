import { media } from "config/breakpoints";
import styles from "./Card.module.scss";

const { xs, xl, sm } = media;
export default {
  container: {
    position: "relative",
    display: "inline-flex",
    flexDirection: "column",
    alignSelf: "flex-start",
    padding: "0.75rem",
    boxShadow: "0 0.25rem 0.5rem rgba(0, 0, 0, 0.16)",
    width: "100%",
    minWidth: 0,
    margin: 0
  },
  blueBorder: {
    boxShadow: "none",
    paddingBottom: "0.75rem"
  },
  content: {
    padding: "0.625rem 2.813rem 0.75rem 0.75rem",
    width: "100%",
    [sm]: {
      padding: "0.75rem 2.813rem 0.75rem 0.75rem"
    }
  },
  accountContent: {
    letterSpacing: "1.2px"
  },
  availableGroup: {
    marginLeft: 0,
    "&, &:last-child": {
      [xs]: {
        margin: "0 0 0.65rem 0.75rem"
      }
    }
  },
  button: {
    width: "auto",
    padding: "0.5rem 0.75rem 0.5rem",
    borderRadius: "0.375rem 0 0.375rem",
    lineHeight: "$tight"
  },
  rateAlertCard: {
    marginTop: "$4",
    paddingTop: "$2",
    paddingBottom: "$2"
  },
  buttonLink: {
    width: "100%",
    borderBottom: "none",
    padding: "0.25rem 0",
    [xs]: {
      width: "auto"
    }
  },
  single: {
    [`& .${styles["button--link"]}`]: {
      [xl]: {
        width: "auto"
      }
    },
    [`& .${styles.available__group}`]: {
      [xl]: {
        marginBottom: "0.75rem !important"
      }
    }
  }
};
