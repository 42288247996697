import { links } from "config/navLinks";
import useSubmitThread from "hooks/useSubmitThread";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CreateThreadRequest } from "services/data/types";
import { UseLinkedAccountMessageProps } from "./interfaces";

export const useLinkedAccountMessage = ({ accountId, subject }: UseLinkedAccountMessageProps) => {
  const navigate = useNavigate();
  const submitThreadContent: CreateThreadRequest = {
    sender: "create-thread",
    subject,
    accountId
  };
  const { postThread, isSubmitThreadSuccess, isSubmitThreadLoading, isSubmitThreadError, submitThreadData } =
    useSubmitThread(submitThreadContent);
  useEffect(() => {
    if (isSubmitThreadSuccess && submitThreadData) {
      navigate(links.inboxThread.link.replace(":id", submitThreadData.createdThreadId));
    }
  }, [isSubmitThreadSuccess, navigate, submitThreadData]);
  return { postThread, isSubmitThreadLoading, isSubmitThreadError };
};
