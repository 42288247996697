import strings from "assets/strings/strings.json";
import StepDefaultIds from "pages/BankfastUserMigration/StepIds";
import { TAllStepIdValues } from "pages/BankfastUserMigration/Steps";
import StepMobileIds from "pages/BankfastUserMigrationMobile/StepIds";
import { IStep } from "services/data/types/bankfastUserMigration/";
import {
  ACCESSIBILITY,
  ACCESSIBILITY_SUPPORT_NEEDED,
  CUSTOMER_EMAIL,
  CUSTOMER_MOBILE,
  MARKETING_PREFERENCES
} from "services/data/types/bankfastUserMigration/selectors";
import {
  customerDetailsEmailSchema,
  customerDetailsGDPRAndA11ySchema,
  customerDetailsMobileSchema,
  otpSchema
} from "services/validationSchemas/";

export const Steps: IStep<TAllStepIdValues>[] = [
  {
    id: StepDefaultIds.EMAIL,
    title: strings.bankfastUserMigration.stepsName.email,
    fields: [CUSTOMER_EMAIL],
    validationSchema: customerDetailsEmailSchema
  },
  {
    id: StepDefaultIds.EMAIL_OTP,
    title: strings.bankfastUserMigration.stepsName.email,
    fields: [CUSTOMER_EMAIL],
    validationSchema: otpSchema
  },
  {
    id: StepDefaultIds.CHANGE_EMAIL,
    title: strings.bankfastUserMigration.stepsName.changeEmail,
    fields: [CUSTOMER_EMAIL],
    validationSchema: customerDetailsEmailSchema,
    continueButtonLabel: strings.bankfastUserMigration.changeEmail.continueButton,
    continueButtonLink: strings.bankfastUserMigration.changeEmail.continueButtonLink
  },
  {
    id: StepMobileIds.MOBILE,
    title: strings.bankfastUserMigrationMobile.stepsName.mobile,
    fields: [CUSTOMER_MOBILE],
    validationSchema: customerDetailsMobileSchema,
    hideContinueButton: true
  },
  {
    id: StepMobileIds.MOBILE_OTP,
    title: strings.bankfastUserMigrationMobile.stepsName.mobile,
    fields: [CUSTOMER_MOBILE],
    validationSchema: otpSchema
  },
  {
    id: StepMobileIds.CHANGE_MOBILE,
    title: strings.bankfastUserMigrationMobile.stepsName.changeMobile,
    fields: [CUSTOMER_MOBILE],
    validationSchema: customerDetailsMobileSchema,
    continueButtonLabel: strings.bankfastUserMigrationMobile.changeMobile.continueButton,
    continueButtonLink: strings.bankfastUserMigrationMobile.changeMobile.continueButtonLink
  },
  {
    id: StepDefaultIds.GDPR_AND_A11Y,
    title: strings.bankfastUserMigration.stepsName.GDPRAndA11y,
    fields: [MARKETING_PREFERENCES, ACCESSIBILITY, ACCESSIBILITY_SUPPORT_NEEDED],
    validationSchema: customerDetailsGDPRAndA11ySchema,
    continueButtonLabel: strings.bankfastUserMigration.GDPRAndA11y.continueButton
  }
];
