import { useState, useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Button, Container, Flex, Heading } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { ArrowRight } from "components/Icons";
import useCustomer from "hooks/useCustomer";
import useSrollToTop from "hooks/useScrollToTop";
import { PageHeader } from "journeys/components/";
import { links } from "config/navLinks";
import styles from "./Success.module.scss";

export const Success = () => {
  useSrollToTop();
  const [mode, setMode] = useState<string | null>(null);
  const navigate = useNavigate();
  const { title, content, goToAccountButton } = strings.bankfastUserMigration.success;
  const { customerData } = useCustomer();

  const successTitle = customerData?.name?.givenNames ? `${title}, ${customerData.name.givenNames}` : title;

  useEffect(() => {
    const currentMode = localStorage.getItem("dataCleansSuccess");

    if (!mode && currentMode !== "true") {
      navigate(links.landing.link);
    }

    if (currentMode === "true") {
      setMode(currentMode);
    }

    if (mode) {
      localStorage.removeItem("dataCleansSuccess");
    }
  }, [navigate, mode]);

  const renderPage = () => {
    if (mode === "true") {
      return (
        <div className={styles.successfulPage}>
          <Container size="1-5" className={styles.successPageContainer}>
            <Flex gap="6" direction="column">
              <Flex gap="4" direction="column">
                <PageHeader title={successTitle} />
                <Heading level="4" as="h2" fontWeight="normal">
                  {content}
                </Heading>
              </Flex>

              <div>
                {/* @TODO: create component in library to pass props asChild or new Link component */}
                <Link to={process.env.REACT_APP_REDIRECT_URI ?? ""}>
                  <Button iconRight variant="primary" size="large" fullWidth>
                    {goToAccountButton}
                    <ArrowRight color="white" aria-hidden="true" />
                  </Button>
                </Link>
              </div>
            </Flex>
          </Container>
        </div>
      );
    }

    return null;
  };

  return renderPage();
};

export default Success;
