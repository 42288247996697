import { Button, Flex, Heading, Modal, Text } from "@shawbrook/sds-component-library";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./SuccessModal.module.scss";

interface ISuccessModal {
  customCallback?: () => void;
  url: string;
  content: string | ReactNode | ReactNode[];
  header: string;
  btnContent: string;
  onEscapeKeyDown?: boolean;
  onPointerDownOutside?: boolean;
  maxWidth?: number;
  headerMarginBottom?: string | number;
  biggerFont?: boolean;
  open?: boolean;
}

const SuccessModal: FC<ISuccessModal> = ({
  customCallback = () => {},
  url,
  content,
  header,
  btnContent,
  onEscapeKeyDown = false,
  onPointerDownOutside = false,
  maxWidth = 786,
  headerMarginBottom = "$2",
  open = false,
  biggerFont = false
}) => {
  const navigate = useNavigate();

  const successCallback = () => {
    customCallback();
    navigate(url);
  };

  return (
    <Modal.Root open={open}>
      <Modal.Content
        css={{ padding: "$6", maxWidth: `${maxWidth}px`, borderRadius: "8px" }}
        onEscapeKeyDown={onEscapeKeyDown ? customCallback : undefined}
        onPointerDownOutside={onPointerDownOutside ? customCallback : undefined}>
        <Heading level={2} fontWeight="semiBold" id="modal-title" css={{ marginBottom: headerMarginBottom }}>
          {header}
        </Heading>
        <Flex direction="column" css={{ marginBottom: "$6" }}>
          {biggerFont ? (
            <Text font="silka" className={styles.content} textStyle="medium">
              {content}
            </Text>
          ) : (
            content
          )}
        </Flex>
        <Flex justify="end">
          <Button
            variant="primary"
            onClick={successCallback}
            size="large"
            css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
            {btnContent}
          </Button>
        </Flex>
      </Modal.Content>
    </Modal.Root>
  );
};

export default SuccessModal;
