import { Button } from "@shawbrook/sds-component-library";
import { FC, useState } from "react";
import { v4 as uuidv4 } from "uuid";

interface IInboxActionBarDropdown {
  options?: string[];
  threadId: string;
  isDisabled: boolean;
}

export const InboxActionBarDropdown: FC<IInboxActionBarDropdown> = ({ threadId, options, isDisabled }) => {
  const [loading, setLoading] = useState(false);

  const handleSendMessage = () => {
    setLoading(true);
    // trying to get a promise to simulate the connection
    setTimeout(() => {
      setLoading(false);
      // TODO: Call hook here to send the selected option
      // eslint-disable-next-line no-alert
      alert(`dropdown picked, threadId: ${threadId}`);
    }, 300);
  };

  return (
    <section>
      Dropdown
      {options &&
        options.map(o => (
          <Button
            disabled={loading || isDisabled}
            key={uuidv4()}
            onClick={handleSendMessage}
            size="medium"
            css={{ width: "10rem" }}>
            {o}
          </Button>
        ))}
    </section>
  );
};
export default InboxActionBarDropdown;
