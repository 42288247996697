import { Box, Text } from "@shawbrook/sds-component-library";
import { Link } from "react-router-dom";
import InlineStyles from "./ArchiveMessage.styles";
import { IArchiveMessageProps } from "./types";

const ArchiveMessage = ({ message, link }: IArchiveMessageProps) => {
  const [messageStart, messageEnd] = message.split("{{messageTrigger}}");

  return (
    <Box css={InlineStyles.alert} role="note">
      {messageStart && messageEnd ? (
        <Text>
          {messageStart}
          <Link to={link.url} style={InlineStyles.archiveLink}>
            {link.text}
          </Link>
          {messageEnd}
        </Text>
      ) : (
        <Text>{messageStart}</Text>
      )}
    </Box>
  );
};

export default ArchiveMessage;
