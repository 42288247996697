import { Button, Flex, Heading, Loader, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import { useLinkedAccountMessage } from "hooks/useLinkedAccountMessage/useLinkedAccountMessage";
import { FC } from "react";
import { useParams } from "react-router-dom";
import { useMainContext } from "state/mainState";
import styles from "../FundingInstructions.module.scss";

interface IUnverifiedLinkedAccInstructions {
  bankName: string;
}

export const UnverifiedLinkedAccountInstructions: FC<IUnverifiedLinkedAccInstructions> = ({ bankName }) => {
  const context = useMainContext();
  const params = useParams();
  const accountId = params.id;

  const { unverifiedLinkedAccountTitle, unverifiedLinkedAccountInfo } = strings.fundingInstructions;
  const { subject } = strings.linkedAccountIssue.unverified;
  const { sendMessage } = strings.general;

  const subjectWithBankName = subject.replace("{{BANK_NAME}}", bankName);
  const { postThread, isSubmitThreadLoading, isSubmitThreadError } = useLinkedAccountMessage({
    accountId,
    subject: subjectWithBankName
  });

  const handleSendMessage = () => {
    postThread();
  };

  if (isSubmitThreadError) {
    return (
      <ErrorRequestView
        requestErrors={[isSubmitThreadError]}
        context={context}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }

  return (
    <>
      <Space gap="3">
        <Heading level="2" as="h2">
          {unverifiedLinkedAccountTitle.replace("{{BANK_NAME}}", bankName)}
        </Heading>
      </Space>
      <Space gap="6">
        <Text className={styles.fundingInstructions__linkedAccIssueText}>{unverifiedLinkedAccountInfo}</Text>
      </Space>
      <Flex justify="end">
        <Button
          variant="primary"
          loading={isSubmitThreadLoading}
          loader={<Loader className={styles.fundingInstructions__loader} />}
          className={styles.fundingInstructions__button}
          onClick={handleSendMessage}
          size="large">
          {sendMessage}
        </Button>
      </Flex>
    </>
  );
};
