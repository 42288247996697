import { IIsaTransferEditSelection, ProductCategory } from "services/data/types";
import { INewAccountState } from "state/newAccountState";

export const productCategorySupportsJointAccounts = (category: ProductCategory) =>
  !(category === ProductCategory.EASY_ACCESS_ISA || category === ProductCategory.FIXED_RATE_ISA);

/**
 * Used to return branch from state with name `fieldName` if not in edit mode,
 * and from (editSelection[fieldName] or if it empty then from state) in edit mode.
 * Used to get initial value for pages in ISA transfer for edit flow from review page
 * to have ability populate pages with initial or edited data.
 * @param state - state object
 * @param editMode - flag to indicate from what branch to get data
 * @param fieldName - branch name
 * @returns - content of particular branch
 */
export const getInitialValue = (
  state: INewAccountState,
  editMode: boolean | undefined,
  fieldName: keyof IIsaTransferEditSelection
) => {
  if (editMode) {
    return state.editSelection && state.editSelection[fieldName] ? state.editSelection[fieldName] : state[fieldName];
  }
  return state[fieldName];
};
