import { InterestRate } from "services/data/types";

export const findMatchingInterestRate = (interestRates: InterestRate[]) =>
  interestRates
    .filter(
      rate =>
        rate.balanceBandFrom != null &&
        rate.balanceBandTo > 0 &&
        rate.effectiveDate != null &&
        new Date(rate.effectiveDate).getTime() < new Date().getTime()
    )
    .sort((rate1, rate2) => rate1.balanceBandTo - rate2.balanceBandTo)
    .reduce((currentRates: InterestRate[], rate: InterestRate) => {
      const currentRateIndex = currentRates.findIndex(
        (el: InterestRate) => el.balanceBandFrom === rate?.balanceBandFrom
      );
      if (currentRateIndex !== -1) {
        const el = currentRates[currentRateIndex];
        if (new Date(rate?.effectiveDate).getTime() > new Date(el.effectiveDate).getTime()) {
          const newCurrentRates = [...currentRates];
          newCurrentRates[currentRateIndex] = rate;
          return newCurrentRates;
        }
        return currentRates;
      }
      return [...currentRates, rate];
    }, []);

export const findInterestRateForAmount = (interestRates: InterestRate[], amount: number): number | undefined => {
  const matchingRates = findMatchingInterestRate(interestRates);
  return (
    matchingRates.find(rate => amount >= rate.balanceBandFrom && amount <= rate.balanceBandTo)?.interestRate ||
    matchingRates[0]?.interestRate ||
    interestRates[0]?.interestRate ||
    0
  );
};
const findInterestRate = (interestRates: InterestRate[], amount: number) => {
  const matchingRates = findMatchingInterestRate(interestRates);

  if (
    matchingRates.length < 2 ||
    amount < matchingRates[0].balanceBandFrom ||
    amount > matchingRates[0].balanceBandTo
  ) {
    return false;
  }

  const lowestInterestRate = matchingRates[0].interestRate;
  const nextBalanceBandFrom = matchingRates[1].balanceBandFrom;
  const nextInterestRate = matchingRates[1].interestRate;

  return { lowestInterestRate, nextBalanceBandFrom, nextInterestRate };
};

export default findInterestRate;
