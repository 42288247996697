import { useState, useEffect, useRef, useCallback } from "react";
import { useQuery } from "@tanstack/react-query";
import usePrevious from "hooks/usePrevious";
import getMessagesInfo from "utils/getMessagesInfo";
import { getMessages } from "services/data/api";
import { QueryKeys } from "services/data/types/queryKeys";
import { IUseMessages } from "hooks/useMessages/interfaces";
import { IInboxContext } from "state/inboxState";
import { API_LIMITS } from "assets/strings/api";
import { IMessage } from "services/data/types";

const useMessages = ({ threadId, enabled, inboxContext: { state, setState } = {} as IInboxContext }: IUseMessages) => {
  const [timeMax, setTimeMax] = useState<boolean | number>(false);
  const [intervalLimit, setIntervalLimit] = useState<boolean | number>(false);
  const [lastLength, setLastLength] = useState<null | number>(null);
  const refetchFirstTime = useRef<number | null>(null);
  const refetchCount = useRef<number>(0);

  const prevThreadId = usePrevious(threadId);

  const resetState = useCallback(
    (onlyRefetchReset: boolean = false) => {
      setTimeMax(false);
      setIntervalLimit(false);
      setLastLength(null);

      if (!onlyRefetchReset) {
        setState({
          isGetMessages: false
        });
      }
    },
    [setState]
  );

  useEffect(() => {
    if (prevThreadId && threadId && prevThreadId !== threadId && timeMax) {
      resetState(true);
    }
  }, [prevThreadId, threadId, timeMax, resetState]);

  useEffect(() => {
    if (!state.isGetMessages) {
      refetchFirstTime.current = null;
      refetchCount.current = 0;
    }
  }, [state]);

  const {
    data,
    isLoading,
    isError: errors,
    error: messagesError
  } = useQuery({
    queryKey: [QueryKeys.MESSAGES, threadId],
    refetchOnWindowFocus: false,
    queryFn: () => getMessages(threadId),
    enabled,
    ...(typeof timeMax === "number" &&
      typeof intervalLimit === "number" &&
      timeMax > 0 && {
        refetchInterval: (_, query) => {
          let interval: boolean | number = Math.min(
            API_LIMITS.RETRY_TIME_STEP * (refetchCount.current + 1),
            intervalLimit
          );

          if (!refetchFirstTime.current) {
            refetchFirstTime.current = query.state.dataUpdatedAt;
          }

          const currentTime = query.state.dataUpdatedAt;
          interval = currentTime && currentTime - refetchFirstTime.current >= timeMax ? false : interval;
          if (interval === false) {
            resetState();
          } else {
            refetchCount.current += 1;
          }

          return interval;
        }
      })
  });

  const messagesData = data as Array<IMessage>;

  useEffect(() => {
    if (errors === false && messagesData) {
      const { messagesLength, isThreadJustCreated, isThreadResolved, newMessageSent, replyReceived } = getMessagesInfo(
        messagesData,
        state.invalidateGetMessages
      );

      if (isThreadJustCreated && timeMax !== API_LIMITS.MESSAGES_TIME_MAX_INITIAL * API_LIMITS.RETRY_TIME_STEP) {
        setTimeMax(API_LIMITS.MESSAGES_TIME_MAX_INITIAL * API_LIMITS.RETRY_TIME_STEP);
        setIntervalLimit(API_LIMITS.MESSAGES_INTERVAL_LIMIT_INITAL * API_LIMITS.RETRY_TIME_STEP);
      }

      if (newMessageSent) {
        if (lastLength && messagesLength > lastLength) {
          refetchFirstTime.current = null;
          refetchCount.current = 0;
        }

        if (timeMax !== API_LIMITS.MESSAGES_TIME_MAX * API_LIMITS.RETRY_TIME_STEP) {
          setTimeMax(API_LIMITS.MESSAGES_TIME_MAX * API_LIMITS.RETRY_TIME_STEP);
          setIntervalLimit(API_LIMITS.MESSAGES_INTERVAL_LIMIT * API_LIMITS.RETRY_TIME_STEP);
        }

        setLastLength(messagesLength);
        setState({
          invalidateGetMessages: false
        });
      }

      if (isThreadResolved || replyReceived) {
        resetState();
      }
    }
  }, [
    errors,
    messagesData,
    timeMax,
    lastLength,
    setState,
    state.invalidateGetMessages,
    setTimeMax,
    setIntervalLimit,
    setLastLength,
    resetState
  ]);

  useEffect(() => {
    if (enabled && errors) {
      resetState(true);
    }
  }, [enabled, errors, resetState]);

  return {
    messagesData,
    errors,
    isLoading,
    messagesError
  };
};

export default useMessages;
