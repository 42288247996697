import { media } from "config/breakpoints";

const { md } = media;

export const inlineStyles = {
  buttons: {
    flexDirection: "column-reverse",
    gap: "1rem",
    button: {
      width: "100%"
    },
    [md]: {
      flexDirection: "row",
      button: {
        width: "auto"
      }
    }
  }
};
