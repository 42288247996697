export default {
  submissionButtons: {
    marginTop: "$10",
    "@sm": {
      flexDirection: "row",
      justifyContent: "space-between"
    }
  },
  submitButton: {
    padding: "1.125rem $6 1.125rem",
    width: "auto",
    "@sm": {
      width: "fit-content"
    }
  },
  cancelButton: {
    borderBottom: "none",
    padding: "1.25rem 0",
    width: "100%",
    "@sm": {
      width: "fit-content"
    }
  }
};
