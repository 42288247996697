import { Dispatch } from "react";
import { useOutletContext } from "react-router-dom";

export interface IInboxContext {
  state: IState;
  setState: Dispatch<Partial<IState>>;
}

export interface IState {
  isHighTraffic: boolean;
  is404Page: boolean;
  isGetMessages: boolean;
  invalidateGetMessages: boolean;
}

export const initialState: IState = {
  isHighTraffic: false,
  is404Page: false,
  isGetMessages: true,
  invalidateGetMessages: false
};

export const useInboxContext = () => useOutletContext<IInboxContext>();
