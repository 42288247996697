import { GlobalContextSetSelectedProfile } from "Actions/GlobalContextAction";
import useProfile from "hooks/useProfile";
import { useEffect } from "react";
import { useGlobalDispatch } from "state/globalContext";

const useProfileLoader = () => {
  const dispatch = useGlobalDispatch();
  const { customerProfileData, isCustomerProfileLoading, customerProfileError, isCustomerProfileError } = useProfile();
  const hasMultipleProfiles = customerProfileData && customerProfileData.length > 1;

  useEffect(() => {
    if (!isCustomerProfileLoading && customerProfileData && customerProfileData.length === 1) {
      dispatch(GlobalContextSetSelectedProfile(customerProfileData[0]));
    }
  }, [dispatch, customerProfileData, isCustomerProfileLoading]);

  return {
    hasMultipleProfiles,
    customerProfileData,
    isCustomerProfileLoading,
    isCustomerProfileError,
    customerProfileError
  };
};

export default useProfileLoader;
