import { Box, Heading, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { PRODUCT_CATEGORY_KEYS } from "config/productCategoryKeys";
import { FC } from "react";
import { ProductCategory } from "services/data/types";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import { formatProductDetailsContent } from "utils/fromatProductDetailsContent/formatProductDetailsContent";

interface IProductSummary {
  productCategory?: ProductCategory;
  noticePeriodDays?: number;
  minBalance?: number;
  lowestInterestRate?: number;
}

interface ICategoryProductQuestion {
  [key: string]: string;
}

interface ICategoryProductSummary {
  [key: string]: ICategoryProductQuestion;
}

export const ProductSummary: FC<IProductSummary> = ({
  productCategory,
  noticePeriodDays = 0,
  minBalance = 0,
  lowestInterestRate = 0
}) => {
  if (!productCategory) return null;
  const { title } = strings.detailsSummary;
  const questions = strings.detailsSummary.questions as unknown as ICategoryProductSummary;

  return (
    <>
      <Space gap="5">
        <Heading as="h3" level={3}>
          {title}
        </Heading>
      </Space>
      {Object.values(questions).map(question => (
        <Space gap="8" key={question.question}>
          <Box>
            <Space gap="2">
              <Heading as="h4" level={4}>
                {question.question}
              </Heading>
            </Space>
            <Text textStyle="body" css={{ whiteSpace: "break-spaces" }}>
              {formatProductDetailsContent({
                content: formatTextWithPhoneHours(question[PRODUCT_CATEGORY_KEYS[productCategory]]),
                minBalance,
                noticePeriodDays,
                lowestInterestRate
              })}
            </Text>
          </Box>
        </Space>
      ))}
    </>
  );
};
