import StepsMobile from "pages/BankfastUserMigrationMobile/StepIds";
import { FieldValues, UseFormGetValues } from "react-hook-form";
import {
  CUSTOMER_EMAIL,
  CUSTOMER_MOBILE,
  EMAIL_SHOW_VERIFICATION_OTP_WARNING,
  EMAIL_VERIFICATION_CONTACT,
  EMAIL_VERIFICATION_OTP,
  EMAIL_VERIFICATION_OTP_ERROR,
  EMAIL_VERIFICATION_OTP_WARNING,
  EMAIL_VERIFICATION_VERIFIED_CONTACT,
  MOBILE_SHOW_VERIFICATION_OTP_WARNING,
  MOBILE_VERIFICATION_CONTACT,
  MOBILE_VERIFICATION_OTP,
  MOBILE_VERIFICATION_OTP_ERROR,
  MOBILE_VERIFICATION_OTP_WARNING,
  MOBILE_VERIFICATION_SCREEN_MODE,
  MOBILE_VERIFICATION_VERIFIED_CONTACT,
  OtpType
} from "services/data/types/bankfastUserMigration/";
import { tranformMobileForAPISend } from "utils";
import { transformEmailForAPISend, transformMobileForAPISend } from "utils/bankFastUserMigrationJourney";
import { ErrorCodes } from "./ErrorCodes";
import {
  EmailUpdateApiPayload,
  EmailVerificationApiPayload,
  MobileUpdateApiPayload,
  MobileVerificationApiPayload
} from "./types";

export const isEmail = (otpType: OtpType) => otpType === "email";

export const VERIFICATION_CONTACT = (otpType: OtpType) =>
  isEmail(otpType) ? EMAIL_VERIFICATION_CONTACT : MOBILE_VERIFICATION_CONTACT;

export const VERIFICATION_VERIFIED_CONTACT = (otpType: OtpType) =>
  isEmail(otpType) ? EMAIL_VERIFICATION_VERIFIED_CONTACT : MOBILE_VERIFICATION_VERIFIED_CONTACT;

export const VERIFICATION_OTP = (otpType: OtpType) =>
  isEmail(otpType) ? EMAIL_VERIFICATION_OTP : MOBILE_VERIFICATION_OTP;

export const VERIFICATION_OTP_ERROR = (otpType: OtpType) =>
  isEmail(otpType) ? EMAIL_VERIFICATION_OTP_ERROR : MOBILE_VERIFICATION_OTP_ERROR;

export const SHOW_VERIFICATION_OTP_WARNING = (otpType: OtpType) =>
  isEmail(otpType) ? EMAIL_SHOW_VERIFICATION_OTP_WARNING : MOBILE_SHOW_VERIFICATION_OTP_WARNING;

export const VERIFICATION_OTP_WARNING = (otpType: OtpType) =>
  isEmail(otpType) ? EMAIL_VERIFICATION_OTP_WARNING : MOBILE_VERIFICATION_OTP_WARNING;

export const CUSTOMER_ITEM = (otpType: OtpType) => (isEmail(otpType) ? CUSTOMER_EMAIL : CUSTOMER_MOBILE);

export const contactOtpItem = (otpType: OtpType, getValues: UseFormGetValues<FieldValues>) => {
  const value = getValues(CUSTOMER_ITEM(otpType));

  const emailBody: EmailVerificationApiPayload = {
    medium: otpType,
    type: "custom",
    value
  };

  const mobileBody: MobileVerificationApiPayload = {
    medium: getValues(MOBILE_VERIFICATION_SCREEN_MODE),
    type: "custom",
    value: tranformMobileForAPISend(value)
  };

  return isEmail(otpType) ? emailBody : mobileBody;
};

export const verifyContactOtpItem = (otpType: OtpType, getValues: UseFormGetValues<FieldValues>) => {
  const value = getValues(CUSTOMER_ITEM(otpType));
  const otp = getValues(VERIFICATION_OTP(otpType));
  const emailBody: EmailUpdateApiPayload = {
    email: value,
    value: otp
  };
  const mobileBody: MobileUpdateApiPayload = {
    phoneNumber: tranformMobileForAPISend(value),
    value: otp
  };

  return isEmail(otpType) ? emailBody : mobileBody;
};

export const transformForUpdateAPISend = (otpType: OtpType) =>
  isEmail(otpType) ? transformEmailForAPISend : transformMobileForAPISend;

export const errorCodeForExistingItem = (otpType: OtpType) =>
  isEmail(otpType) ? ErrorCodes.CUSTOMER_EMAIL_EXISTS : ErrorCodes.CUSTOMER_MOBILE_EXISTS;

export const changeItemStep = (otpType: OtpType) =>
  isEmail(otpType) ? StepsMobile.CHANGE_EMAIL : StepsMobile.CHANGE_MOBILE;

export const otpStep = (otpType: OtpType) => (isEmail(otpType) ? StepsMobile.EMAIL_OTP : StepsMobile.MOBILE_OTP);
