import strings from "assets/strings/strings.json";
import { VALIDATION } from "assets/strings/validation";
import { isStatusCodeWithDetails } from "pages/BankfastUserMigration/ErrorManagement";
import { FieldValues, UseFormSetError } from "react-hook-form";
import { getPhoneFormat } from "utils";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import { THandleErrorStatusProps, TServerStatusData } from "./types";

const ROOT_ERROR = "root.serverError";
const ROOT_WARNING = "root.serverWarning";
const { genericError } = VALIDATION.userMigrationJourney.customerDetails.otp;
const {
  bankfastUserMigration: { generalApiErrorMessage },
  general: { helpCenterNumber }
} = strings;
const setAlertState = ({ id, setError, ...props }: THandleErrorStatusProps) => {
  setError(id, {
    ...props
  });
};

export const handleErrorStatus = (
  data: TServerStatusData | undefined | null,
  setError: UseFormSetError<FieldValues>,
  mode: string = "generalApi"
) => {
  let fallbackError = generalApiErrorMessage;
  if (mode === "otp") {
    fallbackError = genericError;
  }

  fallbackError = formatTextWithPhoneHours(
    fallbackError.replace("{{HELP_CENTER_NUMBER}}", `<strong>${getPhoneFormat(helpCenterNumber)}</strong>`)
  );

  const status = data?.status || data?.statusCode || data?.type;

  let message = "";
  if (isStatusCodeWithDetails(status)) {
    message = data?.userMessage || data?.detail || data?.errors?.Otp?.[0] || fallbackError;
  } else {
    message = data?.userMessage || data?.errors?.Otp?.[0] || fallbackError;
  }

  setAlertState({ id: ROOT_ERROR, setError, message, status });
};

export const handleWarningStatus = (data: any, setError: UseFormSetError<FieldValues>) => {
  setAlertState({ id: ROOT_WARNING, setError, message: data });
};

export const handleFieldErrorStatus = (setError: UseFormSetError<FieldValues>, selector: string, message: string) =>
  setError(selector, { type: "server", message });
