import { LabelTextProps, RadioGroup, RadioItem, Space } from "@shawbrook/sds-component-library";
import { FieldValues, UseControllerProps, useController, useFormContext } from "react-hook-form";

export type WrappedRadioGroupInputProps = {
  label?: LabelTextProps;
  toolTip?: React.ReactNode;
  requiredSign?: boolean;
  options?: { label: string; value: string }[];
} & UseControllerProps<FieldValues, string>;

export const RadioGroupInputForForm = ({
  label = {},
  name,
  toolTip,
  requiredSign,
  options = [],
  ...rest
}: WrappedRadioGroupInputProps) => {
  const { control } = useFormContext();
  const {
    field: { value, onChange }
  } = useController({ control, shouldUnregister: true, name, ...rest });
  return (
    <Space gap={10}>
      <RadioGroup
        orientation="vertical"
        labelText={label}
        value={value}
        required={requiredSign}
        ariaLabel={label?.main || name}
        onValueChange={onChange}>
        {options.map(option => (
          <RadioItem
            id={option.label}
            key={option.label}
            value={option.value}
            label={option.label}
            aria-label={option.label}
          />
        ))}
      </RadioGroup>
    </Space>
  );
};
