import { useQuery } from "@tanstack/react-query";
import { getCustomerInformation } from "services/data/api";
import { QueryKeys } from "services/data/types";
import { useGlobalContext } from "state/globalContext";

const commonQueryOptions = {
  cacheTime: 0,
  staleTime: 0,
  retry: false,
  refetchOnWindowFocus: false
};

const useCustomer = (isDataCleans = false, enabled: boolean = true, customKeys: Array<string> = []) => {
  const queryKey = [QueryKeys.CUSTOMER_DATA, ...customKeys];
  const state = useGlobalContext();

  const {
    data: customerData,
    isLoading: isCustomerLoading,
    isError: isCustomerError,
    error: customerError
  } = useQuery({
    queryKey,
    queryFn: () => getCustomerInformation(state?.selectedProfile),
    ...(isDataCleans ? commonQueryOptions : null),
    enabled
  });

  return {
    customerData,
    isCustomerLoading,
    isCustomerError,
    customerError
  };
};

export default useCustomer;
