import { VALIDATION } from "assets/strings/validation";
import * as yup from "yup";

export const customerDetailsEmailSchema =
  // HARDCODED VALIDATION SCHEMA, IT WILL LATER BE DYNAMICALLY FETCHED FROM THE API

  yup.object().shape({
    customer: yup.object().shape({
      contactDetails: yup.object().shape({
        email: yup
          .string()
          .required(VALIDATION.userMigrationJourney.customerDetails.email.required)
          .matches(/@/, VALIDATION.userMigrationJourney.customerDetails.email.atSymbolRequired)
          .email(VALIDATION.userMigrationJourney.customerDetails.email.validEmailAddress)
          .matches(
            /^([^@]+)*@([a-zA-Z-0-9]+\.)+([a-zA-Z]+)/,
            VALIDATION.userMigrationJourney.customerDetails.email.validEmailAddress
          )
      })
    })
  });
