import { Flex } from "@shawbrook/sds-component-library";
import { CompareAccountsCategory } from "components/CompareAccountsCategory/CompareAccountsCategory";
import { FC } from "react";
import { FormattedAvailableProductGroups } from "utils/formatAvailableProducts/interfaces";

interface ICompareAccountstabProps {
  tabProducts: FormattedAvailableProductGroups;
}

export const CompareAccountsTab: FC<ICompareAccountstabProps> = ({ tabProducts }) => (
  <Flex direction="column" css={{ width: "100%" }}>
    {Object.entries(tabProducts)
      .filter(([, val]) => !!val)
      .map(([key, val]) => (
        <CompareAccountsCategory categoryProducts={val} category={key} key={key} />
      ))}
  </Flex>
);
