import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import StepDefaultIds from "pages/BankfastUserMigration/StepIds";
import { useNavigateToStep } from "pages/BankfastUserMigration/StepManagement";
import { BankfastUserMigrationFlow } from "pages/BankfastUserMigration/Steps";
import StepsMobile from "pages/BankfastUserMigrationMobile/StepIds";
import { isResultSuccessful } from "pages/BankfastUserMigration/ErrorManagement";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { updateContactDetails, verifyContact } from "services/data/api";
import { CUSTOMER, ComponentState, Customer } from "services/data/types/bankfastUserMigration/";
import { links } from "config/navLinks";
import { useDidUpdate } from "utils/customHooks";
import {
  CUSTOMER_ITEM,
  VERIFICATION_OTP_ERROR,
  VERIFICATION_VERIFIED_CONTACT,
  isEmail,
  transformForUpdateAPISend,
  verifyContactOtpItem
} from "../../pages/BankfastUserMigration/OTPProcess/Selectors";
import { IVerificationOTPProcess, ValidateOTPComponentState } from "./IVerificationOTPProcess";
import { handleErrorStatus } from "./handleErrorStatus";

export const QUERY_KEYS = {
  VERIFY_CONTACT_OTP: "verifyContactOTP",
  UPDATE_CONTACT_DETAILS_OTP: "updateContactDetailsOTP"
};

const commonQueryOptions = {
  cacheTime: 0,
  staleTime: 0,
  retry: false,
  refetchOnWindowFocus: false
};

export const useVerificationOTPProcess = ({
  otpType,
  setPostResult,
  flowId,
  currentState,
  setCurrentState,
  customerData
}: IVerificationOTPProcess) => {
  const { getValues, setValue, setError } = useFormContext();
  const navigate = useNavigate();
  const navigateToStep = useNavigateToStep(flowId);
  const [currentEmailOTPState, setCurrentEmailOTPState] = useState<ValidateOTPComponentState | null>(
    ValidateOTPComponentState.CALL_VERIFY_CONTACT_PROCESS
  );
  // this process is documented here:
  // https://shawbrook.atlassian.net/wiki/spaces/SWE/pages/970588318/Front+End+architecture+-+Cut-over+bankfast+user+migration+journey
  // if the above process, the documentation must be updated as well

  const {
    data: verifyContactData,
    isLoading: verifyContactLoading,
    isError: verifyContactError
  } = useQuery({
    queryKey: [QUERY_KEYS.VERIFY_CONTACT_OTP],
    queryFn: () => verifyContact(verifyContactOtpItem(otpType, getValues)),
    onError: (error: any) => handleErrorStatus(error?.response?.data, setError, "otp"),
    enabled:
      currentState === ComponentState.PRESUBMIT &&
      currentEmailOTPState === ValidateOTPComponentState.CALL_VERIFY_CONTACT_PROCESS,
    ...commonQueryOptions
  });

  const {
    data: updateContactDetailsData,
    isFetching: updateContactDetailsFetching,
    isError: updateContactDetailsError
  } = useQuery({
    queryKey: [QUERY_KEYS.UPDATE_CONTACT_DETAILS_OTP],
    queryFn: () => updateContactDetails(transformForUpdateAPISend(otpType)(getValues(CUSTOMER) as Customer)),
    onError: (error: any) => handleErrorStatus(error?.response?.data, setError, "otp"),
    enabled:
      currentState === ComponentState.PRESUBMIT &&
      currentEmailOTPState === ValidateOTPComponentState.CALL_UPDATE_CONTACT_DETAILS,
    ...commonQueryOptions
  });

  useEffect(() => {
    if (
      currentState === ComponentState.PRESUBMIT &&
      currentEmailOTPState === ValidateOTPComponentState.CALL_VERIFY_CONTACT_PROCESS
    ) {
      const isReady = !verifyContactLoading && verifyContactData && !verifyContactError;

      setValue(VERIFICATION_VERIFIED_CONTACT(otpType), isReady ? getValues(CUSTOMER_ITEM(otpType)) : "");
      setValue(VERIFICATION_OTP_ERROR(otpType), verifyContactError);
      if (verifyContactError) {
        setPostResult?.({ result: verifyContactData });
      }

      if (isReady && isResultSuccessful(verifyContactData)) {
        setCurrentEmailOTPState(ValidateOTPComponentState.CALL_UPDATE_CONTACT_DETAILS);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [verifyContactData, verifyContactError, currentEmailOTPState]);

  useEffect(() => {
    const isReady = !updateContactDetailsFetching && updateContactDetailsData;

    if (
      currentState === ComponentState.PRESUBMIT &&
      isReady &&
      currentEmailOTPState === ValidateOTPComponentState.CALL_UPDATE_CONTACT_DETAILS
    ) {
      setPostResult?.({ result: updateContactDetailsData });
      setCurrentEmailOTPState(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContactDetailsData, updateContactDetailsError, currentEmailOTPState]);

  useDidUpdate(() => {
    if (currentState === ComponentState.GO_TO_NEXT_STEP) {
      setCurrentState?.(ComponentState.INITIAL);
      const mobilePhoneNumberVerified = customerData?.contactDetails?.mobilePhoneNumberVerified;
      const commPreferencesProvided = customerData?.contactDetails?.commPreferencesProvided;

      if (isEmail(otpType)) {
        if (mobilePhoneNumberVerified && commPreferencesProvided) {
          localStorage.setItem("dataCleansSuccess", "true");
          navigate(links.bankfastUserMigrationJourneySuccess.link);
        } else if (flowId === BankfastUserMigrationFlow.DEFAULT) {
          navigateToStep(StepDefaultIds.GDPR_AND_A11Y);
        } else {
          navigateToStep(StepsMobile.MOBILE);
        }
      } else if (commPreferencesProvided) {
        localStorage.setItem("dataCleansSuccess", "true");
        navigate(links.bankfastUserMigrationJourneySuccess.link);
      } else {
        navigateToStep(StepsMobile.GDPR_AND_A11Y);
      }
    }
  }, [currentState, customerData]);
};
