import { IsaProviders } from "services/data/types";

export const buttonDisableHandler = (
  selectedIsaProvider: IsaProviders,
  showIsaProviderNameInput: boolean,
  isaProviderName?: string,
  accountNumber?: string,
  sortCode?: string,
  rollNumber?: string,
  investmentType?: string
) => {
  if (
    !selectedIsaProvider.companyName ||
    (showIsaProviderNameInput && !isaProviderName) ||
    (isaProviderName &&
      (isaProviderName.length <= 1 ||
        isaProviderName.length > 60 ||
        !/^[A-Za-z]+$/.test(isaProviderName.replace(/[^A-Za-z]/g, ""))))
  ) {
    return true;
  }

  const isValidAccountNumber =
    (accountNumber && accountNumber.length >= 5 && accountNumber.length <= 15 && /^[0-9]+$/.test(accountNumber)) ||
    false;

  const isValidSortCode = (sortCode && sortCode.length === 8) || false;

  const isValidRollNumber =
    (rollNumber &&
      rollNumber.length >= 5 &&
      rollNumber.length <= 20 &&
      /^[A-Z0-9\-./]*$/.test(rollNumber) &&
      rollNumber.replace(/[^0-9]/g, "").length >= 3) ||
    false;

  if (
    (!isValidAccountNumber && accountNumber && accountNumber.length > 0) ||
    (!isValidSortCode && sortCode && sortCode.length > 0) ||
    (!isValidRollNumber && rollNumber && rollNumber.length > 0)
  ) {
    return true;
  }

  const hasValidAccountOrRollNumber =
    (isValidAccountNumber && isValidSortCode) || (!isValidAccountNumber && !isValidSortCode && isValidRollNumber);

  return !hasValidAccountOrRollNumber || !investmentType;
};
