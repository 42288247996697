import strings from "assets/strings/strings.json";
import { REINVESTEMENT_TYPES, InterestFrequency, ReinvestmentType } from "state/reinvestmentState";

const getInterestInfo = (
  reinvestmentType?: ReinvestmentType,
  interestFrequency?: InterestFrequency,
  interestRate?: number
) => {
  const { staticSavings, maturingSubtitle, maturingText, productSubtitle, frequency } =
    strings.reviewSubmission.interest;
  let interestObject: {
    mainTitle: string;
    maturingAccount?: {
      subTitle: string;
      text: string;
    };
    product: {
      subTitle?: string;
      paymentInterestText: string;
      interestRateText: string;
    };
  } | null = null;

  if (reinvestmentType && interestFrequency && interestRate != null) {
    const mainTitle = strings.reviewSubmission.interest[reinvestmentType].title;
    const frequencyContent = frequency
      .replace("{{FREQUENCY}}", interestFrequency)
      .replace("{{RATE}}", interestRate.toString());
    let maturingSubtitleContent: string | null = null;
    let maturingTextContent: string | null = null;
    let productSubtitleContent: string | undefined;

    if (reinvestmentType === REINVESTEMENT_TYPES.partial) {
      maturingSubtitleContent = maturingSubtitle;
      maturingTextContent = maturingText;
      productSubtitleContent = productSubtitle;
    }

    interestObject = {
      mainTitle,
      ...(maturingSubtitleContent &&
        maturingTextContent && { maturingAccount: { subTitle: maturingSubtitleContent, text: maturingTextContent } }),
      product: {
        subTitle: productSubtitleContent,
        paymentInterestText: staticSavings,
        interestRateText: frequencyContent
      }
    };
  }

  return interestObject;
};

export default getInterestInfo;
