import { useNavigate } from "react-router-dom";
import { IStep } from "services/data/types/bankfastUserMigration/";

import { links } from "config/navLinks";
import { BankfastUserMigrationFlow, IAllBankfastUserMigrationStepTypes } from "pages/BankfastUserMigration/Steps";

export const getStepIndexById = (steps: IStep<IAllBankfastUserMigrationStepTypes>[], stepId: string) =>
  stepId && steps.some(step => step.id === stepId) ? steps.findIndex(x => x.id === stepId) : 0;

export const jsonPathFormat = (jsonPath: string) => jsonPath.replaceAll("[", ".").replaceAll("]", "");

export const doesStepIncludeFieldName = (currentStep: IStep<IAllBankfastUserMigrationStepTypes>, fieldName: string) =>
  currentStep.fields.includes(jsonPathFormat(fieldName));

export const getBankfastUserMigrationLink = (
  stepId: IAllBankfastUserMigrationStepTypes,
  flowId = BankfastUserMigrationFlow.DEFAULT
): string =>
  flowId === BankfastUserMigrationFlow.DEFAULT
    ? links.bankfastUserMigrationJourneySteps.link.replace(":stepId", stepId)
    : links.bankfastUserMigrationMobileJourneySteps.link.replace(":stepId", stepId);

export const useNavigateToStep = (flowId = BankfastUserMigrationFlow.DEFAULT) => {
  const navigateTo = useNavigate();
  return (stepId: IAllBankfastUserMigrationStepTypes) =>
    navigateTo({
      pathname: getBankfastUserMigrationLink(stepId, flowId)
    });
};

export const nextStepId = (
  steps: IStep<IAllBankfastUserMigrationStepTypes>[],
  currentStepIndex?: number
): IAllBankfastUserMigrationStepTypes =>
  steps[currentStepIndex || currentStepIndex === 0 ? currentStepIndex + 1 : 0].id;
