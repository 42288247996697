import strings from "assets/strings/strings.json";
import { ProductCategory } from "services/data/types";
import { isFixedAccountCategory } from "utils";

export const generateInterestFrequencyLabel = (
  labelShape: string,
  interestFrequency: string,
  interestRate: number,
  category: ProductCategory,
  interestRateAnnual: number
) => {
  const interestRateText = isFixedAccountCategory(category)
    ? strings.newAccount.interestOptions.fixed
    : strings.newAccount.interestOptions.variable;
  return labelShape
    .replace("{{INTEREST_FREQUENCY}}", interestFrequency)
    .replace("{{INTEREST_RATE}}", interestRate.toFixed(2))
    .replace("{{INTEREST_RATE_TEXT}}", interestRateText)
    .replace("{{INTEREST_RATE_ANNUAL}}", interestRateAnnual.toFixed(2));
};
