import { Alert, Container, Heading, InfoIcon, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import inlineStyles from "pages/FundingInstructions/FundingInstructions.style";
import { FC } from "react";
import rreplace from "react-string-replace";
import { IAccountDetails } from "services/data/types";
import { formatSortCode } from "utils";
import maskString from "utils/maskString";
import styles from "../FundingInstructions.module.scss";

interface IDefaultFundingInstructions {
  accountData: IAccountDetails;
  customerName: string;
}

export const DefaultFundingInstructions: FC<IDefaultFundingInstructions> = ({ accountData, customerName }) => {
  const { title, linkedAccountInfo, accountInfo, paymentInfo } = strings.fundingInstructions;

  return (
    <>
      <Heading level="2" className={styles.fundingInstructions__title}>
        {title}
      </Heading>
      <Container space="6" css={inlineStyles.linkedAccountContainer}>
        <ol>
          {accountData.linkedAccount && (
            <li className={styles.fundingInstructions__li}>
              <Text font="silka">
                {linkedAccountInfo.p1
                  .replace("{{bankName}}", accountData.linkedAccount.bankName)
                  .replace("{{accountNumber}}", maskString({ str: accountData.linkedAccount.accountNumber }))}
              </Text>
            </li>
          )}
          <li className={styles.fundingInstructions__li}>
            <Text font="silka">{linkedAccountInfo.p2}</Text>
          </li>
        </ol>
      </Container>
      <Container space="3" css={inlineStyles.accountContainer}>
        <Text css={inlineStyles.p} font="silka">
          {rreplace(accountInfo.p1, "{{name}}", (_, i) => (
            <Text as="span" font="silka" color="secondary" fontWeight="semiBold" key={`${i}name`}>
              {customerName}
            </Text>
          ))}
        </Text>
        <Text css={inlineStyles.p} font="silka">
          {rreplace(accountInfo.p2, "{{accountNumber}}", (_, i) => (
            <Text as="span" font="silka" color="secondary" fontWeight="semiBold" key={`${i}accountNumber`}>
              {accountData.accountNumber}
            </Text>
          ))}
        </Text>
        <Text font="silka">
          {rreplace(accountInfo.p3, "{{sortCode}}", (_, i) => (
            <Text as="span" font="silka" color="secondary" fontWeight="semiBold" key={`${i}sortCode`}>
              {formatSortCode(accountData.sortCode)}
            </Text>
          ))}
        </Text>
      </Container>
      <Alert icon={<InfoIcon />} css={inlineStyles.paymentInfo}>
        <Text font="silka" fontWeight="semiBold">
          {paymentInfo}
        </Text>
      </Alert>
    </>
  );
};
