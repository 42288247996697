export default {
  inputsContainer: {
    "@media screen and (max-width: 640px)": {
      flexDirection: "column"
    }
  },
  inputError: {
    color: "var(--shawbrook-red)"
  }
};
