import { GlobalContextReducer } from "Reducers/GlobalContextReducer";
import { useReducer } from "react";
import { GlobalContextProvider, GlobalDispatchProvider, initialState } from "./state/globalContext";

export const GlobalContextWrapper = ({ children }: any) => {
  const [state, dispatch] = useReducer(GlobalContextReducer, initialState);

  return (
    <GlobalContextProvider value={state}>
      <GlobalDispatchProvider value={dispatch}>{children}</GlobalDispatchProvider>
    </GlobalContextProvider>
  );
};
