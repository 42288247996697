import { FC } from "react";

import { ActionBarTypes } from "../types";
import InboxActionBarButtons from "./InboxActionBarButtons";
import InboxActionBarDropdown from "./InboxActionBarDropdown";
import InboxActionBarTextarea from "./InboxActionBarTextarea";

interface IInboxActionBar {
  mode?: ActionBarTypes;
  options?: string[];
  threadId: string;
  isDisabled: boolean;
}

const InboxActionBar: FC<IInboxActionBar> = ({ mode, options, threadId, isDisabled }) => {
  // Error out if options needs to be provided
  if (mode === "choice" && !options) {
    throw new Error("Component requires option if mode is Radios");
  }
  if (mode === "no-response") return <div />;

  // simple switch on the type which is driven externally by the messages
  return (
    <section style={{ paddingTop: "1.25rem" }}>
      {mode === "freetext" && <InboxActionBarTextarea threadId={threadId} isDisabled={isDisabled} />}
      {mode === "choice" && options && options?.length < 100 && (
        <InboxActionBarButtons options={options} threadId={threadId} isDisabled={isDisabled} />
      )}
      {mode === "choice" && options && options?.length > 100 && (
        <InboxActionBarDropdown options={options} threadId={threadId} isDisabled={isDisabled} />
      )}
    </section>
  );
};

export default InboxActionBar;
