import { Button, Loader } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { ArrowRight } from "components/Icons";
import { useFormContext } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { IBankfastUserMigrationState } from "state/bankfastUserMigrationState";
import { getBlockServerErrorStatus } from "utils/getOTPRetryStatus";
import styles from "./SubmitSection.module.scss";
import { ISubmitSection } from "./types";

export const SubmitSection = ({ buttonLabel, buttonLink, onClick, continueBtnLoading }: ISubmitSection) => {
  const {
    trigger,
    clearErrors,
    formState: { errors }
  } = useFormContext<IBankfastUserMigrationState>();
  const navigate = useNavigate();
  const location = useLocation();

  const hasBlockServerErrros = getBlockServerErrorStatus(errors);
  const btnLoading = continueBtnLoading && !hasBlockServerErrros && !buttonLink;

  const onClickHandler = async (e: React.MouseEvent<HTMLButtonElement>) => {
    const redirect =
      location.pathname.indexOf("email-check") > -1
        ? strings.bankfastUserMigration.serverError.continueButtonLink
        : strings.bankfastUserMigrationMobile.serverError.continueButtonLink;

    if (hasBlockServerErrros) {
      e.preventDefault();
      window.location.assign(redirect);

      return;
    }

    // Its treated as a link, so we navigate to the link and prevent the form validation
    if (buttonLink) {
      e.preventDefault();
      navigate(buttonLink);
      return;
    }

    clearErrors();

    const formIsValid = await trigger();

    if (formIsValid && onClick) onClick(e);
  };

  return (
    <div className="formCard--buttons">
      <Button
        type="submit"
        variant="primary"
        fullWidth
        iconRight
        disabled={btnLoading}
        className={styles.submitButton}
        size="large"
        role="button"
        onClick={onClickHandler}>
        <>
          {buttonLabel ?? strings.general.continueButton}
          {btnLoading ? (
            <Loader css={{ width: "1.25rem", height: "1.25rem" }} />
          ) : (
            <ArrowRight color="white" aria-hidden="true" />
          )}
        </>
      </Button>
    </div>
  );
};

export default SubmitSection;
