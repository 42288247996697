import { IsaProviders } from "services/data/types";

export const mockIsaProviderList: IsaProviders[] = [
  {
    id: "0",
    companyName: "Monzo"
  },
  {
    id: "1",
    companyName: "Starling Bank"
  },
  {
    id: "2",
    companyName: "Revolut"
  },
  {
    id: "3",
    companyName: "Other"
  }
];

export const otherProvider: IsaProviders = {
  id: "1",
  companyName: "Other"
};

export const emptyProvider: IsaProviders = {
  id: "0",
  companyName: ""
};
