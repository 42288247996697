import { Loader } from "@shawbrook/sds-component-library";
import { FC } from "react";

import strings from "assets/strings/strings.json";
import styles from "./LoadingSection.module.scss";

interface ILoadingSectionProps {}

export const LoadingSection: FC<ILoadingSectionProps> = () => (
  <section className={styles["loading-section"]}>
    <Loader showRing size="medium" spacing="medium" text={strings.general.loading} textColor="primary" />
  </section>
);

export default LoadingSection;
