export const headerStyles = {
  header: {
    bg: "$shawbrookCharcoal",
    pos: "relative",
    pb: "0.75rem",
    "&::before, &::after": {
      content: "",
      bg: "$shawbrookCharcoal",
      width: "2000px",
      height: "100%",
      d: "block",
      pos: "absolute",
      top: 0,
      bottom: 0,
      right: "100%"
    },
    "&::after": {
      left: "100%"
    }
  }
};
