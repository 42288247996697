export const TOKEN_TIMER_TYPE = {
  start: "start",
  refreshToken: "refreshToken"
} as const;

export interface ITokenWorker {
  type: typeof TOKEN_TIMER_TYPE[keyof typeof TOKEN_TIMER_TYPE];
  msToRefresh?: number | null;
  isLoggedIn?: boolean;
  exp?: number;
}
