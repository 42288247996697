import { useQuery } from "@tanstack/react-query";
import { getNewAccountProducts } from "services/data/api";
import { QueryKeys } from "services/data/types";

const useAvailableProducts = () => {
  const {
    data: availableProductsData,
    isLoading: isAvailableProductsLoading,
    isError: isAvailableProductsError
  } = useQuery({
    queryKey: [QueryKeys.NEW_ACCOUNT_PRODUCTS],
    queryFn: () => getNewAccountProducts()
  });

  return { availableProductsData, isAvailableProductsLoading, isAvailableProductsError };
};

export default useAvailableProducts;
