import { Text } from "@shawbrook/sds-component-library";
import { FC } from "react";
import styles from "./IconButton.module.scss";

interface IIconButtonProps {
  text: string;
  icon?: any;
  onClick: () => void;
}

export const IconButton: FC<IIconButtonProps> = ({ text, icon, onClick }) => (
  <button type="button" className={styles.iconButton__container} onClick={onClick}>
    {icon}
    <Text color="white" font="silka" fontWeight="medium" as="span">
      {text}
    </Text>
  </button>
);
