import { Box, Flex, Text } from "@shawbrook/sds-component-library";
import { FC } from "react";

import strings from "assets/strings/strings.json";
import config from "config";
import styles from "./Footer.module.scss";
import { inlineStyles } from "./Footer.styles";

const Footer: FC = () => {
  const year = new Date().getFullYear();

  return (
    <Box className={styles.footerWrapper}>
      <footer className={styles.footer}>
        <Text color="white" font="silka">
          {strings.footer.replace("{{year}}", `${year}`)}
        </Text>

        <Flex as="ul" css={inlineStyles.list} className={styles.footer__list}>
          {config.footerLinks.map(({ link, text }) => (
            <li key={link}>
              <a href={link} target="blank">
                <Text as="span" font="silka" color="white">
                  {text}
                </Text>
              </a>
            </li>
          ))}
        </Flex>
      </footer>
    </Box>
  );
};

export default Footer;
