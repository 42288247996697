import { Box, Button, DocumentTextIcon, Flex, Heading, Text, VisuallyHidden } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";

import GetFile from "pages/Documents/utils/GetFile";
import { Fragment } from "react";
import { v4 as uuid } from "uuid";
import CONSTANTS from "../../constants";
import InlineStyles from "./FileList.styles";
import { IFileItemProps, IFileListProps, IFileListWrapperProps, IFileMetaProps } from "./types";

const FileListWrapper = ({ children }: IFileListWrapperProps) => <Box css={InlineStyles.listWrapper}>{children}</Box>;

const UnreadMarker = () => <Box as="span" css={InlineStyles.unreadMarker} />;

const FileMeta = ({ meta }: IFileMetaProps) => {
  const {
    createdAt,
    contentType,
    entity: {
      references: { summitAccountNumber }
    }
  } = meta;
  const date = new Date(createdAt);
  const formattedDate = date.toLocaleDateString("en-GB", {
    year: "numeric",
    month: "long",
    day: "numeric"
  });
  const fileFormat = contentType.toUpperCase();
  const {
    documents: { size }
  } = strings;

  return (
    <Box as="ul" css={InlineStyles.fileMeta} aria-label="File details">
      {summitAccountNumber && summitAccountNumber !== "0" ? (
        <Box as="li" css={InlineStyles.listItem}>
          <VisuallyHidden>Account number</VisuallyHidden>
          {summitAccountNumber}
        </Box>
      ) : null}
      <Box as="li" css={InlineStyles.listItem}>
        {size}
      </Box>
      {[fileFormat, formattedDate].map((item: string) => (
        <Box as="li" css={InlineStyles.listItem} key={uuid()}>
          {item}
        </Box>
      ))}
    </Box>
  );
};

const FileItem = ({
  entity,
  title,
  description,
  createdAt,
  contentType,
  isRead = false,
  handleFileClick = id => GetFile(id)
}: IFileItemProps) => {
  const metaProps = { description, createdAt, contentType, entity };

  return (
    <Flex as="li" direction="row" key={entity.id} css={InlineStyles.list}>
      {!isRead && <UnreadMarker />}
      <Box as="span">
        <Heading as="h3" level="6" color="secondary" css={InlineStyles.heading}>
          <DocumentTextIcon height="20" width="20" aria-hidden />
          <Button variant="plain" css={InlineStyles.downloadTrigger} onClick={() => handleFileClick(entity.id)}>
            {title} {!isRead ? <VisuallyHidden>Unread</VisuallyHidden> : null}
          </Button>
        </Heading>
        <FileMeta meta={metaProps} />
      </Box>
    </Flex>
  );
};

const FileList = ({ data, limit, children }: IFileListProps) => (
  <FileListWrapper>
    <Flex as="ul" gap="4" direction="column">
      {data.map((items: IFileItemProps[]) => (
        <Fragment key={uuid()}>
          {items.length ? (
            items
              .filter((_, i) => i !== limit)
              .map(({ entity, ...props }: IFileItemProps) => <FileItem key={entity.id} entity={entity} {...props} />)
          ) : (
            <Text>{CONSTANTS.noFiles}</Text>
          )}
        </Fragment>
      ))}
    </Flex>
    {children}
  </FileListWrapper>
);

export { FileItem, FileList, FileListWrapper, FileMeta };
