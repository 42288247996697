import { Badge, Box, Button, ChatIcon, Flex, Heading, Text } from "@shawbrook/sds-component-library";
import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";

import strings from "assets/strings/strings.json";
import classnames from "classnames";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import { links } from "config/navLinks";
import useThreads from "hooks/useThreads";
import { IThreadSummary } from "services/data/types";
import { IInboxContext } from "state/inboxState";
import { formatToDisplayDate } from "utils";
import { SkeletonLoader } from "./components/SkeletonLoader";
import styles from "./MessageThreadList.module.scss";
import InlineStyles from "./MessageThreadList.styles";

interface IMessageThreadList {
  context: IInboxContext;
}

export const MessageThreadList: FC<IMessageThreadList> = ({ context }) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const canOpenNewThread = pathname !== links.inbox.link;

  const { threadsData, errors, isLoading, threadsError } = useThreads();

  // returns a closure for the navigate without typing
  const clickHandler = (threadId: string) => () => {
    context.setState({
      isGetMessages: true
    });
    navigate(`${links.inbox.link}/thread/${threadId}`);
  };
  const newThread = () => navigate(`${links.inbox.link}`);
  const newThreadMobile = () => navigate(`${links.inboxNew.link}`);

  if (errors) {
    return <ErrorRequestView requestErrors={[threadsError]} context={context} errorModal={<ErrorModal open />} />;
  }

  const unreadThreads = threadsData && threadsData.response?.filter((e: IThreadSummary) => !e.isClosed);
  const readThreads = threadsData && threadsData.response?.filter((e: IThreadSummary) => e.isClosed);
  const threadsSorted = [];
  if (unreadThreads?.length) threadsSorted.push({ label: strings.inbox.openLabel, data: unreadThreads });
  if (readThreads?.length) threadsSorted.push({ label: strings.inbox.archivedLabel, data: readThreads });

  const getClassname = (threadId: string, countOfUnreadMessages: number) =>
    classnames(styles["message-thread-list__thread"], {
      [styles["message-thread-list__thread--selected"]]: pathname.indexOf(threadId) > 0,
      [styles["message-thread-list__thread--unread"]]: countOfUnreadMessages
    });

  // if the list is loading, show it's independent loading state
  return isLoading ? (
    <SkeletonLoader />
  ) : (
    <Box css={InlineStyles.container}>
      <Box css={InlineStyles.top}>
        <Button
          iconLeft
          size="small"
          isWhite
          disabled={!canOpenNewThread}
          css={InlineStyles.buttonNewMessage}
          onClick={newThread}>
          <ChatIcon />
          {strings.inbox.newThreadBtnLabel}
        </Button>
        <Button iconLeft size="small" isWhite css={InlineStyles.buttonNewMessageMobile} onClick={newThreadMobile}>
          <ChatIcon />
          {strings.inbox.newThreadBtnLabel}
        </Button>
      </Box>
      {!unreadThreads && !readThreads && (
        <section className={styles["message-thread-list__section"]}>
          <Text textStyle="bodyStrong">No messages</Text>
        </section>
      )}
      {threadsSorted.map((group: any) => (
        <Box key={group.label} css={InlineStyles.section}>
          <Heading level="2" css={InlineStyles.heading}>
            {group.label}
          </Heading>
          {group.data?.map((el: any) => (
            <Flex
              key={uuidv4()}
              className={getClassname(el.threadId, el.countOfUnreadMessages)}
              align="stretch"
              direction="row"
              gap="3"
              justify="start"
              onClick={clickHandler(el.threadId)}>
              <Flex align="center" direction="row" css={{ padding: "0 0 0 1.25rem" }}>
                {el.countOfUnreadMessages ? (
                  <Badge
                    value={el.countOfUnreadMessages}
                    limit={9}
                    css={{
                      ...InlineStyles.badge,
                      width: el.countOfUnreadMessages.count > 8 ? "1rem" : "1.1rem",
                      height: el.countOfUnreadMessages.count > 8 ? "1rem" : "1.1rem",
                      fontSize: el.countOfUnreadMessages.count > 8 ? "0.75rem" : "0.7rem"
                    }}
                  />
                ) : (
                  <ChatIcon width="24" height="24" />
                )}
              </Flex>
              <Flex
                align="stretch"
                direction="column"
                css={{
                  flexGrow: "1",
                  padding: "1rem"
                }}>
                {el.subject && (
                  <Text className={styles["message-thread-list__subject"]} fontWeight="semiBold">
                    {el.subject}
                  </Text>
                )}
                <Text className={styles["message-thread-list__date"]} css={InlineStyles.messageThreadListDate}>
                  {el.mostRecentMessage &&
                    el.mostRecentMessage.sentDate &&
                    formatToDisplayDate(el.mostRecentMessage.sentDate)}
                </Text>
              </Flex>
            </Flex>
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default MessageThreadList;
