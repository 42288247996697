import { FC } from "react";

import strings from "assets/strings/strings.json";
import classnames from "classnames";
import SystemInfoCard from "components/SystemInfoCard/SystemInfoCard";
import InfoLayout from "layouts/InfoLayout/InfoLayout";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";

export const UnknownError: FC = () => {
  const { title, description, action } = strings.unknownError;
  const cxLayout = classnames("layoutWrapper--landing", "layoutWrapper--landing--info");

  const descriptionAdjusted = formatTextWithPhoneHours(description);

  return (
    <InfoLayout className={cxLayout}>
      <SystemInfoCard
        title={title}
        description={descriptionAdjusted}
        actionTitle={action}
        action={() => window.location.reload()}
      />
    </InfoLayout>
  );
};
