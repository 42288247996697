export default {
  cardVariants: {
    info: {
      paddingTop: "$2",
      paddingBottom: "$2"
    },
    warning: {
      padding: "$2 $2-5",
      color: "$shawbrookCharcoal"
    }
  },
  card: {
    // this should be removed once the font is updated in the component library
    h2: {
      fontFamily: "$body"
    },
    "@xl": {
      minWidth: "750px"
    }
  },
  actionCard: {
    "& > div:last-child": {
      minWidth: "fit-content",
      "@sm": {
        "& > *": {
          margin: 0
        }
      }
    },
    adjusted: {
      "& > div:last-child": {
        minWidth: "fit-content"
      }
    }
  },
  button: {
    borderBottom: 0,
    padding: "$1 0",
    "&:hover, &:active": {
      "& svg path": {
        fill: "$shawbrookCharcoal"
      }
    },
    "& svg": {
      marginLeft: "0"
    }
  },
  buttonText: {
    lineHeight: "$tight",
    marginRight: "0.125rem"
  }
};
