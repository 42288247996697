import { Box, Heading } from "@shawbrook/sds-component-library";
import { ArrowLeft } from "assets/images";
import strings from "assets/strings/strings.json";
import { IconButton } from "components/IconButton/IconButton";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { handleBackButtonClick } from "utils/newAccountNavigation/newAccountNavigation";
import styles from "./SecondaryHeader.module.scss";

interface ISecondarHeaderProps {
  title: string;
  backLink: string;
  isEdit?: boolean;
  prevPath?: string;
}

export const SecondaryHeader: FC<ISecondarHeaderProps> = ({ title, backLink, isEdit = false, prevPath = "" }) => {
  const navigate = useNavigate();

  return (
    <Box as="header" className={styles.header}>
      <IconButton
        onClick={() => handleBackButtonClick(backLink, isEdit, navigate, prevPath)}
        icon={<ArrowLeft />}
        text={strings.general.back}
      />
      <Heading color="white">{title}</Heading>
    </Box>
  );
};
