import { IStep } from "services/data/types/bankfastUserMigration/";
import {
  ACCESSIBILITY,
  ACCESSIBILITY_SUPPORT_NEEDED,
  CUSTOMER_EMAIL,
  MARKETING_PREFERENCES
} from "services/data/types/bankfastUserMigration/selectors";
import { customerDetailsEmailSchema, customerDetailsGDPRAndA11ySchema, otpSchema } from "services/validationSchemas/";

import strings from "assets/strings/strings.json";
import StepMobileIds from "pages/BankfastUserMigrationMobile/StepIds";
import StepDefaultIds from "./StepIds";

const AllStepIds = { ...StepMobileIds, ...StepDefaultIds };
type TAllStepIds = keyof typeof AllStepIds;

export type TAllStepIdValues = typeof AllStepIds[TAllStepIds];

export type IAllBankfastUserMigrationStepTypes = StepMobileIds | StepDefaultIds;

export enum BankfastUserMigrationFlow {
  DEFAULT = "default",
  MOBILE = "mobile"
}

export const Steps: IStep<TAllStepIdValues>[] = [
  {
    id: StepDefaultIds.EMAIL,
    title: strings.bankfastUserMigration.stepsName.email,
    fields: [CUSTOMER_EMAIL],
    validationSchema: customerDetailsEmailSchema
  },
  {
    id: StepDefaultIds.EMAIL_OTP,
    title: strings.bankfastUserMigration.stepsName.email,
    fields: [CUSTOMER_EMAIL],
    validationSchema: otpSchema
  },
  {
    id: StepDefaultIds.CHANGE_EMAIL,
    title: strings.bankfastUserMigration.stepsName.changeEmail,
    fields: [CUSTOMER_EMAIL],
    validationSchema: customerDetailsEmailSchema,
    continueButtonLabel: strings.bankfastUserMigration.changeEmail.continueButton,
    continueButtonLink: strings.bankfastUserMigration.changeEmail.continueButtonLink
  },
  {
    id: StepDefaultIds.GDPR_AND_A11Y,
    title: strings.bankfastUserMigration.stepsName.GDPRAndA11y,
    fields: [MARKETING_PREFERENCES, ACCESSIBILITY, ACCESSIBILITY_SUPPORT_NEEDED],
    validationSchema: customerDetailsGDPRAndA11ySchema,
    continueButtonLabel: strings.bankfastUserMigration.GDPRAndA11y.continueButton
  }
];
