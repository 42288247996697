export default {
  button: {
    borderBottom: 0,
    padding: "$1-5 0",
    "&:hover, &:active": {
      "& ins": {
        color: "$pink4"
      }
    },
    "& svg": {
      marginRight: "$1",
      width: "24px",
      height: "24px"
    }
  },
  buttonText: {
    lineHeight: "$tight"
  }
};
