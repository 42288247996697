import { useMutation } from "@tanstack/react-query";
import { submitNewAccount } from "services/data/api";
import { INewAccountPayload } from "services/data/types";

const useSubmitNewAccount = (content: INewAccountPayload) => {
  const {
    mutate: postNewAccount,
    isError: isSubmitNewAccountError,
    error: submitNewAccountError,
    isSuccess: isSubmitNewAccountSuccess,
    isLoading: isSubmitNewAccountLoading,
    data: submitNewAccountData
  } = useMutation({
    mutationFn: () => submitNewAccount(content)
  });

  return {
    postNewAccount,
    isSubmitNewAccountError,
    submitNewAccountError,
    isSubmitNewAccountSuccess,
    isSubmitNewAccountLoading,
    submitNewAccountData
  };
};

export default useSubmitNewAccount;
