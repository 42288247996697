import { Card, Heading, LeftArrow, Loader } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import { IconButton } from "components/IconButton/IconButton";
import useAccount from "hooks/useAccount";
import useCustomer from "hooks/useCustomer";
import useScrollToTop from "hooks/useScrollToTop";
import inlineStyles from "pages/FundingInstructions/FundingInstructions.style";
import { useNavigate, useParams } from "react-router-dom";
import { IAccountDetails } from "services/data/types";
import { useMainContext } from "state/mainState";
import hasLinkedAccountsMissed from "utils/hasLinkedAccountsMissed";
import { DefaultFundingInstructions } from "./DefaultFundingInstructions/DefaultFundingInstructions";
import styles from "./FundingInstructions.module.scss";
import { MissingLinkedAccountInstructions } from "./MissingLinkedAccount/MissingLinkedAccountInstructions";
import { UnverifiedLinkedAccountInstructions } from "./UnverifiedLinkedAccountInstructions/UnverifiedLinkedAccountInstructions";

export const FundingInstructions = () => {
  const params = useParams();
  const navigate = useNavigate();
  const context = useMainContext();
  useScrollToTop();
  const accountId = params.id || "";

  const { customerData, isCustomerError, isCustomerLoading, customerError } = useCustomer();
  const { accountDetailData, accountResolved, accountErrored, accountError } = useAccount({ accountId });

  const { back } = strings.general;
  const { fundingInstructions } = strings.navigation;

  if (accountErrored || isCustomerError) {
    return (
      <ErrorRequestView
        requestErrors={[customerError, accountError]}
        context={context}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }

  const accountData = accountDetailData as IAccountDetails;
  const customerName = customerData
    ? `${customerData?.name?.givenNames?.join(" ")} ${customerData?.name?.familyName!}`
    : "";

  const isLinkedAccountMissing = accountDetailData && hasLinkedAccountsMissed([accountDetailData]);
  const isLinkedAccountVerified = accountDetailData?.linkedAccount?.verified;

  return (
    <section className={styles.fundingInstructions__wrapper}>
      <header>
        <IconButton onClick={() => navigate(-1)} icon={<LeftArrow />} text={back} />
        <Heading color="white">{fundingInstructions}</Heading>
      </header>
      <section className={styles.page__content}>
        <Card css={inlineStyles.card} variant="white">
          {(!accountResolved || isCustomerLoading) && (
            <Loader showRing size="medium" spacing="medium" text={strings.general.loading} textColor="primary" />
          )}
          {accountResolved && !isCustomerLoading && (
            <>
              {isLinkedAccountMissing && <MissingLinkedAccountInstructions />}
              {!isLinkedAccountMissing && !isLinkedAccountVerified && (
                <UnverifiedLinkedAccountInstructions bankName={accountData.linkedAccount?.bankName || ""} />
              )}
              {!isLinkedAccountMissing && isLinkedAccountVerified && (
                <DefaultFundingInstructions accountData={accountData} customerName={customerName} />
              )}
            </>
          )}
        </Card>
        <aside>
          <AskAQuestion />
        </aside>
      </section>
    </section>
  );
};

export default FundingInstructions;
