import { useFormContext } from "react-hook-form";
import { NumberFormatBase, NumberFormatValues } from "react-number-format";
import { ITextInput, TextInput } from "../TextInput";

type IPatternTextInput = {
  format: (value: string) => string;
  valueIsNumericString?: boolean;
  type?: "text" | "password" | "tel";
  isAllowed?: (values: NumberFormatValues) => boolean;
} & Omit<ITextInput, "type">;

export const PatternTextInput = ({
  fieldName,
  format,
  isAllowed,
  valueIsNumericString = false,
  ...props
}: IPatternTextInput) => {
  const { setValue } = useFormContext();

  return (
    <NumberFormatBase
      {...props}
      fieldName={fieldName}
      customInput={TextInput}
      valueIsNumericString={valueIsNumericString}
      format={format}
      setFormValue={false}
      isAllowed={isAllowed}
      onValueChange={(e: NumberFormatValues) => setValue(fieldName, e.formattedValue)}
    />
  );
};

export default PatternTextInput;
