export default {
  showMore: {
    color: "$shawbrookPink",
    display: "flex",
    gap: "4px",
    width: "100%",
    borderBottom: "1px solid currentColor",
    "&:hover, &:focus": {
      color: "$pink4"
    }
  },
  wrapper: {
    bg: "$white",
    p: "20px 0"
  }
};
