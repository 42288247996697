import { links } from "config/navLinks";

const LIMITS: Readonly<Record<string, string>> = Object.freeze({
  MATURED_GROSS: "0.10"
});

const MATURED_FUNDS: Readonly<Record<string, string>> = Object.freeze({
  MATURED_FUNDS: "Matured Funds",
  MATURED_FUNDS_ISA: "Cash ISA Matured Funds"
});

const IFRAMES: Readonly<Record<string, string>> = Object.freeze({
  DOCUMENT_DOWNLOAD: "documentDownload"
});

const MESSAGES: Readonly<Record<string, string>> = Object.freeze({
  TEMP_ID: "temp"
});

const INLINE_ERRORS: Readonly<RegExp> = /enter a passcode to continue|passcode is incorrect|passcode has expired/gi;

const INFO_COMPLETED_URLS = [links.resetPasswordCompleted.link, links.profile.link];

export { IFRAMES, INFO_COMPLETED_URLS, INLINE_ERRORS, LIMITS, MATURED_FUNDS, MESSAGES };
