import { useEffect, useState } from "react";
import { useInfiniteQuery } from "@tanstack/react-query";

import { API_LIMITS } from "assets/strings/api";
import { UseTransfersProps } from "hooks/useTransfers/interfaces";
import { getTransfers } from "services/data/api";
import { IGetPaymentsParams, QueryKeys } from "services/data/types";

const useTransfers = ({ sourceAccountIdArg, limit = API_LIMITS.PAYMENTS_DEFAULT_LIMIT }: UseTransfersProps) => {
  const sourceAccountId = sourceAccountIdArg ?? "";
  const [offset, setOffset] = useState(0);

  const paymentParams: IGetPaymentsParams = { limit: limit + 1, offset };

  const { hasNextPage, ...result } = useInfiniteQuery({
    queryKey: [QueryKeys.TRANSFER_HISTORY, sourceAccountId],
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    queryFn: () => getTransfers(sourceAccountId, paymentParams),
    getNextPageParam: lastPage => (lastPage?.length === limit + 1 ? true : undefined),
    cacheTime: 0
  });

  useEffect(() => {
    result.fetchNextPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offset]);

  const loadNextPage = () => {
    setOffset(prev => prev + limit);
  };

  return {
    transfersQuery: result,
    hasNextPage,
    offset,
    loadNextPage
  };
};

export default useTransfers;
