import { useEffect } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { links } from "config/navLinks";
import { SERVICE_TICK } from "assets/strings/api";

const addServiceTick = () => {
  if (
    !process.env.REACT_APP_SERVICE_TICK_ID ||
    !process.env.REACT_APP_SERVICE_TICK_CID ||
    !process.env.REACT_APP_SERVICE_TICK_SRC
  )
    return;
  const st = document.createElement("SCRIPT") as any;
  const custom1 = SERVICE_TICK.CUSTOM_VALUE;
  st.type = "text/javascript";
  st.src = process.env.REACT_APP_SERVICE_TICK_SRC;
  st.setAttribute("cid", process.env.REACT_APP_SERVICE_TICK_CID);
  st.setAttribute("id", process.env.REACT_APP_SERVICE_TICK_ID);
  st.setAttribute(SERVICE_TICK.CUSTOM_NAME, custom1);
  if (document.getElementsByTagName("head")[0]) {
    document.getElementsByTagName("head")[0].appendChild(st);
  }
};

const removeServiceTick = () => {
  if (!process.env.REACT_APP_SERVICE_TICK_ID) return;
  document.getElementById(process.env.REACT_APP_SERVICE_TICK_ID)?.remove();

  Array.from(document.getElementsByTagName("link"))
    .find(el => el.href.includes(SERVICE_TICK.LINK_URL))
    ?.remove();

  Promise.resolve().then(() => {
    const buttons = document.querySelectorAll(`.${SERVICE_TICK.BUTTON_CLASS}`);
    buttons.forEach(button => button.remove());
  });
};

const serviceTickLinks = [links.landing.link, links.moveMoneyReview.link, links.account.link, links.myProfile.link];

export const useServiceTick = () => {
  const { pathname } = useLocation();
  useEffect(() => {
    if (process.env.REACT_APP_SERVICE_TICK_ID) {
      if (serviceTickLinks.find(el => matchPath(el, pathname))) {
        if (!document.getElementById(process.env.REACT_APP_SERVICE_TICK_ID)) {
          addServiceTick();
        }
      } else {
        removeServiceTick();
      }
    }
  }, [pathname]);
};
