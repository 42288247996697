import { links } from "config/navLinks";
import { NavigateFunction } from "react-router-dom";

export const handleBackButtonClick = (
  link: string,
  editMode: boolean | undefined,
  navigate: NavigateFunction,
  prevPath = ""
) => {
  navigate(editMode ? links.newAccountSummary.link : link, { state: { previousLocationPathName: prevPath } });
};
