import * as yup from "yup";
import { OTPSchema } from "./commonSchema";

export const otpSchema =
  // HARDCODED VALIDATION SCHEMA, IT WILL LATER BE DYNAMICALLY FETCHED FROM THE API

  yup.object().shape({
    verification: yup.object().shape({
      otp: OTPSchema
    })
  });
