export const inlineStyles = {
  logo: {
    background: "none",
    "&:hover, &:active": {
      background: "none"
    },
    "&:focus": {
      outline: "none"
    }
  }
};
