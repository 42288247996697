import { Dispatch } from "react";
import { useOutletContext } from "react-router-dom";
import { IAccountDetails, IAccountSummary, Instruction, ProductCategory } from "services/data/types";
import { FormattedAvailableProduct } from "utils/formatAvailableProducts/interfaces";

export interface IReinvestmentStateDispatcher {
  accountId: string;
  partialData: Partial<IReinvestmentStateItem>;
}
export interface IReinvestmentContext {
  state: IReinvestmentState;
  setState: Dispatch<IReinvestmentStateDispatcher>;
}

export const REINVESTEMENT_TYPES = {
  partial: "partial",
  full: "full"
} as const;

export const CHOSEN_ACCOUNTS = {
  savings: "savings",
  linked: "linked"
} as const;

export const INTEREST_FREQUENCY = {
  monthly: "monthly",
  annually: "annually"
} as const;

export type MaturingAccount = IAccountSummary | IAccountDetails;
export type ReinvestmentType = typeof REINVESTEMENT_TYPES[keyof typeof REINVESTEMENT_TYPES];
export type InterestFrequency = typeof INTEREST_FREQUENCY[keyof typeof INTEREST_FREQUENCY];
export type ChosenAccount = typeof CHOSEN_ACCOUNTS[keyof typeof CHOSEN_ACCOUNTS];

export const initialReinvestmentState: IReinvestmentState = {};

export interface IReinvestmentStateItem {
  maturingAccount?: MaturingAccount;
  amountToReinvest?: number;
  chosenAccount?: ChosenAccount;
  selectedProductSetName?: string;
  selectedProductId?: string;
  instructions?: Array<Instruction>;
  selectedProductSet?: FormattedAvailableProduct;
  categories?: Set<ProductCategory>;
  editMode?: boolean;
}

export type IReinvestmentState = {
  [key: string]: IReinvestmentStateItem;
};

export const useReinvestmentContext = () => useOutletContext<IReinvestmentContext>();
