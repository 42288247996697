export const breakpoints = {
  "2xs": 320,
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1248,
  xxl: 1280,
  xxxl: 1440,
  noMotion: "prefers-reduced-motion"
} as const;

/**
 * More info here
 * @link https://www.totaltypescript.com/get-keys-of-an-object-where-values-are-of-a-given-type
 */
type KeysWithValsOfType<T, V> = keyof { [P in keyof T as T[P] extends V ? P : never]: P };

type MediaQuery = Record<KeysWithValsOfType<typeof breakpoints, number>, string>;

// Generate media queries from breakpoints
export const media = Object.fromEntries(
  // Convert breakpoint object to key/value array
  Object.entries(breakpoints)
    // Filter out any non-number values (e.g. prefers-reduced-motion)
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    .filter(([_, value]) => typeof value === "number")
    // Create a media query string for each breakpoint
    .map(([key, breakpoint]) => [key, `@media (min-width: ${breakpoint}px)`])
  /**
   * We need to cast the type here
   * @todo look to remove this when we upgrade to TS 5.5 so we can use inferred type predicates
   * @link https://devblogs.microsoft.com/typescript/announcing-typescript-5-5-beta/#inferred-type-predicates
   */
) as MediaQuery;
