import { Container, Text } from "@shawbrook/sds-component-library";
import { FC } from "react";

import { FscsLogo } from "assets/images";
import strings from "assets/strings/strings.json";
import styles from "./migrationFooter.module.scss";

const MigrationFooter: FC = () => {
  const year = new Date().getFullYear();

  return (
    <Container as="footer" className={styles.footer} space="0">
      <Text color="white">{strings.migrationFooter.replace("{{year}}", `${year}`)}</Text>
      <div className={styles.footerLogo}>
        <FscsLogo />
        <span>Financial Services &nbsp; Compensation Scheme</span>
      </div>
    </Container>
  );
};

export default MigrationFooter;
