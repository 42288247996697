import { Alert, Button, CrossIcon, Text } from "@shawbrook/sds-component-library";
import { useEffect, useRef, useState } from "react";
import { formatTextWithPhoneHours } from "utils/formatTextWithPhoneHours/FormatTextWithPhoneHours";
import styles from "./ChangeAlert.module.scss";

export type TChangeAlert = {
  boxText: string;
  buttonLabel: string;
  boxTextB?: string;
};

export const ChangeAlert = ({ boxText, boxTextB, buttonLabel }: TChangeAlert) => {
  const [show, setShow] = useState(false);
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const closeRef = useRef<HTMLButtonElement | null>(null);

  const showHandler = () => {
    setShow(!show);
  };

  useEffect(() => {
    const handleEsc = (event: KeyboardEvent) => {
      if (event.key === "Escape") {
        setShow(false);
        buttonRef.current?.focus();
      }
    };

    if (show) {
      closeRef.current?.focus();
    } else {
      buttonRef.current?.focus();
    }

    document.addEventListener("keydown", handleEsc);
  }, [show]);

  return (
    <>
      <Button
        className={styles.simpleLink}
        variant="tertiary"
        onClick={showHandler}
        ref={buttonRef}
        aria-controls="change-modal"
        aria-expanded={show}>
        {buttonLabel}
      </Button>
      {show ? (
        <Alert
          id="change-modal"
          action={
            <Button
              iconRight
              type="button"
              size="medium"
              variant="plain"
              title="Close"
              className={styles.CrossButton}
              onClick={showHandler}
              ref={closeRef}>
              <CrossIcon className={styles.CrossIcon} />
            </Button>
          }
          variant="generic">
          <Text>{boxText}</Text>
          {boxTextB ? <Text>{formatTextWithPhoneHours(boxTextB)}</Text> : null}
        </Alert>
      ) : null}
    </>
  );
};
