import { yupResolver } from "@hookform/resolvers/yup";
import { Flex } from "@shawbrook/sds-component-library";
import React from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";
import { ApiError, ComponentState, IStep } from "services/data/types/bankfastUserMigration/";
import { IBankfastUserMigrationState } from "state/bankfastUserMigrationState";
import { useDidUpdate } from "utils/customHooks";
import StepMobile from "../BankfastUserMigrationMobile/StepIds";
import StepDefault from "./StepIds";

type IFormProps = {
  defaultValues: IBankfastUserMigrationState;
  children: any;
  onSubmit: SubmitHandler<IBankfastUserMigrationState>;
  currentStep: IStep<StepDefault | StepMobile>;
  currentState: ComponentState;
  apiStepErrors?: ApiError[] | null;
};

export const Form = ({ defaultValues, children, onSubmit, currentStep, currentState, apiStepErrors }: IFormProps) => {
  let validationSchema;
  switch (currentStep.id) {
    default:
      validationSchema = currentStep.validationSchema;
  }

  const methods = useForm<IBankfastUserMigrationState>({
    defaultValues,
    resolver: validationSchema ? yupResolver(validationSchema) : undefined
  });

  const { handleSubmit, setError, clearErrors } = methods;

  useDidUpdate(() => {
    if (currentState === ComponentState.INITIAL) {
      clearErrors();
    }
  }, [apiStepErrors, currentState, setError]);

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <Flex gap="10" direction="column">
          {React.Children.map(children, child =>
            child && child.props.currentStep !== undefined
              ? React.createElement(child.type, {
                  ...{
                    ...child.props,
                    key: child.props.name
                  }
                })
              : child
          )}
        </Flex>
      </form>
    </FormProvider>
  );
};
export default Form;
