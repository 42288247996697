import { useRef, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { UseTransferProps } from "hooks/useTransfer/interfaces";
import { QueryKeys, IPaymentsResponse } from "services/data/types";
import { getTransfer } from "services/data/api";
import { API_LIMITS } from "assets/strings/api";

const useTransfer = ({
  sourceAccountIdArg,
  paymentIdArg,
  isRefetch = false,
  enabled = true,
  resetState
}: UseTransferProps) => {
  const sourceAccountId = sourceAccountIdArg ?? "";
  const paymentId = paymentIdArg ?? "";
  const refetchFirstTime = useRef<number | null>(null);

  useEffect(() => {
    if (isRefetch === false) {
      refetchFirstTime.current = null;
    }
  }, [isRefetch]);

  const {
    data,
    isLoading: isTransferLoading,
    isError: isTransferError,
    isSuccess: isTransferSuccess,
    status: transferStatus,
    isFetching: transferFetching
  } = useQuery({
    queryKey: [QueryKeys.TRANSFER_DETAIL, sourceAccountId, paymentId],
    queryFn: () => getTransfer(sourceAccountId, paymentId),
    enabled,
    ...((isRefetch === true || (typeof isRefetch === "number" && isRefetch > 0)) && {
      refetchInterval: (_, query) => {
        let interval: boolean | number = Math.min(
          API_LIMITS.RETRY_TIME_STEP * 2 ** query.state.dataUpdateCount,
          API_LIMITS.RETRY_MAX_TIME
        );

        if (!refetchFirstTime.current) {
          refetchFirstTime.current = query.state.dataUpdatedAt;
        }

        if (typeof isRefetch === "number" && isRefetch > 0) {
          const currentTime = query.state.dataUpdatedAt;
          interval = currentTime && currentTime - refetchFirstTime.current >= isRefetch ? false : interval;
          if (interval === false && resetState) {
            resetState(false);
          }
        }

        return interval;
      }
    })
  });
  const transferData = data as IPaymentsResponse | undefined;

  return {
    transferData,
    isTransferLoading,
    isTransferError,
    isTransferSuccess,
    transferStatus,
    transferFetching
  };
};

export default useTransfer;
