import { useQuery } from "@tanstack/react-query";
import { getAvailableThreads } from "services/data/api";
import { QueryKeys } from "services/data/types/queryKeys";

const useThreads = () => {
  const {
    data: threadsData,
    isLoading,
    isError: errors,
    error: threadsError
  } = useQuery({
    queryKey: [QueryKeys.THREADS],
    queryFn: () => getAvailableThreads(),
    refetchOnWindowFocus: false
  });

  return {
    threadsData,
    errors,
    isLoading,
    threadsError
  };
};

export default useThreads;
