import { ChangeMobile, Mobile, MobileOTP } from ".";
import { ChangeEmail, Email, EmailOTP, GDPRAndA11y } from "../BankfastUserMigration";

/* eslint-disable */
// This is required because of warnings about missing keys in the pages array, it will be fixed when we have the full form structure
export const pages = [
  <Email />,
  <EmailOTP />,
  <ChangeEmail />,
  <Mobile />,
  <MobileOTP />,
  <ChangeMobile />,
  <GDPRAndA11y />
];

export const BankfastUserMigrationMobileFormStructure = {
  steps: pages.length,
  pages
};

export default BankfastUserMigrationMobileFormStructure;
