import { Text } from "@shawbrook/sds-component-library";
import { differenceInDays } from "date-fns";
import { ReactNode } from "react";
import rreplace from "react-string-replace";

import { ERROR_CODES } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import { ProductCategory } from "services/data/types";
import { ALERT_ACTION_MODE, ALERT_VARIANTS, AlertActionMode, AlertVariants } from "types/types";
import { formatToDisplayDate, getPhoneFormat } from "utils";
import { isMaturedAccountProductCategory } from "utils/isMaturedAccountProductCategory/isMaturedAccountProductCategory";

interface IGetNonWithdrawableInfo {
  maturityDate?: string;
  reason?: string;
  openToFundingReason?: string;
  category?: ProductCategory;
  isLinkedAccVerified?: boolean;
}

const getNonWithdrawableInfo = ({
  maturityDate,
  reason,
  category,
  openToFundingReason,
  isLinkedAccVerified
}: IGetNonWithdrawableInfo) => {
  let text: string | ReactNode;
  let heading: string | undefined;
  let variant: AlertVariants = ALERT_VARIANTS.info;
  let actionMode: AlertActionMode | undefined;

  if (isMaturedAccountProductCategory(category)) {
    ({ heading, maturedAccountText: text } = strings.moveMoney.nonWithdrawable);

    text = rreplace(text as string, "{{HELP_CENTER_NUMBER}}", (_, i) => (
      <Text fontWeight="bold" font="silka" as="span" key={`helpCenterNumber${i}`}>
        {getPhoneFormat(strings.general.helpCenterNumber)}
      </Text>
    ));

    variant = ALERT_VARIANTS.warning;
    actionMode = ALERT_ACTION_MODE.sendMessage;
  } else if (reason === ERROR_CODES.ACTIVE_SOURCE_ACCOUNT) {
    ({ closedHeading: heading } = strings.moveMoney.nonWithdrawable);
    text = undefined;
    variant = ALERT_VARIANTS.warning;
  } else if (reason === ERROR_CODES.INVALID_AVAILABLE_BALANCE) {
    ({ zeroBalanceHeading: heading } = strings.moveMoney.nonWithdrawable);
    text = undefined;
    variant = ALERT_VARIANTS.warning;
  } else if (
    (category === ProductCategory.FIXED_RATE && openToFundingReason === ERROR_CODES.PEGA_OVERRIDE) ||
    reason === ERROR_CODES.NOT_WITHDRAWABLE_FIXED_RATE
  ) {
    const { fixedAccountText, fixedAccountTextRemainingSignature, fixedAccountLessThanADaySignature, day, days } =
      strings.moveMoney.nonWithdrawable;
    const formattedMaturityDate = formatToDisplayDate(maturityDate || "");
    let daysNumber: string | number = differenceInDays(new Date(maturityDate || ""), new Date());
    const daysSignature = daysNumber === 1 || daysNumber === 0 ? day : days;
    heading = strings.moveMoney.nonWithdrawable.fixedAccountHeading;

    if (daysNumber === 0) {
      daysNumber = fixedAccountLessThanADaySignature;
    }

    text = rreplace(fixedAccountText, "{{maturityDate}}", (_, i) => (
      <Text fontWeight="semiBold" font="silka" as="span" key={`fixedMaturityDate${i}`}>
        {formattedMaturityDate}
      </Text>
    ));

    text = rreplace(text as string, "{{daysLeft}}", (_, i) => (
      <Text fontWeight="semiBold" font="silka" as="span" key={`fixedDaysLeft${i}`}>
        {`${daysNumber} ${daysSignature} ${fixedAccountTextRemainingSignature}`}
      </Text>
    ));
  } else if (category === ProductCategory.FIXED_RATE_ISA) {
    ({ fixedAccountISAHeading: heading } = strings.moveMoney.nonWithdrawable);
    text = rreplace(
      strings.moveMoney.nonWithdrawable.fixedAccountISAText as string,
      "{{HELP_CENTER_NUMBER}}",
      (_, i) => (
        <Text fontWeight="bold" font="silka" as="span" key={`helpCenterNumber${i}`}>
          {getPhoneFormat(strings.general.helpCenterNumber)}
        </Text>
      )
    );
    actionMode = ALERT_ACTION_MODE.sendMessage;
  } else if (category === ProductCategory.NOTICE) {
    ({ noticeAccountHeading: heading } = strings.moveMoney.nonWithdrawable);
    text = strings.moveMoney.nonWithdrawable.noticeAccountText;
    variant = ALERT_VARIANTS.warning;
    actionMode = ALERT_ACTION_MODE.sendMessage;
  } else if (isLinkedAccVerified === false) {
    ({ linkedAccUnverifiedHeading: heading, linkedAccUnverifiedText: text } = strings.moveMoney.nonWithdrawable);
    variant = ALERT_VARIANTS.warning;
    actionMode = ALERT_ACTION_MODE.verifyLinkedAccount;
  } else if (
    reason === ERROR_CODES.NOT_WITHDRAWABLE_ISA_FIXED_RATE ||
    reason === ERROR_CODES.NOT_WITHDRAWABLE_NOTICE ||
    reason === ERROR_CODES.NOT_WITHDRAWABLE_ISA_NOTICE ||
    reason === ERROR_CODES.NOT_WITHDRAWABLE_ISA ||
    reason === ERROR_CODES.NOT_WITHDRAWABLE_POST_MATURITY
  ) {
    ({ heading, text } = strings.moveMoney.nonWithdrawable);
    text = rreplace(text as string, "{{HELP_CENTER_NUMBER}}", (_, i) => (
      <Text fontWeight="bold" font="silka" as="span" key={`helpCenterNumber${i}`}>
        {getPhoneFormat(strings.general.helpCenterNumber)}
      </Text>
    ));
    variant = ALERT_VARIANTS.warning;
    actionMode = ALERT_ACTION_MODE.sendMessage;
  }

  return {
    text,
    heading,
    variant,
    actionMode
  };
};

export default getNonWithdrawableInfo;
