import { Container, ProgressBar } from "@shawbrook/sds-component-library";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import LoadingSection from "components/LoadingSection/LoadingSection";
import useCustomer from "hooks/useCustomer";
import { SubmitSection } from "journeys/components/";
import Priorities from "pages/BankfastUserMigration/PageSequence";
import MobilePriorities from "pages/BankfastUserMigrationMobile/PageSequence";
import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import {
  ApiError,
  APIReturnResult,
  CommsPreferences,
  ComponentState
} from "services/data/types/bankfastUserMigration/";
import { IBankfastUserMigrationState, initialState } from "state/bankfastUserMigrationState";
import { useMainContext } from "state/mainState";
import { useDidUpdate } from "utils/customHooks";
import { calculatePercentage } from "../../utils/bankFastUserMigrationJourney";
import MobileFormStucture from "../BankfastUserMigrationMobile/FormStructure";
import StepMobile from "../BankfastUserMigrationMobile/StepIds";
import { Steps as stepsMobile } from "../BankfastUserMigrationMobile/Steps";
import {
  filterOnlyErrorsFromStep,
  getGeneralError,
  getKnownErrorsFromResult,
  isResultSuccessful
} from "./ErrorManagement";
import { Form } from "./Form";
import DefaultFormStructure from "./FormStructure";
import StepDefault from "./StepIds";
import { getStepIndexById } from "./StepManagement";
import { BankfastUserMigrationFlow, Steps as stepsDefault } from "./Steps";
import useEnforceUserJourney from "./useEnforceUserJourney";

type AllStepsFromAllJourneys = StepDefault | StepMobile;

export const Wrapper = ({ flowId = BankfastUserMigrationFlow.DEFAULT }: { flowId?: BankfastUserMigrationFlow }) => {
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const steps = flowId === BankfastUserMigrationFlow.DEFAULT ? stepsDefault : stepsMobile;
  const currentStep = steps[currentStepIndex];
  const [isBtnHidden, setIsBtnHidden] = useState(currentStep?.hideContinueButton ?? false);
  const [currentState, setCurrentState] = useState(ComponentState.INITIAL);
  const [apiErrors, setApiErrors] = useState<{ errors: ApiError[] | null } | undefined>();
  const [formData, setFormData] = useState<IBankfastUserMigrationState | null | undefined>();
  const [postResult, setPostResult] = useState<{ result: APIReturnResult | null } | undefined>();
  const [continueBtnLoading, setContinueBtnLoading] = useState(false);
  const [staticCommsPreferences, setStaticCommsPreferences] = useState<CommsPreferences | null>(null);

  const { stepId } = useParams();
  const formStructure = flowId === BankfastUserMigrationFlow.DEFAULT ? DefaultFormStructure : MobileFormStucture;
  const priorities = flowId === BankfastUserMigrationFlow.DEFAULT ? Priorities : MobilePriorities;
  const location = useLocation();
  const { customerData, isCustomerError, isCustomerLoading, customerError } = useCustomer(
    true,
    currentState === ComponentState.INITIAL,
    [stepId as string]
  );
  const context = useMainContext();

  useEnforceUserJourney(
    customerData,
    formData?.customer.contactDetails.email,
    formData?.customer.contactDetails.mobilePhoneNumber
  );

  useEffect(() => {
    setCurrentStepIndex(getStepIndexById(steps, stepId as AllStepsFromAllJourneys));
  }, [location.pathname, stepId, steps]);

  useEffect(() => {
    setIsBtnHidden(steps[currentStepIndex]?.hideContinueButton ?? false);
  }, [currentStepIndex, steps]);

  useDidUpdate(() => {
    setCurrentState(ComponentState.PRESUBMIT);
  }, [formData]);

  useDidUpdate(() => {
    if (currentState !== ComponentState.INITIAL) {
      setCurrentState(
        isResultSuccessful(postResult?.result) ? ComponentState.GO_TO_NEXT_STEP : ComponentState.DISPLAY_ERRORS
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiErrors]);

  useDidUpdate(() => {
    if (postResult) {
      setCurrentState(ComponentState.SUBMITTED);
    }
  }, [postResult]);

  // this process is documented here:
  // https://shawbrook.atlassian.net/wiki/spaces/SWE/pages/970588318/Front+End+architecture+-+Cut-over+bankfast+user+migration+journey
  // if the above process, the documentation must be updated as well

  useDidUpdate(() => {
    if (currentState === ComponentState.INITIAL) {
      setPostResult(undefined);
      setApiErrors(undefined);
    }

    if (currentState === ComponentState.SUBMITTED) {
      const knownErrors = getKnownErrorsFromResult(postResult?.result);
      if (Array.isArray(knownErrors)) {
        setApiErrors({
          errors: knownErrors
        });
      } else {
        setApiErrors({
          errors: !isResultSuccessful(postResult?.result) ? getGeneralError() : null
        });
      }
    }

    if (currentState === ComponentState.PRESUBMIT) {
      setContinueBtnLoading(true);
    }

    if (
      currentState === ComponentState.INITIAL ||
      currentState === ComponentState.GO_TO_NEXT_STEP ||
      currentState === ComponentState.DISPLAY_ERRORS
    ) {
      setContinueBtnLoading(false);
    }
  }, [currentState]);

  useEffect(() => {
    if (customerData && !staticCommsPreferences) {
      setStaticCommsPreferences({
        email: customerData.contactDetails?.emailVerified!,
        mobile: customerData.contactDetails?.mobilePhoneNumberVerified!,
        communication: customerData.contactDetails?.commPreferencesProvided!
      });
    }
  }, [staticCommsPreferences, setStaticCommsPreferences, customerData]);

  const onSubmit = (data: IBankfastUserMigrationState) => {
    setFormData((prevData: IBankfastUserMigrationState | null | undefined) => ({
      ...prevData,
      ...data
    }));
  };

  if (isCustomerError) {
    return (
      <ErrorRequestView context={context} requestErrors={[customerError]} errorModal={<ErrorModal open allPages />} />
    );
  }

  return (
    <main>
      {staticCommsPreferences && (
        <>
          <Container size="3" space="9">
            <ProgressBar
              labelText={currentStep?.title}
              progress={calculatePercentage(
                steps,
                priorities,
                staticCommsPreferences,
                stepId as AllStepsFromAllJourneys
              )}
            />
          </Container>
          <Container
            size="1-5"
            space={{
              "@initial": 10,
              "@md": 24
            }}>
            <Form
              defaultValues={initialState}
              onSubmit={onSubmit}
              currentStep={currentStep}
              apiStepErrors={filterOnlyErrorsFromStep(currentStep, apiErrors?.errors)}
              currentState={currentState}>
              {React.cloneElement(formStructure.pages[currentStepIndex], {
                currentState,
                currentStep: steps[currentStepIndex],
                flowId,
                currentStepIndex,
                setPostResult,
                setCurrentState,
                apiErrors: apiErrors?.errors,
                onSubmit,
                continueBtnLoading,
                customerData,
                setIsBtnHidden
              })}

              {!isBtnHidden && (
                <SubmitSection
                  continueBtnLoading={continueBtnLoading}
                  buttonLabel={currentStep.continueButtonLabel}
                  buttonLink={currentStep.continueButtonLink}
                />
              )}
            </Form>
          </Container>
        </>
      )}
      {isCustomerLoading && !staticCommsPreferences && <LoadingSection />}
    </main>
  );
};

export default Wrapper;
