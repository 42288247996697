import { media } from "config/breakpoints";

const { md } = media;

export const inlineStyles = {
  lists: {
    flexDirection: "column",
    [md]: {
      flexDirection: "row"
    }
  }
};
