import { TextInput as SDSTextInput, Tooltip, InputFieldSizes } from "@shawbrook/sds-component-library";
import { ChangeEvent, ChangeEventHandler } from "react";
import { useFormContext } from "react-hook-form";
import { getFirstErrorFromJSONPaths } from "utils/reactHookFormUtils";

export type ITextInput = {
  id: string;
  fieldName: string;
  label: string;
  description?: string;
  className?: string;
  errorFieldNames?: string[];
  autoFocus?: boolean;
  autoComplete?: string;
  toolTipText?: string;
  fieldSize?: InputFieldSizes;
  type?: "text" | "password" | "number" | "email" | "date" | "tel";
  onChange?: ChangeEventHandler<HTMLInputElement>;
  icon?: React.ReactNode;
  setFormValue?: boolean;
};
export const TextInput = ({
  id,
  fieldName,
  label,
  description,
  className,
  errorFieldNames = [fieldName],
  autoFocus = false,
  autoComplete,
  toolTipText,
  type = "text",
  fieldSize,
  onChange,
  icon,
  setFormValue = true
}: ITextInput) => {
  const {
    formState: { errors },
    setValue,
    register
  } = useFormContext();

  const handleValueChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (setFormValue) {
      setValue(fieldName, e.target.value);
    }
    onChange?.(e);
  };

  return (
    <SDSTextInput
      id={id}
      className={className}
      labelText={{
        main: label,
        sub: description
      }}
      {...register(fieldName)}
      onChange={handleValueChange}
      type={type}
      fieldSize={fieldSize}
      aria-invalid={errors && getFirstErrorFromJSONPaths(errors, errorFieldNames) ? "true" : "false"}
      infoMessage={errors && getFirstErrorFromJSONPaths(errors, errorFieldNames)?.message?.toString()}
      status={errors && getFirstErrorFromJSONPaths(errors, errorFieldNames) ? "error" : "default"}
      autoFocus={autoFocus}
      autoComplete={autoComplete}
      icon={icon}
      toolTip={
        toolTipText ? (
          <Tooltip
            alignOffset={0}
            avoidCollisions
            side="right"
            sideOffset={8}
            text={toolTipText}
            variant="light"
            css={{ margin: "1rem" }}
          />
        ) : null
      }
    />
  );
};

export default TextInput;
