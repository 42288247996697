/* eslint-disable react/no-unused-prop-types */
import { FC } from "react";

const DEFAULT_WIDTH = 24;
const DEFAULT_HEIGHT = 24;
interface IIconProps {
  height?: number;
  width?: number;
  color: string;
  title?: string;
  role?: string;
  arialabelledby?: string;
}

const ArrowLeft: FC<IIconProps> = ({ color, height, width, ...props }) => (
  <svg
    width={width || DEFAULT_WIDTH}
    height={height || DEFAULT_HEIGHT}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7071 6.29289C12.0976 6.68342 12.0976 7.31658 11.7071 7.70711L8.41421 11L18 11C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13L8.41421 13L11.7071 16.2929C12.0976 16.6834 12.0976 17.3166 11.7071 17.7071C11.3166 18.0976 10.6834 18.0976 10.2929 17.7071L5.29289 12.7071C5.10536 12.5196 5 12.2652 5 12C5 11.7348 5.10536 11.4804 5.29289 11.2929L10.2929 6.29289C10.6834 5.90237 11.3166 5.90237 11.7071 6.29289Z"
      fill={color}
    />
  </svg>
);

const ArrowRight: FC<IIconProps> = ({ color, height, width }) => (
  <svg
    width={width || DEFAULT_WIDTH}
    height={height || DEFAULT_HEIGHT}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.2929 6.29289C12.6834 5.90237 13.3166 5.90237 13.7071 6.29289L18.7071 11.2929C18.8946 11.4804 19 11.7348 19 12C19 12.2652 18.8946 12.5196 18.7071 12.7071L13.7071 17.7071C13.3166 18.0976 12.6834 18.0976 12.2929 17.7071C11.9024 17.3166 11.9024 16.6834 12.2929 16.2929L15.5858 13L6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11L15.5858 11L12.2929 7.70711C11.9024 7.31658 11.9024 6.68342 12.2929 6.29289Z"
      fill={color}
    />
  </svg>
);

const Calendar: FC<IIconProps> = ({ color }) => (
  <svg width="38" height="42" viewBox="0 0 38 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.667 0.141602C12.6795 0.141602 13.5003 1.05817 13.5003 2.18882V4.23605H24.5003V2.18882C24.5003 1.05817 25.3211 0.141602 26.3337 0.141602C27.3462 0.141602 28.167 1.05817 28.167 2.18882V4.23605H31.8337C34.8712 4.23605 37.3337 6.98576 37.3337 10.3777V34.9444C37.3337 38.3363 34.8712 41.086 31.8337 41.086H6.16699C3.12943 41.086 0.666992 38.3363 0.666992 34.9444V10.3777C0.666992 6.98576 3.12943 4.23605 6.16699 4.23605H9.83366V2.18882C9.83366 1.05817 10.6545 0.141602 11.667 0.141602ZM9.83366 8.33049H6.16699C5.15447 8.33049 4.33366 9.24706 4.33366 10.3777V34.9444C4.33366 36.075 5.15447 36.9916 6.16699 36.9916H31.8337C32.8462 36.9916 33.667 36.075 33.667 34.9444V10.3777C33.667 9.24706 32.8462 8.33049 31.8337 8.33049H28.167V10.3777C28.167 11.5084 27.3462 12.4249 26.3337 12.4249C25.3211 12.4249 24.5003 11.5084 24.5003 10.3777V8.33049H13.5003V10.3777C13.5003 11.5084 12.6795 12.4249 11.667 12.4249C10.6545 12.4249 9.83366 11.5084 9.83366 10.3777V8.33049ZM8.00033 18.5666C8.00033 17.436 8.82114 16.5194 9.83366 16.5194H28.167C29.1795 16.5194 30.0003 17.436 30.0003 18.5666C30.0003 19.6973 29.1795 20.6138 28.167 20.6138H9.83366C8.82114 20.6138 8.00033 19.6973 8.00033 18.5666Z"
      fill={color}
    />
  </svg>
);

const Smile: FC<IIconProps> = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4C28.8366 4 36 11.1634 36 20C36 28.8366 28.8366 36 20 36C11.1634 36 4 28.8366 4 20C4 11.1634 11.1634 4 20 4ZM40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40C31.0457 40 40 31.0457 40 20ZM28 16C28 14.8954 27.1046 14 26 14H25.98C24.8754 14 23.98 14.8954 23.98 16C23.98 17.1046 24.8754 18 25.98 18H26C27.1046 18 28 17.1046 28 16ZM16 16C16 14.8954 15.1046 14 14 14H13.98C12.8754 14 11.98 14.8954 11.98 16C11.98 17.1046 12.8754 18 13.98 18H14C15.1046 18 16 17.1046 16 16ZM27.0711 24.2426C26.29 23.4616 25.0237 23.4616 24.2426 24.2426C21.8995 26.5858 18.1005 26.5858 15.7574 24.2426C14.9763 23.4616 13.71 23.4616 12.9289 24.2426C12.1479 25.0237 12.1479 26.29 12.9289 27.0711C16.8342 30.9763 23.1658 30.9763 27.0711 27.0711C27.8521 26.29 27.8521 25.0237 27.0711 24.2426Z"
      fill={color}
    />
  </svg>
);
const Pin: FC<IIconProps> = ({ color, ...props }) => (
  <svg width="36" height="41" viewBox="0 0 36 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M27.8995 8.1005C22.4322 2.63316 13.5678 2.63316 8.1005 8.1005C2.63316 13.5678 2.63316 22.4322 8.1005 27.8995L16.5875 36.3865C17.368 37.167 18.6318 37.1672 19.4134 36.3856L27.8995 27.8995C33.3668 22.4322 33.3668 13.5678 27.8995 8.1005ZM5.27208 5.27208C12.3015 -1.75736 23.6985 -1.75736 30.7279 5.27208C37.7574 12.3015 37.7574 23.6985 30.7279 30.7279L23.7234 37.7325C23.6606 37.7953 23.7508 37.705 23.688 37.7678L22.2418 39.214C19.8992 41.5566 16.1027 41.5586 13.759 39.2149L5.27208 30.7279C-1.75736 23.6985 -1.75736 12.3015 5.27208 5.27208ZM18 14C15.7909 14 14 15.7909 14 18C14 20.2091 15.7909 22 18 22C20.2091 22 22 20.2091 22 18C22 15.7909 20.2091 14 18 14ZM10 18C10 13.5817 13.5817 10 18 10C22.4183 10 26 13.5817 26 18C26 22.4183 22.4183 26 18 26C13.5817 26 10 22.4183 10 18Z"
      fill={color}
    />
  </svg>
);

const Pound: FC<IIconProps> = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20 4C11.1634 4 4 11.1634 4 20C4 28.8366 11.1634 36 20 36C28.8366 36 36 28.8366 36 20C36 11.1634 28.8366 4 20 4ZM0 20C0 8.9543 8.9543 0 20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20ZM16 14C16 10.6863 18.6863 8 22 8C25.3137 8 28 10.6863 28 14C28 15.1046 27.1046 16 26 16C24.8954 16 24 15.1046 24 14C24 12.8954 23.1046 12 22 12C20.8954 12 20 12.8954 20 14V18H22C23.1046 18 24 18.8954 24 20C24 21.1046 23.1046 22 22 22H20V24C20 24.7013 19.8797 25.3744 19.6586 26H26C27.1046 26 28 26.8954 28 28C28 29.1046 27.1046 30 26 30H14C12.8954 30 12 29.1046 12 28C12 26.8954 12.8954 26 14 26C15.1046 26 16 25.1046 16 24V22H14C12.8954 22 12 21.1046 12 20C12 18.8954 12.8954 18 14 18H16V14Z"
      fill={color}
    />
  </svg>
);

const Phone: FC<IIconProps> = ({ color }) => (
  <svg width="28" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M0 6a6 6 0 0 1 6-6h16a6 6 0 0 1 6 6v28a6 6 0 0 1-6 6H6a6 6 0 0 1-6-6V6Zm6-2a2 2 0 0 0-2 2v28a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H6Zm6 28a2 2 0 0 1 2-2h.02a2 2 0 1 1 0 4H14a2 2 0 0 1-2-2Z"
      fill={color}
    />
  </svg>
);

const Email: FC<IIconProps> = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.1096 5.14254C20.4378 4.69467 19.5626 4.69467 18.8908 5.14254L4.89081 14.4759C4.63875 14.6439 4.43229 14.864 4.28185 15.1166L18.8908 24.8559C19.5626 25.3038 20.4378 25.3038 21.1096 24.8559L35.7186 15.1166C35.5681 14.864 35.3617 14.6439 35.1096 14.4759L21.1096 5.14254ZM39.9905 15.7985C39.8838 13.9228 38.9025 12.1971 37.3284 11.1477L23.3284 1.81434C21.313 0.470741 18.6874 0.470741 16.672 1.81434L2.67201 11.1477C1.09793 12.1971 0.116633 13.9228 0.00991575 15.7985C0.00137882 15.8838 -0.00161739 15.9694 0.000817462 16.0546C0.000414535 16.0831 0.000212475 16.1115 0.000212475 16.14V33.9992C0.000212475 37.313 2.6865 39.9992 6.00021 39.9992H34.0002C37.3139 39.9992 40.0002 37.313 40.0002 33.9992V16.14C40.0002 16.1115 40 16.0831 39.9996 16.0546C40.002 15.9694 39.999 15.8838 39.9905 15.7985ZM36.0002 19.7363L28.1058 24.9992L36.0002 30.2622V19.7363ZM35.776 34.9202L24.5002 27.4029L23.3284 28.1841C21.313 29.5277 18.6874 29.5277 16.672 28.1841L15.5002 27.4029L4.22438 34.9202C4.55755 35.5613 5.22774 35.9992 6.00021 35.9992H34.0002C34.7727 35.9992 35.4429 35.5613 35.776 34.9202ZM4.00021 30.2622L11.8947 24.9992L4.00021 19.7363V30.2622Z"
      fill={color}
    />
  </svg>
);

const Shield: FC<IIconProps> = ({ color, ...props }) => (
  <svg width="40" height="41" viewBox="0 0 40 41" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.666 1.39839C19.4254 0.718757 20.5744 0.718766 21.3337 1.39841C25.2273 4.88329 30.3641 6.99994 36 6.99994C36.3807 6.99994 36.7589 6.99029 37.1345 6.97124C38.0827 6.92315 38.9341 7.54836 39.172 8.46753C39.7128 10.557 40 12.7464 40 15C40 27.1177 31.7121 37.2946 20.4985 40.1808C20.1715 40.265 19.8285 40.265 19.5015 40.1808C8.28794 37.2946 0 27.1177 0 15C0 12.7463 0.287211 10.5568 0.828069 8.46727C1.06599 7.54811 1.91734 6.92291 2.86557 6.971C3.24113 6.99005 3.61935 6.9997 4 6.9997C9.63582 6.9997 14.7725 4.88314 18.666 1.39839ZM4.36291 10.9972C4.12468 12.294 4 13.6317 4 15C4 25.0681 10.7649 33.561 20 36.1724C29.2351 33.561 36 25.0681 36 15C36 13.6318 35.8753 12.2942 35.6371 10.9975C29.7446 10.9168 24.3225 8.87397 19.9998 5.49505C15.6772 8.87381 10.2553 10.9165 4.36291 10.9972ZM27.4142 15.5858C28.1953 16.3669 28.1953 17.6332 27.4142 18.4142L19.4142 26.4142C18.6332 27.1953 17.3668 27.1953 16.5858 26.4142L12.5858 22.4142C11.8047 21.6332 11.8047 20.3669 12.5858 19.5858C13.3668 18.8048 14.6332 18.8048 15.4142 19.5858L18 22.1716L24.5858 15.5858C25.3668 14.8048 26.6332 14.8048 27.4142 15.5858Z"
      fill={color}
    />
  </svg>
);

const Save: FC<IIconProps> = ({ color }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 3C12.5523 3 13 3.44772 13 4L13 11.5858L14.2929 10.2929C14.6834 9.90237 15.3166 9.90237 15.7071 10.2929C16.0976 10.6834 16.0976 11.3166 15.7071 11.7071L12.7071 14.7071C12.3166 15.0976 11.6834 15.0976 11.2929 14.7071L8.29289 11.7071C7.90237 11.3166 7.90237 10.6834 8.29289 10.2929C8.68342 9.90237 9.31658 9.90237 9.70711 10.2929L11 11.5858L11 4C11 3.44772 11.4477 3 12 3ZM2 9C2 7.34315 3.34315 6 5 6H8C8.55228 6 9 6.44772 9 7C9 7.55228 8.55228 8 8 8H5C4.44772 8 4 8.44772 4 9V18C4 18.5523 4.44772 19 5 19H19C19.5523 19 20 18.5523 20 18V9C20 8.44772 19.5523 8 19 8H16C15.4477 8 15 7.55228 15 7C15 6.44772 15.4477 6 16 6H19C20.6569 6 22 7.34315 22 9V18C22 19.6569 20.6569 21 19 21H5C3.34315 21 2 19.6569 2 18V9Z"
      fill={color}
    />
  </svg>
);

const Info: FC<IIconProps> = ({ color, ...props }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2ZM0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20C4.47715 20 0 15.5228 0 10ZM9 6C9 5.44772 9.44771 5 10 5H10.01C10.5623 5 11.01 5.44772 11.01 6C11.01 6.55228 10.5623 7 10.01 7H10C9.44771 7 9 6.55228 9 6ZM8 10C8 9.44771 8.44772 9 9 9H10C10.5523 9 11 9.44771 11 10V13C11.5523 13 12 13.4477 12 14C12 14.5523 11.5523 15 11 15H10C9.44771 15 9 14.5523 9 14V11C8.44772 11 8 10.5523 8 10Z"
      fill={color}
    />
  </svg>
);

const UserIcon: FC<IIconProps> = ({ color }) => (
  <svg width="40" height="40" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z"
      fill={color}
    />
  </svg>
);

const BoxArrowInLeft: FC<IIconProps> = ({ color }) => (
  <svg width="22" height="22" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 3.5a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v9a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-2a.5.5 0 0 1 1 0v2A1.5 1.5 0 0 1 9.5 14h-8A1.5 1.5 0 0 1 0 12.5v-9A1.5 1.5 0 0 1 1.5 2h8A1.5 1.5 0 0 1 11 3.5v2a.5.5 0 0 1-1 0v-2z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5H14.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z"
      fill={color}
    />
  </svg>
);

const InfoCircle: FC<IIconProps> = ({ color }) => (
  <svg width="22" height="22" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
      fill={color}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
      fill={color}
    />
  </svg>
);

const SuccessIcon: FC<IIconProps> = ({ color }) => (
  <svg className="success-icon" width="16" height="16" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z"
      fill={color}
    />
  </svg>
);

const CheckCircleIcon: FC<IIconProps> = ({ color }) => (
  <svg
    width="16"
    height="16"
    className="check-circle-icon"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.99967 1.66665C4.05416 1.66665 1.66634 4.05446 1.66634 6.99998C1.66634 9.9455 4.05416 12.3333 6.99967 12.3333C9.94519 12.3333 12.333 9.9455 12.333 6.99998C12.333 4.05446 9.94519 1.66665 6.99967 1.66665ZM0.333008 6.99998C0.333008 3.31808 3.31778 0.333313 6.99967 0.333313C10.6816 0.333313 13.6663 3.31808 13.6663 6.99998C13.6663 10.6819 10.6816 13.6666 6.99967 13.6666C3.31778 13.6666 0.333008 10.6819 0.333008 6.99998Z"
      fill={color}
    />
  </svg>
);

const AgeIcon: FC<IIconProps> = ({ ...props }) => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 8C32.8366 8 40 15.1634 40 24C40 32.8366 32.8366 40 24 40C15.1634 40 8 32.8366 8 24C8 15.1634 15.1634 8 24 8ZM44 24C44 12.9543 35.0457 4 24 4C12.9543 4 4 12.9543 4 24C4 35.0457 12.9543 44 24 44C35.0457 44 44 35.0457 44 24Z"
      fill="#E10A93"
    />
    <path
      d="M16.8704 31.1469C17.3351 31.1469 17.7118 30.7702 17.7118 30.3055V18.1551C17.7118 17.787 17.4134 17.4887 17.0453 17.4887C16.8512 17.4887 16.6688 17.5756 16.532 17.7134C15.8306 18.4201 14.9463 18.8009 13.7314 18.9872C13.3303 19.0486 13.0308 19.4023 13.0687 19.8064C13.104 20.1821 13.4195 20.4651 13.7942 20.4205C14.5437 20.3311 15.5605 20.1248 16.029 19.8468V30.3055C16.029 30.7702 16.4057 31.1469 16.8704 31.1469Z"
      fill="#E10A93"
    />
    <path
      d="M26.6134 23.9216C28.184 23.0915 29.1937 22.0162 29.1937 20.375C29.1937 18.4319 27.7353 17.2434 25.2859 17.2434C22.8926 17.2434 21.0977 18.4319 21.0977 20.8278C21.0977 22.469 22.1634 23.5254 23.4909 24.148C21.8456 24.9026 20.6489 25.9779 20.6489 27.8644C20.6489 29.8075 21.939 31.3733 24.9493 31.3733C27.7166 31.3733 29.4368 29.7509 29.4368 27.6191C29.4368 25.4874 28.2214 24.6385 26.6134 23.9216ZM27.4644 20.6727C27.2604 22.9283 23.0503 23.0489 22.8459 20.7933C22.8397 20.7248 22.8365 20.6546 22.8365 20.5825C22.8365 19.3563 23.734 18.6583 25.1924 18.6583C26.7817 18.6583 27.4735 19.2997 27.4735 20.4693C27.4735 20.538 27.4704 20.6057 27.4644 20.6727ZM22.5807 26.741C23.3197 24.991 26.1968 24.8567 27.2863 26.4129C27.5194 26.7457 27.6605 27.1413 27.6605 27.6191C27.6605 28.8076 26.95 29.9207 25.0054 29.9207C23.0983 29.9207 22.3878 29.0152 22.3878 27.7512C22.3878 27.3684 22.4563 27.0354 22.5807 26.741Z"
      fill="#E10A93"
    />
    <path
      d="M33.125 22.75C33.0396 22.75 32.9582 22.7234 32.8806 22.6701C32.803 22.6169 32.7642 22.5484 32.7642 22.4648V20.0461H30.5061C30.4285 20.0461 30.3664 20.0157 30.3198 19.9549C30.2733 19.8864 30.25 19.8142 30.25 19.7381C30.25 19.662 30.2733 19.5936 30.3198 19.5327C30.3664 19.4643 30.4285 19.4301 30.5061 19.4301H32.7642V17.2852C32.7642 17.2016 32.803 17.1331 32.8806 17.0799C32.9582 17.0266 33.0435 17 33.1366 17C33.222 17 33.3035 17.0266 33.3811 17.0799C33.4587 17.1331 33.4975 17.2016 33.4975 17.2852V19.4301H35.7439C35.8215 19.4301 35.8836 19.4643 35.9302 19.5327C35.9767 19.5936 36 19.662 36 19.7381C36 19.8142 35.9767 19.8864 35.9302 19.9549C35.8836 20.0157 35.8215 20.0461 35.7439 20.0461H33.4975V22.4648C33.4975 22.5484 33.4587 22.6169 33.3811 22.6701C33.3035 22.7234 33.2181 22.75 33.125 22.75Z"
      fill="#E10A93"
    />
    <path d="M33.125 22.6085V17.146" stroke="#E10A93" strokeLinecap="round" />
    <path d="M35.8564 19.875H30.3939" stroke="#E10A93" strokeLinecap="round" />
  </svg>
);

const EditIcon: FC<IIconProps> = () => (
  <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.7071 2.29289C16.3166 1.90237 15.6834 1.90237 15.2929 2.29289L7 10.5858L7 12H8.41421L16.7071 3.70711C17.0976 3.31658 17.0976 2.68342 16.7071 2.29289ZM13.8787 0.87868C15.0503 -0.292893 16.9497 -0.292893 18.1213 0.87868C19.2929 2.05025 19.2929 3.94975 18.1213 5.12132L9.53553 13.7071C9.34799 13.8946 9.09364 14 8.82842 14H6C5.44772 14 5 13.5523 5 13L5 10.1716C5 9.90636 5.10536 9.652 5.29289 9.46447L13.8787 0.87868ZM3 4C2.44772 4 2 4.44772 2 5V16C2 16.5523 2.44772 17 3 17H14C14.5523 17 15 16.5523 15 16V11C15 10.4477 15.4477 10 16 10C16.5523 10 17 10.4477 17 11V16C17 17.6569 15.6569 19 14 19H3C1.34315 19 0 17.6569 0 16V5C0 3.34315 1.34315 2 3 2H8C8.55228 2 9 2.44772 9 3C9 3.55229 8.55228 4 8 4H3Z"
      fill="#E10A93"
    />
  </svg>
);

export {
  AgeIcon,
  ArrowLeft,
  ArrowRight,
  BoxArrowInLeft,
  Calendar,
  CheckCircleIcon,
  EditIcon,
  Email,
  Info,
  InfoCircle,
  Phone,
  Pin,
  Pound,
  Save,
  Shield,
  Smile,
  SuccessIcon,
  UserIcon
};
