import { FC, ReactElement } from "react";
import { Text, Container } from "@shawbrook/sds-component-library";
import SystemInfoCard from "components/SystemInfoCard/SystemInfoCard";
import { links } from "config/navLinks";
import strings from "assets/strings/strings.json";
import { INotFound } from "pages/NotFound/interfaces";
import inlineStyles from "pages/NotFound/NotFound.style";
import styles from "./NotFound.module.scss";

export const NotFound: FC<INotFound> = ({ withLayout = false }) => {
  const {
    title,
    description: { entrance, p1, p2 },
    action
  } = strings.notFound;

  const getDescription = (marginBottom: string | number): ReactElement => {
    const containerCx = {
      ...inlineStyles.container,
      marginBottom
    };

    return (
      <Container space="0" css={containerCx}>
        <Text fontWeight="semiBold" font="silka" color="grey" css={inlineStyles.entrance}>
          {entrance}
        </Text>
        <ul className={styles.notFound__ul}>
          <li className={styles.notFound__li}>
            <Text font="silka" color="grey" as="span">
              {p1}
            </Text>
          </li>
          <li className={styles.notFound__li}>
            <Text font="silka" color="grey" as="span">
              {p2}
            </Text>
          </li>
        </ul>
      </Container>
    );
  };

  return (
    <SystemInfoCard
      title={title}
      withLayout={withLayout}
      description={getDescription}
      actionTitle={action}
      actionHref={links.landing.link}
    />
  );
};

export default NotFound;
