import { useQuery } from "@tanstack/react-query";
import { UseMaturityInstructionProps } from "hooks/useMaturityInstruction/interfaces";
import { QueryKeys } from "services/data/types";
import { getMaturityInstruction } from "services/data/api";

const useMaturityInstruction = ({ sourceAccountIdArg, enabled = true }: UseMaturityInstructionProps) => {
  const sourceAccountId = sourceAccountIdArg ?? "";
  // need to disable cache because in case of review submission screen we need
  // to make sure the maturityData that we store in the state is up to date
  const {
    data: maturityInstructionData,
    isLoading: isMaturityInstructionLoading,
    isError: isMaturityInstructionError
  } = useQuery({
    queryKey: [QueryKeys.MATURITY_INSTRUCTION, sourceAccountId],
    queryFn: () => getMaturityInstruction(sourceAccountId),
    enabled,
    cacheTime: 0
  });

  return {
    maturityInstructionData,
    isMaturityInstructionError,
    isMaturityInstructionLoading
  };
};

export default useMaturityInstruction;
