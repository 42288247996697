import { Box, Button, Flex, Heading, Space, Loader } from "@shawbrook/sds-component-library";
import { useFormContext } from "react-hook-form";

import { ChatAltIcon, PhoneIcon } from "assets/images";
import strings from "assets/strings/strings.json";
import { MOBILE_VERIFICATION_SCREEN_MODE } from "services/data/types/bankfastUserMigration/";
import { IBankfastUserMigrationState, MobileOtpMode, MobileOtpScreenModeType } from "state/bankfastUserMigrationState";
import Styles from "./MobileNumberOtpVerification.module.scss";

interface IMobileNumberVerificationChoiceProps {
  onSubmit?: (data: IBankfastUserMigrationState) => void;
  loading?: boolean;
}

export const MobileNumberVerificationChoice = ({ onSubmit, loading }: IMobileNumberVerificationChoiceProps) => {
  const { confirmationWay, confirmationOptions } = strings.bankfastUserMigrationMobile.mobile;
  const { trigger, clearErrors, setValue, getValues } = useFormContext();
  const otpType = getValues(MOBILE_VERIFICATION_SCREEN_MODE);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>, screenMode: MobileOtpScreenModeType) => {
    await clearErrors();

    const formIsValid = await trigger();

    setValue(MOBILE_VERIFICATION_SCREEN_MODE, screenMode);
    if (formIsValid) onSubmit?.(getValues() as IBankfastUserMigrationState);
  };
  return (
    <Box>
      <Space gap="2">
        <Heading level="4" as="h2" fontWeight="medium">
          {confirmationWay}
        </Heading>
      </Space>
      <Flex gap="4" direction="column" className={Styles.MobileNumberOtpVerification__Wrapper}>
        <Button
          iconLeft
          variant="secondary"
          size="medium"
          disabled={loading}
          iconRight={loading && otpType === MobileOtpMode.SMS}
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e, MobileOtpMode.SMS)}
          fullWidth>
          <ChatAltIcon />
          {confirmationOptions.text}
          {loading && otpType === MobileOtpMode.SMS && <Loader css={{ width: "1.25rem", height: "1.25rem" }} />}
        </Button>
        <Button
          iconLeft
          variant="secondary"
          disabled={loading}
          iconRight={loading && otpType === MobileOtpMode.VOICE}
          size="medium"
          onClick={(e: React.MouseEvent<HTMLButtonElement>) => handleClick(e, MobileOtpMode.VOICE)}
          fullWidth>
          <PhoneIcon />
          {confirmationOptions.call}
          {loading && otpType === MobileOtpMode.VOICE && <Loader css={{ width: "1.25rem", height: "1.25rem" }} />}
        </Button>
      </Flex>
    </Box>
  );
};
