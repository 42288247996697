import React, { Dispatch } from "react";
import { useOutletContext } from "react-router-dom";
import { IAccountDetails, IAccountSummary } from "services/data/types";

export interface IMoveMoneyContext {
  state: IState;
  setState: Dispatch<Partial<IState>>;
}

export interface IToAccount {
  id: string;
  accountNumber: string;
  sortCode: string;
  internal: boolean;
  customerName: string;
  balance: number;
  availableBalance: number;
  minBalance?: number;
  bankName?: string;
  accountTitle: string;
  issueNumber?: string;
}

export type FromAccountProp = IAccountSummary | IAccountDetails;

export const initialState: IState = {
  fromAccount: null,
  toAccount: null,
  amount: {
    error: null
  },
  modal: null,
  displayLeaveModal: true,
  displaySuccessModal: false,
  displayErrorModal: false,
  isHighTraffic: false,
  isLinkedAccountWarning: false
};

export interface IState {
  fromAccount: FromAccountProp | null;
  toAccount: IToAccount | null;
  amount: {
    value?: number;
    error: string | null;
  };
  paymentId?: string;
  modal: React.ReactNode | null;
  displayLeaveModal: boolean;
  displaySuccessModal: boolean;
  displayErrorModal: boolean;
  reference?: string;
  isHighTraffic: boolean;
  isLinkedAccountWarning: boolean;
}

export const useMoveMoneyContext = () => useOutletContext<IMoveMoneyContext>();
