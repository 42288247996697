import { Button, Card, Heading, Text } from "@shawbrook/sds-component-library";
import classnames from "classnames";
import { FC, ReactElement } from "react";

import ConditionalWrapper from "components/ConditionalWrapper";
import { InfoLayout } from "layouts/InfoLayout/InfoLayout";
import inlineStyles from "./SystemInfoCard.styles";

interface ISystemInfoCard {
  title: string;
  description: string | ((marginBottom: string | number) => ReactElement);
  actionTitle?: string;
  actionHref?: string;
  withLayout?: boolean;
  action?: () => void;
}

export const SystemInfoCard: FC<ISystemInfoCard> = ({
  title,
  description,
  actionTitle,
  actionHref,
  action,
  withLayout = false
}) => {
  const pMb = actionTitle ? "$10" : 0;
  const cxLayout = classnames("layoutWrapper--landing", "layoutWrapper--landing--info");
  const renderWithLayout = (children: ReactElement) => <InfoLayout className={cxLayout}>{children}</InfoLayout>;

  return (
    <ConditionalWrapper wrapper={renderWithLayout} condition={withLayout}>
      <Card css={inlineStyles.card}>
        <Heading level={2} as="h1" css={{ mb: "0.75rem" }}>
          {title}
        </Heading>
        {typeof description === "string" ? (
          <Text fontWeight="semiBold" font="silka" color="grey" css={{ mb: pMb }}>
            {description}
          </Text>
        ) : (
          <>{description(pMb)}</>
        )}
        {actionTitle && (
          <Button
            size="medium"
            variant="primary"
            role={action ? "button" : "link"}
            as={action ? "button" : "a"}
            css={{ ml: "auto", display: "block" }}
            href={actionHref}
            onClick={action}>
            {actionTitle}
          </Button>
        )}
      </Card>
    </ConditionalWrapper>
  );
};

export default SystemInfoCard;
