import strings from "assets/strings/strings.json";
import { AccountType, ProductCategory } from "services/data/types";
import { getAvailableBalance } from "utils";
import { IGetReinvestmentAccountData } from "utils/getReinvestmentAccountData/interfaces";

const getReinvestmentAccountData = ({ accountDetailData, accountId, state }: IGetReinvestmentAccountData) => {
  let accountDisplayName: string = "";
  let accountIssueNumber = 0;
  let currentInterestRate = 0;
  let interestFrequency = "";
  let accountBalance: number | null = null;
  let accountMaturityDate: string | undefined = "";
  let linkedAccountBank: string | undefined;
  let accountType: AccountType | null = null;
  let accountProductCategory: ProductCategory | null = null;

  if (accountDetailData) {
    accountDisplayName = accountDetailData.product.title;
    accountIssueNumber = accountDetailData.product.issueNumber;
    accountBalance = getAvailableBalance(accountDetailData);
    currentInterestRate = accountDetailData.product.interestRate;
    interestFrequency = (
      accountDetailData.product.interestFrequency || accountDetailData.interest.interestFrequency
    )?.toLowerCase();
    accountMaturityDate = accountDetailData.maturity?.maturityDate;
    linkedAccountBank = accountDetailData?.linkedAccount?.bankName || "";
    accountType = accountDetailData.accountType;
    accountProductCategory = accountDetailData.product.category;
  } else if (state[accountId]?.maturingAccount) {
    const accountFromState = state[accountId as string]?.maturingAccount;
    accountDisplayName = accountFromState?.product?.title || "";
    accountIssueNumber = accountFromState?.product?.issueNumber || 0;
    accountBalance = getAvailableBalance(accountFromState);
    currentInterestRate = accountFromState?.product?.interestRate || 0;
    interestFrequency = accountFromState?.interest?.interestFrequency?.toLowerCase() || "";
    accountMaturityDate = accountFromState?.maturity?.maturityDate;
    linkedAccountBank = accountFromState?.linkedAccount?.bankName || "";
    accountType = accountFromState?.accountType || null;
    accountProductCategory = accountFromState?.product?.category || null;
  }

  const accountIssueNumberText = `${strings.general.issueNumber} ${accountIssueNumber}`;

  return {
    accountDisplayName,
    accountIssueNumber,
    accountIssueNumberText,
    currentInterestRate,
    interestFrequency,
    accountBalance,
    accountMaturityDate,
    linkedAccountBank,
    accountType,
    accountProductCategory
  };
};

export default getReinvestmentAccountData;
