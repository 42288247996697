/**
 * @todo - Refactor these breakpoints.
 * @link - https://shawbrook.atlassian.net/browse/SDD-10543
 */

export const inlineStyles = {
  title: {
    "@media (min-width: 926px) and (max-width: 1040px), (max-width: 730px)": {
      flexDirection: "column"
    }
  },
  download: {
    margin: "0.1875rem 0 auto auto",
    "@media (min-width: 926px) and (max-width: 1040px), (max-width: 730px)": {
      margin: "1rem 0 0"
    }
  }
};
