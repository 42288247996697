import { IState } from "state/moveMoneyState";

export const saveMoveMoneyStateToStorage = (state: Partial<IState>) => {
  const json = JSON.stringify(state);
  sessionStorage.setItem("moveMoneyState", json);
};

export const getMoveMoneyStateFromStorage = (): Partial<IState> | null => {
  let moveMoneyState: Partial<IState> | null = null;
  const json = sessionStorage.getItem("moveMoneyState");

  try {
    moveMoneyState = JSON.parse(json as string);

    if (!moveMoneyState) {
      moveMoneyState = null;
    }
  } catch (e) {
    if (e instanceof Error) {
      console.log(e.message);
    }
  }

  return moveMoneyState;
};

export const clearStorage = (itemName: string) => {
  sessionStorage.removeItem(itemName);
};
