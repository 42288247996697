import { Flex, List, Space, Text } from "@shawbrook/sds-component-library";
import rreplace from "react-string-replace";
import { IModalInstructions } from "types/types";
import styles from "../../PostSubmitModals/PostSubmitModals.module.scss";

const statementsDoc = <b>statements and documents</b>;

export const ModalInstructions = ({
  message,
  instructions,
  subMessage,
  subInstructions,
  isVerified
}: IModalInstructions) => (
  <>
    <Space gap={6}>
      <Flex direction="column">
        <Text textStyle="medium">{message}</Text>
      </Flex>
    </Space>
    <Space gap={6}>
      <Flex>
        <List type="ol" noMarker>
          {instructions.map(instruction => (
            <Space gap={1} key={instruction.key}>
              <List.Item className={isVerified ? styles.linkedAccount__noEa : ""}>
                <Text as="span" font="inter">
                  {rreplace(instruction.p, "{{statementsDocuments}}", () => (
                    <Text as="span" font="inter" key={instruction.key}>
                      {statementsDoc}
                    </Text>
                  ))}
                </Text>
              </List.Item>
            </Space>
          ))}
        </List>
      </Flex>
    </Space>
    {subMessage && (
      <Space gap={6}>
        <Flex direction="column">
          <Text textStyle="medium">{subMessage}</Text>
        </Flex>
      </Space>
    )}
    {subInstructions && (
      <Space gap={6}>
        <Flex>
          <List type="ol" noMarker>
            {subInstructions.map(instruction => (
              <Space gap={1} key={instruction.key}>
                <List.Item className={styles.linkedAccount__noEa}>
                  <Text as="span" font="inter">
                    {rreplace(instruction.p, "{{statementsDocuments}}", () => (
                      <Text as="span" font="inter" key={instruction.key}>
                        {statementsDoc}
                      </Text>
                    ))}
                  </Text>
                </List.Item>
              </Space>
            ))}
          </List>
        </Flex>
      </Space>
    )}
  </>
);
