import { links } from "config/navLinks";
import { useNavigate } from "react-router-dom";

import { useQuery } from "@tanstack/react-query";
import { BankfastUserMigrationFlow } from "pages/BankfastUserMigration/Steps";
import { handleErrorStatus } from "hooks/useVerificationOTPProcess/handleErrorStatus";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { patchCustomerGDPRAndA11y } from "services/data/api";
import {
  ACCESSIBILITY_DETAILS,
  ComponentState,
  GDPR_AND_A11Y_ERROR,
  MARKETING_PREFERENCES
} from "services/data/types/bankfastUserMigration";
import { useDidUpdate } from "utils/customHooks";
import { getGDPRAndA11yFinalOptions } from "utils/bankFastUserMigrationJourney";
import { ISendGDPRAndA11y } from "./interfaces";

export const QUERY_KEYS = {
  UPDATE_CONTACT_DETAILS_GDPR: "updateContactDetailsGDPR"
};

const commonQueryOptions = {
  cacheTime: 0,
  staleTime: 0,
  retry: false,
  refetchOnWindowFocus: false
};

const useSendGDPRAndA11y = ({ setPostResult, currentState, setCurrentState, flowId }: ISendGDPRAndA11y) => {
  const navigate = useNavigate();
  const { getValues, setValue, setError } = useFormContext();

  const {
    data: GDPRAndA11yData,
    isLoading: isGDPRAndA11yLoading,
    isError: isGDPRAndA11yError
  } = useQuery({
    queryKey: [QUERY_KEYS.UPDATE_CONTACT_DETAILS_GDPR],
    queryFn: async () => {
      const { marketing, accessibility } = getGDPRAndA11yFinalOptions(
        getValues(MARKETING_PREFERENCES),
        getValues(ACCESSIBILITY_DETAILS)
      );
      return patchCustomerGDPRAndA11y({
        contactDetails: {
          commPreferencesProvided: true,
          commPreferences: {
            marketing,
            accessibility
          }
        }
      });
    },
    onError: () => handleErrorStatus(null, setError),
    enabled: currentState === ComponentState.PRESUBMIT,
    ...commonQueryOptions
  });

  useEffect(() => {
    if (currentState === ComponentState.PRESUBMIT) {
      const isReady = !isGDPRAndA11yLoading && GDPRAndA11yData && !isGDPRAndA11yError;

      setValue(GDPR_AND_A11Y_ERROR, !isReady);
      setPostResult?.({ result: GDPRAndA11yData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GDPRAndA11yData, isGDPRAndA11yError]);

  useDidUpdate(() => {
    if (currentState === ComponentState.GO_TO_NEXT_STEP) {
      setCurrentState?.(ComponentState.INITIAL);

      // both flows end with the same success page
      if (flowId === BankfastUserMigrationFlow.DEFAULT || flowId === BankfastUserMigrationFlow.MOBILE) {
        localStorage.setItem("dataCleansSuccess", "true");
        navigate(links.bankfastUserMigrationJourneySuccess.link);
      }
    }
  }, [currentState]);
};

export default useSendGDPRAndA11y;
