/* eslint-disable no-param-reassign */
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights, SeverityLevel } from "@microsoft/applicationinsights-web";

let reactPlugin: ReactPlugin;
let appInsights: ApplicationInsights | null = null;

enum TelemetryLoggingLevels {
  OFF = 0,
  CRITICAL = 1,
  CRITICAL_AND_WARNING = 2
}

const createTelemetryService = () => {
  const initialize = () => {
    const connectionString = process.env.REACT_APP_APPINSIGHTS_CONN_STR;

    if (!connectionString) {
      if (process.env.NODE_ENV !== "development") {
        // eslint-disable-next-line no-console
        console.warn("App Insights connection string not provided");
      }
      return;
    }

    reactPlugin = new ReactPlugin();

    appInsights = new ApplicationInsights({
      config: {
        connectionString,
        extensions: [reactPlugin],
        loggingLevelConsole:
          process.env.NODE_ENV === "test" ? TelemetryLoggingLevels.CRITICAL_AND_WARNING : TelemetryLoggingLevels.OFF,
        disableFetchTracking: false,
        enableAutoRouteTracking: true,
        loggingLevelTelemetry: TelemetryLoggingLevels.CRITICAL_AND_WARNING,
        maxBatchInterval: 0
      }
    });

    appInsights.loadAppInsights();
  };
  return { reactPlugin, appInsights, initialize };
};

export const AzureAppInsights = createTelemetryService();
export const getAppInsights = () => appInsights;
export const getReactPlugin = () => reactPlugin;

const setAdditionalInfo = (data: any[]) => (data.length === 1 ? undefined : JSON.stringify(data.slice(1)));

const setMessage = (data: any[]) => (typeof data[0] === "string" ? data[0] : JSON.stringify(data[0]));

const setException = (data: any[]) => (data[0] instanceof Error ? data[0] : undefined);

const setExceptionMessage = (exception: Error | undefined, data: any[]) => (exception ? exception.message : data[0]);

const logSeverityMap = {
  log: SeverityLevel.Information,
  warn: SeverityLevel.Warning,
  error: SeverityLevel.Error,
  info: SeverityLevel.Information
} as const;

export function decorateConsoleWithAppInsights(console: Console) {
  const originalConsole = { ...console };

  Object.entries(logSeverityMap).forEach(el => {
    const [method, severityLevel] = el as [keyof typeof logSeverityMap, SeverityLevel];

    console[method] = (...data: any[]) => {
      originalConsole[method](...data);

      if (method === "error" || method === "warn") {
        let exception = setException(data);
        const id = (() => {
          const message = setExceptionMessage(exception, data);
          if (exception) {
            exception = new Error(message);
            return message;
          }

          if (typeof data[0] === "string") {
            data[0] = message;
            return message;
          }
          return JSON.stringify(data[0]);
        })();
        appInsights?.trackException({
          exception,
          id,
          severityLevel,
          properties: {
            additionalInformation: setAdditionalInfo(data)
          }
        });

        return;
      }

      const message = setMessage(data);
      appInsights?.trackEvent({
        name: `${method.toUpperCase()} - ${message}`,
        properties: {
          severityLevel,
          message,
          additionalInformation: setAdditionalInfo(data)
        }
      });
    };
  });
}

export function getAppInsightsHeaders(): { [key: string]: string } {
  // x-ms-session-id is passed explicitly to the backend so we can correlate
  // backend operations with the frontend ones in App Insights
  return {
    "x-ms-session-id": appInsights?.context.getSessionId() ?? ""
  };
}
