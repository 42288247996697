import { MsalProvider } from "@azure/msal-react";
import ReactDOM from "react-dom/client";
import pca from "services/data/pca";
import { initialize } from "utils/trackerLib";
import isNoStrictAuth from "utils/isNoStrictAuth";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (isNoStrictAuth()) {
  initialize();
}

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <MsalProvider instance={pca}>
    <App />
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
