import classnames from "classnames";
import { StrictMode } from "react";
import { Outlet, useLocation } from "react-router-dom";

import { INFO_COMPLETED_URLS } from "assets/strings/values";
import usePageTrack from "hooks/usePageTrack";
import { useServiceTick } from "hooks/useServiceTick/useServiceTick";
import { InfoLayout } from "layouts/InfoLayout/InfoLayout";

export const Info = () => {
  const { pathname } = useLocation();
  const infoCompleteLayout = INFO_COMPLETED_URLS.some(url => url.indexOf(pathname) !== -1);
  usePageTrack();
  useServiceTick();

  const cxLayout = classnames("layoutWrapper--landing", {
    "layoutWrapper--landing--info": !infoCompleteLayout,
    "layoutWrapper--landing--info-complete": infoCompleteLayout
  });

  const renderLayout = () => (
    <InfoLayout className={cxLayout} infoCompleteLayout={infoCompleteLayout}>
      <Outlet />
    </InfoLayout>
  );

  return <StrictMode>{renderLayout()}</StrictMode>;
};

export default Info;
