import { useQuery } from "@tanstack/react-query";
import FEATURE_FLAGS from "config/featureFlags";
import { getCustomerProfile } from "services/data/api";
import { QueryKeys } from "services/data/types";

const useProfile = () => {
  const {
    data: customerProfileData,
    isLoading: isCustomerProfileLoading,
    isError: isCustomerProfileError,
    error: customerProfileError
  } = useQuery({
    queryKey: [QueryKeys.CUSTOMER_PROFILES],
    queryFn: () => getCustomerProfile(),
    enabled: FEATURE_FLAGS.allowBusinessBanking
  });

  return {
    customerProfileData,
    isCustomerProfileLoading,
    isCustomerProfileError,
    customerProfileError
  };
};

export default useProfile;
