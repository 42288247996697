import { Box, Button, DownArrowIcon } from "@shawbrook/sds-component-library";
import InlineStyles from "./ShowMore.styles";
import { IShowMoreProps } from "./types";

const ShowMore = ({ onClick, status, children }: IShowMoreProps) => (
  <Box css={InlineStyles.wrapper}>
    <Button onClick={onClick} variant="plain" css={InlineStyles.showMore} disabled={status !== "idle"}>
      {status === "idle" ? (
        <>
          {children}
          <DownArrowIcon width="12" height="12" aria-hidden />
        </>
      ) : (
        "Loading..."
      )}
    </Button>
  </Box>
);

export default ShowMore;
