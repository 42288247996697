export default {
  top: { p: "0.75rem", backgroundColor: "var(--card-header)" },
  buttonNewMessage: {
    width: "100%",
    display: "none",
    "@md": {
      display: "inline-block"
    },
    "&:hover:not(:disabled):not(:focus)": {
      color: "var(--shawbrook-white)"
    }
  },
  buttonNewMessageMobile: {
    width: "100%",
    display: "inline-block",
    "@md": {
      display: "none"
    }
  },
  container: {
    display: "inline-flex",
    flexDirection: "column",
    maxWidth: "var(--max-white-box-width)",
    width: "100%",
    minWidth: 0,
    borderRadius: 0,
    m: 0,
    p: 0,
    "@md": {
      borderRadius: "0.25rem 0"
    }
  },
  section: {
    color: "var(--shawbrook-charcoal)",
    m: 0
  },
  heading: { p: "1rem 0 1rem 1.25rem", fontSize: "var(--fontSizes-3)", fontWeight: "var(--fontWeights-semiBold)" },

  messageThreadListDate: {
    fontFamily: "Inter",
    fontSize: "0.875rem",
    lineHeight: "150%",
    fontWeight: "500",
    letterSpacing: "0.0875rem"
  },
  badge: {
    minWidth: "0",
    margin: "auto 0 auto 0",
    position: "relative",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    alignContent: "center"
  }
};
