import { Box, Button, RightArrow, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import { useLinkedAccountMessage } from "hooks/useLinkedAccountMessage/useLinkedAccountMessage";
import { useParams } from "react-router-dom";
import { useMainContext } from "state/mainState";
import styles from "./LinkedAccountMissing.module.scss";

export const LinkedAccountMissing = () => {
  const context = useMainContext();
  const params = useParams();
  const accountId = params.id;

  const { content } = strings.linkedAccountCard.missing;
  const { subject } = strings.linkedAccountIssue.missing;
  const { sendMessage } = strings.general;

  const { postThread, isSubmitThreadError } = useLinkedAccountMessage({
    accountId,
    subject
  });

  const handleSendMessage = () => {
    postThread();
  };

  if (isSubmitThreadError) {
    return (
      <ErrorRequestView
        requestErrors={[isSubmitThreadError]}
        context={context}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }

  return (
    <Box className={styles.linkedAccountMissing}>
      <Text>{content}</Text>
      <Button variant="tertiary" iconRight onClick={handleSendMessage}>
        {sendMessage}
        <RightArrow width="24" height="24" />
      </Button>
    </Box>
  );
};
