import { InstructionType } from "services/data/types";

const getSwitchInstructionType = (
  amountToReinvestArg: string,
  amountToWithdrawArg: string,
  accountBalance: number | null
): InstructionType | undefined => {
  let instructionType;
  const amountToReinvest = Number.parseFloat(amountToReinvestArg);
  const amountToWithdraw = Number.parseFloat(amountToWithdrawArg);

  if (accountBalance != null) {
    if (!Number.isNaN(amountToReinvest) && amountToReinvest === accountBalance) {
      instructionType = InstructionType.REINVEST_NEW;
    }
    if (!Number.isNaN(amountToWithdraw) && amountToWithdraw === accountBalance) {
      instructionType = InstructionType.WITHDRAW;
    }
  }

  return instructionType;
};

export default getSwitchInstructionType;
