import { useMutation } from "@tanstack/react-query";
import { patchNINumber } from "services/data/api";

const useNINumber = (niNumber: string) => {
  const {
    mutate: mutateNINumber,
    isError: isNINumberError,
    error: niNumberError,
    isSuccess: isPatchNINumberSuccess,
    isLoading: isNINumberLoading,
    data: niNumberData
  } = useMutation({
    mutationFn: () =>
      patchNINumber({
        personalDetails: {
          nationalInsuranceNumber: {
            number: niNumber
          }
        }
      })
  });

  return {
    mutateNINumber,
    niNumberData,
    isNINumberLoading,
    isNINumberError,
    isPatchNINumberSuccess,
    niNumberError
  };
};

export default useNINumber;
