import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { VALIDATION } from "assets/strings/validation";
import { API_LIMITS, REG_EXPS } from "assets/strings/api";
import { IState } from "state/moveMoneyState";
import { formatBalance } from "utils";
import { GenericObject } from "types/interfaces";

const moveMoneyValidationSchema = (state: IState) => {
  const schema = yup.object().shape({
    moveMoney: yup.object().shape({
      currencyInput: yup
        .string()
        .required(VALIDATION.moveMoney.currencyInput.required)
        .test("positiveNumber", VALIDATION.moveMoney.currencyInput.required, value => {
          let numValue: string | number = value.replace(REG_EXPS.MOVE_MONEY_AMOUNT_PREFIX, "").replaceAll(",", "");
          numValue = Number(numValue ?? 0);
          return numValue > 0;
        })
        .test(
          "moreThanMinBalanceDestinationAccount",
          VALIDATION.moveMoney.currencyInput.moreThanMinBalanceDestinationAccount,
          function moreThanMinBalanceSourceAccount(value) {
            let valid: boolean | yup.ValidationError = true;
            let numValue: string | number = value.replace(REG_EXPS.MOVE_MONEY_AMOUNT_PREFIX, "").replaceAll(",", "");
            numValue = Number(numValue ?? 0);

            if (state.toAccount?.internal) {
              const minBalance =
                state.toAccount.minBalance != null ? state.toAccount.minBalance : API_LIMITS.MIN_BALANCE;
              if (numValue + state.toAccount.balance < minBalance) {
                const bindings: GenericObject = {
                  "{{minBalance}}": formatBalance(minBalance),
                  "{{remainingToMin}}": formatBalance(minBalance - state.toAccount.balance)
                };

                valid = this.createError({
                  message: VALIDATION.moveMoney.currencyInput.moreThanMinBalanceDestinationAccount.replaceAll(
                    /{{minBalance}}|{{remainingToMin}}/g,
                    match => bindings[match]
                  )
                });
              }
            }

            return valid;
          }
        )
    })
  });

  return yupResolver(schema);
};

export default moveMoneyValidationSchema;
