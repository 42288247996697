import { Alert, InfoIcon, Text, WarningIcon } from "@shawbrook/sds-component-library";
import { FC, ReactNode } from "react";
import { useNavigate } from "react-router-dom";

import strings from "assets/strings/strings.json";
import { ActionTrigger } from "components/ActionTrigger/ActionTrigger";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import { links } from "config/navLinks";
import { useLinkedAccountMessage } from "hooks/useLinkedAccountMessage/useLinkedAccountMessage";
import styles from "pages/MoveMoney/components/MoveMoneyInfo/MoveMoneyInfo.style";
import { useMainContext } from "state/mainState";
import { GenericObject } from "types/interfaces";
import { AlertActionMode, AlertVariants, ALERT_ACTION_MODE, ALERT_VARIANTS } from "types/types";

interface IMoveMoneyInfo {
  customStyles?: GenericObject;
  text?: string | ReactNode;
  variant?: AlertVariants;
  heading?: string;
  actionMode?: AlertActionMode;
  adjustActionTriggerStyle?: boolean;
  accountId?: string;
  bankName?: string;
  isLinkedAccountMissing?: boolean;
}

const MoveMoneyInfo: FC<IMoveMoneyInfo> = ({
  text,
  heading = "",
  customStyles = {},
  variant = ALERT_VARIANTS.info,
  actionMode,
  adjustActionTriggerStyle = false,
  accountId,
  bankName = "",
  isLinkedAccountMissing
}) => {
  const navigate = useNavigate();
  const context = useMainContext();

  const { subjectWithTransfer: unverifiedLinkedAccSubject } = strings.linkedAccountIssue.unverified;
  const { subjectWithTransfer: missingLinkedAccSubject } = strings.linkedAccountIssue.missing;
  const { sendMessage, compareAccounts: compareAccountsText } = strings.general;

  // I'm doing it this way as it seems that missingLinkedAccSubject is safer as a fallback value
  const subject = !isLinkedAccountMissing ? unverifiedLinkedAccSubject : missingLinkedAccSubject;

  const subjectWithBankName = subject.replace("{{BANK_NAME}}", bankName);

  const { postThread, isSubmitThreadError } = useLinkedAccountMessage({
    accountId,
    subject: subjectWithBankName
  });

  const sendMessageHandler = () => {
    navigate(links.inbox.link);
  };

  const compareAccounts = () => {
    navigate(links.compareAccounts.link);
  };

  const verifyLinkedAccount = () => {
    postThread();
  };

  const confirmLinkedAccount = () => {
    postThread();
  };

  const renderMoveMoneyInfo = () => {
    if (text || heading) {
      const variantStyles = variant as keyof typeof styles.cardVariants;
      let icon: ReactNode | null = null;
      let actionTrigger: ReactNode | undefined;

      switch (variant) {
        case ALERT_VARIANTS.info:
          icon = <InfoIcon />;
          break;
        case ALERT_VARIANTS.warning:
          icon = <WarningIcon />;
          break;
        default:
          break;
      }

      switch (actionMode) {
        case ALERT_ACTION_MODE.sendMessage:
          actionTrigger = <ActionTrigger actionText={sendMessage} action={sendMessageHandler} />;
          break;
        case ALERT_ACTION_MODE.compareAccounts:
          actionTrigger = <ActionTrigger actionText={compareAccountsText} action={compareAccounts} />;
          break;
        case ALERT_ACTION_MODE.verifyLinkedAccount:
          actionTrigger = <ActionTrigger actionText={sendMessage} action={verifyLinkedAccount} />;
          break;
        case ALERT_ACTION_MODE.confirmLinkedAccount:
          actionTrigger = <ActionTrigger actionText={sendMessage} action={confirmLinkedAccount} />;
          break;
        default:
          break;
      }

      const cardStyles = {
        ...styles.card,
        ...styles.cardVariants[variantStyles],
        ...(actionTrigger && styles.actionCard),
        ...(adjustActionTriggerStyle && styles.actionCard.adjusted),
        ...customStyles
      };

      return (
        <Alert icon={icon} variant={variant} css={cardStyles} action={actionTrigger} title={heading}>
          {text && <Text>{text}</Text>}
        </Alert>
      );
    }

    return null;
  };
  if (isSubmitThreadError) {
    return (
      <ErrorRequestView
        requestErrors={[isSubmitThreadError]}
        context={context}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }
  return renderMoveMoneyInfo();
};

export default MoveMoneyInfo;
