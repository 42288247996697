import { CATEGORIES_ORDER } from "config/categoryOrder";
import { IAvailableProduct } from "services/data/types";
import { transformTerm } from "./transformTerm";

export const sortAvailableProducts = (availableProducts: IAvailableProduct[]) => {
  const newAccounts = [...availableProducts].sort((a, b) => {
    const catA = CATEGORIES_ORDER[a.category] || 0;
    const catB = CATEGORIES_ORDER[b.category] || 0;
    if (catA !== catB) {
      return catA - catB;
    }
    const termA = transformTerm(a.term);
    const termB = transformTerm(b.term);
    return termA - termB;
  });
  return newAccounts;
};
