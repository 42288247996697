import { Flex } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import useSrollToTop from "hooks/useScrollToTop";
import { Description, OtpVerification, PageHeader } from "journeys/components/";
import { ApiAlerts } from "journeys/components/ApiAlerts";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { CUSTOMER_EMAIL, ComponentState, IScreenProps } from "services/data/types/bankfastUserMigration/";
import { getBlockServerErrorStatus } from "utils/getOTPRetryStatus";
import { useVerificationOTPProcess } from "../../../hooks/useVerificationOTPProcess/useVerificationOTPProcess";

export const EmailOTP = ({
  flowId,
  setPostResult,
  setCurrentState,
  currentState = ComponentState.INITIAL,
  customerData
}: IScreenProps) => {
  useSrollToTop();
  const { "email-otp": verificationStrings } = strings.bankfastUserMigration;
  const {
    getValues,
    formState: { errors }
  } = useFormContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (getValues(CUSTOMER_EMAIL) === "") {
      navigate(strings.bankfastUserMigration.changeEmail.continueButtonLink);
    }
  }, [getValues, navigate]);

  useVerificationOTPProcess({
    otpType: "email",
    setPostResult,
    flowId,
    currentState,
    setCurrentState,
    customerData
  });

  if (getBlockServerErrorStatus(errors)) {
    return (
      <>
        <PageHeader title={verificationStrings.title} />
        <ApiAlerts errors={errors} />
      </>
    );
  }

  return (
    <Flex gap="6" direction="column">
      <PageHeader title={verificationStrings.title}>
        <Description
          description={verificationStrings.description}
          identifier="[EMAIL]"
          value={getValues(CUSTOMER_EMAIL)}
        />
      </PageHeader>
      <OtpVerification strings={verificationStrings.passcode} otpType="email" otpId="verificationOTP" />
    </Flex>
  );
};

export default EmailOTP;
