import {
  Button,
  Card,
  Grid,
  Heading,
  RadioGroup,
  RadioItem,
  RightArrow,
  Space,
  Text
} from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import LoadingSection from "components/LoadingSection/LoadingSection";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import useNewAccountLinkedAccounts from "hooks/useNewAccountLinkedAccounts/useNewAccountLinkedAccounts";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ILinkedAccount } from "services/data/types";
import { useNewAccountContext } from "state/newAccountState";
import { formatSortCode } from "utils";
import styles from "./LinkedAccount.module.scss";

export const LinkedAccount = () => {
  const navigate = useNavigate();
  const {
    title,
    linkedAccount: { headingText, secondaryText, addAnotherLinkedAccountText }
  } = strings.newAccount;
  const { continueButton } = strings.general;
  const { state, setState, clearState } = useNewAccountContext();
  const [linkedAccount, setLinkedAccount] = useState<ILinkedAccount | undefined>(state.linkedAccount);
  const { linkedAccountsData, hasVerifiedLinkedAccounts, linkedAccountsDataFetched, linkedAccountsDataLoading } =
    useNewAccountLinkedAccounts();

  const defaultLinkedAcc = state.linkedAccount?.accountNumber;

  useEffect(() => {
    if (state.selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
    if (linkedAccountsDataFetched && !hasVerifiedLinkedAccounts) {
      navigate(links.newAccountLinkedAccountForm.link);
    }
  }, [state.selectedProductSet, linkedAccountsDataFetched, hasVerifiedLinkedAccounts, navigate]);

  const handleLinkedAccountChange = (e: string) => {
    const selectedLinkedAccount = linkedAccountsData?.find(
      linkedAccountItem => `${linkedAccountItem.sortCode}.${linkedAccountItem.accountNumber}` === e
    );
    if (selectedLinkedAccount) {
      setLinkedAccount({ id: e, ...selectedLinkedAccount } as ILinkedAccount);
    }
  };

  const handleContinue = () => {
    setState({
      linkedAccount
    });
    navigate(state.editMode ? links.newAccountSummary.link : links.newAccountInterest.link);
  };

  const handleAddAnotherLinkedAccount = () => {
    setState({ linkedAccount: undefined });
    navigate(links.newAccountLinkedAccountForm.link);
  };

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={links.annualSavings.link} isEdit={state.editMode} />
      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" width="auto">
            {linkedAccountsDataLoading && <LoadingSection />}
            {!linkedAccountsDataLoading && (
              <>
                <Heading level={2}>{headingText}</Heading>
                <Text>{secondaryText}</Text>
                <Space gap="10">
                  <RadioGroup
                    labelText={{ main: "" }}
                    defaultValue={defaultLinkedAcc}
                    onValueChange={handleLinkedAccountChange}
                    value={linkedAccount?.id}
                    orientation="vertical">
                    {linkedAccountsData?.map?.(linkedAccountData => (
                      <RadioItem
                        key={`${linkedAccountData.sortCode}.${linkedAccountData.accountNumber}`}
                        id={`${linkedAccountData.sortCode}.${linkedAccountData.accountNumber}`}
                        label={linkedAccountData.bankName}
                        subLabel={[
                          linkedAccountData.holderName,
                          formatSortCode(linkedAccountData.sortCode),
                          linkedAccountData.accountNumber
                        ]}
                        value={`${linkedAccountData.sortCode}.${linkedAccountData.accountNumber}`}
                      />
                    ))}
                  </RadioGroup>
                </Space>

                <div className={styles.page__buttonSection}>
                  <div>
                    <Button
                      className={styles.page__addAnotherLinkedAccountbtn}
                      size="large"
                      variant="secondary"
                      onClick={handleAddAnotherLinkedAccount}>
                      {addAnotherLinkedAccountText}
                    </Button>
                  </div>
                  <Button
                    className={styles.page__continuebtn}
                    iconRight
                    disabled={!linkedAccount && !state.linkedAccount}
                    size="large"
                    onClick={handleContinue}>
                    {continueButton}
                    <RightArrow width="24" height="24" />
                  </Button>
                </div>
              </>
            )}
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={state.selectedProductSet?.title!}
            issueNumber={state.selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
