import { IFRAMES } from "assets/strings/values";
import { getDocument, updateDocumentStatus } from "services/data/api";
import { CONTENT_TYPES } from "../constants";

export const GetFile = async (id: string) => {
  const file = await getDocument(id);
  const fileType = (file.contentType as string).toLowerCase();
  const mimeType = CONTENT_TYPES[fileType as keyof typeof CONTENT_TYPES];
  const fileTitle = file.title;
  const fileHref = `data:${mimeType};base64,${file.content}`;

  const iframeTemplate = document.createElement("div");
  const iframeHtml = document.createElement("html");
  const iframeHead = document.createElement("head");
  const iframeMeta = document.createElement("meta");
  iframeMeta.setAttribute("charset", "UTF-8");
  const iframeScript = document.createElement("script");
  iframeScript.setAttribute("src", "scripts/iframes/download/index.js");
  iframeHead.appendChild(iframeMeta);
  iframeHead.appendChild(iframeScript);
  iframeHtml.appendChild(iframeHead);
  iframeHtml.appendChild(document.createElement("body"));
  iframeTemplate.appendChild(iframeHtml);

  const iframesToRemove = document.body.querySelectorAll(`[data-id="${IFRAMES.DOCUMENT_DOWNLOAD}"]`);
  iframesToRemove.forEach(iframeToRemove => iframeToRemove.remove());

  const iframe = document.createElement("iframe");
  iframe.setAttribute("data-id", IFRAMES.DOCUMENT_DOWNLOAD);
  iframe.setAttribute("hidden", "hidden");
  iframe.setAttribute("srcdoc", iframeTemplate.innerHTML);
  document.body.appendChild(iframe);

  iframe.onload = () => {
    if (iframe.contentWindow) {
      iframe.contentWindow.postMessage({ fileTitle, fileHref, type: "document-download" }, window.location.origin);
    }
  };

  // Mark as read once downloaded
  updateDocumentStatus(id);
};

export default GetFile;
