/* eslint-disable react/no-unstable-nested-components */
import {
  Alert,
  Card as CardComponent,
  Flex,
  Heading,
  InfoIcon,
  Pill,
  Space,
  Text
} from "@shawbrook/sds-component-library";
import classnames from "classnames";
import { FC, useEffect } from "react";

import {
  GlobalContextSetCustomerId,
  GlobalContextSetHasAccounts,
  GlobalContextSetSelectedProfile
} from "Actions/GlobalContextAction";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import Card from "components/Card/Card";
import { CardSection } from "components/Card/interfaces";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import { LoadingSection } from "components/LoadingSection/LoadingSection";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import useCustomer from "hooks/useCustomer";
import useProfile from "hooks/useProfile";
import useSrollToTop from "hooks/useScrollToTop";
import { useNavigate } from "react-router-dom";
import { useGlobalContext, useGlobalDispatch } from "state/globalContext";
import { useMainContext } from "state/mainState";
import { ProfileType } from "types/types";
import { formatToDisplayDate, getAvailableBalance, getCapitalBalance, greetings } from "utils";
import styles from "./Landing.module.scss";
import { NewAccountPrompt } from "./NewAccountPrompt/NewAccountPrompt";

const INTEREST_FREQUENCY: Readonly<Record<string, string>> = Object.freeze({
  Annually: "Annual",
  Monthly: "Monthly"
});

export const Landing: FC = () => {
  const context = useMainContext();
  const state = useGlobalContext();
  const dispatch = useGlobalDispatch();
  const navigate = useNavigate();
  const hours = new Date().getHours();
  const {
    multipleAccountsTitle,
    singleAccountTitle,
    noAccountsInfo: { title: noAccountsTitle, content: noAccountsContent }
  } = strings.landing;
  useSrollToTop();
  const { customerData, isCustomerError, isCustomerLoading, customerError } = useCustomer();
  const { accountsData, isSingleAccount, accountResolved, accountErrored, accountsError } = useAccount();
  const { customerProfileData, isCustomerProfileLoading } = useProfile();

  useEffect(() => {
    if (customerData && !isCustomerLoading) {
      dispatch(GlobalContextSetCustomerId(customerData.id));
    }
  }, [customerData, isCustomerLoading, dispatch]);

  useEffect(() => {
    if (accountResolved && accountsData?.length === 0) {
      dispatch(GlobalContextSetHasAccounts(false));
    }
  }, [accountResolved, accountsData?.length, dispatch]);

  useEffect(() => {
    if (customerProfileData && !isCustomerProfileLoading && state.selectedProfile === undefined) {
      if (customerProfileData.length === 1 && state.selectedProfile === null) {
        dispatch(GlobalContextSetSelectedProfile(customerProfileData[0]));
      }
      navigate(links.profile.link);
    }
  }, [customerProfileData, dispatch, isCustomerProfileLoading, state.selectedProfile, navigate]);

  if (isCustomerError || accountErrored) {
    return (
      <ErrorRequestView
        requestErrors={[customerError, accountsError]}
        context={context}
        errorModal={<ErrorModal open allPages />}
      />
    );
  }

  const cx = classnames(styles.card__group, {
    [styles["card__group--single"]]: accountResolved ? isSingleAccount : true
  });

  return (
    <div className={styles.wrapper}>
      <header className={styles["landing-title"]}>
        <Flex gap={2}>
          <Text textStyle="medium" font="silka" color="white" css={{ marginBottom: "$3" }}>
            {greetings(hours)}
            {accountResolved && customerData ? `, ${customerData?.name?.givenNames![0]}` : ""}
          </Text>
          {state.selectedProfile?.profileType.toString().toLowerCase() === ProfileType[ProfileType.business] && (
            <Pill> {state.selectedProfile.profileName} </Pill>
          )}
        </Flex>

        <Heading as="h1" color="white" className={styles.landingTitleHeadings}>
          {accountsData && accountsData.length > 0 && !isSingleAccount ? multipleAccountsTitle : singleAccountTitle}
        </Heading>
      </header>
      <section className={styles.card__wrapper}>
        <div>
          <Space gap="10">
            {(!accountResolved || isCustomerLoading) && <LoadingSection />}
            {customerData && accountsData?.length === 0 && (
              <CardComponent width="full" variant="white">
                <Alert icon={<InfoIcon />} title={noAccountsTitle}>
                  <Text>{noAccountsContent}</Text>
                </Alert>
              </CardComponent>
            )}
            {customerData && accountsData && (
              <section className={cx}>
                {accountsData.map(el => (
                  <Card
                    key={el.id}
                    accountId={el.id}
                    title={el.product.title || el.product.displayName}
                    status={el.status}
                    issueNumber={`${strings.general.issueNumber} ${el.product.issueNumber}`}
                    balance={getCapitalBalance(el)}
                    availableBalance={getAvailableBalance(el)}
                    rate={el.product.interestRate}
                    frequency={INTEREST_FREQUENCY[el.product.interestFrequency || el.interest.interestFrequency]}
                    noticePeriodDays={el.product.noticePeriodDays}
                    endOfTerm={el.maturity?.maturityDate ? formatToDisplayDate(el.maturity.maturityDate) : ""}
                    endOfTermForNonDisplay={el.maturity?.maturityDate || ""}
                    sortCode={el.sortCode}
                    accountNumber={el.accountNumber}
                    openToFunding={el.openToFunding.enabled}
                    openToFundingReason={el.openToFunding.reason}
                    single={isSingleAccount}
                    category={el.product.category}
                    preMaturing={el.maturity?.preMaturing}
                    maturityInstructionsSet={el.maturity?.instructionsSet}
                    maxBalance={el.product.maxBalance}
                    viewAccount
                    transfer
                    greyBox
                    section={CardSection.LANDING}
                    interestRates={el.product.interestRates}
                    withdrawable={el.withdrawability.enabled}
                    withdrawableReason={el.withdrawability.reason}
                    outboundWithdrawable={el.outboundWithdrawability?.enabled}
                    linkedAccount={el.linkedAccount}
                    accountType={el.accountType}
                    productType={el.product.type}
                    unauthorisedAccountCredit={el.unauthorisedAccountCredit}
                  />
                ))}
              </section>
            )}
          </Space>
          <NewAccountPrompt hasAccounts={accountsData?.length !== 0} />
        </div>

        <aside className={styles["card__need-help"]}>
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};

export default Landing;
