import strings from "assets/strings/strings.json";

export const config = {
  colors: {
    white: "#fff",
    black: "#000000",
    shawbrookWhite: "#f9f9f9",
    shawbrookPink: "#e10a93",
    shawbrookGreen: "#0f801a",
    shawbrookBlue: "#46467a",
    shawbrookGray: "#a1a3a9"
  },
  helpEmail: "https://www.shawbrook.co.uk/direct/savings/help/contacts/",
  footerLinks: [
    {
      link: "https://www.shawbrook.co.uk/customer-security/",
      text: strings.footerLabel.customerSecurity
    },
    {
      link: "https://www.shawbrook.co.uk/privacy-notice/",
      text: strings.footerLabel.privacyNotice
    },
    {
      link: "https://www.shawbrook.co.uk/cookie-notice/",
      text: strings.footerLabel.cookieNotice
    },
    {
      link: "https://www.shawbrook.co.uk/terms-of-use/",
      text: strings.footerLabel.termsOfUse
    },
    {
      link: "https://www.shawbrook.co.uk/accessibility/",
      text: strings.footerLabel.accessibility
    }
  ],
  savingsCompareAccLink: "https://www.shawbrook.co.uk/direct/savings/",
  savingsHelpCentreLink: {
    link: "https://www.shawbrook.co.uk/savings/help/",
    text: strings.inbox.helpCentreLinkLabel
  },
  addressSearchInput: {
    countries: ["GB"],
    locale: "en-GB"
  }
};
export default config;
