import { FieldErrors, FieldValues } from "react-hook-form";

import { ErrorMessage } from "services/data/types/bankfastUserMigration";

export const resolveJSONPath = (
  obj: FieldErrors<FieldValues>,
  path: string,
  separator = "."
): ErrorMessage | undefined => path && path.split(separator).reduce((prev: any, curr: string) => prev?.[curr], obj);

export const getFirstPathThatHasError = (obj: FieldErrors<FieldValues>, paths: string[], separator = ".") =>
  paths.find(path => resolveJSONPath(obj, path, separator));

export const getFirstErrorFromJSONPaths = (obj: FieldErrors<FieldValues>, paths: string[], separator = ".") =>
  resolveJSONPath(obj, getFirstPathThatHasError(obj, paths, separator) ?? "", separator);

export default {
  resolveJSONPath,
  getFirstErrorFromJSONPaths
};
