import { Heading } from "@shawbrook/sds-component-library";
import { FC, ReactNode } from "react";
import styles from "./WhiteBox.module.scss";

interface IWhiteBox {
  title?: string;
  children?: ReactNode;
  className?: string;
}

export const WhiteBox: FC<IWhiteBox> = ({ title, className, children = null }) => (
  <div className={`${styles["white-box__container"]}${className ? ` ${className}` : ""}`}>
    {title && (
      <Heading className={styles["white-box__title"]} level="3">
        {title}
      </Heading>
    )}
    {children}
  </div>
);

export default WhiteBox;
