import { Box, Button, ChatIcon, Flex, Heading } from "@shawbrook/sds-component-library";

import strings from "assets/strings/strings.json";
import styles from "../MessageThreadList.module.scss";
import MessageThreadListInlineStyles from "../MessageThreadList.styles";
import InlineStyles from "./SkeletonLoader.styles";

const skeletonsNumber = ["1", "2", "3"];

export const SkeletonLoader = () => (
  <Box css={MessageThreadListInlineStyles.container} data-testid="skeleton-loader">
    <Box css={MessageThreadListInlineStyles.top}>
      <Button iconLeft size="small" isWhite disabled css={MessageThreadListInlineStyles.buttonNewMessage}>
        <ChatIcon />
        {strings.inbox.newThreadBtnLabel}
      </Button>
      <Button
        iconLeft
        size="small"
        isWhite
        disabled
        css={MessageThreadListInlineStyles.buttonNewMessageMobile}
        onClick={() => {}}>
        <ChatIcon />
        {strings.inbox.newThreadBtnLabel}
      </Button>
    </Box>
    <section className={styles["message-thread-list__section"]}>
      <Heading level="2" css={MessageThreadListInlineStyles.heading}>
        Open
      </Heading>
      {skeletonsNumber.map(key => (
        <Flex key={key} direction="row" align="center" gap="4" justify="start" css={InlineStyles.flex}>
          <Box css={InlineStyles.boxCircle} />
          <Flex direction="column" gap="3">
            <Box css={InlineStyles.boxFirstRectangle} />
            <Box css={InlineStyles.boxSecondRectangle} />
            <Box css={InlineStyles.boxThirdRectangle} />
          </Flex>
        </Flex>
      ))}
    </section>
  </Box>
);
