import { Space, Text } from "@shawbrook/sds-component-library";
import { FC } from "react";
import rreplace from "react-string-replace";
import { IAccountDetailItem } from "types/types";

export const AccountDetailItem: FC<IAccountDetailItem> = ({ text, variable, replacement }) => (
  <Space gap={0}>
    <Text>
      {rreplace(text, `{{${variable}}}`, (_, i) => (
        <Text as="span" color="secondary" fontWeight="bold" key={`${i}{variable}`}>
          {replacement}
        </Text>
      ))}
    </Text>
  </Space>
);
