import strings from "assets/strings/strings.json";
import { IAvailableProduct, ProductCategory } from "services/data/types";
import { formatProductsGroup } from "utils/formatAvailableProducts/formatProductsGroup";
import { FormattedAvailableProduct, FormattedAvailableProductGroups } from "utils/formatAvailableProducts/interfaces";
import { transformTerm } from "utils/formatAvailableProducts/transformTerm";

const cashISA = "cashISA";
const allAccounts = "allAccounts";

const TABS_VALUES: Readonly<Record<string, string>> = Object.freeze({
  allAccounts: "allAccounts",
  [ProductCategory.EASY_ACCESS]: "easyAccess",
  [cashISA]: "cashISA",
  [ProductCategory.NOTICE]: "noticeAccount",
  [ProductCategory.FIXED_RATE]: "fixedRateBonds"
});

const TABS_CATEGORIES: Readonly<Record<string, string[]>> = Object.freeze({
  [ProductCategory.EASY_ACCESS]: [ProductCategory.EASY_ACCESS, ProductCategory.EASY_ACCESS_ISA],
  [cashISA]: [ProductCategory.EASY_ACCESS_ISA, ProductCategory.FIXED_RATE_ISA],
  [ProductCategory.NOTICE]: [ProductCategory.NOTICE],
  [ProductCategory.FIXED_RATE]: [ProductCategory.FIXED_RATE, ProductCategory.FIXED_RATE_ISA]
});

const tabsTitles = strings.newAccount.compareAccounts.accountsTabs;

export interface NewAccountTab {
  title: string;
  value: string;
  content: FormattedAvailableProductGroups;
}

type NewAccountProducts = {
  [key: string]: FormattedAvailableProduct[];
};

export const formatNewAccountProducts = (products: IAvailableProduct[]) => {
  const categories: Set<ProductCategory> = new Set();
  const formattedProducts = products
    .filter(el => el.category !== ProductCategory.NOTICE_ISA)
    .sort((a, b) => {
      const termA = transformTerm(a.term);
      const termB = transformTerm(b.term);
      return termA - termB;
    });

  formattedProducts.forEach(product => {
    categories.add(product.category);
  });

  const newAccountProducts: NewAccountProducts = Object.fromEntries(Array.from(categories, a => [a, []]));

  formattedProducts.forEach(product => {
    const formattedGroup = formatProductsGroup(product);
    if (formattedGroup) {
      newAccountProducts[product.category].push(formattedGroup);
    }
  });

  return { newAccountProducts, categories };
};

export const sortAvailableProducts = (availableProducts: NewAccountProducts) => {
  const objectOrder = {
    [ProductCategory.EASY_ACCESS]: null,
    [ProductCategory.EASY_ACCESS_ISA]: null,
    [ProductCategory.NOTICE]: null,
    [ProductCategory.FIXED_RATE]: null,
    [ProductCategory.FIXED_RATE_ISA]: null
  };

  const newAvailableProducts = Object.assign(objectOrder, availableProducts);

  return newAvailableProducts;
};

export const generateNewAccountTabs = (availableProducts: NewAccountProducts, categories: Set<ProductCategory>) => {
  const newAvailableProducts = sortAvailableProducts(availableProducts);
  let tabs: NewAccountTab[] = [];
  if (categories.size) {
    const allTab = {
      title: tabsTitles[TABS_VALUES.allAccounts as keyof typeof tabsTitles],
      value: TABS_VALUES.allAccounts,
      content: newAvailableProducts
    };
    tabs = [...tabs, allTab];
    Object.entries(TABS_VALUES).forEach(([key, value]) => {
      let currentContent = {};

      if (key !== allAccounts) {
        Object.keys(newAvailableProducts).forEach(category => {
          if (TABS_CATEGORIES[key].includes(category)) {
            currentContent = {
              ...currentContent,
              [category]: newAvailableProducts[category]
            };
          }
        });
        if (Object.keys(currentContent).length) {
          const newTab = {
            title: tabsTitles[TABS_VALUES[key] as keyof typeof tabsTitles],
            value,
            content: currentContent
          };

          tabs = [...tabs, newTab];
        }
      }
    });
  }
  return tabs;
};

export { TABS_VALUES };
