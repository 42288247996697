export default {
  tag: {
    Approved: {
      backgroundColor: "$yellow3"
    },
    Pending: {
      backgroundColor: "$yellow3"
    },
    ManuallyRejected: {
      backgroundColor: "$negative",
      color: "$white"
    },
    Rejected: {
      backgroundColor: "$negative",
      color: "$white"
    },
    ManualReview: {
      backgroundColor: "$negative",
      color: "$white"
    }
  },
  tagText: {
    letterSpacing: "1.2px",
    fontFamily: "$display",
    padding: "$0-5 $1"
  },
  text: {
    color: {
      withdrawal: {
        color: "$negative"
      },
      deposit: {
        color: "$green4"
      }
    }
  },
  container: {
    padding: {
      "@xs": {
        paddingLeft: 0,
        paddingRight: 0
      }
    },
    margin: {
      marginTop: "$4"
    }
  }
};
