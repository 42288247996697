import { updateDocumentStatus } from "services/data/api";

export interface IVisibleDocumentFile {
  isRead?: boolean;
  entity: {
    id: string;
  };
}

export const markVisibleDocumentsAsRead = (data: IVisibleDocumentFile[][]) =>
  data.map(pages =>
    pages
      .filter(file => file.isRead === false || file.isRead === undefined)
      .map(file => updateDocumentStatus(file.entity.id))
  );

export default markVisibleDocumentsAsRead;
