import { Dispatch } from "react";
import { useOutletContext } from "react-router-dom";
import {
  AccountType,
  AnnualSavings,
  IIsaTransferEditSelection,
  ILinkedAccount,
  INoticePeriod,
  InterestFrequency,
  InterestPaymentOption,
  IsaDetails,
  IsaProviders,
  TransferAmount
} from "services/data/types";
import { FormattedAvailableProduct } from "utils/formatAvailableProducts/interfaces";

export interface INewAccountContext {
  state: INewAccountState;
  setState: Dispatch<Partial<INewAccountState>>;
  clearState: () => void;
}

export interface INewAccountState {
  selectedProductSet?: FormattedAvailableProduct;
  accountType?: AccountType;
  annualSavings?: AnnualSavings;
  linkedAccount?: ILinkedAccount;
  selectedProductId?: string;
  selectedFrequency?: InterestFrequency;
  interestPaymentOption?: InterestPaymentOption;
  editMode?: boolean;
  transferISASavingsToCurrentAccount?: string;
  niNumber?: string;
  isaDetails?: IsaDetails;
  fullTransfer?: string;
  toppedUpAccountInCY?: string;
  transferAmount?: TransferAmount;
  noticePeriod?: INoticePeriod;
  editSelection?: IIsaTransferEditSelection;
  isaProvidersList?: IsaProviders[];
}

export const initialNewAccountState: INewAccountState = {};

export const useNewAccountContext = () => useOutletContext<INewAccountContext>();
