import { Alert, Icon, Space, Text } from "@shawbrook/sds-component-library";
import { CURRENCIES } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import { RadioGroupInputForForm } from "components/RadioGroupInputForForm/RadioGroupInputForForm";
import { contentConfig } from "config/contentConfig";
import { useFormContext } from "react-hook-form";
import { useNewAccountContext } from "state/newAccountState";
import { formatBalance } from "utils";
import { WrappedCurrencyInput } from "../InputComponents/WrappedCurrencyInput";
import styles from "../TransferAmount.module.scss";

export const PartialTransferYesTopped = () => {
  const {
    amountPaidToISA,
    additionalAmount,
    warningMessage,
    totalAmountLabel,
    totalAmountSubLabel,
    transferMoreMoney
  } = strings.newAccount.transferAmount;
  const { minValueAmountToPaidISA: minValue, maxValueAmountToPaidISA } = contentConfig;

  const {
    state: { selectedProductSet, accountType }
  } = useNewAccountContext();

  const { watch } = useFormContext();

  const { accountTypes } = strings.newAccount.accountType;
  const { soleAccount } = accountTypes;
  const maxValueAdditionalAmount =
    (accountType === soleAccount.id ? selectedProductSet?.soleMaxBalance : selectedProductSet?.jointMaxBalance) || 0;

  const rulesAmountPaidToISA = {
    required: amountPaidToISA.validations.minimumError.replace("{{MIN_AMOUNT}}", `${formatBalance(minValue)}`),
    min: {
      value: minValue,
      message: amountPaidToISA.validations.minimumError.replace("{{MIN_AMOUNT}}", `${formatBalance(minValue)}`)
    },
    max: {
      value: maxValueAmountToPaidISA,
      message: amountPaidToISA.validations.maximumError.replace(
        "{{MAX_AMOUNT}}",
        `${formatBalance(maxValueAmountToPaidISA)}`
      )
    }
  };

  const transferMoreMoneyValue = watch("transferMoreMoney");
  const amountPaidToISAValue = watch("amountPaidToISA");
  const additionalAmountValue = watch("additionalAmount");
  const maxValue = Number(maxValueAdditionalAmount || 0) - Number(amountPaidToISAValue || 0);
  const totalAmountToTransferParts = formatBalance(
    Number(amountPaidToISAValue || 0) + Number(additionalAmountValue || 0)
  ).split(".");

  const rulesAdditionalAmount = {
    required: additionalAmount.validations.minimumError.replace("{{MIN_AMOUNT}}", `${formatBalance(minValue)}`),
    min: {
      value: minValue,
      message: additionalAmount.validations.minimumError.replace("{{MIN_AMOUNT}}", `${formatBalance(minValue)}`)
    },
    max: {
      value: maxValue,
      message: additionalAmount.validations.maximumError.replace("{{MAX_BALANCE}}", `${formatBalance(maxValue)}`)
    }
  };

  return (
    <>
      <Space gap={10}>
        <Alert variant="warning" icon={<Icon name="warning" color="charcoal" />} title={warningMessage} />
      </Space>
      <WrappedCurrencyInput
        label={{ main: amountPaidToISA.label, sub: amountPaidToISA.subLabel }}
        name="amountPaidToISA"
        prefix={CURRENCIES.GBP}
        rules={rulesAmountPaidToISA}
      />
      <RadioGroupInputForForm
        label={{ main: transferMoreMoney.label, sub: transferMoreMoney.subLabel }}
        name="transferMoreMoney"
        options={transferMoreMoney.options}
        rules={{ required: true }}
      />
      {transferMoreMoneyValue === "Yes" && (
        <>
          <WrappedCurrencyInput
            label={{ main: additionalAmount.label }}
            name="additionalAmount"
            prefix={CURRENCIES.GBP}
            rules={rulesAdditionalAmount}
          />
          <Text font="silka" as="span" className={styles.totalAmountLabel}>
            {totalAmountLabel}
          </Text>
          <Text as="span" fontWeight="medium" textStyle="body" className={styles.totalAmountSubLabel}>
            {totalAmountSubLabel}
          </Text>
          <Text font="silka" fontWeight="medium" textStyle="xlarge">
            {totalAmountToTransferParts[0]}
            <Text font="silka" fontWeight="medium" textStyle="large" as="span">
              .{totalAmountToTransferParts[1]}
            </Text>
          </Text>
        </>
      )}
    </>
  );
};
