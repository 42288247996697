import { Box, Button, Card, Heading, Loader, RadioGroup, RadioItem, Tooltip } from "@shawbrook/sds-component-library";
import { FC, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { ArrowLeft, ArrowRight } from "assets/images";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import { IconButton } from "components/IconButton/IconButton";
import { ReinvestmentAccounts } from "components/ReinvestmentAccounts/ReinvestmentAccounts";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import usePopulateProductSet from "hooks/usePopulateProductSet";
import useSrollToTop from "hooks/useScrollToTop";
import { InstructionType, IProduct } from "services/data/types";
import { CHOSEN_ACCOUNTS, useReinvestmentContext } from "state/reinvestmentState";
import convertToTitleCase from "utils/convertToTitleCase";
import getReinvestmentAccountData from "utils/getReinvestmentAccountData";
import { headerStyles } from "../HeaderStyles.styles";
import styles from "./ChooseInterest.module.scss";

export const ChooseInterest: FC = () => {
  const { state, setState } = useReinvestmentContext();
  const params = useParams();
  useSrollToTop();
  const accountId = params.id || "";
  const chosenAccount = CHOSEN_ACCOUNTS.savings;
  const [selectedProductId, setsSelectedProductid] = useState<string | undefined>(state[accountId]?.selectedProductId);
  const navigate = useNavigate();
  const { accountDetailData } = useAccount({ accountId });
  const { noSelectedProductSet, isAvailableProductsError } = usePopulateProductSet({
    accountId,
    condition: !state[accountId]?.selectedProductSet
  });

  const { reviewButton } = strings.general;
  const { title } = strings.reinvestment;
  const { interestOptionsTitle, tooltip } = strings.chooseInterest;

  const sets = state[accountId]?.selectedProductSet;
  const interestOptions: IProduct[] = sets?.products || [];

  useEffect(() => {
    if (typeof accountId !== "string" || typeof state[accountId]?.selectedProductSetName === "undefined") {
      navigate(`${links.maturityInstruction.link}/select-account/${accountId}`);
    }
  }, [state, accountId, navigate]);

  if (isAvailableProductsError) {
    return <ErrorModal open allPages />;
  }

  const handleFrequencyChange = (e: string) => {
    setsSelectedProductid(e);
  };

  const handleEdit = () => {
    navigate(`${links.maturityInstruction.link}/select-account/${accountId}`);
  };

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleContinue = () => {
    const currInstructions = state[accountId]?.instructions;
    const product = state[accountId].selectedProductSet?.products.find(el => el.id === selectedProductId);
    if (currInstructions === undefined || !currInstructions[0] || !product) {
      return;
    }
    const { interestRate, interestFrequency, id, title: productTitle } = product;
    const instructionsWithProduct = [...currInstructions];
    const reinvestInstruction = instructionsWithProduct.find(el => el.type === InstructionType.REINVEST_NEW);
    if (reinvestInstruction) {
      reinvestInstruction.toProduct = {
        title: productTitle,
        id,
        interestRate,
        interestFrequency
      };
    }

    setState({
      accountId,
      partialData: {
        chosenAccount,
        selectedProductId,
        instructions: instructionsWithProduct
      }
    });
    navigate(`${links.maturityInstruction.link}/review-submission/${accountId}`);
  };

  const productDisplayName = state[accountId]?.selectedProductSetName || "";
  const productIssueNumber = state[accountId]?.selectedProductSet
    ? `${strings.general.issueNumber} ${state[accountId]?.selectedProductSet?.issueNumber}`
    : "";

  const {
    accountDisplayName,
    accountIssueNumberText: accountIssueNumber,
    accountBalance
  } = getReinvestmentAccountData({ accountDetailData, state, accountId });

  return (
    <div className="chooseInterest">
      <Box as="header" css={headerStyles.header}>
        <IconButton icon={<ArrowLeft />} text={strings.general.back} onClick={handleBackButton} />
        <Heading color="white">{title}</Heading>
      </Box>
      <section className={styles.page__content}>
        <Card className={styles.page__content__card}>
          {noSelectedProductSet && (
            <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
          )}
          {!noSelectedProductSet && (
            <>
              <div className="radioGroup">
                <RadioGroup
                  value={selectedProductId}
                  css={{ marginTop: "1.25rem" }}
                  onValueChange={handleFrequencyChange}
                  id="choose-reinvestment"
                  labelText={{ main: interestOptionsTitle }}
                  ariaLabel="choose reinvestment"
                  orientation="vertical"
                  toolTip={
                    <Tooltip
                      alignOffset={0}
                      avoidCollisions
                      side="bottom"
                      sideOffset={8}
                      text={tooltip}
                      variant="dark"
                    />
                  }>
                  {interestOptions.map(option => (
                    <RadioItem
                      key={option.id}
                      value={option.id}
                      label={`${convertToTitleCase(option.interestFrequency || "")} – ${option.interestRate}%`}
                      id={option.id}
                      aria-label={option.interestFrequency}
                    />
                  ))}
                </RadioGroup>
              </div>
              <div className={styles.reviewButton}>
                <Button iconRight type="button" disabled={!selectedProductId} size="medium" onClick={handleContinue}>
                  {reviewButton}
                  <ArrowRight />
                </Button>
              </div>{" "}
            </>
          )}
        </Card>
        <aside>
          <ReinvestmentAccounts
            accountDisplayName={accountDisplayName}
            accountIssueNumber={accountIssueNumber}
            accountBalance={accountBalance || 0}
            productDisplayName={productDisplayName}
            productIssueNumber={productIssueNumber}
            handleEdit={handleEdit}
          />
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};
