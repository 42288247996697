import strings from "assets/strings/strings.json";
import { FC } from "react";
import { IAccountDetails } from "types/types";
import { formatSortCode } from "utils";
import { AccountDetailItem } from "../AccountDetailItem/AccountDetailItem";

export const AccountDetails: FC<IAccountDetails> = ({ name, accountNumber, sortCode }) => (
  <>
    <AccountDetailItem text={strings.newAccount.fundingModal.accountInfo.p1} variable="name" replacement={name} />
    <AccountDetailItem
      text={strings.newAccount.fundingModal.accountInfo.p2}
      variable="accountNumber"
      replacement={accountNumber}
    />
    <AccountDetailItem
      text={strings.newAccount.fundingModal.accountInfo.p3}
      variable="sortCode"
      replacement={formatSortCode(sortCode)}
    />
  </>
);
