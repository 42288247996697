import { Card, Flex, Heading, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { FC } from "react";
import { formatBalance } from "utils";
import { inlineStyles } from "./ProductEstimatedBalance.styles";

interface IProductEstimatedBalance {
  termNumber?: number;
  termPeriodIndicator?: string;
  interestRate: number;
  initialBalance: number;
}

const calculateInterest = (balance: number, interestRate: number, termAmount?: number, periodIndicator?: string) => {
  // impossible to determine interest if one part of term is present and the other is not
  if (!termAmount !== !periodIndicator) return 0;
  const termNumber = termAmount || 12;
  return Math.round(balance * (1 + interestRate / 100) ** (termNumber / 12)) - balance;
};

export const ProductEstimatedBalance: FC<IProductEstimatedBalance> = ({
  termNumber,
  termPeriodIndicator,
  interestRate,
  initialBalance
}) => {
  const interest = calculateInterest(initialBalance, interestRate, termNumber, termPeriodIndicator);
  const { title, openingDeposit, interestEarned, defaultPeriod, balanceAfter, disclaimer } = strings.estimatedBalance;
  const balanceAfterText =
    termNumber && termPeriodIndicator ? `${termNumber} ${termPeriodIndicator.toLowerCase()}` : defaultPeriod;
  return (
    <>
      <Space gap="2">
        <Heading as="h4" level={4}>
          {title.replace("{{PERIOD}}", balanceAfterText)}
        </Heading>
      </Space>
      <Flex gap="5" direction="column">
        <Card variant="filled" width="full">
          <Flex justify="between" css={inlineStyles.balanceCard}>
            <Flex direction="column">
              <Space gap="2-5">
                <Text textStyle="body" fontWeight="bold">
                  {openingDeposit}
                </Text>
              </Space>
              <Text textStyle="medium">{formatBalance(initialBalance, true)}</Text>
            </Flex>
            <Flex direction="column">
              <Space gap="2-5">
                <Text textStyle="body" fontWeight="bold">
                  {balanceAfter} {balanceAfterText}
                </Text>
              </Space>
              <Text textStyle="medium">{formatBalance(initialBalance + interest, true)}</Text>
            </Flex>
            <Flex direction="column">
              <Space gap="2-5">
                <Text textStyle="body" fontWeight="bold">
                  {interestEarned}
                </Text>
              </Space>
              <Text textStyle="medium">{formatBalance(interest, true)}</Text>
            </Flex>
          </Flex>
        </Card>
        <Text color="grey">{disclaimer}</Text>
      </Flex>
    </>
  );
};
