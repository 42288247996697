import { Box, Flex, Heading, LeftArrow, Text } from "@shawbrook/sds-component-library";
import IconLink from "components/IconLink/IconLink";
import { useParams } from "react-router-dom";

import strings from "assets/strings/strings.json";
import { ErrorModal } from "components/ErrorModal";
import ErrorRequestView from "components/ErrorRequestView";
import LoadingSection from "components/LoadingSection/LoadingSection";
import MessageList from "components/MessageList/MessageList";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import useMessages from "hooks/useMessages";
import useThreads from "hooks/useThreads";
import { IThreadSummary, SenderType } from "services/data/types";
import { useInboxContext } from "state/inboxState";
import { formatToDisplayDate } from "utils";
import styles from "./InboxThreadPage.module.scss";
import InlineStyles from "./InboxThreadPage.styles";
import InboxActionBar from "./components/InboxActionBar";
import { ActionBarTypes } from "./types";

export const InboxThreadPage = () => {
  const params = useParams();
  const context = useInboxContext();
  const threadId = params.id as string;
  const startMessages = Boolean(context.state.isGetMessages && threadId);
  const { threadsData, errors: threadErrors, threadsError, isLoading: threadsLoading } = useThreads();
  const {
    messagesData,
    errors,
    isLoading: messagesLoading,
    messagesError
  } = useMessages({ threadId, enabled: startMessages, inboxContext: context });

  const accountId = threadsData?.response?.find(t => t.threadId === params.id)?.accountId;
  const { accountDetailData } = useAccount({ accountId, statusFilter: [] });
  const { title, issueNumber } = accountDetailData?.product || {};
  const { issueNumber: issueString } = strings.general;

  const accountName = title && issueNumber ? `${title} ${issueString} ${issueNumber}` : "";

  if (errors || threadErrors) {
    return (
      <ErrorRequestView
        requestErrors={[threadsError, messagesError]}
        context={context}
        errorModal={<ErrorModal open />}
      />
    );
  }

  const totalMessages = messagesData?.length || 0;
  const lastMessage = messagesData?.length ? messagesData[messagesData.length - 1] : undefined;

  // this is used to instruct the InboxActionBar on what type to use, default being Textarea (FreeText)
  const lastMessageActionBar = lastMessage?.response || { type: "freetext", options: [] };

  const thread = threadsData && threadsData.response?.find((t: IThreadSummary) => t.threadId === params.id);
  const creationDate = messagesData && messagesData[0].sentDate && formatToDisplayDate(messagesData[0].sentDate);
  const subject = thread?.subject as string;
  const isLoading = messagesLoading || threadsLoading || !creationDate;

  // only disable the send button if we are waiting on the first autoresponder message
  const shouldDisableSendBtn =
    totalMessages === 1 &&
    lastMessage?.sender !== SenderType.AUTOMATED &&
    !lastMessage?.content &&
    context.state.isGetMessages;

  return isLoading ? (
    <LoadingSection />
  ) : (
    <section className={styles.inbox}>
      <Flex direction="row" align="start" gap="1" justify="start">
        <Box css={{ m: 0, "> a": { m: 0 }, "@md": { display: "none" } }}>
          <IconLink to={links.inbox.link} icon={<LeftArrow />} />
        </Box>
        <Flex direction="column" gap="1" css={{ pb: "$6" }}>
          <Heading css={{ pt: 0, "@md": { pt: "$6" } }} level="4">
            {subject}
          </Heading>
          {(creationDate || accountName) && (
            <Text color="grey" fontWeight="light" css={InlineStyles.creationDate} data-testid="message-meta">
              {accountName && `${accountName} | `}
              {creationDate && `Created ${creationDate}`}
            </Text>
          )}
        </Flex>
      </Flex>

      {messagesData && <MessageList messages={messagesData} gettingMessages={context.state.isGetMessages} />}

      {thread?.isClosed && (
        <Box css={{ mb: "2rem" }}>
          <Text color="grey" fontWeight="bold" css={InlineStyles.endConversationHeading}>
            {strings.inbox.endConversationHeading}
          </Text>
          <Text color="grey" fontWeight="light" css={InlineStyles.endConversationText}>
            {strings.inbox.endConversationText}
          </Text>
        </Box>
      )}

      {lastMessageActionBar && thread && !thread.isClosed && (
        <InboxActionBar
          threadId={thread.threadId}
          mode={lastMessageActionBar.type.toLowerCase() as ActionBarTypes}
          options={lastMessageActionBar.options}
          isDisabled={shouldDisableSendBtn}
        />
      )}
    </section>
  );
};

export default InboxThreadPage;
