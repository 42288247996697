import { VALIDATION } from "assets/strings/validation";
import * as yup from "yup";

export const formatMobileNumberInput = (mobileNumber: string): string => {
  const cleanInput = mobileNumber.replaceAll(" ", "").substring(0, 11);
  return `${cleanInput.slice(0, 5)}${cleanInput.length > 5 ? ` ${cleanInput.slice(5)}` : ""}`;
};

export const customerDetailsMobileSchema = yup.object().shape({
  customer: yup.object().shape({
    contactDetails: yup.object().shape({
      mobilePhoneNumber: yup
        .string()
        .required(VALIDATION.userMigrationJourney.customerDetails.mobile.required)
        .test("valid", VALIDATION.userMigrationJourney.customerDetails.mobile.mobileFormatIncorrect, value => {
          const transformedValue = value.replaceAll(" ", "");
          return (
            transformedValue.startsWith("07") && (transformedValue.length === 10 || transformedValue.length === 11)
          );
        })
    })
  })
});
