import { InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";

import { loginRequest } from "config/authConfig";
import { resetCustomer } from "utils/trackerLib";
import { links } from "config/navLinks";

const sendUserToLoginPage = (
  instance: IPublicClientApplication,
  inProgress: InteractionStatus,
  customerNumber: string | null
) => {
  const accounts = instance.getAllAccounts();
  if (inProgress === InteractionStatus.None && !accounts.length) {
    const redirectRequest = {
      ...loginRequest,
      extraQueryParameters: {
        ...(customerNumber && { customerNumber })
      }
    };
    instance.loginRedirect(redirectRequest);
  }

  if (accounts?.[0]?.idTokenClaims?.exp && accounts[0].idTokenClaims.exp < new Date().getTime() / 1000) {
    localStorage.clear();
    sessionStorage.clear();
    resetCustomer();
    instance.logoutRedirect({ postLogoutRedirectUri: links.landing.link });
  }
};

export default sendUserToLoginPage;
