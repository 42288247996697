import React from "react";

import { TelemetryProviderProps } from "./types";

const TelemetryProvider: React.FC<TelemetryProviderProps> = ({ children }) => (
  // eslint-disable-next-line react/jsx-no-useless-fragment
  <>{children}</>
);

export { TelemetryProvider };
export default TelemetryProvider;
