import {
  AuthenticationResult,
  BrowserAuthError,
  InteractionRequiredAuthError,
  IPublicClientApplication
} from "@azure/msal-browser";

import { SESSION_STORAGE_KEY } from "assets/strings/api";
import { loginRequest } from "config/authConfig";
import { links } from "config/navLinks";
import { LOGGED_OUT_TYPE } from "types/types";
import { resetCustomer } from "utils/trackerLib";

const getAccessToken = async (instance: IPublicClientApplication): Promise<AuthenticationResult> => {
  const accounts = instance.getAllAccounts();
  let idToken = "";
  let authResult = { idToken: "" } as AuthenticationResult;

  if (accounts.length) {
    const tokenRequest = {
      account: accounts[0],
      ...loginRequest
    };

    try {
      authResult = await instance.acquireTokenSilent(tokenRequest);
      ({ idToken } = authResult);
      sessionStorage.setItem(SESSION_STORAGE_KEY.ACCESS_TOKEN, idToken);
    } catch (e) {
      if (
        e instanceof InteractionRequiredAuthError &&
        sessionStorage.getItem(SESSION_STORAGE_KEY.AUTH_POPUP_OPEN) !== "true"
      ) {
        try {
          sessionStorage.setItem(SESSION_STORAGE_KEY.AUTH_POPUP_OPEN, "true");
          authResult = await instance.acquireTokenPopup(loginRequest);
          ({ idToken } = authResult);
          sessionStorage.setItem(SESSION_STORAGE_KEY.ACCESS_TOKEN, idToken);
          sessionStorage.setItem(SESSION_STORAGE_KEY.AUTH_POPUP_OPEN, "false");
        } catch (eToken) {
          sessionStorage.setItem(SESSION_STORAGE_KEY.AUTH_POPUP_OPEN, "false");
          if (eToken instanceof Error) {
            console.log(eToken);
          }
          resetCustomer();
          localStorage.setItem("loggedOut", LOGGED_OUT_TYPE.auto);
          instance.logoutRedirect({ postLogoutRedirectUri: links.loggedOut.link });
        }
      }

      if (e instanceof BrowserAuthError) {
        localStorage.clear();
        resetCustomer();
        localStorage.setItem("loggedOut", LOGGED_OUT_TYPE.auto);
        instance.logoutRedirect({ postLogoutRedirectUri: links.loggedOut.link });
      }

      if (e instanceof Error) {
        console.log(e.message);
      }
    }
  }

  return authResult;
};

export default getAccessToken;
