import { Button, Heading, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { useNewAccountContext } from "state/newAccountState";
import { formatBalance } from "utils";
import styles from "../Summary.module.scss";

export type ISATransferInfoProps = {
  handleEdit: (link: string) => void;
};

export const ISATransferInfo = ({ handleEdit }: ISATransferInfoProps) => {
  const {
    state: {
      transferISASavingsToCurrentAccount,
      fullTransfer,
      isaDetails,
      toppedUpAccountInCY,
      noticePeriod,
      transferAmount
    }
  } = useNewAccountContext();

  const {
    isaTransfer: {
      title: isaTransferTitle,
      noTransfer,
      yesTransfer,
      fullTransferLabel,
      partialTransferLabel,
      toppedCYLabel,
      notToppedCYLabel,
      transferAmountLabel,
      hasNoticePeriodLabel,
      noNoticePeriodLabel,
      immediateTransferLabel,
      delayedTransferLabel,
      transferHasMoneyFromPYLabel,
      transferHasNoMoneyFromPYLabel,
      yesTransferMoreMoneyLabel,
      noTransferMoreMoneyLabel,
      totalTransferAmountLabel
    }
  } = strings.newAccount.summary;

  const investmentTypes = strings.newAccount.ISADetails.isaInvestmentType;
  const startTransferOptions = strings.newAccount.notice.startTransfer.options;

  const isTransferSavings = transferISASavingsToCurrentAccount === "Yes";
  const isFullTransfer = fullTransfer === "Yes";
  const isToppedinCY = toppedUpAccountInCY === "Yes";
  const transferFlowLabel = isTransferSavings ? yesTransfer : noTransfer;
  const hasNoticePeriod = noticePeriod?.hasNoticePeriod === "Yes";

  return (
    <>
      <Space gap="5">
        <Heading level={3}>{isaTransferTitle}</Heading>
      </Space>
      <Space gap="8" className={styles.optionsCard__content}>
        <Space gap={`${isTransferSavings ? 3 : 0}`}>
          <Text>{transferFlowLabel}</Text>
        </Space>
        {isTransferSavings && (
          <>
            <Space gap="0">
              <Text>
                {isaDetails?.isaProvider?.companyName === "Other"
                  ? isaDetails?.isaProviderNameIfOther
                  : isaDetails?.isaProvider?.companyName}
              </Text>
            </Space>
            <Space gap="0">
              <Text>{isaDetails?.accountNumber}</Text>
            </Space>
            <Space gap="0">
              <Text>{isaDetails?.sortCode}</Text>
            </Space>
            <Space gap="0">
              <Text>{isaDetails?.buildingSocietyRollNumber}</Text>
            </Space>
            <Space gap="3">
              <Text>
                {investmentTypes.find(investmentTypeOption => investmentTypeOption.value === isaDetails?.investmentType)
                  ?.label || ""}
              </Text>
            </Space>
            <Space gap="3">
              <Text>{isFullTransfer ? fullTransferLabel : partialTransferLabel}</Text>
            </Space>

            <Space gap="3">
              <Text>
                {isToppedinCY
                  ? toppedCYLabel.replace(
                      "{{TOPPED_AMOUNT}}",
                      `${formatBalance(Number(transferAmount?.amountPaidToISA || 0))}`
                    )
                  : notToppedCYLabel}
              </Text>
            </Space>

            {isFullTransfer && isToppedinCY && (
              <Space gap="3">
                <Text>
                  {transferAmount?.isaContainsSavingFromPY === "Yes"
                    ? transferHasMoneyFromPYLabel
                    : transferHasNoMoneyFromPYLabel}
                </Text>
              </Space>
            )}

            {!isFullTransfer && isToppedinCY && (
              <Space gap="3">
                <Text>
                  {transferAmount?.transferMoreMoney === "Yes"
                    ? yesTransferMoreMoneyLabel.replace(
                        "{{ADDITIONAL_AMOUNT}}",
                        `${formatBalance(Number(transferAmount?.additionalAmount || 0))}`
                      )
                    : noTransferMoreMoneyLabel}
                </Text>
              </Space>
            )}
            {!isFullTransfer && isToppedinCY && transferAmount?.transferMoreMoney === "Yes" && (
              <Space gap="3">
                <Text>
                  {totalTransferAmountLabel.replace(
                    "{{TOTAL_TRANSFER_AMOUNT}}",
                    `${formatBalance(
                      Number(transferAmount?.amountPaidToISA || 0) + Number(transferAmount?.additionalAmount || 0)
                    )}`
                  )}
                </Text>
              </Space>
            )}

            {!isFullTransfer && !isToppedinCY && (
              <Space gap="3">
                <Text>
                  {transferAmountLabel.replace(
                    "{{TRANSFER_AMOUNT}}",
                    `${formatBalance(Number(transferAmount?.amountPaidToISA || 0))}`
                  )}
                </Text>
              </Space>
            )}

            <Space gap="3">
              <Text>{hasNoticePeriod ? hasNoticePeriodLabel : noNoticePeriodLabel}</Text>
            </Space>
            {hasNoticePeriod && (
              <Space gap="0">
                <Text>
                  {noticePeriod?.startTransfer === startTransferOptions[0].value
                    ? immediateTransferLabel
                    : delayedTransferLabel}
                </Text>
              </Space>
            )}
          </>
        )}
        <Button variant="tertiary" onClick={() => handleEdit(links.isaTransfer.link)}>
          {strings.general.editButton}
        </Button>
      </Space>
    </>
  );
};
