import { useMutation, useQueryClient } from "@tanstack/react-query";
import { MESSAGES } from "assets/strings/values";
import { useRef } from "react";
import { submitMessages } from "services/data/api";
import { IMessage } from "services/data/types";
import { QueryKeys } from "services/data/types/queryKeys";
import { IInboxContext } from "state/inboxState";
import { IUseSubmitMessages } from "./interfaces";

const useSubmitMessages = ({
  threadId,
  message,
  inboxContext: { setState } = {} as IInboxContext
}: IUseSubmitMessages) => {
  const queryClient = useQueryClient();
  const mutationCounterRef = useRef(0);

  const {
    mutate: postMessages,
    isError: isSubmitMessagesError,
    error: submitMessagesError,
    isSuccess: isSubmitMessagesSuccess,
    isLoading: isSubmitMessagesLoading,
    data: submitMessagesData
  } = useMutation({
    mutationFn: () => submitMessages(threadId, { content: message }),
    onMutate: async () => {
      // Cancel any outgoing refetches
      // (so they don't overwrite our optimistic update)
      await queryClient.cancelQueries({ queryKey: [QueryKeys.MESSAGES, threadId] });

      // Snapshot the previous value
      const previousMessages = queryClient.getQueryData([QueryKeys.MESSAGES, threadId]);

      // Optimistically update to the new value
      // this will be reverted once the thread list updates, but will appear to be the same
      // no flicker should happen
      queryClient.setQueryData([QueryKeys.MESSAGES, threadId], (old: any) => [
        ...old,
        {
          content: message,
          messageId: MESSAGES.TEMP_ID,
          direction: "Inbound",
          sentDate: new Date().toISOString(),
          subject: "-"
        } as unknown as IMessage
      ]);

      // Increment the mutation counter
      mutationCounterRef.current += 1;

      return async () => {
        setState({
          isGetMessages: true
        });
        mutationCounterRef.current -= 1;

        // Invalidate and refetch
        await queryClient.invalidateQueries({ queryKey: [QueryKeys.MESSAGES, threadId] });

        // If the mutation has been cancelled, restore the previous value
        if (mutationCounterRef.current === 0) {
          queryClient.setQueryData([QueryKeys.MESSAGES, threadId], previousMessages);
        }
      };
    },
    onSettled: () => {
      // because this context is just for submitting / posting messages, we need
      // to alert the queryClient what data is now stale and needs to be invalidated

      // Invalidate and refetch
      mutationCounterRef.current -= 1;
      if (mutationCounterRef.current === 0) {
        setState({
          invalidateGetMessages: true,
          isGetMessages: true
        });
        queryClient.invalidateQueries({ queryKey: [QueryKeys.MESSAGES, threadId] });
      }
    }
  });

  return {
    postMessages,
    isSubmitMessagesError,
    submitMessagesError,
    isSubmitMessagesSuccess,
    isSubmitMessagesLoading,
    submitMessagesData
  };
};

export default useSubmitMessages;
