import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Flex, Heading, Modal } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import getErrorMessageInfo from "utils/getErrorMessageInfo";
import { TransferStatus } from "services/data/types";
import { links } from "config/navLinks";

interface IErrorModal {
  customCallback?: () => void;
  open?: boolean;
  onEscapeKeyDown?: boolean;
  onPointerDownOutside?: boolean;
  transferStatus?: TransferStatus;
  tryAgainCallback?: (() => void) | boolean;
}

export const ErrorModal: FC<IErrorModal> = ({
  customCallback = () => {},
  open = false,
  onEscapeKeyDown = false,
  onPointerDownOutside = false,
  transferStatus,
  tryAgainCallback
}) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { heading, content } = getErrorMessageInfo(transferStatus);

  const backToHomeCallback = () => {
    customCallback();

    if (pathname === links.landing.link) {
      window.location.assign(links.landing.link);
    } else {
      navigate(links.landing.link);
    }
  };

  return (
    <Modal.Root open={open}>
      <Modal.Content
        css={{ padding: "$6", maxWidth: "786px", borderRadius: "8px" }}
        onEscapeKeyDown={onEscapeKeyDown ? customCallback : undefined}
        onPointerDownOutside={onPointerDownOutside ? customCallback : undefined}>
        <Heading level={2} fontWeight="semiBold" id="modal-title" css={{ marginBottom: "$6" }}>
          {heading}
        </Heading>
        <Flex direction="column" css={{ marginBottom: "$6" }}>
          {content}
        </Flex>
        <Flex justify="end" gap={tryAgainCallback ? "4" : undefined}>
          {typeof tryAgainCallback !== "boolean" && tryAgainCallback ? (
            <>
              <Button
                size="large"
                variant="secondary"
                onClick={backToHomeCallback}
                css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
                {strings.moveMoney.modals.error.button}
              </Button>
              <Button
                size="large"
                variant="primary"
                onClick={tryAgainCallback}
                css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
                {strings.moveMoney.modals.error.tryAgainButton}
              </Button>
            </>
          ) : (
            <Button
              size="large"
              variant="primary"
              onClick={backToHomeCallback}
              css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
              {strings.moveMoney.modals.error.button}
            </Button>
          )}
        </Flex>
      </Modal.Content>
    </Modal.Root>
  );
};
