import {
  Alert,
  Box,
  Button,
  Card,
  Checkbox,
  Divider,
  DownArrowIcon,
  Flex,
  Heading,
  InfoIcon,
  Loader,
  Tag,
  Text
} from "@shawbrook/sds-component-library";
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { ArrowLeft, ArrowRight } from "assets/images";
import { DATES } from "assets/strings/formats";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import { IconButton } from "components/IconButton/IconButton";
import SuccessModal from "components/SuccessModal";
import WarningModal from "components/WarningModal/WarningModal";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import useCancelInstruction from "hooks/useCancelMaturityInstruction";
import useMaturityInstruction from "hooks/useMaturityInstruction";
import usePopulateProductSet from "hooks/usePopulateProductSet";
import useSrollToTop from "hooks/useScrollToTop";
import useSubmitInstruction from "hooks/useSubmitInstruction";
import inlineStyles from "pages/Reinvestment/ReviewSubmission/ReviewSubmission.style";
import { InstructionType, ReceivedInstructionProduct } from "services/data/types";
import { InterestFrequency, REINVESTEMENT_TYPES, useReinvestmentContext } from "state/reinvestmentState";
import { CheckedState } from "types/types";
import {
  formatBalance,
  formatToDisplayDate,
  getCancelModalText,
  getInstructionDisplayDate,
  getReinvestmnentType
} from "utils";
import getInterestInfo from "utils/getInterestInfo";
import getReinvestmentAccountData from "utils/getReinvestmentAccountData";
import getReinvestmentSuccessMessage from "utils/getReinvestmentSuccessMessage";
import { headerStyles } from "../HeaderStyles.styles";
import styles from "./ReviewSubmission.module.scss";

export const ReviewSubmission = () => {
  const navigate = useNavigate();
  const params = useParams();
  useSrollToTop();
  const [confirmChecked, setConfirmChecked] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [showInstruction, setShowInstruction] = useState(false);
  const [putInstructionStarted, setPutInstruction] = useState(false);
  const [cancelInstructionStarted, setCancelInstruction] = useState(false);
  const [skipRefreshingAccounts, setSkipRefresingAccounts] = useState(true);
  const [isResetProductSet, setIsResetProductSet] = useState(false);
  const [displaySubmitError, setDisplaySubmitError] = useState(false);
  const accountId = params.id || "";

  const { state, setState } = useReinvestmentContext();
  const { accountDetailData, accountErrored } = useAccount({ accountId });
  const { isAccountsLoading } = useAccount({ skipRequest: skipRefreshingAccounts });
  const { isSubmitInstructionSuccess, isSubmitInstructionLoading, postInstruction, isSubmitInstructionError } =
    useSubmitInstruction({
      sourceAccountId: accountId,
      instructions: state[accountId]?.instructions || []
    });

  const { isCancelInstructionSuccess, isCancelInstructionLoading, cancelInstruction, isCancelInstructionError } =
    useCancelInstruction({
      sourceAccountIdArg: accountId
    });

  usePopulateProductSet({ accountId, condition: isResetProductSet, setCondition: setIsResetProductSet });

  const [searchParams] = useSearchParams();
  const edit = searchParams.get("edit");
  const editMode = state[accountId]?.editMode;
  const { maturityInstructionData, isMaturityInstructionError, isMaturityInstructionLoading } = useMaturityInstruction({
    sourceAccountIdArg: accountId,
    enabled: !!editMode
  });
  const instructionActionInProgress =
    isCancelInstructionLoading || isSubmitInstructionLoading || (!skipRefreshingAccounts && isAccountsLoading);

  useEffect(() => {
    if (isSubmitInstructionError || isCancelInstructionError) {
      setDisplaySubmitError(true);
    }
  }, [isSubmitInstructionError, isCancelInstructionError]);

  useEffect(() => {
    if (edit !== "true" || state[accountId]?.instructions) {
      setShowInstruction(true);
    }
  }, [edit, setShowInstruction, state, accountId]);

  useEffect(() => {
    if (edit === "true" && !state[accountId]?.editMode) {
      setState({
        accountId,
        partialData: {
          editMode: true
        }
      });
    }
  }, [accountId, edit, setState, state]);

  useEffect(() => {
    if (editMode && !state[accountId] && accountDetailData) {
      setState({
        accountId: accountDetailData.id,
        partialData: {
          maturingAccount: accountDetailData
        }
      });
    }
  }, [accountDetailData, accountId, editMode, setState, state]);

  // TODO add 'toAccount' case
  const productFromInstruction = maturityInstructionData?.instructions?.find(
    el => el.type === InstructionType.REINVEST_NEW
  )?.toProduct as ReceivedInstructionProduct;

  const reinvestFromState = state[accountId]?.instructions?.find(el => el.type === InstructionType.REINVEST_NEW);

  const resetState = useCallback(
    (isChange?: boolean) => {
      const productId = productFromInstruction?.id || "";
      const setName = productFromInstruction?.title || "";
      setState({
        accountId,
        partialData: {
          selectedProductSetName: setName,
          selectedProductId: productId,
          instructions: maturityInstructionData?.instructions
        }
      });

      if (isChange) {
        setIsResetProductSet(true);
      }
    },
    [
      productFromInstruction?.id,
      productFromInstruction?.title,
      setState,
      accountId,
      maturityInstructionData?.instructions
    ]
  );

  useEffect(() => {
    if (
      editMode &&
      !isMaturityInstructionLoading &&
      maturityInstructionData &&
      !state[accountId]?.instructions &&
      accountDetailData
    ) {
      resetState();
    }
  }, [
    accountDetailData,
    accountId,
    editMode,
    isMaturityInstructionError,
    isMaturityInstructionLoading,
    maturityInstructionData,
    navigate,
    resetState,
    state
  ]);

  useEffect(() => {
    if (!edit && (typeof accountId !== "string" || typeof state[accountId]?.selectedProductSetName === "undefined")) {
      navigate(links.selectAccount.link.replace(":id", accountId));
    }
  }, [state, accountId, navigate, edit]);

  useEffect(() => {
    if (
      state[accountId]?.selectedProductId &&
      state[accountId]?.selectedProductSet &&
      !state[accountId]?.selectedProductSet?.products?.find(el => el.id === state[accountId]?.selectedProductId) &&
      !isResetProductSet
    ) {
      navigate(links.chooseInterest.link.replace(":id", accountId));
    }
  }, [navigate, state, accountId, isResetProductSet]);

  useEffect(() => {
    if (isSubmitInstructionSuccess || isCancelInstructionSuccess) {
      setSkipRefresingAccounts(false);
    }
  }, [isSubmitInstructionSuccess, isCancelInstructionSuccess]);

  useEffect(() => {
    if (!skipRefreshingAccounts && isAccountsLoading === false) {
      if (putInstructionStarted) {
        setShowSuccessModal(true);
      }
      if (cancelInstructionStarted) {
        setShowCancelModal(false);
        navigate(links.landing.link);
      }
    }
  }, [skipRefreshingAccounts, isAccountsLoading, putInstructionStarted, cancelInstructionStarted, navigate]);

  const {
    accountDisplayName,
    accountIssueNumber,
    currentInterestRate,
    interestFrequency,
    accountBalance,
    accountMaturityDate,
    linkedAccountBank,
    accountProductCategory
  } = getReinvestmentAccountData({ accountDetailData, state, accountId });

  useEffect(() => {
    if (state[accountId]?.instructions?.length === 2 && accountBalance != null) {
      const reinvestNewState = state[accountId].instructions?.find(el => el.type === InstructionType.REINVEST_NEW);

      if (reinvestNewState?.amount?.amount === accountBalance) {
        setState({
          accountId,
          partialData: {
            instructions: [
              {
                ...reinvestNewState,
                useRemainingBalance: true,
                amount: undefined
              }
            ]
          }
        });
      }
    }
  }, [state, setState, accountBalance, accountId]);

  if (edit === "true" && (accountErrored || isMaturityInstructionError)) {
    return <ErrorModal open allPages />;
  }

  const sets = state[accountId]?.selectedProductSet;
  const selectedProduct = sets && sets.products.find(el => el.id === state[accountId]?.selectedProductId);
  const productDisplayName = selectedProduct?.title || productFromInstruction?.title || "";
  const productIssueNumber = selectedProduct?.issueNumber || productFromInstruction?.issueNumber || 0;
  const productInterestFrequency =
    selectedProduct?.interestFrequency || productFromInstruction?.interestFrequency?.toLocaleLowerCase() || "";
  const productInterestRate = selectedProduct?.interestRate || productFromInstruction?.interestRate || 0;

  const interestInfo = getInterestInfo(
    getReinvestmnentType(state[accountId]?.instructions),
    productInterestFrequency as InterestFrequency,
    productInterestRate
  );

  const maturityDate = formatToDisplayDate(accountMaturityDate || "");

  const {
    title,
    modals: {
      success: { heading, btnText },
      cancel: { title: cancel, text, description: cancelDescription, cancelEditTitle }
    }
  } = strings.reinvestment;
  const {
    rateLabel,
    subtitle,
    description,
    descriptionEdit,
    maturing,
    selected,
    cancelInstruction: cancelInstructionBtn,
    cancel: cancelBtn,
    cancelEdit,
    balance,
    amountToReinvest,
    amountToWithdraw,
    submissionForm: { checkbox },
    infoText
  } = strings.reviewSubmission;
  const { submitBtn, backToHomeButton } = strings.general;

  const currentReinvestmentType =
    state[accountId]?.instructions?.length === 2 ? REINVESTEMENT_TYPES.partial : REINVESTEMENT_TYPES.full;

  const amountToReinvestValue =
    (currentReinvestmentType === REINVESTEMENT_TYPES.full
      ? accountBalance
      : state[accountId]?.instructions?.find(el => el.type === InstructionType.REINVEST_NEW)?.amount?.amount) || 0;
  const amountToWithdrawValue =
    state[accountId]?.instructions?.find(el => el.type === InstructionType.WITHDRAW)?.amount?.amount || 0;

  const hasInstructionChanged =
    editMode &&
    state[accountId]?.selectedProductId &&
    (productFromInstruction?.id !== reinvestFromState?.toProduct?.id ||
      productFromInstruction?.interestRate !== reinvestFromState?.toProduct?.interestRate ||
      productFromInstruction?.interestFrequency?.toLowerCase() !==
        reinvestFromState?.toProduct?.interestFrequency?.toLowerCase() ||
      maturityInstructionData?.instructions?.length !== state[accountId]?.instructions?.length ||
      maturityInstructionData?.instructions?.find(el => el.type === InstructionType.REINVEST_NEW)?.amount?.amount !==
        amountToReinvestValue);

  const cancelModalText = getCancelModalText(editMode || false, hasInstructionChanged, accountProductCategory, text);

  let cancelButtonText = cancelBtn;
  if (editMode) {
    cancelButtonText = hasInstructionChanged ? cancelEdit : cancelInstructionBtn;
  }

  const cancelModalTitle = hasInstructionChanged ? cancelEditTitle : cancel;

  const editInfoText = infoText.replace(
    "{{DATE}}",
    getInstructionDisplayDate([DATES.isoWithTimezoneShort, DATES.isoWithLongTimezone], accountMaturityDate)
  );

  const confirmationCheckboxHandler = (checked: CheckedState) => {
    if (typeof checked === "boolean") {
      setConfirmChecked(checked);
    }
  };

  const successCallback = () => {
    setShowSuccessModal(false);
  };

  const handleCloseCancelModal = () => {
    setShowCancelModal(false);
  };

  const handleCancel = () => {
    if (editMode) {
      if (hasInstructionChanged) {
        resetState(true);
        setShowCancelModal(false);
      } else {
        setCancelInstruction(true);
        cancelInstruction();
      }
    } else {
      navigate(links.landing.link);
    }
  };

  const handleSubmit = () => {
    setPutInstruction(true);
    postInstruction();
  };

  const handleBackToHome = () => {
    navigate(links.landing.link);
  };

  const errorCallback = () => {
    setDisplaySubmitError(false);
  };

  const gap = currentReinvestmentType === REINVESTEMENT_TYPES.full ? "$4" : "calc($4 * 2 + 24px)";

  return (
    <div className={styles.title}>
      <ErrorModal customCallback={errorCallback} open={displaySubmitError} />
      <WarningModal
        handleClose={handleCloseCancelModal}
        handleConfirm={handleCancel}
        open={showCancelModal}
        title={cancelModalTitle}
        text={cancelModalText}
        description={(editMode && !hasInstructionChanged && cancelDescription) || undefined}
        maturityDate={maturityDate}
        loading={instructionActionInProgress}
      />
      <SuccessModal
        customCallback={successCallback}
        open={showSuccessModal}
        url={links.landing.link}
        content={getReinvestmentSuccessMessage({
          accountTitle: productDisplayName,
          instructionsArg: state[accountId]?.instructions,
          issueNumber: productIssueNumber,
          accountMaturityDate,
          amounts: {
            toReinvest: amountToReinvestValue,
            toWithdraw: amountToWithdrawValue
          },
          linkedAccountBank
        })}
        header={heading}
        btnContent={btnText}
        headerMarginBottom="$6"
      />

      <Box as="header" css={headerStyles.header}>
        <IconButton onClick={() => navigate(-1)} icon={<ArrowLeft />} text={strings.general.back} />
        <Heading color="white">{title}</Heading>
      </Box>

      <section className={styles.page__content}>
        <section>
          {!showInstruction && (
            <Card css={inlineStyles.bodyCard}>
              <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
            </Card>
          )}
          {showInstruction && (
            <>
              <div className={styles.page__content__heading}>
                <Heading level={2}>{subtitle}</Heading>
                <Text textStyle="body" fontWeight="medium" className={styles.page__content__heading__description}>
                  {editMode ? descriptionEdit.replace("{{DATE}}", maturityDate) : description}
                </Text>
              </div>
              <Card css={inlineStyles.bodyCard}>
                {editMode && (
                  <Alert variant="info" icon={<InfoIcon />} css={{ width: "fit-content", marginBottom: "2rem" }}>
                    <Text fontWeight="medium">{editInfoText}</Text>
                  </Alert>
                )}
                <Flex direction="column" gap={2} css={inlineStyles.accountCardWrapper}>
                  <Flex className={styles.accountCard} direction="column">
                    <Text data-testid="maturingCardTitle" fontWeight="medium">
                      {maturing}
                    </Text>
                    <div className={styles.accountCard__content}>
                      <Tag isLarge css={{ padding: "$0-5 $1" }} className={styles.accountCard__content__tag}>
                        <span className={styles.accountCard__content__accountName}>{accountDisplayName}</span>
                        {` ${strings.general.issueNumber} ${accountIssueNumber}`}
                      </Tag>
                      <Text textStyle="xlarge" className={styles.accountCard__content__text}>
                        {`${currentInterestRate}% ${rateLabel}`}
                      </Text>
                      <Text fontWeight="medium">{`${currentInterestRate}% ${interestFrequency}`}</Text>
                    </div>
                  </Flex>
                  <div className={styles.accountCard__content__icon}>
                    <DownArrowIcon height="24" width="24" />
                  </div>
                  <Flex className={styles.accountCard} direction="column">
                    <Text data-testid="selectedCardTitle" fontWeight="semiBold">
                      {selected}
                    </Text>
                    <div className={`${styles.accountCard__content} ${styles["accountCard__content--pink"]}`}>
                      <Tag isLarge css={{ padding: "$0-5 $1" }} className={styles.accountCard__content__tag}>
                        <span className={styles.accountCard__content__accountName}>{productDisplayName}</span>
                        {` ${strings.general.issueNumber} ${productIssueNumber}`}
                      </Tag>
                      <Text textStyle="xlarge" className={styles.accountCard__content__text}>
                        {`${productInterestRate}% ${rateLabel}`}
                      </Text>
                      <Button
                        variant="tertiary"
                        css={inlineStyles.editButton}
                        className={styles.editButton}
                        onClick={() => navigate(`${links.maturityInstruction.link}/select-account/${accountId}`)}>
                        {strings.general.editButton}
                      </Button>
                    </div>
                  </Flex>
                </Flex>
                <Flex
                  direction="column"
                  gap={2}
                  css={{
                    marginTop: "1.5rem",
                    "@sm": { gap, flexDirection: "row" }
                  }}>
                  {currentReinvestmentType === REINVESTEMENT_TYPES.full && (
                    <>
                      <Flex className={styles.accountCard} direction="column">
                        <Text fontWeight="medium">{balance}</Text>
                        <div className={styles.accountCard__content}>
                          <Text textStyle="xlarge" className={styles.accountCard__content__text}>
                            {formatBalance(accountBalance || 0)}
                          </Text>
                        </div>
                      </Flex>
                      <div className={styles.accountCard__content__icon}>
                        <DownArrowIcon height="24" width="24" />
                      </div>
                    </>
                  )}
                  <Flex className={styles.accountCard} direction="column">
                    <Text fontWeight="semiBold">{amountToReinvest}</Text>
                    <div className={`${styles.accountCard__content} ${styles["accountCard__content--pink"]}`}>
                      <Text textStyle="xlarge" className={styles.accountCard__content__text}>
                        {formatBalance(amountToReinvestValue || 0)}
                      </Text>
                      <Button
                        variant="tertiary"
                        css={inlineStyles.editButton}
                        className={styles.editButton}
                        onClick={() =>
                          navigate(
                            `${links.maturityInstruction.link}/${
                              currentReinvestmentType === REINVESTEMENT_TYPES.full
                                ? "choose-reinvestment"
                                : "amount-to-reinvest"
                            }/${accountId}`
                          )
                        }>
                        {strings.general.editButton}
                      </Button>
                    </div>
                  </Flex>
                  {currentReinvestmentType === REINVESTEMENT_TYPES.partial && (
                    <Flex className={styles.accountCard} css={{ mt: "1rem", "@sm": { mt: "0" } }} direction="column">
                      <Text fontWeight="semiBold">{amountToWithdraw}</Text>
                      <div className={`${styles.accountCard__content} ${styles["accountCard__content--pink"]}`}>
                        <Text textStyle="xlarge" className={styles.accountCard__content__text}>
                          {formatBalance(amountToWithdrawValue || 0)}
                        </Text>
                        <Button
                          variant="tertiary"
                          css={inlineStyles.editButton}
                          className={styles.editButton}
                          onClick={() => navigate(`${links.maturityInstruction.link}/amount-to-reinvest/${accountId}`)}>
                          {strings.general.editButton}
                        </Button>
                      </div>
                    </Flex>
                  )}
                </Flex>
                {interestInfo && (
                  <>
                    <Text css={inlineStyles.interestHeading[currentReinvestmentType]} textStyle="medium">
                      {interestInfo.mainTitle}
                    </Text>
                    {interestInfo.maturingAccount && (
                      <>
                        <Text data-testid="maturingInterestTitle" css={{ fontSize: "$4", marginBottom: "$3" }}>
                          {interestInfo.maturingAccount.subTitle}
                        </Text>
                        <div className={`${styles.interestCard} ${styles.interestCard__separator}`}>
                          <Text
                            textStyle="body"
                            css={{ marginBottom: 0 }}
                            className={styles.interestCard__text}
                            fontWeight="medium">
                            {interestInfo.maturingAccount.text}
                          </Text>
                        </div>
                      </>
                    )}
                    {interestInfo.product.subTitle && (
                      <Text data-testid="productInterestTitle" css={{ fontSize: "$4", marginBottom: "$3" }}>
                        {interestInfo.product.subTitle}
                      </Text>
                    )}
                    <div className={`${styles.interestCard} ${styles.interestCard__regular}`}>
                      <Text
                        textStyle="body"
                        css={{ marginBottom: 0 }}
                        className={styles.interestCard__text}
                        fontWeight="medium">
                        {interestInfo.product.paymentInterestText}
                      </Text>
                    </div>
                    <div className={`${styles.interestCard} ${styles.interestCard__regular}`}>
                      <Text
                        textStyle="body"
                        css={{ marginBottom: 0 }}
                        className={styles.interestCard__text}
                        fontWeight="medium">
                        {interestInfo.product.interestRateText}
                      </Text>
                      <Button
                        css={inlineStyles.editButton}
                        className={styles.editButton}
                        variant="tertiary"
                        onClick={() => navigate(`${links.maturityInstruction.link}/choose-interest/${accountId}`)}>
                        {strings.general.editButton}
                      </Button>
                    </div>
                  </>
                )}
                <Divider />
                {!hasInstructionChanged && editMode && (
                  <Flex direction="columnReverse" css={inlineStyles.submissionButtons}>
                    <Button
                      variant="tertiary"
                      onClick={() => setShowCancelModal(true)}
                      className={styles["submissionCard__buttons--cancel"]}
                      css={inlineStyles.cancelButton}
                      role="link">
                      {cancelButtonText}
                    </Button>
                    <Button
                      iconRight
                      size="medium"
                      variant="primary"
                      css={inlineStyles.submitButton}
                      onClick={handleBackToHome}>
                      {backToHomeButton}
                    </Button>
                  </Flex>
                )}
                {(hasInstructionChanged || !editMode) && (
                  <Card variant="filled" css={inlineStyles.submissionCard}>
                    <Checkbox
                      id="reinvestment-confirm"
                      data-testid="reinvestment-confirm"
                      onCheckedChange={confirmationCheckboxHandler}
                      label={checkbox}
                    />
                    <Flex
                      direction="columnReverse"
                      className={styles.submissionCard__buttons}
                      css={inlineStyles.submissionButtons}>
                      <Button
                        variant="tertiary"
                        onClick={() => setShowCancelModal(true)}
                        className={styles["submissionCard__buttons--cancel"]}
                        css={inlineStyles.cancelButton}
                        role="link">
                        {cancelButtonText}
                      </Button>
                      <Button
                        iconRight
                        size="medium"
                        disabled={!confirmChecked || instructionActionInProgress}
                        variant="primary"
                        css={inlineStyles.submitButton}
                        onClick={handleSubmit}>
                        {instructionActionInProgress ? (
                          <Loader size="small" />
                        ) : (
                          <>
                            {submitBtn}
                            <ArrowRight />
                          </>
                        )}
                      </Button>
                    </Flex>
                  </Card>
                )}
              </Card>
            </>
          )}
        </section>
        <aside>
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};

export default ReviewSubmission;
