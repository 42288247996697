import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { UseMoveMoneyFromProps } from "hooks/useMoveMoneyFrom/interfaces";
import { IAllAccounts } from "services/data/types";
import { useMoveMoneyContext } from "state/moveMoneyState";
import { saveMoveMoneyStateToStorage } from "utils/sessionStorage";

const useMoveMoneyFrom = ({ accountsData, accountDetailData, accountErrored }: UseMoveMoneyFromProps) => {
  const navigate = useNavigate();
  const { state, setState } = useMoveMoneyContext();
  const { description, noAvailableDescription } = strings.moveMoney.moveMoneyFrom;

  useEffect(() => {
    if (accountDetailData && accountErrored === false) {
      const moveMoneyState = {
        fromAccount: accountDetailData,
        toAccount: null
      };

      saveMoveMoneyStateToStorage(moveMoneyState);
      setState(moveMoneyState);
    }
  }, [accountDetailData, accountErrored, setState]);

  useEffect(() => {
    if (accountDetailData && accountErrored === false && state.fromAccount) {
      navigate(links.moveMoney.link);
    }
  }, [accountDetailData, accountErrored, state, navigate]);

  const isSingleAccount = accountsData?.length === 1;
  const fromAccountValid = state.fromAccount !== null;
  const backUrl = !isSingleAccount && !fromAccountValid ? links.landing.link : links.moveMoney.link;
  let withdrawableAccounts: IAllAccounts = [];
  let nonWithdrawableAccounts: IAllAccounts = [];

  if (accountsData?.length) {
    withdrawableAccounts = accountsData.filter(
      account =>
        (account.withdrawability.enabled || account.outboundWithdrawability?.enabled) &&
        (account.linkedAccount?.verified || !account.linkedAccount || !account.linkedAccount?.accountNumber)
    );
    nonWithdrawableAccounts = accountsData.filter(
      account =>
        (account.withdrawability.enabled === false && account.outboundWithdrawability?.enabled === false) ||
        account.linkedAccount?.verified === false
    );
  }

  const subTitle = withdrawableAccounts.length ? description : noAvailableDescription;

  return {
    backUrl,
    withdrawableAccounts,
    nonWithdrawableAccounts,
    subTitle
  };
};

export default useMoveMoneyFrom;
