export default {
  modal: {
    padding: "60px $6",
    maxWidth: "634px",
    borderRadius: "8px"
  },
  loaderWrapper: {
    marginBottom: "$5"
  },
  info: {
    textAlign: "center"
  },
  warning: {
    fontWeight: "$medium",
    textAlign: "center"
  }
};
