import { useQuery } from "@tanstack/react-query";
import { VALIDATION } from "assets/strings/validation";
import {
  handleErrorStatus,
  handleFieldErrorStatus,
  handleWarningStatus
} from "hooks/useVerificationOTPProcess/handleErrorStatus";
import {
  getKnownErrorsFromResult,
  isResultSuccessful,
  isResultValidError
} from "pages/BankfastUserMigration/ErrorManagement";
import { useNavigateToStep } from "pages/BankfastUserMigration/StepManagement";
import StepDefaultIds from "pages/BankfastUserMigrationMobile/StepIds";
import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { useLocation } from "react-router-dom";
import { generateOtpForContact, updateContactDetails } from "services/data/api";
import {
  CUSTOMER,
  CUSTOMER_EMAIL,
  CUSTOMER_MOBILE,
  ComponentState,
  Customer
} from "services/data/types/bankfastUserMigration/";
import {
  CUSTOMER_ITEM,
  SHOW_VERIFICATION_OTP_WARNING,
  VERIFICATION_CONTACT,
  VERIFICATION_OTP,
  VERIFICATION_OTP_ERROR,
  VERIFICATION_OTP_WARNING,
  VERIFICATION_VERIFIED_CONTACT,
  changeItemStep,
  contactOtpItem,
  errorCodeForExistingItem,
  otpStep,
  transformForUpdateAPISend
} from "../../pages/BankfastUserMigration/OTPProcess/Selectors";
import { IFirstStepOTPSubmit } from "./ISendOTPProcess";
import { IGenerateOtpForContact } from "./types";

const QUERY_KEYS = {
  GET_OTP_FOR_CONTACT: "getOTPForContact",
  UPDATE_CONTACT_DETAILS_OTP: "updateContactDetailsOTP"
};

const EMAIL_DUPLICATE = "CUSTOMER_EMAIL_EXISTS";
const MOBILE_DUPLICATE = "CUSTOMER_MOBILEPHONENUMBER_EXISTS";
const SECOND_MOBILE_DUPLICATE = "CUSTOMER_SECONDARYPHONENUMBER_EXISTS";
const EMAIL_INVALID = "EMAIL_INVALID";
const EMAIL_FORMAT_INCORRECT = "EMAIL_FORMAT_INCORRECT";
const MOBILE_FORMAT_INCORRECT = "MOBILE_FORMAT_INCORRECT";

const commonQueryOptions = {
  cacheTime: 0,
  staleTime: 0,
  retry: false,
  refetchOnWindowFocus: false
};

export const useGenerateOtpForContact = ({
  otpType,
  setPostResult,
  callGenerateOtpForContactData,
  setCallGenerateOtpForContactData,
  isResendPasscode = false
}: IGenerateOtpForContact) => {
  const { getValues, setValue, setError } = useFormContext();

  const {
    data: generateOtpForContactData,
    isError: generateOtpForContactError,
    isFetching: generateOtpForContactFetching
  } = useQuery({
    queryKey: [QUERY_KEYS.GET_OTP_FOR_CONTACT],
    queryFn: async () => {
      const data = await generateOtpForContact(contactOtpItem(otpType, getValues));
      if (data.headers?.location) {
        // Flag which triggers the second time that the warning has been shown, not the first time
        setValue(
          SHOW_VERIFICATION_OTP_WARNING(otpType),
          getValues(SHOW_VERIFICATION_OTP_WARNING(otpType)) >= 0 ? 1 : 0
        );
        // The warning we want to show, but not reset as part of errors
        setValue(VERIFICATION_OTP_WARNING(otpType), data.headers?.location);
      }
      if (data.headers?.location && !!isResendPasscode) {
        handleWarningStatus(data.headers?.location, setError);
      }
      return data;
    },
    onError: (error: any) => handleErrorStatus(error?.response?.data, setError),
    enabled: callGenerateOtpForContactData,
    ...commonQueryOptions
  });

  useEffect(() => {
    if (callGenerateOtpForContactData && !generateOtpForContactFetching) {
      setValue(VERIFICATION_VERIFIED_CONTACT(otpType), "");
      setValue(VERIFICATION_OTP(otpType), "");
      setValue(VERIFICATION_CONTACT(otpType), getValues(CUSTOMER_ITEM(otpType)));
      setValue(VERIFICATION_OTP_ERROR(otpType), generateOtpForContactError);
      setPostResult?.({ result: generateOtpForContactData });
      setCallGenerateOtpForContactData(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generateOtpForContactData, callGenerateOtpForContactData, generateOtpForContactFetching]);

  return { generateOtpForContactFetching };
};

export const useSendOTPProcess = ({
  otpType,
  setPostResult,
  flowId,
  currentState,
  setCurrentState
}: IFirstStepOTPSubmit) => {
  const { getValues, setError } = useFormContext();
  const navigateToStep = useNavigateToStep(flowId);
  const location = useLocation();

  // this process is documented here:
  // https://shawbrook.atlassian.net/wiki/spaces/SWE/pages/970588318/Front+End+architecture+-+Cut-over+bankfast+user+migration+journey
  // if the above process is changed, the documentation must be updated as well
  interface ErrorHandlingStrategies {
    [key: string]: (errorResponse: any) => void;
  }
  const errorHandlingStrategies: ErrorHandlingStrategies = {
    [EMAIL_DUPLICATE]: () => navigateToStep(StepDefaultIds.CHANGE_EMAIL),
    [MOBILE_DUPLICATE]: () => navigateToStep(StepDefaultIds.CHANGE_MOBILE),
    [SECOND_MOBILE_DUPLICATE]: () => navigateToStep(StepDefaultIds.CHANGE_MOBILE),
    [EMAIL_INVALID]: () =>
      handleFieldErrorStatus(
        setError,
        CUSTOMER_EMAIL,
        VALIDATION.userMigrationJourney.customerDetails.email.validEmailAddress
      ),
    [EMAIL_FORMAT_INCORRECT]: () =>
      handleFieldErrorStatus(
        setError,
        CUSTOMER_EMAIL,
        VALIDATION.userMigrationJourney.customerDetails.email.atSymbolRequired
      ),
    [MOBILE_FORMAT_INCORRECT]: () =>
      handleFieldErrorStatus(
        setError,
        CUSTOMER_MOBILE,
        VALIDATION.userMigrationJourney.customerDetails.mobile.mobileFormatIncorrect
      )
  };

  const handleErrorResponse = (error: any) => {
    const errorCode = error?.response?.data?.errors?.[0]?.code;
    const errorHandler = errorHandlingStrategies[errorCode];
    if (errorHandler) {
      errorHandler(error.response);
    } else {
      handleErrorStatus(error?.response?.data, setError);
    }
  };

  const [callGenerateOtpForContactData, setCallGenerateOtpForContactData] = useState(false);

  const {
    data: updateContactDetailsData,
    isLoading: updateContactDetailsLoading,
    isError: updateContactDetailsError
  } = useQuery({
    queryKey: [QUERY_KEYS.UPDATE_CONTACT_DETAILS_OTP],
    queryFn: async () =>
      updateContactDetails(transformForUpdateAPISend(otpType)(getValues(CUSTOMER) as Customer), true),
    onError: (error: any) => {
      handleErrorResponse(error);
    },
    enabled: currentState === ComponentState.PRESUBMIT,
    ...commonQueryOptions
  });

  useEffect(() => {
    // This function runs every time the location changes
    setCurrentState?.(ComponentState.INITIAL);
  }, [location, setCurrentState]);

  useEffect(() => {
    if (currentState === ComponentState.PRESUBMIT) {
      if (!updateContactDetailsLoading && updateContactDetailsData && isResultSuccessful(updateContactDetailsData)) {
        setCallGenerateOtpForContactData(true);
      } else if (updateContactDetailsError) {
        if (
          isResultValidError(updateContactDetailsData) &&
          getKnownErrorsFromResult(updateContactDetailsData)?.filter(x => x.code === errorCodeForExistingItem(otpType))
        ) {
          setCurrentState?.(ComponentState.INITIAL);
          navigateToStep(changeItemStep(otpType));
        } else {
          setPostResult?.({ result: updateContactDetailsData });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateContactDetailsData]);

  useGenerateOtpForContact({
    otpType,
    setPostResult,
    callGenerateOtpForContactData,
    setCallGenerateOtpForContactData
  });

  useEffect(() => {
    if (currentState === ComponentState.GO_TO_NEXT_STEP) {
      setCurrentState?.(ComponentState.INITIAL);
      navigateToStep(otpStep(otpType));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentState]);
};
