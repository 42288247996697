import { Text } from "@shawbrook/sds-component-library";
import { DATES } from "assets/strings/formats";
import strings from "assets/strings/strings.json";
import { ReactNode } from "react";
import rreplace from "react-string-replace";
import { getInstructionDisplayDate } from "utils";
import { IGetReinvestmentSuccessMessage } from "utils/getReinvestmentSuccessMessage/interfaces";

const getReinvestmentSuccessMessage = ({
  amounts,
  accountTitle,
  instructionsArg,
  accountMaturityDate,
  issueNumber,
  linkedAccountBank = ""
}: IGetReinvestmentSuccessMessage) => {
  const instructions = instructionsArg || [];
  const { toReinvest = 0, toWithdraw = 0 } = amounts || {};
  const instructionDate = getInstructionDisplayDate(
    [DATES.isoWithTimezoneShort, DATES.isoWithLongTimezone],
    accountMaturityDate
  );

  const { p1, p2 } = strings.reinvestment.modals.success;
  let p1Formatted: Iterable<ReactNode> | ReactNode | null = null;
  let p2Formatted: Iterable<ReactNode> | ReactNode | null = null;
  let text1;
  let text2;

  if (instructions.length === 1) {
    ({ text: text1 } = p1.full[instructions[0].type]);
    ({ text: text2 } = p2.full[instructions[0].type]);

    p1Formatted = text1
      .replace("{{accountTitle}}", `${accountTitle} ${strings.general.issueNumber} ${issueNumber}`)
      .replace("{{BANK}}", linkedAccountBank);

    p1Formatted = rreplace(p1Formatted as string, "{{action}}", (_, i) => (
      <Text as="span" textStyle="medium" css={{ fontWeight: "$medium" }} key={`action${instructions[0]}${i}`}>
        {strings.reinvestment.modals.success.p1.full[instructions[0].type].action}
      </Text>
    ));

    p2Formatted = rreplace(text2, "{{DATE}}", (_, i) => (
      <Text as="span" textStyle="medium" css={{ fontWeight: "$medium" }} key={`DATE${instructions[0]}${i}`}>
        {instructionDate}
      </Text>
    ));
  } else if (instructions.length > 1) {
    const { reinvestAction, withdrawAction } = p1.partial;
    ({ text: text1 } = p1.partial);
    ({ text: text2 } = p2.partial);

    p1Formatted = text1
      .replace("{{accountTitle}}", `${accountTitle} ${strings.general.issueNumber} ${issueNumber}`)
      .replace("{{bankName}}", linkedAccountBank);

    p1Formatted = rreplace(p1Formatted as string, "{{amountToReinvest}}", (_, i) => (
      <Text as="span" textStyle="medium" css={{ fontWeight: "$medium" }} key={`amountToReinvestPartial${i}`}>
        {`${reinvestAction} £${toReinvest.toLocaleString()}`}
      </Text>
    ));

    p1Formatted = rreplace(p1Formatted as string, "{{amountToWithdraw}}", (_, i) => (
      <Text as="span" textStyle="medium" css={{ fontWeight: "$medium" }} key={`amountToWithdrawPartial${i}`}>
        {`${withdrawAction} £${toWithdraw.toLocaleString()}`}
      </Text>
    ));

    p2Formatted = rreplace(text2, "{{DATE}}", (_, i) => (
      <Text as="span" textStyle="medium" css={{ fontWeight: "$medium" }} key={`DATEPartial${i}`}>
        {instructionDate}
      </Text>
    ));
  }

  p1Formatted = (
    <Text textStyle="medium" css={{ marginBottom: "$12" }}>
      {p1Formatted}
    </Text>
  );
  p2Formatted = <Text textStyle="medium">{p2Formatted}</Text>;

  return (
    <>
      {p1Formatted}
      {p2Formatted}
    </>
  );
};

export default getReinvestmentSuccessMessage;
