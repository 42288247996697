/* eslint-disable react/no-danger */
import { Text } from "@shawbrook/sds-component-library";
import classnames from "classnames";
import { sanitize } from "dompurify";
import { FC } from "react";

import { addedAttributes, allowedTags } from "config/secureMessageConfig";
import { useCleanMessagesHtml } from "hooks/useCleanMessagesHtml";
import { MessageDirection, MessageDirectionType } from "services/data/types";
import styles from "./MessageBubble.module.scss";

interface IMessageBubble {
  direction?: MessageDirectionType;
  sender?: string;
  message?: string;
  left?: boolean;
  right?: boolean;
  bgColor?: string;
  customClasses?: string;
}

const MessageBubble: FC<IMessageBubble> = ({
  direction,
  sender,
  left = true,
  right = false,
  bgColor = "#FFCCF3",
  customClasses = "",
  message = ""
}) => {
  const cx = classnames(`${styles.messageBubble__container} ${customClasses}`, {
    [styles.messageBubbleLeft]: left,
    [styles.messageBubbleRight]: right
  });

  const cleanMessage = useCleanMessagesHtml(message);
  const htmlSanatizedCollegue = sanitize(message, { ALLOWED_TAGS: allowedTags, ADD_ATTR: addedAttributes });

  return (
    <section className={cx} style={{ backgroundColor: bgColor }}>
      {sender && <Text fontWeight="bold">{sender}</Text>}
      {direction === MessageDirection.OUTBOUND && (
        <div
          className={styles.messageBubble__body}
          data-testid="BubbleMessage-testId"
          dangerouslySetInnerHTML={{ __html: htmlSanatizedCollegue }}
        />
      )}
      {direction === MessageDirection.INBOUND && (
        <div
          className={styles.messageBubble__body}
          data-testid="BubbleMessage-testId"
          dangerouslySetInnerHTML={{ __html: cleanMessage }}
        />
      )}
    </section>
  );
};
export default MessageBubble;
