import { FC } from "react";

import { Button, Flex, Heading, List, Modal, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { useNavigate } from "react-router-dom";
import maskString from "utils/maskString";
import { AccountDetails } from "../components/AccountDetails/AccountDetails";
import { PaymentInfo } from "../components/PaymentInfo/PaymentInfo";
import { IHasEa, IPostSubmitModal } from "./interface";
import styles from "./PostSubmitModals.module.scss";

interface ILinkedAccountDetails {
  title: string;
  p1: string;
  p2: string;
}

interface ITopUpInstructions {
  linkedAccountDetails: ILinkedAccountDetails;
  linkedAccountNumber: string;
}

export const TopUpInstructions: FC<ITopUpInstructions & IHasEa> = ({
  hasEa,
  linkedAccountDetails,
  linkedAccountNumber
}) => {
  const listItemClassName = hasEa ? styles.linkedAccount__hasEa : styles.linkedAccount__noEa;
  const maskedAccountNumber = maskString({ str: linkedAccountNumber });
  return (
    <>
      <Space gap={6}>
        <Flex direction="column">
          <Text textStyle="medium">{linkedAccountDetails.title}</Text>
        </Flex>
      </Space>
      <Space gap={6}>
        <Flex>
          <List type="ol" noMarker>
            <Space gap={1}>
              <List.Item className={listItemClassName}>
                <Text as="span" font="inter">
                  {linkedAccountDetails.p1.replace("{{maskedAccount}}", maskedAccountNumber)}
                </Text>
              </List.Item>
            </Space>
            <List.Item className={listItemClassName}>
              <Text as="span" font="inter">
                {linkedAccountDetails.p2.replace("{{maskedAccount}}", maskedAccountNumber)}
              </Text>
            </List.Item>
          </List>
        </Flex>
      </Space>
    </>
  );
};

const FundingModal: FC<IPostSubmitModal & IHasEa> = ({
  name,
  linkedAccountNumber,
  // Account id is not in use for now, will be required when we resolve the queries in the ticket SDD-3496
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  newAccountId,
  accountDetails,
  hasEa
}) => {
  const navigate = useNavigate();

  const handleBackToHome = () => navigate(links.landing.link);
  const handleMoveMoney = () => navigate(links.moveMoney.link);

  const title = strings.newAccount.fundingModal.verifiedTitle.replace("{{name}}", name);
  const linkedAccountDetails = hasEa ? strings.newAccount.fundingModal.hasEa : strings.newAccount.fundingModal.noEa;

  return (
    <Modal.Root open>
      <Modal.Content className={styles.modalStyles} onEscapeKeyDown={undefined} onPointerDownOutside={undefined}>
        <Heading level={2} fontWeight="semiBold" id="funding-modal-title" css={{ marginBottom: "$6" }}>
          {title}
        </Heading>
        <TopUpInstructions {...{ hasEa, linkedAccountDetails, linkedAccountNumber }} />
        <AccountDetails {...accountDetails} />
        <PaymentInfo />
        <Flex className={styles.buttonConatiner} gap="4" justify="end">
          <Button
            variant={hasEa ? "secondary" : "primary"}
            onClick={handleBackToHome}
            size="large"
            className={styles.buttonConatiner__buttons}
            css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
            {strings.general.backToHomeButton}
          </Button>
          {hasEa && (
            <Button
              onClick={handleMoveMoney}
              size="large"
              className={styles.buttonConatiner__buttons}
              css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
              {strings.navigation.moveMoney}
            </Button>
          )}
        </Flex>
      </Modal.Content>
    </Modal.Root>
  );
};

export { FundingModal };
