import {
  Alert,
  Button,
  Card as CardComponent,
  Flex,
  Icon,
  InfoIcon,
  Tag,
  Text,
  WarningIcon
} from "@shawbrook/sds-component-library";
import { FC, KeyboardEvent } from "react";
import { useNavigate } from "react-router-dom";

import { ArrowLeft } from "assets/images";
import { ERROR_CODES } from "assets/strings/api";
import { QUERY_PARAMS } from "assets/strings/queryParams";
import strings from "assets/strings/strings.json";
import classnames from "classnames";
import { AccountMenu } from "components/AccountMenu/AccountMenu";
import { AlertWithActionButton } from "components/AlertWithActionButton/AlertWithActionButton";
import { CardSection, ICardProps } from "components/Card/interfaces";
import { links } from "config/navLinks";
import useMaturityInstruction from "hooks/useMaturityInstruction";
import MoveMoneyInfo from "pages/MoveMoney/components/MoveMoneyInfo";
import { AccountStatus, InstructionType, LinkedAccountUnverifiedReason, ProductCategory } from "services/data/types";
import { formatBalance, formatSortCode, getInstructionsSetInfoText, getMaturityInfoText } from "utils";
import findInterestRate from "utils/findInterestRate";
import { formatTextWithPhone } from "utils/formatTextWithPhone/FormatTextWithPhone";
import getNonDepositableInfo from "utils/getNonDepositableInfo";
import getNonWithdrawableInfo from "utils/getNonWithdrawableInfo";
import { isMaturedAccountProductCategory } from "utils/isMaturedAccountProductCategory/isMaturedAccountProductCategory";
import styles from "./Card.module.scss";
import inlineStyles from "./Card.styles";

const Card: FC<ICardProps> = ({
  accountId,
  title,
  status,
  balance,
  maxBalance,
  interestRates,
  category,
  availableBalance,
  openToFunding,
  openToFundingReason,
  withdrawable,
  outboundWithdrawable,
  withdrawableReason,
  fromAccountWithdrawable,
  fromAccountWithdrawableReason,
  rate,
  single,
  accountDetails,
  endOfTerm,
  endOfTermForNonDisplay,
  fromISA,
  noticePeriodDays,
  section,
  issueNumber = "",
  accountNumber,
  bankName,
  personName,
  sortCode,
  viewAccount,
  transfer,
  frequency,
  blueBorder,
  greyBox,
  linkedAccount,
  accountType,
  unauthorisedAccountCredit,
  customClasses = "",
  productType,
  onClickHandler = () => {},
  role = "",
  preMaturing = false,
  maturityInstructionsSet = false
}) => {
  const navigate = useNavigate();

  const { maturityInstructionData } = useMaturityInstruction({
    sourceAccountIdArg: accountId,
    enabled: maturityInstructionsSet
  });

  const cx = classnames(`${styles.card__container} ${customClasses}`, {
    [styles["card--single"]]: single || accountDetails,
    [styles.blueBorder]: blueBorder
  });

  const inlineCx = {
    ...inlineStyles.container,
    ...((single || accountDetails) && inlineStyles.single),
    ...(blueBorder && inlineStyles.blueBorder)
  };

  const cxTag = classnames(styles.tag, {
    [styles["tag__with-personName"]]: personName
  });

  const cxAccount = classnames(styles.card__account, {
    [styles["card__account-without-availableBalance"]]: typeof availableBalance === "undefined"
  });

  let nextInterestRate = null;
  let nextBalanceBandFrom = null;

  if (
    section === CardSection.LANDING &&
    category &&
    category !== ProductCategory.FIXED_RATE &&
    category !== ProductCategory.FIXED_RATE_ISA &&
    interestRates &&
    availableBalance != null
  ) {
    const interestRateData = findInterestRate(interestRates, availableBalance);
    if (interestRateData) {
      nextInterestRate = interestRateData.nextInterestRate;
      nextBalanceBandFrom = interestRateData.nextBalanceBandFrom;
    }
    if (nextInterestRate && nextInterestRate === rate) {
      nextInterestRate = null;
    }
  }

  const isLinkedAccount = !!(linkedAccount && linkedAccount?.accountNumber);
  const isMaturedAccount = isMaturedAccountProductCategory(category);
  let nonWithdrawableInfo = null;
  let nonDepositableInfo = null;
  if (
    (section === CardSection.MOVE_MONEY_FROM ||
      (section === CardSection.LANDING && category === ProductCategory.FIXED_RATE && productType === "Business")) &&
    ((withdrawable === false && outboundWithdrawable === false) || linkedAccount?.verified === false)
  ) {
    nonWithdrawableInfo = getNonWithdrawableInfo({
      maturityDate: endOfTermForNonDisplay,
      reason: withdrawableReason,
      category,
      openToFundingReason,
      isLinkedAccVerified: linkedAccount?.verified
    });
  }
  if (
    section === CardSection.MOVE_MONEY_TO &&
    (openToFunding === false || fromAccountWithdrawable === false || linkedAccount?.verified === false)
  ) {
    nonDepositableInfo = getNonDepositableInfo({
      reason: openToFundingReason,
      fromAccountWithdrawableReason,
      category,
      isLinkedAccVerified: linkedAccount?.verified
    });
  }

  const tabIndex = role ? 0 : -1;

  const allowGreyBox =
    greyBox &&
    (rate != null ||
      frequency ||
      maxBalance !== undefined ||
      endOfTerm ||
      (!!noticePeriodDays && noticePeriodDays !== 0));

  const onKeyPressHandler = (e: KeyboardEvent) => {
    if (role && e.key === "Enter") {
      onClickHandler();
    }
  };

  const moveMoneyHandler = () => {
    if (balance === 0) {
      navigate(links.fundingInstructions.link.replace(":id", accountId || ""));
    } else {
      navigate(`${links.moveMoney.link}${!single ? `?${QUERY_PARAMS.FROM_ACCOUNT}=${accountId}` : ""}`);
    }
  };

  const accountHandler = () => {
    navigate(`/account/${accountId}`, { state: { single } });
  };

  const maturityHandler = () => {
    navigate(`${links.maturityInstruction.link}/select-account/${accountId}`);
  };

  const viewInstructionHandler = () => {
    if (
      maturityInstructionData?.instructions.length === 2 ||
      maturityInstructionData?.instructions[0].type === InstructionType.REINVEST_NEW
    ) {
      navigate(`${links.maturityInstruction.link}/review-submission/${accountId}?edit=true`);
    } else if (maturityInstructionData?.instructions[0].type === InstructionType.WITHDRAW) {
      navigate(`${links.maturityInstruction.link}/withdraw-full/${accountId}?edit=true`);
    }
  };

  const {
    availableLabel,
    viewAccountDetails,
    deposit,
    eotLabel,
    frequency: frequencyLabel,
    maturingButton,
    maxBalance: maxBalanceLabel,
    moveMoney,
    noticePeriod,
    noticePeriodDays: noticePeriodDaysLabel,
    rateLabel,
    viewInstructionButton,
    fullReinvestmentInfo,
    fullWithdrawalInfo,
    partialReinvestmentInfo,
    minBalanceRateInfo,
    endOfTermDateWarningLine1,
    endOfTermDateWarningLine2,
    fundingClosed,
    fundingClosedDescription,
    maturedAccountAlert,
    noticeSignPost
  } = strings.card;
  const { sendMessage } = strings.general;

  let instructionSetInfoText = "";
  if (maturityInstructionData?.instructions?.length === 1) {
    if (maturityInstructionData?.instructions[0].type === InstructionType.WITHDRAW)
      instructionSetInfoText = fullWithdrawalInfo;
    else if (maturityInstructionData?.instructions[0].type === InstructionType.REINVEST_NEW)
      instructionSetInfoText = fullReinvestmentInfo;
  } else if (maturityInstructionData?.instructions?.length === 2) {
    instructionSetInfoText = partialReinvestmentInfo;
  }

  const noticeBusinessAccountWithNonZeroBalance =
    productType === "Business" && category === ProductCategory.NOTICE && balance && balance > 0;

  const isBAF =
    balance === 0 &&
    linkedAccount?.verified === false &&
    linkedAccount?.unverifiedReason === LinkedAccountUnverifiedReason.BANK_CHECK_FAIL;
  const isClosedToFunding = balance === 0 && !openToFunding && openToFundingReason === ERROR_CODES.PEGA_OVERRIDE;
  const isUAC = !!balance && balance > 0 && unauthorisedAccountCredit === true;

  const fundAccountDisabled = isMaturedAccount || isBAF || isClosedToFunding || isUAC;

  const addMoneyButtonDisabled = isMaturedAccount || isClosedToFunding || isUAC;

  const maturityAlertMessage =
    (isMaturedAccount &&
      (category === ProductCategory.MATURED_FUNDS_ISA
        ? maturedAccountAlert.isaMessage
        : maturedAccountAlert.bondMessage)) ||
    "";

  return (
    <CardComponent
      data-testid="account-card"
      css={inlineCx}
      className={cx}
      role={role}
      tabIndex={tabIndex}
      onClick={onClickHandler}
      onKeyDown={onKeyPressHandler}>
      {title && (
        <Flex gap="2">
          {accountType === "Joint" && (section === CardSection.LANDING || section === CardSection.ACCOUNT) && (
            <Tag variant="info" isLarge css={{ padding: "$0-5 $1", fontFamily: "$display" }} className={cxTag}>
              <span className={styles["tag__line--bold"]}>{accountType}</span>
            </Tag>
          )}
          <Tag isLarge css={{ padding: "$0-5 $1", fontFamily: "$body" }} className={cxTag}>
            <span className={styles["tag__line--bold"]}>{title}</span>
            {` ${issueNumber}`}
          </Tag>
        </Flex>
      )}
      {personName && (
        <Text className={styles.card__personName} color="grey" fontWeight="bold">
          {personName}
        </Text>
      )}
      <div className={styles.balance__group}>
        {bankName && (
          <Text textStyle="medium" font="silka" as="span" css={{ fontWeight: "var(--fontWeights-medium)" }}>
            {bankName}
          </Text>
        )}
        {balance !== undefined && (
          <div className={styles.amount__block}>
            <Text textStyle="xlarge" as="span" font="silka">
              {formatBalance(balance).split(".")[0]}
            </Text>
            <Text textStyle="large" as="span" font="silka">
              .{formatBalance(balance).split(".")[1]}
            </Text>
          </div>
        )}
        {availableBalance !== undefined && (
          <Text css={inlineStyles.availableGroup} className={styles.available__group}>
            <Text as="span" color="grey" fontWeight="medium">
              {availableLabel}
            </Text>
            <Text as="span" color="grey" fontWeight="medium">
              {" "}
              {formatBalance(availableBalance)}
            </Text>
          </Text>
        )}
      </div>
      <div className={cxAccount}>
        <Text>
          <Text as="small" fontWeight="normal" css={inlineStyles.accountContent}>
            {accountNumber}
          </Text>
          <Text as="small" fontWeight="normal" css={inlineStyles.accountContent}>
            {formatSortCode(sortCode)}
          </Text>
        </Text>
      </div>
      {allowGreyBox && (
        <CardComponent variant="filled" css={inlineStyles.content} className={styles.card__content}>
          {rate != null && (
            <div className={styles.card__content_child}>
              <Text color="grey">{rateLabel}</Text>
              <Text fontWeight="bold">{rate.toFixed(2)}%</Text>
            </div>
          )}
          {frequency && (
            <div className={styles.card__content_child}>
              <Text color="grey">{frequencyLabel}</Text>
              <Text fontWeight="bold">{frequency}</Text>
            </div>
          )}
          {maxBalance !== undefined && (
            <div className={styles.card__content_child}>
              <Text color="grey">{!isMaturedAccount && maxBalanceLabel}</Text>
              <Text fontWeight="bold">{!isMaturedAccount && formatBalance(maxBalance)}</Text>
            </div>
          )}
          {!!endOfTerm && (
            <div className={styles.card__content_child}>
              <Text color="grey">{eotLabel}</Text>
              <Text fontWeight="bold">{endOfTerm}</Text>
            </div>
          )}

          {!!noticePeriodDays && noticePeriodDays !== 0 && (
            <div className={styles.card__content_child}>
              <Text color="grey">{noticePeriod}</Text>
              <Text fontWeight="bold">
                {noticePeriodDays}
                {noticePeriodDaysLabel}
              </Text>
            </div>
          )}
        </CardComponent>
      )}

      {(transfer || viewAccount) && (
        <div className={styles["card__button-group"]}>
          {transfer && !maturityInstructionsSet && (
            <Button
              variant="secondary"
              size="medium"
              onClick={moveMoneyHandler}
              role="link"
              css={inlineStyles.button}
              disabled={addMoneyButtonDisabled}>
              {balance === 0 ? deposit : moveMoney}
            </Button>
          )}
          {viewAccount && (
            <Button
              variant="tertiary"
              onClick={accountHandler}
              role="link"
              css={inlineStyles.buttonLink}
              className={styles["button--link"]}>
              <span>{viewAccountDetails}</span> <ArrowLeft />
            </Button>
          )}
          {section === CardSection.ACCOUNT && (
            <AccountMenu
              title={title}
              linkedAccount={linkedAccount}
              status={status}
              category={category}
              issueNumber={issueNumber}
              unauthorisedAccountCredit={unauthorisedAccountCredit}
            />
          )}
        </div>
      )}
      {nextInterestRate != null && !isClosedToFunding && !isUAC && !isMaturedAccount && (
        <Alert
          icon={<WarningIcon />}
          variant="warning"
          title={minBalanceRateInfo
            .replace("{{minBalance}}", formatBalance(nextBalanceBandFrom || 0))
            .replace(
              "{{outboundRate}}",
              nextInterestRate?.toLocaleString(undefined, { minimumFractionDigits: 2 }) || ""
            )}
          css={inlineStyles.rateAlertCard}
        />
      )}
      {maturityInstructionsSet && instructionSetInfoText && !fundAccountDisabled && !isMaturedAccount && (
        <div className={styles.warning}>
          <Alert
            variant="info"
            css={{ fontWeight: "semiBold" }}
            action={
              <Button
                variant="tertiary"
                onClick={viewInstructionHandler}
                role="link"
                css={inlineStyles.buttonLink}
                className={styles["button--link"]}>
                <span>{viewInstructionButton}</span> <ArrowLeft />
              </Button>
            }>
            <Flex className={styles.infoAlert}>
              <div>
                <InfoIcon />
              </div>
              <Text fontWeight="medium" font="silka">
                {getInstructionsSetInfoText(
                  endOfTermForNonDisplay || "",
                  instructionSetInfoText,
                  linkedAccount?.bankName
                )}
              </Text>
            </Flex>
          </Alert>
        </div>
      )}
      {preMaturing && !maturityInstructionsSet && !fundAccountDisabled && (
        <div className={styles.warning}>
          <Alert
            variant="warning"
            css={{ fontWeight: "semiBold" }}
            action={
              <Button
                variant="tertiary"
                onClick={maturityHandler}
                role="link"
                css={inlineStyles.buttonLink}
                className={styles["button--link"]}>
                <span>{maturingButton}</span> <ArrowLeft />
              </Button>
            }>
            <Flex className={styles.infoAlert}>
              <div>
                <WarningIcon />
              </div>
              <Text fontWeight="semiBold" as="small" font="silka">
                {getMaturityInfoText(endOfTermForNonDisplay || "")}
              </Text>
            </Flex>
          </Alert>
        </div>
      )}
      {noticeBusinessAccountWithNonZeroBalance && (
        <Alert
          variant="warning"
          css={inlineStyles.rateAlertCard}
          action={
            <Button
              className={styles.noticeAlertButton}
              iconRight
              onClick={() => navigate(links.inbox.link)}
              role="link"
              variant="plain">
              <Text font="silka" className={styles.noticeAlertButton__text}>
                {sendMessage}
              </Text>
              <Icon name="rightArrow" height="10" width="13" />
            </Button>
          }>
          <Flex className={styles.card__warning}>
            <div>
              <WarningIcon width="20" height="20" />
            </div>
            <Text fontWeight="bold">{noticeSignPost}</Text>
          </Flex>
        </Alert>
      )}
      {fromISA && <MoveMoneyInfo customStyles={{ marginTop: "$4" }} text={strings.moveMoney.outbondTransfer.text} />}
      {(section === CardSection.LANDING || section === CardSection.ACCOUNT) &&
        openToFunding === false &&
        openToFundingReason === ERROR_CODES.PEGA_OVERRIDE &&
        balance === 0 && (
          <MoveMoneyInfo
            customStyles={{ marginTop: "$4" }}
            heading={fundingClosed}
            text={formatTextWithPhone(fundingClosedDescription)}
            actionMode="compareSavingsAccounts"
            adjustActionTriggerStyle
          />
        )}
      {(section === CardSection.MOVE_MONEY_FROM ||
        (section === CardSection.LANDING && category === ProductCategory.FIXED_RATE && productType === "Business")) &&
        ((withdrawable === false && outboundWithdrawable === false) || linkedAccount?.verified === false) && (
          <MoveMoneyInfo
            customStyles={{ marginTop: "$4" }}
            heading={nonWithdrawableInfo?.heading}
            text={nonWithdrawableInfo?.text}
            variant={nonWithdrawableInfo?.variant}
            actionMode={nonWithdrawableInfo?.actionMode}
            bankName={linkedAccount?.bankName}
            isLinkedAccountMissing={!isLinkedAccount}
            accountId={accountId}
          />
        )}
      {section === CardSection.MOVE_MONEY_TO &&
        (openToFunding === false || fromAccountWithdrawable === false || linkedAccount?.verified === false) && (
          <MoveMoneyInfo
            customStyles={{ marginTop: "$4" }}
            heading={nonDepositableInfo?.heading}
            text={nonDepositableInfo?.text}
            variant={nonDepositableInfo?.variant}
            actionMode={nonDepositableInfo?.actionMode}
            bankName={linkedAccount?.bankName}
            isLinkedAccountMissing={!isLinkedAccount}
            accountId={accountId}
          />
        )}

      {(section === CardSection.LANDING || section === CardSection.ACCOUNT) &&
        category &&
        (category === ProductCategory.FIXED_RATE || category === ProductCategory.FIXED_RATE_ISA) &&
        status === AccountStatus.UNFUNDED &&
        !isMaturedAccount &&
        !isClosedToFunding &&
        !isUAC && (
          <div className={styles.warning}>
            <Alert
              title={endOfTermDateWarningLine1.replace("{{rate}}", rate ? rate.toFixed(2) : "0")}
              icon={<InfoIcon />}
              variant="info">
              <Text>{endOfTermDateWarningLine2}</Text>
            </Alert>
          </div>
        )}
      {(section === CardSection.LANDING || section === CardSection.ACCOUNT) && isMaturedAccount && (
        <AlertWithActionButton
          actionHandler={() => navigate(links.inbox.link)}
          buttonText={sendMessage}
          title={maturedAccountAlert.title}
          body={maturityAlertMessage}
        />
      )}
    </CardComponent>
  );
};

export default Card;
