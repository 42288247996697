import { Box, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { FC } from "react";
import { formatSortCode } from "utils";
import styles from "./LinkedAccountData.module.scss";

interface ILinkedAccountData {
  bank: string;
  name: string;
  acNumber: string;
  sortCode: string;
  hasLongCard?: boolean;
}

export const LinkedAccountData: FC<ILinkedAccountData> = ({ bank, name, acNumber, sortCode, hasLongCard }) => (
  <Box>
    <div className={styles.linkedAccountGroup}>
      {bank && (
        <dl>
          <dt>
            <Text as="span" color="grey">
              {strings.bankLabel}
            </Text>
          </dt>
          <dd>
            <Text as="span" fontWeight="bold" className={styles.linkedAccountGroup__breakWord}>
              {bank}
            </Text>
          </dd>
        </dl>
      )}
      {!hasLongCard && name && (
        <dl>
          <dt>
            <Text as="span" color="grey">
              {strings.general.nameLabel}
            </Text>
          </dt>
          <dd>
            <Text as="span" fontWeight="bold" className={styles.linkedAccountGroup__breakWord}>
              {name}
            </Text>
          </dd>
        </dl>
      )}
    </div>

    <div className={styles["linkedAccountGroup-row"]}>
      {hasLongCard && name && (
        <dl>
          <dt>
            <Text as="span" color="grey">
              {strings.general.nameLabel}
            </Text>
          </dt>
          <dd>
            <Text as="span" fontWeight="bold" className={styles.linkedAccountGroup__breakWord}>
              {name}
            </Text>
          </dd>
        </dl>
      )}
      {acNumber && (
        <dl>
          <dt>
            <Text as="span" color="grey">
              {strings.accountLabel}
            </Text>
          </dt>
          <dd>
            <Text as="span" fontWeight="bold">
              {acNumber}
            </Text>
          </dd>
        </dl>
      )}
      {sortCode && (
        <dl>
          <dt>
            <Text as="span" color="grey">
              {strings.sortCodeLabel}
            </Text>
          </dt>
          <dd>
            <Text as="span" fontWeight="bold">
              {formatSortCode(sortCode)}
            </Text>
          </dd>
        </dl>
      )}
    </div>
  </Box>
);
