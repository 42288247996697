import { useQuery } from "@tanstack/react-query";

import { ERROR_CODES } from "assets/strings/api";
import { useAccountProps } from "hooks/useAccount/interfaces";
import { getAccountInformation, getAccountInformationFromId } from "services/data/api";
import { AccountStatus, QueryKeys } from "services/data/types";
import { useGlobalContext } from "state/globalContext";

const useAccount = ({
  accountId,
  accountSummaryParams = {},
  skipRequest = false,
  statusFilter = [AccountStatus.CLOSED],
  openToFundingReasonFilter = [ERROR_CODES.PEGA_OVERRIDE]
}: useAccountProps = {}) => {
  const idProvided = accountId != null;
  const state = useGlobalContext();

  const {
    data: accountsData,
    isLoading: isAccountsLoading,
    isError: isAccountsError,
    error: accountsError
  } = useQuery({
    queryKey: [QueryKeys.ACCOUNT_SUMMARY, accountSummaryParams],
    queryFn: () => getAccountInformation(accountSummaryParams, state?.selectedProfile),
    enabled: !idProvided && !skipRequest
  });

  const {
    data: accountDetailData,
    isLoading: isAccountDetailLoading,
    isError: isAccountDetailError,
    error: accountError
  } = useQuery({
    queryKey: [QueryKeys.ACCOUNT_DETAIL, accountId],
    queryFn: () => getAccountInformationFromId(accountId),
    enabled: idProvided && !skipRequest
  });

  let accountsDataResult = accountsData;
  let accountDetailDataResult = accountDetailData;

  if (statusFilter) {
    if (
      idProvided &&
      isAccountDetailLoading === false &&
      accountDetailDataResult &&
      statusFilter.includes(accountDetailDataResult.status)
    ) {
      accountDetailDataResult = undefined;
    }

    if (isAccountsLoading === false && accountsDataResult) {
      accountsDataResult = accountsDataResult.filter(account => !statusFilter.includes(account.status));
    }
  }

  if (openToFundingReasonFilter) {
    if (
      idProvided &&
      isAccountDetailLoading === false &&
      accountDetailDataResult &&
      !accountDetailDataResult.openToFunding.enabled &&
      accountDetailDataResult.openToFunding.reason &&
      openToFundingReasonFilter.includes(accountDetailDataResult.openToFunding.reason) &&
      accountDetailDataResult.status === AccountStatus.UNFUNDED
    ) {
      accountDetailDataResult = undefined;
    }

    if (isAccountsLoading === false && accountsDataResult) {
      accountsDataResult = accountsDataResult.filter(
        account =>
          !(
            !account.openToFunding.enabled &&
            account.openToFunding.reason &&
            openToFundingReasonFilter.includes(account.openToFunding.reason) &&
            account.status === AccountStatus.UNFUNDED
          )
      );
    }
  }

  const isSingleAccount = accountsDataResult?.length === 1;
  let accountResolved: boolean = false;
  let accountErrored: boolean = false;

  if (skipRequest) {
    accountResolved = true;
  } else if (idProvided) {
    accountResolved = isAccountDetailLoading === false;
    accountErrored = isAccountDetailError || (isAccountDetailLoading === false && !accountDetailDataResult);
  } else {
    accountResolved = isAccountsLoading === false;
    accountErrored = isAccountsError;
  }

  return {
    isSingleAccount,
    accountResolved,
    isAccountDetailLoading,
    isAccountsLoading,
    accountErrored,
    accountsData: accountsDataResult,
    accountDetailData: accountDetailDataResult,
    accountsError,
    accountError
  };
};

export default useAccount;
