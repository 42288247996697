import { useState } from "react";
import { useMsal } from "@azure/msal-react";
import { useIdleTimer, EventsType } from "react-idle-timer";
import { resetCustomer } from "utils/trackerLib";
import { links } from "config/navLinks";
import { IDLE_PARAMS } from "assets/strings/api";
import { LOGGED_OUT_TYPE } from "types/types";

const useIdleModal = () => {
  const { accounts, instance } = useMsal();
  const isLoggedIn = accounts?.length > 0;
  const [isIdleModalOpen, setIsIdleModalOpen] = useState(false);
  const { timeout, promptBeforeIdle, throttle, events } = IDLE_PARAMS;

  const handleLogout = () => {
    setIsIdleModalOpen(false);
    localStorage.clear();
    sessionStorage.clear();
    resetCustomer();
    localStorage.setItem("loggedOut", LOGGED_OUT_TYPE.auto);
    instance.logoutRedirect({ postLogoutRedirectUri: links.loggedOut.link });
  };

  const onActive = () => {
    setIsIdleModalOpen(false);
  };

  const onIdle = () => {
    handleLogout();
  };

  const onPrompt = () => {
    setIsIdleModalOpen(true);
  };

  const { activate } = useIdleTimer({
    timeout: timeout as number,
    promptBeforeIdle: promptBeforeIdle as number,
    onActive,
    onIdle,
    onPrompt,
    crossTab: true,
    leaderElection: true,
    syncTimers: throttle as number,
    disabled: !isLoggedIn,
    events: events as Array<EventsType>
  });

  const handleStayLoggedIn = () => {
    activate();
  };

  return { isIdleModalOpen, handleLogout, handleStayLoggedIn };
};

export default useIdleModal;
