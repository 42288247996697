import { Button, Card, Divider, Flex, RightArrow, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { findCategoryInterestRateText } from "components/CompareAccountsCategory/CompareAccountsCategory";
import { PRODUCT_CARD_SECTION, ProductCard } from "components/ProductCard/ProductCard";
import { ProductDocuments } from "components/ProductDocuments/ProductDocuments";
import { ProductEstimatedBalance } from "components/ProductEstimatedBalance/ProductEstimatedBalance";
import { ProductFeatures } from "components/ProductFeatures/ProductFeatures";
import { ProductSummary } from "components/ProductSummary/ProductSummary";
import { ProsCons } from "components/ProsCons/ProsCons";
import { SecondaryHeader } from "components/SecondaryHeader/SecondaryHeader";
import { links } from "config/navLinks";
import useSrollToTop from "hooks/useScrollToTop";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ProductCategory } from "services/data/types";
import { useNewAccountContext } from "state/newAccountState";
import { INTEREST_FREQUENCY } from "state/reinvestmentState";
import { findInterestRate, findProductForTerm, isISAAccountCategory } from "utils";
import { findInterestRateForAmount } from "utils/findInterestRate";
import styles from "./AccountDetails.module.scss";
import { inlineStyles as accountDetailsStyles } from "./AccountDetails.styles";

const initialBalance = 1000;

export const AccountDetails = () => {
  useSrollToTop();
  const navigate = useNavigate();

  const { state } = useNewAccountContext();

  const { title, chooseAnotherAccount, apply } = strings.newAccount.accountDetails;

  useEffect(() => {
    if (!state.selectedProductSet) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, state]);

  const handleApply = () => {
    navigate(links.newAccountType.link);
  };

  const handleChooseAnotherAccount = () => {
    navigate(links.compareAccounts.link);
  };

  const product = state.selectedProductSet;
  const interestRateText = product ? findCategoryInterestRateText(product.category) : "";
  const issueNumber = product?.issueNumber ? `${strings.general.issueNumber} ${product?.issueNumber}` : null;

  const annualProduct = findProductForTerm(product?.products || [], INTEREST_FREQUENCY.annually);
  const annualInterestRateForInitialBalance =
    findInterestRateForAmount(annualProduct?.interestRates || [], initialBalance) || 0;

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={links.compareAccounts.link} />

      <section className={styles.page__content}>
        <section>
          <Card variant="raised">
            <ProductCard
              displayName={product?.title || ""}
              variableProductType={ProductCategory.FIXED_RATE !== product?.category}
              interestRateAnnual={findInterestRate(product?.products || [], INTEREST_FREQUENCY.annually)}
              interestRateMonthly={findInterestRate(product?.products || [], INTEREST_FREQUENCY.monthly)}
              maxInterestRate={product?.maxInterestRate || 0}
              maxBalanceSole={product?.soleMaxBalance || 0}
              maxBalanceJoint={product?.category === ProductCategory.EASY_ACCESS ? product?.jointMaxBalance : null}
              pdf={product?.kpiLink || ""}
              onSelect={() => handleApply()}
              interestRateText={interestRateText}
              section={PRODUCT_CARD_SECTION.NEW_ACCOUNT_DETAILS}
              issueNumber={product?.issueNumber}
            />
            <ProsCons productCategory={product?.category} />
            <Divider />
            <ProductFeatures
              productCategory={product?.category}
              noticePeriodDays={product?.noticePeriodDays || 0}
              maxBalanceJoint={product?.jointMaxBalance || 0}
              maxBalanceSole={product?.soleMaxBalance || 0}
              minBalance={product?.minBalance || 0}
            />
            <Divider />
            <ProductSummary
              productCategory={product?.category}
              minBalance={product?.lowestRateUpperBound || product?.minBalance || 0}
              noticePeriodDays={product?.noticePeriodDays || 0}
              lowestInterestRate={product?.lowestInterestRate || 0}
            />
            <ProductEstimatedBalance
              interestRate={annualInterestRateForInitialBalance}
              termNumber={annualProduct?.term?.number}
              termPeriodIndicator={annualProduct?.term?.periodIndicator}
              initialBalance={initialBalance}
            />
            <Space gap="3">
              <Divider />
            </Space>
            <Space gap="12">
              <ProductDocuments
                isISA={(product?.category && isISAAccountCategory(product?.category)) || false}
                productName={`${product?.title} ${issueNumber || ""}`}
                kpi={product?.kpiLink || ""}
              />
            </Space>
            <Flex justify="between" css={accountDetailsStyles.buttons}>
              <Button type="button" size="medium" variant="secondary" onClick={handleChooseAnotherAccount}>
                {chooseAnotherAccount}
              </Button>
              <Button iconRight type="button" size="medium" onClick={handleApply}>
                {apply}
                <RightArrow width="24" height="24" />
              </Button>
            </Flex>
          </Card>
        </section>
        <aside>
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};
