import { Box, Button, Card, Grid, Heading, RadioGroup, RadioItem, RightArrow } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { SelectedAccount } from "components/SelectedAccount/SelectedAccount";
import { links } from "config/navLinks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useNewAccountContext } from "state/newAccountState";
import { formatCurrentTaxYearRange } from "utils/formatCurrentTaxYearRange";
import { SecondaryHeader } from "../../../components/SecondaryHeader/SecondaryHeader";
import { getInitialValue } from "../utils";
import styles from "./TaxYearWaypoint.module.scss";

export const TaxYearWaypoint = () => {
  const navigate = useNavigate();
  const { headingText, questionText, options } = strings.newAccount.taxYearWaypoint;
  const { title } = strings.newAccount;
  const {
    state,
    state: { selectedProductSet, fullTransfer, editMode, editSelection },
    setState,
    clearState
  } = useNewAccountContext();
  const [selectedOption, setSelectedOption] = useState(
    getInitialValue(state, editMode, "toppedUpAccountInCY") as string
  );

  const isContinueDisabled = selectedOption === undefined;

  const formattedHeadingText = formatCurrentTaxYearRange(headingText);

  useEffect(() => {
    if (selectedProductSet === undefined) {
      navigate(links.compareAccounts.link);
    }
  }, [navigate, selectedProductSet]);

  const handleContinue = () => {
    if (editMode) {
      setState({
        editSelection: {
          ...editSelection,
          toppedUpAccountInCY: selectedOption,
          ...(selectedOption === "No" && editSelection?.fullTransfer === "Yes" ? { transferAmount: undefined } : {})
        }
      });
      navigate(
        selectedOption === "No" && editSelection?.fullTransfer === "Yes" ? links.notice.link : links.transferAmount.link
      );
    } else {
      setState({ toppedUpAccountInCY: selectedOption });
      navigate(selectedOption === "No" && fullTransfer === "Yes" ? links.notice.link : links.transferAmount.link);
    }
  };

  const handleSelect = (value: string) => {
    setSelectedOption(value);
  };

  return (
    <div className={styles.page}>
      <SecondaryHeader title={title} backLink={links.fullPartialTransfer.link} />

      <Grid className={styles.page__content}>
        <section>
          <Card variant="white" className={styles.page__cardContainer} width="auto">
            <Box>
              <Heading level={2}>{formattedHeadingText}</Heading>
            </Box>

            <Box>
              <RadioGroup
                orientation="vertical"
                labelText={{ main: questionText }}
                value={selectedOption}
                onValueChange={handleSelect}>
                {options.map(option => (
                  <RadioItem id={option.label} key={option.label} value={option.value} label={option.label} />
                ))}
              </RadioGroup>
            </Box>

            <Button
              className={styles.page__cardContainer__continueButton}
              iconRight
              disabled={isContinueDisabled}
              type="button"
              size="large"
              onClick={handleContinue}>
              {strings.general.continueButton}
              <RightArrow width="24" height="24" />
            </Button>
          </Card>
        </section>
        <aside>
          <SelectedAccount
            productName={selectedProductSet?.title!}
            issueNumber={selectedProductSet?.issueNumber!}
            clearState={clearState}
          />
          <AskAQuestion />
        </aside>
      </Grid>
    </div>
  );
};
