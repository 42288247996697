import { useQuery } from "@tanstack/react-query";

import { useRef, useState } from "react";
import { getAccountInformationFromId } from "services/data/api";
import { IAccountDetails, QueryKeys } from "services/data/types";
import { IUsePollNewAccountStatusProps } from "./interface";

const usePollNewAccountStatus = ({
  accountId,
  isValidDataReturned,
  maxTotalTime = 30000,
  pollingInterval = 1000
}: IUsePollNewAccountStatusProps) => {
  const [enabled, setEnabled] = useState<boolean>(false);
  const [completed, setCompleted] = useState<boolean>(false);
  const startTimeRef = useRef<number>(performance.now());

  const refetchInterval = (data: IAccountDetails | undefined): number | false => {
    if (!enabled) return false;
    const isValid = data && isValidDataReturned(data);
    if (!isValid) {
      if (performance.now() - startTimeRef.current > maxTotalTime) {
        setCompleted(true);
        return false;
      }
      return pollingInterval;
    }
    setCompleted(true);
    return false;
  };

  const triggerPolling = () => {
    startTimeRef.current = performance.now();

    setEnabled(true);
  };

  const { data, isLoading, isError, error } = useQuery({
    queryKey: [QueryKeys.ACCOUNT_DETAIL, accountId],
    queryFn: () => getAccountInformationFromId(accountId),
    enabled,
    refetchInterval
  });

  return {
    data,
    isLoading,
    isError,
    error,
    completed,
    triggerPolling
  };
};

export { usePollNewAccountStatus };
