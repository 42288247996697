const QUERY_PARAMS: Readonly<Record<string, string>> = Object.freeze({
  FROM_ACCOUNT: "from-account",
  CUSTOMER_NUMBER: "customerNumber",
  NO_STRICT_AUTH: "noStrictAuth"
});

const HASH_PARAMS: Readonly<Record<string, string>> = Object.freeze({
  ID_TOKEN: "id_token"
});

export { QUERY_PARAMS, HASH_PARAMS };
