import strings from "assets/strings/strings.json";
import { convertTo12HTime, getPhoneFormat } from "utils";

export const formatTextWithPhoneHours = (text: string) => {
  const workStart = +(process.env.REACT_APP_WORK_START || 0);
  const workEnd = +(process.env.REACT_APP_WORK_END || 0);

  return text
    .replace("{{HELP_CENTER_NUMBER}}", getPhoneFormat(strings.general.helpCenterNumber))
    .replace("{{START_TIME}}", convertTo12HTime(workStart))
    .replace("{{END_TIME}}", convertTo12HTime(workEnd));
};
