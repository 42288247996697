import { createContext, useContext } from "react";
import { IGlobalContextAction } from "types/interfaces";
import { GlobalContextType } from "types/types";

export const initialState: GlobalContextType = {
  selectedProfile: undefined,
  availableProfiles: [],
  customerId: "",
  hasAccounts: true
};

const GlobalContext = createContext(initialState);

export const GlobalContextProvider = GlobalContext.Provider;

export const useGlobalContext = () => useContext(GlobalContext);

const GlobalDispatchContext = createContext<React.Dispatch<IGlobalContextAction>>(() => {});

export const GlobalDispatchProvider = GlobalDispatchContext.Provider;

export const useGlobalDispatch = () => {
  const context = useContext(GlobalDispatchContext);
  if (context === undefined) {
    throw new Error("GlobalDispatchContext must be used within a GlobalContextProvider");
  }
  return context;
};
