import { Box, Button, Card, Flex, Heading, Loader, Tag, Text } from "@shawbrook/sds-component-library";
import { FC } from "react";
import CurrencyInput from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/src/components/CurrencyInputProps";
import { useParams } from "react-router-dom";

import { ArrowLeft, ArrowRight } from "assets/images";
import { CURRENCIES } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import classnames from "classnames";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import { IconButton } from "components/IconButton/IconButton";
import { ReinvestmentAccounts } from "components/ReinvestmentAccounts/ReinvestmentAccounts";
import WarningModal from "components/WarningModal/WarningModal";
import useAmountToReinvest from "hooks/useAmountToReinvest";
import useCurrencyInput from "hooks/useCurrencyInput";
import usePopulateProductSet from "hooks/usePopulateProductSet";
import useSrollToTop from "hooks/useScrollToTop";
import { InstructionType } from "services/data/types";
import { useReinvestmentContext } from "state/reinvestmentState";
import { formatBalance } from "utils";
import { headerStyles } from "../HeaderStyles.styles";
import styles from "./AmountToReinvest.module.scss";
import inlineStyles from "./AmountToReinvest.styles";

export const AmountToReinvest: FC = () => {
  const { state } = useReinvestmentContext();
  useSrollToTop();
  const params = useParams();
  const accountId = params.id || "";

  let initialAmountToReinvest =
    state[accountId]?.instructions?.find(el => el.type === InstructionType.REINVEST_NEW)?.amount?.amount?.toString() ||
    "";
  let initialAmountToWithdraw =
    state[accountId]?.instructions?.find(el => el.type === InstructionType.WITHDRAW)?.amount?.amount?.toString() || "";
  if (initialAmountToReinvest && !initialAmountToWithdraw) {
    initialAmountToWithdraw = "0";
  }
  if (!initialAmountToReinvest && initialAmountToWithdraw) {
    initialAmountToReinvest = "0";
  }

  const { noSelectedProductSet, isAvailableProductsError } = usePopulateProductSet({
    accountId,
    condition: !state[accountId]?.selectedProductSet
  });

  const {
    amount: amountToReinvest,
    setAmount: setAmountToReinvest,
    handleChange: handleReinvestAmountChange,
    onInputRefChange: reinvestRefCallback
  } = useCurrencyInput(initialAmountToReinvest);
  const {
    amount: amountToWithdraw,
    setAmount: setAmountToWithdraw,
    handleChange: handleWithdrawAmountChange,
    onInputRefChange: withdrawRefCallback
  } = useCurrencyInput(initialAmountToWithdraw);

  const {
    productDisplayName,
    productIssueNumber,
    accountDisplayName,
    accountIssueNumber,
    accountBalance,
    productMaxBalance,
    title,
    question,
    description,
    amountToReinvestLabel,
    amountToWithdrawLabel,
    maxBalanceError,
    continueButton,
    switchTitle,
    switchText,
    switchAccept,
    switchDecline,
    handleEdit,
    handleContinue,
    handleCloseWarningModal,
    handleFlowSwitch,
    handleBackButton,
    isMaxBalanceError,
    shouldDisableContinue,
    showWarningModel
  } = useAmountToReinvest({
    accountId,
    setAmountToReinvest,
    setAmountToWithdraw,
    amountToReinvest,
    amountToWithdraw
  });

  if (isAvailableProductsError) {
    return <ErrorModal open allPages />;
  }

  const inputContainerCx = classnames(styles.page__inputsContainer, {
    [styles["page__inputsContainer--error"]]: isMaxBalanceError
  });

  return (
    <div className={styles.amountToReinvest}>
      <WarningModal
        handleClose={handleCloseWarningModal}
        handleConfirm={handleFlowSwitch}
        open={showWarningModel || false}
        title={switchTitle}
        text={switchText}
        accept={switchAccept}
        decline={switchDecline}
      />
      <Box as="header" css={headerStyles.header}>
        <IconButton onClick={handleBackButton} icon={<ArrowLeft />} text={strings.general.back} />
        <Heading color="white">{title}</Heading>
      </Box>
      <section className={styles.page}>
        <Card css={{ width: "100%", height: "fit-content" }}>
          {noSelectedProductSet && (
            <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
          )}
          {!noSelectedProductSet && (
            <>
              <Heading level={2}>{question}</Heading>
              <Text textStyle="body" fontWeight="medium" css={{ mt: "var(--space-3)", mb: "var(--space-8)" }}>
                {description}
              </Text>
              <Tag isLarge>
                <Text as="span" fontWeight="semiBold">
                  {accountDisplayName}
                </Text>
                {` `}
                {accountIssueNumber}
              </Tag>
              <Text textStyle="xlarge" css={{ mb: "1.375rem" }}>
                {formatBalance(accountBalance || 0)}
              </Text>
              <Flex css={inlineStyles.inputsContainer} gap={5}>
                <Flex css={{ width: "100%", "@md": { width: "100%" } }} className={inputContainerCx} direction="column">
                  <Text css={{ fontSize: "var(--fontSizes-4)" }} id="amountToReinvestLabel">
                    {amountToReinvestLabel}
                  </Text>
                  {isMaxBalanceError && (
                    <Text
                      fontWeight="normal"
                      css={inlineStyles.inputError}
                      className={styles.page__inputsContainer__error}>
                      {maxBalanceError.replace("{{MAX_BALANCE}}", formatBalance(productMaxBalance || 0))}
                    </Text>
                  )}
                  <div>
                    <CurrencyInput
                      value={amountToReinvest}
                      allowNegativeValue={false}
                      decimalsLimit={2}
                      name="amountToReinvest"
                      aria-labelledby="amountToReinvestLabel"
                      onValueChange={(value?: string, _?: string, values?: CurrencyInputOnChangeValues) =>
                        handleReinvestAmountChange(
                          value || "",
                          accountBalance || 0,
                          setAmountToWithdraw,
                          values?.formatted
                        )
                      }
                      prefix={CURRENCIES.GBP}
                      ref={reinvestRefCallback}
                      autoComplete="off"
                    />
                  </div>
                </Flex>
                <Flex css={{ width: "100%" }} className={styles.page__inputsContainer} direction="column">
                  <Text css={{ fontSize: "var(--fontSizes-4)" }} id="amountToWithdrawLabel">
                    {amountToWithdrawLabel}
                  </Text>
                  <div>
                    <CurrencyInput
                      value={amountToWithdraw}
                      allowNegativeValue={false}
                      decimalsLimit={2}
                      name="amountToWithdraw"
                      aria-labelledby="amountToWithdrawLabel"
                      onValueChange={(value?: string, _?: string, values?: CurrencyInputOnChangeValues) =>
                        handleWithdrawAmountChange(
                          value || "",
                          accountBalance || 0,
                          setAmountToReinvest,
                          values?.formatted
                        )
                      }
                      prefix={CURRENCIES.GBP}
                      ref={withdrawRefCallback}
                      autoComplete="off"
                    />
                  </div>
                </Flex>
              </Flex>
              <div className={styles.page__continueButton}>
                <Button
                  css={{ width: "100%", "@md": { width: "auto" } }}
                  iconRight
                  type="button"
                  size="medium"
                  onClick={handleContinue}
                  disabled={shouldDisableContinue}>
                  {continueButton}
                  <ArrowRight />
                </Button>
              </div>
            </>
          )}
        </Card>
        <aside>
          <ReinvestmentAccounts
            accountDisplayName={accountDisplayName}
            accountIssueNumber={accountIssueNumber}
            accountBalance={accountBalance || 0}
            productDisplayName={productDisplayName}
            productIssueNumber={productIssueNumber}
            handleEdit={handleEdit}
          />
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};

export default AmountToReinvest;
