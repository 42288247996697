import Steps from "pages/BankfastUserMigration/StepIds";
import { IAllBankfastUserMigrationStepTypes } from "pages/BankfastUserMigration/Steps";
import StepMobileIds from "pages/BankfastUserMigrationMobile/StepIds";
import { ICustomerAccessibility, ICustomerCommPreferences, ICustomerMarketing } from "services/data/types";
import {
  APICustomer,
  CommsPreferences,
  Customer,
  Groups,
  GroupsSteps,
  IStep,
  PageSequence
} from "services/data/types/bankfastUserMigration/";

const groupsSteps: GroupsSteps = {
  email: [Steps.EMAIL, Steps.EMAIL_OTP, Steps.CHANGE_EMAIL],
  mobile: [StepMobileIds.MOBILE, StepMobileIds.MOBILE_OTP, StepMobileIds.CHANGE_MOBILE],
  communication: [StepMobileIds.GDPR_AND_A11Y]
};

export const calculatePercentage = (
  steps: IStep<IAllBankfastUserMigrationStepTypes>[],
  priorities: PageSequence,
  commsPreferences: CommsPreferences,
  currentStepId: IAllBankfastUserMigrationStepTypes
) => {
  let progressValue = 0;

  const modifiedSteps = Object.entries(commsPreferences).reduce((currentSteps, [preference, preferenceValue]) => {
    if (preferenceValue && groupsSteps[preference as Groups]) {
      const currentGroupSteps: Array<IAllBankfastUserMigrationStepTypes> = groupsSteps[preference as Groups];

      return currentSteps.filter(step => !currentGroupSteps.includes(step.id));
    }

    return currentSteps;
  }, steps);

  const modifiedPriorities = Object.entries(commsPreferences).reduce(
    (currentPriorities, [preference, preferenceValue]) => {
      if (preferenceValue && groupsSteps[preference as Groups]) {
        return currentPriorities.filter(priority => priority !== preference);
      }

      return currentPriorities;
    },
    priorities
  );

  if (modifiedSteps.find(step => step.id === currentStepId)) {
    let currentGroup: Groups | null = null;

    Object.keys(groupsSteps).forEach(group => {
      if (!currentGroup && groupsSteps[group as Groups].includes(currentStepId)) {
        currentGroup = group as Groups;
      }
    });

    if (currentGroup && modifiedPriorities.includes(currentGroup)) {
      progressValue = Math.floor((modifiedPriorities.indexOf(currentGroup) / modifiedPriorities.length) * 100);
    }
  }

  return progressValue;
};

export const transformEmailForAPISend = (customer: Customer): APICustomer =>
  ({
    contactDetails: {
      email: customer.contactDetails.email,
      emailVerified: true
    }
  } as APICustomer);

export const transformMobileForAPISend = (customer: Customer): APICustomer =>
  ({
    contactDetails: {
      mobilePhoneNumber: customer.contactDetails.mobilePhoneNumber,
      mobilePhoneNumberVerified: true
    }
  } as APICustomer);

export const getGDPRAndA11yFinalOptions = (
  marketing: Partial<ICustomerMarketing> = {},
  accessibility: Partial<ICustomerAccessibility> = {}
) => {
  let finalOptions = {} as ICustomerCommPreferences;

  const allMarketingKeys: Array<keyof ICustomerMarketing> = ["sms", "email", "phoneCall"];
  const allAccessibilityKeys: Array<keyof ICustomerAccessibility> = [
    "translator",
    "relayUKService",
    "writingOnly",
    "largePrint",
    "braille"
  ];

  allMarketingKeys.forEach(marketingKey => {
    finalOptions = {
      ...finalOptions,
      marketing: {
        ...finalOptions.marketing,
        [marketingKey]: marketing[marketingKey] ?? false
      }
    };
  });

  allAccessibilityKeys.forEach(accessibilityKey => {
    finalOptions = {
      ...finalOptions,
      accessibility: {
        ...finalOptions.accessibility,
        [accessibilityKey]: accessibility[accessibilityKey] ?? false
      }
    };
  });

  return finalOptions;
};
