import { ITransferValidationText } from "assets/interfaces";
import { ERROR_CODES } from "assets/strings/api";
import strings from "assets/strings/strings.json";

const serverValidationTexts: Array<ITransferValidationText> = [
  {
    code: [
      ERROR_CODES.APPLICATION_MISSING,
      ERROR_CODES.PERSONAL_DETAILS_MISSING,
      ERROR_CODES.CONTACT_DETAILS_MISSING,
      ERROR_CODES.EMPLOYMENT_DETAILS_MISSING,
      ERROR_CODES.CONFIRMATIONS_MISSING,
      ERROR_CODES.WHAT_YOU_WILL_NEED_MISSING,
      ERROR_CODES.ELIGIBILITY_MISSING,
      ERROR_CODES.PAYMENT_MISSING,
      ERROR_CODES.MISSING_SOURCE_ACCOUNT_ID,
      ERROR_CODES.MISSING_DESTINATION_ACCOUNT_ID,
      ERROR_CODES.ITEM_MISSING,
      ERROR_CODES.INVALID_ACCOUNT,
      ERROR_CODES.ACCOUNT_ID_REQUIRED,
      ERROR_CODES.ACCOUNT_NOT_FOUND
    ],
    message: strings.moveMoney.defaultErrorMessage
  },
  {
    code: [ERROR_CODES.PRODUCT_MISSING, ERROR_CODES.PRODUCT_ID_MISSING],
    message:
      "Sorry, we're having a technical problem. Try again in a few minutes. If the problem continues, call us on {{HELP_CENTER_NUMBER}} to complete your request."
  },
  {
    code: [ERROR_CODES.SOURCE_ACCOUNT_NOT_FOUND, ERROR_CODES.DESTINATION_ACCOUNT_NOT_FOUND],
    message:
      "Sorry, we cannot display this account right now because we're having a technical problem. Send us a message or call us on {{HELP_CENTER_NUMBER}} if you want to move money or discuss this account."
  },
  {
    code: [ERROR_CODES.PAYMENT_ID_REQUIRED, ERROR_CODES.PAYMENT_NOT_FOUND],
    message:
      "Sorry, we're having a technical problem and can't display your transaction details. Check again later. Send us a message or call us on {{HELP_CENTER_NUMBER}} if the problem persists."
  },
  {
    code: ERROR_CODES.GENERIC_UNKNOWN_LOGIN_ERROR,
    message:
      "Sorry, we're having a technical problem and cannot log you in right now. Try again in a few minutes. If the problem continues, call us on {{HELP_CENTER_NUMBER}}."
  },
  {
    code: ERROR_CODES.ACCOUNT_TYPE_MISSING,
    message: "Select an account type to continue."
  },
  {
    code: ERROR_CODES.EMAIL_INVALID_EXTENSION,
    message: "Enter a valid email address."
  },
  {
    code: ERROR_CODES.EMAIL_FORMAT_INCORRECT,
    message: "Your email address must include the @ symbol."
  },
  {
    code: ERROR_CODES.EMAIL_MISSING,
    message: "Enter your email address to continue."
  },
  {
    code: ERROR_CODES.PASSCODE_BLANK,
    message:
      "Enter your passcode to continue. If you have not received an email from us yet, check your junk mail or wait a few minutes for it to arrive. Make sure that the email address you provided is correct. You can edit it if you need to."
  },
  {
    code: ERROR_CODES.PASSCODE_RESEND_WARNING,
    message: "You can resend the passcode up to 5 times in an hour. You have 2 attempts remaining."
  },
  {
    code: ERROR_CODES.PASSCODE_RESEND_TIMEOUT,
    message: "You can resend the passcode up to 5 times in an hour. Try again in {{number}} minutes."
  },
  {
    code: ERROR_CODES.AMOUNT_INVALID,
    message: "Enter an amount to continue."
  },
  {
    code: ERROR_CODES.AMOUNT_GREATER_CURRENT_BALANCE,
    message: "Enter an amount less than or equal to your current balance."
  },
  {
    code: ERROR_CODES.NOT_WITHDRAWABLE_MULTIPLE_ACCOUNTS,
    message:
      "Your accounts may not have reached maturity, or transfers might not be possible at the moment. View your accounts for more details."
  },
  {
    code: ERROR_CODES.MOVE_MONEY_FROM_NOTICE,
    message: "This date is estimated using your notice period, excluding weekends and bank holidays."
  },
  {
    code: ERROR_CODES.INVALID_AVAILABLE_BALANCE,
    message: "You need to add money to your account before you can transfer funds."
  },
  {
    code: ERROR_CODES.PRODUCT_TYPE_INVALID,
    message: "This account must reach maturity before you can move money from it."
  },
  {
    code: ERROR_CODES.ACCOUNT_NOT_FUNDABLE,
    message:
      "We removed this account from sale because a new rate is available. You can open a new account if you'd like to deposit more money."
  },
  {
    code: ERROR_CODES.ACTIVE_SOURCE_ACCOUNT,
    message: "You cannot withdraw money from this account because it's closed."
  },
  {
    code: ERROR_CODES.ACTIVE_DESTINATION_ACCOUNT,
    message: "You cannot add money to this account because it's closed."
  },
  {
    code: ERROR_CODES.ZERO_BALANCE,
    message: strings.moveMoney.nonWithdrawable.zeroBalanceHeading
  },
  {
    code: ERROR_CODES.AMOUNT_MUST_BE_GREATER_THAN_CUSTOMER_MIN_LIMIT,
    message: "Enter at least £{{minWithdrawLimit}}. If you want to withdraw less than this, please contact us."
  }
];

export { serverValidationTexts };
