import { Box, Button, DownloadIcon, Flex, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import { FC } from "react";
import styles from "./ProductDocumentsItem.module.scss";
import { inlineStyles } from "./ProductDocumentsItem.styles";

interface IProductDocumentsItem {
  title: string;
  content: string;
  link: string;
}

export const ProductDocumentsItem: FC<IProductDocumentsItem> = ({ title, content, link }) => {
  const { download } = strings.general;
  return (
    <Flex css={inlineStyles.item} gap="3">
      <Box>
        <Space gap="1">
          <Text font="silka" textStyle="body">
            {title}
          </Text>
        </Space>
        <Text textStyle="body" color="grey">
          {content}
        </Text>
      </Box>
      <Button
        as="a"
        iconRight
        variant="plain"
        target="_blank"
        className={styles.item__download}
        css={inlineStyles.item.download}
        href={link || links.documentMissing.link}>
        {download}
        <DownloadIcon />
      </Button>
    </Flex>
  );
};
