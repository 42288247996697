import { Alert, ErrorIcon, Text, WarningIcon } from "@shawbrook/sds-component-library";
import purify from "dompurify";
import { TServerResponseAlertProps } from "./types";

export const ServerResponseAlert = ({ errors, variant }: TServerResponseAlertProps) => {
  const { type, message } = errors;
  const Icon = variant === "error" ? ErrorIcon : WarningIcon;

  return (
    <Alert icon={<Icon />} variant={variant}>
      {type && <Text>{type}</Text>}
      <Text dangerouslySetInnerHTML={{ __html: purify.sanitize(message) }} />
    </Alert>
  );
};

export const ApiAlerts = ({ errors }: any) => {
  if (!errors.root) return null;

  const { serverError, serverWarning } = errors.root;

  if (serverError) {
    return <ServerResponseAlert errors={serverError} variant="error" />;
  }

  if (serverWarning) {
    return <ServerResponseAlert errors={serverWarning} variant="warning" />;
  }

  return null;
};
