import { Box, Flex, Heading, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { PRODUCT_CATEGORY_KEYS } from "config/productCategoryKeys";
import { FC } from "react";
import { ProductCategory } from "services/data/types";
import { formatProductDetailsContent } from "utils/fromatProductDetailsContent/formatProductDetailsContent";

interface IProductFeatures {
  productCategory?: ProductCategory;
  noticePeriodDays?: number;
  maxBalanceSole?: number;
  maxBalanceJoint?: number;
  minBalance?: number;
}

interface ICategoryProductFeatures {
  [key: string]: { title: string; content: string }[];
}

export const ProductFeatures: FC<IProductFeatures> = ({
  productCategory,
  noticePeriodDays,
  maxBalanceJoint,
  maxBalanceSole,
  minBalance
}) => {
  if (!productCategory) return null;
  const { title } = strings.featuresAndBenefits;
  const featuresStrings = strings.featuresAndBenefits.categoryFeatures as unknown as ICategoryProductFeatures;
  const categoryFeatures = productCategory && featuresStrings[PRODUCT_CATEGORY_KEYS[productCategory]];
  if (!categoryFeatures) return null;
  return (
    <>
      <Space gap="5">
        <Heading as="h3" level={3}>
          {title}
        </Heading>
      </Space>
      <Flex direction="column" gap="8">
        {categoryFeatures.map(({ title: featureTitle, content }) => {
          const titleToDisplay = featureTitle.replace("{{NOTICE_PERIOD_DAYS}}", noticePeriodDays?.toString() || "");
          const contentToDisplay = formatProductDetailsContent({
            content,
            noticePeriodDays,
            maxBalanceJoint,
            maxBalanceSole,
            minBalance
          });
          return (
            <Box key={titleToDisplay}>
              <Space gap="2">
                <Heading as="h4" level={4}>
                  {titleToDisplay}
                </Heading>
              </Space>
              <Text textStyle="body" css={{ whiteSpace: "break-spaces" }}>
                {contentToDisplay}
              </Text>
            </Box>
          );
        })}
      </Flex>
    </>
  );
};
