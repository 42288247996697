export default {
  wrapper: { margin: "20px auto", p: "0 20px" },
  header: {
    bg: "$shawbrookCharcoal",
    pos: "relative",
    pb: "12px",
    "&::before, &::after": {
      content: "",
      bg: "$shawbrookCharcoal",
      width: "2000px",
      height: "100%",
      d: "block",
      pos: "absolute",
      top: 0,
      bottom: 0,
      right: "100%"
    },
    "&::after": {
      left: "100%"
    }
  },
  grid: { gridTemplateColumns: "1fr", gap: "20px", "@lg": { gridTemplateColumns: "2fr 1fr", gap: "30px" } },
  mainWrapper: { overflow: "hidden", "@lg": { pb: "115px" } },
  alert: { mb: "30px" }
};
