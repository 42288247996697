import { Button, ChatIcon, Heading, Text } from "@shawbrook/sds-component-library";
import { useNavigate } from "react-router-dom";

import strings from "assets/strings/strings.json";
import inlineStyles from "components/AskAQuestion/AskAQuestion.style";
import { links } from "config/navLinks";
import styles from "./AskAQuestion.module.scss";

export const AskAQuestion = ({
  text = strings.general.ask,
  link = links.inbox.link
}: {
  text?: string;
  link?: string;
}) => {
  const navigate = useNavigate();

  const redirectToMessages = () => {
    navigate(link);
  };

  return (
    <div>
      <Heading as="h3" level="6" className={styles.needHelp__header} fontWeight="semiBold" css={{ fontSize: "$3" }}>
        {strings.help}
      </Heading>
      <div className={styles.needHelp__body}>
        <Button variant="tertiary" onClick={redirectToMessages} iconLeft css={inlineStyles.button}>
          <ChatIcon width="24" height="24" />
          <Text as="ins" font="silka" color="secondary" css={inlineStyles.buttonText}>
            {text}
          </Text>
        </Button>
      </div>
    </div>
  );
};

export default AskAQuestion;
