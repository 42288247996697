/* eslint-disable no-param-reassign */
import { addHook, removeHook, sanitize } from "dompurify";

const tagSpecificAtributesWhitelist: { [key: string]: string[] } = {
  div: ["class", "style", "id", "data-testid"],
  fieldset: ["class"],
  input: ["class", "type", "name", "size", "id", "value", "alt", "disabled", "required", "maxlength", "checked"],
  label: ["class", "for", "id"],
  p: [],
  strong: [],
  span: ["class"],
  textarea: ["class", "name", "id", "cols", "rows", "maxlength", "disabled", "required"],
  "&nbsp": []
};

export const useCleanMessagesHtml = (htmlMessage: string) => {
  addHook("uponSanitizeAttribute", (node, data) => {
    const attrs = tagSpecificAtributesWhitelist[node.tagName.toLocaleLowerCase()];
    if (attrs) {
      if (attrs.includes(data.attrName)) {
        data.forceKeepAttr = true;
      } else {
        data.forceKeepAttr = false;
        data.keepAttr = false;
      }
    }
  });

  const cleanMessage = sanitize(htmlMessage, {
    ALLOWED_TAGS: ["div", "fieldset", "input", "label", "p", "strong", "span", "textarea", "&nbsp"]
  });

  removeHook("uponSanitizeAttribute");

  return cleanMessage;
};
