/* eslint-disable react/no-unstable-nested-components */
import { Box, Button, Heading, RightArrow, Space, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { config } from "config";
import FEATURE_FLAGS from "config/featureFlags";
import { links } from "config/navLinks";
import useSrollToTop from "hooks/useScrollToTop";
import { FC } from "react";
import { useNavigate } from "react-router-dom";
import styles from "./NewAccountPrompt.module.scss";

interface INewAccountPrompt {
  hasAccounts?: boolean;
}

export const NewAccountPrompt: FC<INewAccountPrompt> = ({ hasAccounts = true }) => {
  const navigate = useNavigate();
  const { newAccountTitle, newAccountDescription, firstAccountTitle, newAccountLink } = strings.landing;
  useSrollToTop();
  const { hideNewAccount } = FEATURE_FLAGS;

  const handleCompareAccounts = () => {
    if (hideNewAccount) {
      window.open(config.savingsCompareAccLink, "_blank");
    } else {
      navigate(links.compareAccounts.link);
    }
  };

  const title = hasAccounts ? newAccountTitle : firstAccountTitle;

  return (
    <Box>
      <Space gap="2">
        <Heading level={2}>{title}</Heading>
      </Space>
      <Space gap="3-5">
        <Text color="grey">{newAccountDescription}</Text>
      </Space>
      <Button className={styles.button} iconRight variant="tertiary" onClick={handleCompareAccounts}>
        {newAccountLink}
        <RightArrow />
      </Button>
    </Box>
  );
};
