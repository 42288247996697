import {
  Alert,
  Button,
  Flex,
  Heading,
  Loader,
  Text,
  TextInput,
  Tooltip,
  WarningIcon
} from "@shawbrook/sds-component-library";
import classnames from "classnames";
import CurrencyInput from "react-currency-input-field";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ArrowRight } from "assets/images";
import strings from "assets/strings/strings.json";
import WhiteBox from "components/WhiteBox/WhiteBox";
import useMoveMoneyForm from "hooks/useMoveMoneyForm";
import useSubmitTransfer from "hooks/useSubmitTransfer";
import styles from "pages/MoveMoney/MoveMoney.module.scss";
import inlineStyles from "pages/MoveMoney/MoveMoney.styles";
import { useMoveMoneyContext } from "state/moveMoneyState";

const MoveMoneyForm = () => {
  const { state, setState } = useMoveMoneyContext();
  const navigate = useNavigate();
  const { postTransfer, isSubmitTransferError, submitTransferError, isSubmitTransferSuccess, isSubmitTransferLoading } =
    useSubmitTransfer({
      paymentParams: {
        validateonly: true
      },
      sourceAccountIdArg: state.fromAccount?.id,
      destinationAccountIdArg: state.toAccount?.id,
      amountValueArg: state.amount.value,
      useLinkedAccount: !state.toAccount?.internal,
      referenceArg: state.reference
    });
  const {
    handleSubmit,
    control,
    errors,
    onValueChange,
    btnRef,
    onCurrencyInputRefChange,
    outboundPaymentWarning,
    onReferenceChange,
    onReferenceInputRefChange
  } = useMoveMoneyForm({
    navigate,
    moveMoneyContext: { state, setState },
    isSubmitTransferError,
    submitTransferError,
    isSubmitTransferSuccess
  });

  const amountHeadingCx = classnames({
    [styles.moveMoneyFieldHeadings]: !errors.moveMoney
  });
  const inputCx = classnames(styles["move-money__input"], {
    [styles["move-money__input--regular"]]: !errors.moveMoney,
    [styles["move-money__input--errored"]]: errors.moveMoney
  });
  const inputHolderCx = classnames(styles["move-money__input-holder"], {
    [styles["move-money__input-holder--errored"]]: errors.moveMoney
  });

  const shouldDisplayReference = !state.toAccount?.internal;

  const renderForm = () => {
    if (
      (state.fromAccount?.withdrawability.enabled || state.fromAccount?.outboundWithdrawability?.enabled) &&
      state.toAccount?.accountNumber
    ) {
      return (
        <form onSubmit={handleSubmit(() => postTransfer())}>
          <WhiteBox>
            <Heading level="5" className={amountHeadingCx}>
              {strings.moveMoney.amount}
            </Heading>
            {errors.moveMoney?.currencyInput && (
              <Text
                as="span"
                fontWeight="normal"
                css={inlineStyles.inputError}
                className={styles["move-money__input-error"]}>
                {errors.moveMoney.currencyInput.message}
              </Text>
            )}
            <div className={inputHolderCx}>
              <Controller
                name="moveMoney.currencyInput"
                control={control}
                render={({ field: { name, value, onChange } }) => (
                  <CurrencyInput
                    name={name}
                    onChange={onChange}
                    defaultValue={value}
                    allowNegativeValue={false}
                    decimalsLimit={2}
                    className={inputCx}
                    ref={onCurrencyInputRefChange}
                    onValueChange={onValueChange}
                    prefix="£ "
                    autoComplete="off"
                  />
                )}
              />
            </div>
            {outboundPaymentWarning && (
              <Alert variant="warning" css={{ marginTop: "$3", p: "$2 $6" }}>
                <Flex align="center" className={styles["move-money__outbound-rate-warning"]}>
                  <div>
                    <WarningIcon width="20" height="20" />
                  </div>
                  <Text fontWeight="semiBold" font="silka">
                    {outboundPaymentWarning}
                  </Text>
                </Flex>
              </Alert>
            )}
          </WhiteBox>
          {shouldDisplayReference && (
            <WhiteBox>
              <Flex align="center" className={amountHeadingCx} gap={2}>
                <Heading level="5">{strings.moveMoney.referenceOptional}</Heading>
                <Tooltip
                  label="Tell me more"
                  css={{ fontFamily: "$display" }}
                  text={strings.moveMoney.referenceTooltip}
                />
              </Flex>
              <div className={inputHolderCx}>
                <Controller
                  name="moveMoney.reference"
                  control={control}
                  render={({ field: { name, value } }) => (
                    <TextInput
                      id={name}
                      type="text"
                      maxLength={18}
                      defaultValue={value}
                      onChange={onReferenceChange}
                      ref={onReferenceInputRefChange}
                    />
                  )}
                />
              </div>
            </WhiteBox>
          )}
          <div className={styles.mmReviewButton}>
            <Button
              variant="primary"
              iconRight={!isSubmitTransferLoading}
              disabled={isSubmitTransferLoading}
              type="submit"
              size="medium"
              ref={btnRef}
              className={styles.mmPrimaryButton}>
              {isSubmitTransferLoading ? (
                <Loader size="small" />
              ) : (
                <>
                  {strings.moveMoney.review}
                  <ArrowRight />
                </>
              )}
            </Button>
          </div>
        </form>
      );
    }

    return null;
  };

  return renderForm();
};

export default MoveMoneyForm;
