export default {
  listWrapper: { bg: "$white", p: "$4", mt: "$3" },
  list: {
    borderBottom: "1px solid $grey1",
    gap: "6px",
    pb: "$5",
    "@lg": {
      gap: "18px"
    },
    "&:last-child": { border: "none", pb: 0 }
  },
  unreadMarker: {
    width: "8px",
    minW: "8px",
    height: "8px",
    bg: "$shawbrookPink",
    borderRadius: "100%",
    display: "block",
    alignSelf: "center"
  },
  heading: {
    display: "flex",
    gap: "6px",
    mb: "6px",
    lineHeight: "$tight",
    fontSize: "$3",
    textAlign: "left",
    textDecoration: "underline"
  },
  fileMeta: {
    fontFamily: "$body",
    fontSize: "$1",
    fontWeight: "$medium",
    letterSpacing: "1.2px",
    color: "$grey4",
    ml: "2px"
  },
  downloadTrigger: {
    color: "$shawbrookPink",
    p: 0,
    wordBreak: "break-word",
    textAlign: "left"
  },
  listItem: {
    display: "inline",
    "&::after": {
      content: "|",
      display: "inline",
      margin: "0 4px"
    },
    "&:last-child::after": { content: "" }
  },
  link: { textDecoration: "underline" }
};
