import { Box, Flex, Heading } from "@shawbrook/sds-component-library";

import styles from "./pageHeader.module.scss";

export interface PageHeaderProps {
  title: string;
  icon?: any;
  children?: any;
}

export const PageHeader = ({ title, icon, children }: PageHeaderProps) => (
  <Flex gap="6" direction="column">
    <Heading>{title}</Heading>
    {children && (
      <Box className={icon ? styles.descriptionWithIcon : ""}>
        {icon}
        <Box css={icon && { marginTop: "0.1rem" }}>{children}</Box>
      </Box>
    )}
  </Flex>
);
