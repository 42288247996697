export default {
  profileMenuItem: {
    border: "none",
    justifyContent: "center",
    "&:hover, &:focus": {
      backgroundColor: "var(--shawbrook-white)"
    },
    active: {
      backgroundColor: "var(--shawbrook-white)"
    }
  },
  navBar: {
    overflowX: "auto",
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "&::-webkit-scrollbar": {
      display: "none"
    }
  }
};
