import PiwikPro, { PageViews, UserManagement, DownloadAndOutlink } from "@piwikpro/react-piwik-pro";

const config = {
  siteId: process.env.REACT_APP_PIWIK_SITE_ID,
  accountUrl: process.env.REACT_APP_PIWIK_ACCOUNT_URL
};

export const initialize = () => {
  try {
    PiwikPro.initialize(config.siteId as string, config.accountUrl as string);
  } catch (e) {
    if (e instanceof Error) {
      console.log(e.message);
    }
  }
};

export const trackPage = (title?: string) => {
  try {
    PageViews.trackPageView(title);
  } catch (e) {
    if (e instanceof Error) {
      console.log(e.message);
    }
  }
};

export const resetCustomer = () => {
  try {
    UserManagement.resetUserId();
  } catch (e) {
    if (e instanceof Error) {
      console.log(e.message);
    }
  }
};

export const enableLinkTracking = () => {
  try {
    DownloadAndOutlink.enableLinkTracking(true);
  } catch (e) {
    if (e instanceof Error) {
      console.log(e.message);
    }
  }
};
