import { HEADERS } from "assets/strings/api";
import {
  CreateMessageRequest,
  CreateThreadRequest,
  IAvailableProduct,
  IAvailableThreads,
  ICustomerGDPRAndA11yBody,
  ILinkedAccountResponse,
  IMaturityInstructionResponse,
  IMessage,
  IMessagesUnread,
  INewAccountPayload,
  ININumberBody,
  ISubmitMessagesResponse,
  ISubmitNewAccountResponse,
  ISubmitThreadResponse
} from "services/data/types";
import { Profile } from "types/types";
import fromObjectToParams from "utils/fromObjectToParams";
import axios, { apiUrls } from "./axios";
import {
  AxiosReturnType,
  IAccountDetails,
  IAccountSummaryParams,
  IAllAccounts,
  ICustomer,
  IGetPaymentsParams,
  IInstructionBody,
  IPaymentsBody,
  IPaymentsParams,
  IPaymentsResponse
} from "./types";
import { APICustomer } from "./types/bankfastUserMigration";

const addProfileSuffixUrl = (url: string, profile: Profile) => {
  const profileSuffix = profile ? `?profileId=${profile.profileId}` : "";
  return `${url}${profileSuffix}`;
};

export function getAccountInformation(paramsObj: IAccountSummaryParams = {}, profile?: Profile): Promise<IAllAccounts> {
  if (localStorage.getItem("accountsErrorCode")) {
    return new Promise((_, reject) =>
      // eslint-disable-next-line
      reject({
        isAxiosError: true,
        toJSON: () => {},
        response: { status: Number(localStorage.getItem("accountsErrorCode")), data: {} }
      })
    );
  }

  return axios
    .get(`${addProfileSuffixUrl(apiUrls.accounts, profile!)}${fromObjectToParams(paramsObj)}`)
    .then((result: AxiosReturnType<IAllAccounts>) => result.data);
}

export function getAccountInformationFromId(id: any): Promise<IAccountDetails> {
  return axios.get(`${apiUrls.accounts}/${id}`).then((result: AxiosReturnType<IAccountDetails>) => result.data);
}

export function submitTransfer(
  sourceAccountId: string,
  body: IPaymentsBody,
  paramsObj: IPaymentsParams = {}
): Promise<IPaymentsResponse | void> {
  const url = `${apiUrls.payments.replace(":sourceAccountId", sourceAccountId)}${fromObjectToParams(paramsObj)}`;
  return axios.post(url, body).then((result: AxiosReturnType<IPaymentsResponse | void>) => result.data);
}

export function getTransfers(
  sourceAccountId: string,
  paramsObj: IGetPaymentsParams = {}
): Promise<Array<IPaymentsResponse> | undefined> {
  if (localStorage.getItem("transfersData")) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise(resolve => resolve(JSON.parse(localStorage.getItem("transfersData") as string)));
  }

  const url = `${apiUrls.payments.replace(":sourceAccountId", sourceAccountId)}${fromObjectToParams(paramsObj)}`;
  return axios.get(url).then((result: AxiosReturnType<Array<IPaymentsResponse>>) => result.data);
}

export function getTransfer(sourceAccountId: string, paymentId: string): Promise<IPaymentsResponse> {
  if (localStorage.getItem("transferData")) {
    // eslint-disable-next-line no-promise-executor-return
    return new Promise((resolve, reject) => {
      if (localStorage.getItem("transferData") === "error") {
        // eslint-disable-next-line
        reject({});
      } else {
        resolve(JSON.parse(localStorage.getItem("transferData") as string));
      }
    });
  }

  const url = apiUrls.payments.replace(":sourceAccountId", sourceAccountId);
  return axios.get(`${url}/${paymentId}`).then((result: AxiosReturnType<IPaymentsResponse>) => result.data);
}

export function getAllProviders() {
  return axios.get(apiUrls.provider).then(response => response.data);
}

export function getAvailableProducts(sourceAccountId: string): Promise<IAvailableProduct[]> {
  const url = apiUrls.availableProducts.replace(":sourceAccountId", sourceAccountId);
  return axios.get(url).then((result: AxiosReturnType<IAvailableProduct[]>) => result.data);
}

export function getNewAccountProducts(): Promise<IAvailableProduct[]> {
  return axios.get(apiUrls.newAccountProducts).then((result: AxiosReturnType<IAvailableProduct[]>) => result.data);
}

export function getMaturityInstruction(sourceAccountId: string): Promise<IMaturityInstructionResponse> {
  const url = apiUrls.setInstruction.replace(":sourceAccountId", sourceAccountId);
  return axios.get(url).then((result: AxiosReturnType<IMaturityInstructionResponse>) => result.data);
}

export function deleteMaturityInstruction(sourceAccountId: string): Promise<IMaturityInstructionResponse> {
  const url = apiUrls.setInstruction.replace(":sourceAccountId", sourceAccountId);
  return axios.delete(url).then((result: AxiosReturnType<IMaturityInstructionResponse>) => result.data);
}

export function submitInstruction(sourceAccountId: string, body: IInstructionBody): Promise<void> {
  const url = apiUrls.setInstruction.replace(":sourceAccountId", sourceAccountId);
  return axios.put(url, body).then((result: AxiosReturnType<void>) => result.data);
}

export function getCustomerInformation(profile?: Profile): Promise<ICustomer> {
  return axios
    .get(addProfileSuffixUrl(apiUrls.customer, profile!))
    .then((result: AxiosReturnType<ICustomer>) => result.data);
}

export function patchNINumber(body: ININumberBody): Promise<ICustomer> {
  return axios
    .patch(`${apiUrls.customers}?validateOnly=true`, body)
    .then((result: AxiosReturnType<ICustomer>) => result.data);
}

export function patchCustomerGDPRAndA11y(body: ICustomerGDPRAndA11yBody): Promise<any> {
  return axios.patch(`${apiUrls.customers}`, body);
}

export function generateOtpForContact(body: any): Promise<any> {
  return axios.post(`${apiUrls.contact}`, body).then((result: AxiosReturnType<any>) => result);
}

export function verifyContact(body: any): Promise<any> {
  return axios.post(`${apiUrls.verifyContact}`, body);
}

export function updateContactDetails(body: APICustomer, justValidate: boolean = false): Promise<any> {
  return axios.patch(`${apiUrls.customers}${justValidate ? "?validateOnly=true" : ""}`, body);
}

export function getCustomerProfile(): Promise<Profile[]> {
  return axios.get(apiUrls.profiles).then((result: AxiosReturnType<Profile[]>) => result.data);
}

/* Secure messages */

export function getNotificationCount(): Promise<IMessagesUnread> {
  const url = apiUrls.messagesUnread;
  return axios.get(url).then((result: AxiosReturnType<IMessagesUnread>) => result.data);
}

/* Documents */
export function getDocuments({ offset = 0, limit = 10 }): Promise<any> {
  const customerId =
    localStorage.getItem("savingsCustomerId") || HEADERS.Authorization.noStrict.defaultSavingsCustomerId;
  const url = apiUrls.documents.replace(":customerId", customerId);

  // @NOTE: Rate limiting this to 100 documents per request
  return axios
    .get(`${url}?offset=${offset}&limit=${limit < 100 ? limit : 100}`)
    .catch(() =>
      // @FIX: API returns a 404 when offset goes beyond data, so we need to catch it and return an empty array
      ({ data: [] })
    )
    .then((result: AxiosReturnType<any>) => result.data);
}

export function getDocument(documentId: string): Promise<any> {
  return axios
    .get(apiUrls.document.replace(":documentId", documentId))
    .then((result: AxiosReturnType<any>) => result.data);
}

export function updateDocumentStatus(documentId: string): Promise<any> {
  return axios
    .post(apiUrls.documentRead.replace(":documentId", documentId))
    .then((result: AxiosReturnType<any>) => result.data);
}

/* Method for getting a single thread of messages */
export function getMessages(threadId: string): Promise<IMessage[]> {
  const url = apiUrls.messages.replace(":threadId", threadId);
  return axios.get(url).then((result: AxiosReturnType<IMessage[]>) => result.data);
}

/* Method for getting a list of threads */
export function getAvailableThreads(): Promise<IAvailableThreads> {
  const url = apiUrls.availableThreads;
  return axios.get(url).then((result: AxiosReturnType<IAvailableThreads>) => result.data);
}

/* Method for starting a new thread */
export function submitThread(body: CreateThreadRequest): Promise<any> {
  return axios
    .post(`${apiUrls.availableThreads}`, body)
    .then((result: AxiosReturnType<ISubmitThreadResponse | void>) => result.data);
}

/* Method for replying to a thread */
export function submitMessages(threadId: string, body: CreateMessageRequest): Promise<any> {
  return axios
    .post(`${apiUrls.messages.replace(":threadId", threadId)}`, body)
    .then((result: AxiosReturnType<ISubmitMessagesResponse | void>) => result.data);
}

export function getLinkedAccounts(): Promise<ILinkedAccountResponse[]> {
  const customerId =
    localStorage.getItem("savingsCustomerId") || HEADERS.Authorization.noStrict.defaultSavingsCustomerId;
  const url = customerId ? `${apiUrls.linkedAccounts}?savingsCustomerId=${customerId})` : apiUrls.linkedAccounts;
  return axios.get(url).then((result: AxiosReturnType<ILinkedAccountResponse[]>) => result.data);
}

export function submitNewAccount(data: INewAccountPayload): Promise<any> {
  return axios.post(apiUrls.accounts, data).then((result: AxiosReturnType<ISubmitNewAccountResponse>) => result.data);
}
