import { Button } from "@shawbrook/sds-component-library";
import { FC } from "react";
import classnames from "classnames";

import { LabelPink, LogoPink } from "assets/images";
import { links } from "config/navLinks";
import styles from "./NavLogo.module.scss";
import { inlineStyles } from "./NavLogo.style";

interface INavLogo {
  infoCompleteLayout?: boolean;
}

export const NavLogo: FC<INavLogo> = ({ infoCompleteLayout }) => {
  const navCx = classnames(styles.nav, {
    [styles.nav__complete]: infoCompleteLayout
  });
  const logoParams = {
    logo: {
      width: infoCompleteLayout ? 31 : 26,
      height: infoCompleteLayout ? 47 : 40
    },
    label: {
      width: infoCompleteLayout ? 162 : 136,
      height: infoCompleteLayout ? 24 : 21
    }
  };

  return (
    <nav>
      <div className={navCx}>
        <Button
          className={styles.nav__logo}
          css={inlineStyles.logo}
          href={links.landing.link}
          title="Shawbrook"
          role="link"
          as="a">
          <LogoPink aria-hidden="true" width={logoParams.logo.width} height={logoParams.logo.height} />
          <LabelPink aria-hidden="true" width={logoParams.label.width} height={logoParams.label.height} />
        </Button>
      </div>
    </nav>
  );
};
