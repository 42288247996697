import { ReactElement, FC } from "react";

interface IConditionalWrapper {
  children: ReactElement;
  wrapper: (children: ReactElement) => ReactElement;
  condition: boolean;
}

const ConditionalWrapper: FC<IConditionalWrapper> = ({ children, wrapper, condition }): ReactElement =>
  condition ? wrapper(children) : children;

export default ConditionalWrapper;
