import { isAxiosError } from "axios";
import { FC, useEffect, useState, ReactElement } from "react";
import { ErrorModal } from "pages/MoveMoney/components/Modals";
import { STATUS_CODE } from "assets/strings/api";
import { Contexts } from "types/types";

interface IErrorRequestView {
  requestErrors: Array<unknown>;
  context: Contexts;
  errorModal?: ReactElement;
  includeNotFound?: boolean;
}

const HIGH_TRAFFIC_CODES = [STATUS_CODE.REQUEST_TIMEOUT, STATUS_CODE.GATEWAY_TIMEOUT, STATUS_CODE.SERVICE_UNAVAILABLE];

const ErrorRequestView: FC<IErrorRequestView> = ({
  requestErrors,
  context: { setState },
  errorModal = <ErrorModal open />,
  includeNotFound = false
}) => {
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    let highTraffic = false;
    let notFoundPage = false;

    requestErrors.forEach(error => {
      if (
        !notFoundPage &&
        isAxiosError(error) &&
        includeNotFound &&
        (error?.response?.status || 0) === STATUS_CODE.NOT_FOUND
      ) {
        setState({
          is404Page: true
        });
        notFoundPage = true;
      }
    });

    requestErrors.forEach(error => {
      if (
        !notFoundPage &&
        !highTraffic &&
        isAxiosError(error) &&
        HIGH_TRAFFIC_CODES.includes(error?.response?.status || 0)
      ) {
        setState({
          isHighTraffic: true
        });
        highTraffic = true;
      }
    });

    if (!highTraffic && !notFoundPage) {
      setShowErrorModal(true);
    }
  }, [requestErrors, includeNotFound, setState]);

  const renderComponent = () => {
    if (showErrorModal) {
      return errorModal;
    }

    return null;
  };

  return renderComponent();
};

export default ErrorRequestView;
