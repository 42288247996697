import { QUERY_PARAMS } from "assets/strings/queryParams";
import modifyURLParams from "utils/modifyURLParams";

const isNoStrictAuth = () => {
  const noStrictAuthDefault = process.env.REACT_APP_NO_STRICT_AUTH === "true";
  const allowNoStrictAuthCustom = process.env.REACT_APP_ALLOW_SETTING_NO_STRICT_AUTH === "true";
  const noStrictAuthParam = new URLSearchParams(window.location.search).get(QUERY_PARAMS.NO_STRICT_AUTH);

  if (allowNoStrictAuthCustom && noStrictAuthParam) {
    if (noStrictAuthParam === "false" || noStrictAuthParam === "true") {
      localStorage.setItem("noStrictAuth", noStrictAuthParam);
    }

    modifyURLParams({
      paramsToModifyArg: QUERY_PARAMS.NO_STRICT_AUTH
    });
  }

  const noStrictAuthCustom = allowNoStrictAuthCustom && localStorage.getItem("noStrictAuth") === "true";
  return noStrictAuthCustom || noStrictAuthDefault;
};

export default isNoStrictAuth;
