import { Text } from "@shawbrook/sds-component-library";
import { ReactNode } from "react";
import rreplace from "react-string-replace";

import { API_LIMITS, ERROR_CODES } from "assets/strings/api";
import strings from "assets/strings/strings.json";
import { ProductCategory } from "services/data/types";
import { AlertActionMode, AlertVariants, ALERT_ACTION_MODE, ALERT_VARIANTS } from "types/types";
import { getPhoneFormat } from "utils";
import { isMaturedAccountProductCategory } from "utils/isMaturedAccountProductCategory/isMaturedAccountProductCategory";

interface IGetNonDepositableInfo {
  reason?: string;
  fromAccountWithdrawableReason?: string;
  category?: ProductCategory;
  isLinkedAccVerified?: boolean;
}

const getNonDepositableInfo = ({
  reason,
  fromAccountWithdrawableReason,
  category,
  isLinkedAccVerified
}: IGetNonDepositableInfo) => {
  let text: string | ReactNode;
  let heading: string | undefined;
  let variant: AlertVariants = ALERT_VARIANTS.warning;
  let actionMode: AlertActionMode | undefined;

  if (isMaturedAccountProductCategory(category)) {
    ({ maturedAccountHeading: heading } = strings.moveMoney.nonDepositable);
    text = undefined;
  } else if (reason === ERROR_CODES.ACTIVE_DESTINATION_ACCOUNT) {
    ({ closedHeading: heading } = strings.moveMoney.nonDepositable);
    text = undefined;
  } else if (reason === ERROR_CODES.MOVE_MONEY_MAX_BALANCE_REACHED) {
    ({ maxBalanceHeading: heading } = strings.moveMoney.nonDepositable);
    text = undefined;
  } else if (reason === ERROR_CODES.MOVE_MONEY_MAX_SUBSCRIPTION_REACHED) {
    const { currency } = strings.moveMoney.nonDepositable;
    ({ allowanceHeading: heading, allowanceText: text } = strings.moveMoney.nonDepositable);
    text = rreplace(text as string, "{{allowanceLimit}}", (_, i) => (
      <Text fontWeight="semiBold" font="silka" as="span" key={`allowanceLimit${i}`}>
        {`${currency}${API_LIMITS.ALLOWANCE.toLocaleString()}`}
      </Text>
    ));
  } else if (
    // According to move money TO alert priorities sheet, this should be top priority
    (category === ProductCategory.FIXED_RATE || category === ProductCategory.FIXED_RATE_ISA) &&
    reason === ERROR_CODES.PEGA_OVERRIDE
  ) {
    ({ fixedAccountHeading: heading, textNewAccount: text } = strings.moveMoney.nonDepositable);
    actionMode = ALERT_ACTION_MODE.compareAccounts;
    variant = ALERT_VARIANTS.info;
  } else if (reason === ERROR_CODES.ISA_CLOSED_FOR_FUNDING || reason === ERROR_CODES.POST_MATURITY_CLOSED_FOR_FUNDING) {
    ({ heading, text } = strings.moveMoney.nonDepositable);
    text = rreplace(text as string, "{{HELP_CENTER_NUMBER}}", (_, i) => (
      <Text fontWeight="bold" font="silka" as="span" key={`helpCenterNumber${i}`}>
        {getPhoneFormat(strings.general.helpCenterNumber)}
      </Text>
    ));
    actionMode = ALERT_ACTION_MODE.sendMessage;
  } else if (
    fromAccountWithdrawableReason === ERROR_CODES.NOT_WITHDRAWABLE_ISA &&
    (category === ProductCategory.FIXED_RATE ||
      category === ProductCategory.FIXED_RATE_ISA ||
      category === ProductCategory.EASY_ACCESS)
  ) {
    ({ fromISAHeading: heading, fromEAISAText: text } = strings.moveMoney.nonDepositable);
    text = rreplace(text as string, "{{HELP_CENTER_NUMBER}}", (_, i) => (
      <Text fontWeight="bold" font="silka" as="span" key={`helpCenterNumber${i}`}>
        {getPhoneFormat(strings.general.helpCenterNumber)}
      </Text>
    ));
    actionMode = ALERT_ACTION_MODE.sendMessage;
  } else if (isLinkedAccVerified === false) {
    ({ linkedAccUnverifiedHeading: heading, linkedAccUnverifiedText: text } = strings.moveMoney.nonDepositable);
    variant = ALERT_VARIANTS.warning;
    actionMode = ALERT_ACTION_MODE.verifyLinkedAccount;
  } else if (
    fromAccountWithdrawableReason === ERROR_CODES.NOT_WITHDRAWABLE_ISA &&
    (category === ProductCategory.NOTICE || category === ProductCategory.EASY_ACCESS_ISA)
  ) {
    ({ fromISAHeading: heading, fromEAISAText: text } = strings.moveMoney.nonDepositable);
    text = rreplace(text as string, "{{HELP_CENTER_NUMBER}}", (_, i) => (
      <Text fontWeight="bold" font="silka" as="span" key={`helpCenterNumber${i}`}>
        {getPhoneFormat(strings.general.helpCenterNumber)}
      </Text>
    ));
    actionMode = ALERT_ACTION_MODE.sendMessage;
  } else if (
    fromAccountWithdrawableReason === ERROR_CODES.NOT_WITHDRAWABLE_ISA_FIXED_RATE ||
    fromAccountWithdrawableReason === ERROR_CODES.NOT_WITHDRAWABLE_ISA_NOTICE
  ) {
    ({ fromISAHeading: heading, text } = strings.moveMoney.nonDepositable);
    text = rreplace(text as string, "{{HELP_CENTER_NUMBER}}", (_, i) => (
      <Text fontWeight="bold" font="silka" as="span" key={`helpCenterNumber${i}`}>
        {getPhoneFormat(strings.general.helpCenterNumber)}
      </Text>
    ));
    actionMode = ALERT_ACTION_MODE.sendMessage;
  }

  return {
    text,
    heading,
    variant,
    actionMode
  };
};

export default getNonDepositableInfo;
