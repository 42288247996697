import {
  Button,
  Grid,
  Heading,
  List,
  Pill,
  RadioGroup,
  RadioItem,
  RightArrow,
  Space,
  Text
} from "@shawbrook/sds-component-library";
import { useQueryClient } from "@tanstack/react-query";
import { GlobalContextSetAvailableProfiles, GlobalContextSetSelectedProfile } from "Actions/GlobalContextAction";
import strings from "assets/strings/strings.json";
import { links } from "config/navLinks";
import useProfile from "hooks/useProfile";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobalContext, useGlobalDispatch } from "state/globalContext";
import { Profile, ProfileType } from "types/types";
import styles from "./ChooseProfile.module.scss";

export const ChooseProfile = () => {
  const navigate = useNavigate();
  const state = useGlobalContext();
  const dispatch = useGlobalDispatch();
  const { heading } = strings.profile;
  const { continueButton } = strings.general;
  const [selectedProfile, setSelectedProfile] = useState<Profile>();
  const queryClient = useQueryClient();
  const { customerProfileData, isCustomerProfileLoading } = useProfile();

  useEffect(() => {
    if (customerProfileData && !isCustomerProfileLoading) {
      dispatch(GlobalContextSetAvailableProfiles(customerProfileData));

      const preselectedProfile = customerProfileData.find(
        (profile: Profile) => profile.profileType.toLocaleString().toLowerCase() === ProfileType[ProfileType.personal]
      );
      if (selectedProfile === undefined) {
        setSelectedProfile(preselectedProfile);
      }
    }
  }, [customerProfileData, isCustomerProfileLoading, selectedProfile, dispatch]);

  const handleSelectProfile = (e: any) => {
    const selectedProfileOnSelect = state.availableProfiles?.find((profile: Profile) => profile.profileId === e);
    setSelectedProfile(selectedProfileOnSelect);
  };

  const handleContinue = () => {
    queryClient.invalidateQueries();
    dispatch(GlobalContextSetSelectedProfile(selectedProfile as Profile));
    navigate(links.landing.link);
  };

  return (
    <div className={styles.page}>
      <Grid className={styles.page__profileContainer}>
        <Space gap="10" />
        <Heading>{heading}</Heading>

        <Space gap="6">
          <Grid
            as={RadioGroup}
            onValueChange={handleSelectProfile}
            value={selectedProfile?.profileId}
            orientation="vertical"
            labelText={{ main: "" }}>
            {state.availableProfiles
              .sort((a: Profile, b: Profile) => a.profileType.toString().localeCompare(b.profileType.toString()))
              .reverse()
              ?.map((profile: Profile) => (
                <List.Item
                  tile
                  key={profile.profileId}
                  className={
                    profile.profileId === selectedProfile?.profileId
                      ? styles.page__profileContainer__selectedProfile
                      : styles.page__profileContainer__profileItems
                  }>
                  <RadioItem
                    id={profile.profileId}
                    key={profile.profileId}
                    value={profile.profileId}
                    label={profile.profileName}
                  />
                  <Pill>
                    <Text fontWeight="bold">{profile.profileType}</Text>
                  </Pill>
                </List.Item>
              ))}
          </Grid>
        </Space>

        <Button fullWidth iconRight size="medium" onClick={handleContinue}>
          {continueButton}
          <RightArrow width="14" height="12" />
        </Button>
      </Grid>
    </div>
  );
};
