import { IMaskString } from "utils/maskString/interfaces";

const maskString = ({ str, mask = "X", tailNumber = 4 }: IMaskString) => {
  let maskedStr;

  if (typeof tailNumber === "number") {
    maskedStr = str.slice(0, -tailNumber).replace(/./g, mask) + str.slice(-tailNumber);
  } else {
    maskedStr = str.replace(/./g, mask);
  }

  return maskedStr;
};

export default maskString;
