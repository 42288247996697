export default {
  fscs: {
    whiteSpace: "break-spaces",
    textAlign: "left",
    ml: "0.5rem",
    fontSize: "var(--fontSizes-2)",
    lineHeight: "106%",
    fontFamily: "Inter"
  }
};
