import React from "react";
import { Customer } from "services/data/types/bankfastUserMigration";

import { useOutletContext } from "react-router-dom";

export enum MobileOtpMode {
  SMS = "sms",
  VOICE = "voice"
}

export type MobileOtpScreenModeType = MobileOtpMode.SMS | MobileOtpMode.VOICE;

export interface IBankfastUserMigrationAppContext {
  state: IBankfastUserMigrationState;
  setState: React.Dispatch<Partial<IBankfastUserMigrationState>>;
}

export const initialState: IBankfastUserMigrationState = {
  customer: {
    contactDetails: {
      email: "",
      emailVerified: false,
      mobilePhoneNumber: "",
      mobilePhoneNumberVerified: false,
      commPreferencesProvided: false,
      secondaryPhoneNumber: "",
      commPreferences: {
        marketing: {
          email: false,
          sms: false
        },
        voice: {
          translatorRequired: false,
          translationRequired: false
        },
        document: {
          largePrint: false
        }
      }
    }
  },
  emailVerification: {
    contact: "",
    verifiedContact: "",
    otp: "",
    otpError: false
  },
  mobileVerification: {
    screenMode: MobileOtpMode.SMS,
    contact: "",
    verifiedContact: "",
    otp: "",
    otpError: false
  },
  isHighTraffic: false
};

type OtpVerification = {
  contact: string;
  verifiedContact: string;
  otp: string;
  otpError: boolean;
};

export interface IBankfastUserMigrationState {
  customer: Customer;
  emailVerification: OtpVerification;
  mobileVerification: OtpVerification & { screenMode: MobileOtpScreenModeType };
  isHighTraffic: boolean;
}

export const useBankfastUserMigrationAppContext = () => useOutletContext<IBankfastUserMigrationAppContext>();
