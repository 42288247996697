export default {
  card: {
    width: "100%",
    maxWidth: "var(--large-screen)",
    padding: "$3 0 0"
  },
  container: {
    paddingLeft: "$5",
    paddingRight: "$5"
  },
  heading: {
    paddingLeft: "$5",
    paddingRight: "$5"
  }
};
