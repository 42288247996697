import { Flex, Loader, Modal, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import inlineStyles from "components/RequestInProgressModal/RequestInProgressModal.styles";
import { FC } from "react";

interface IRequestInProgressModal {
  open?: boolean;
  infoText?: string;
  warningText?: string;
}

const RequestInProgressModal: FC<IRequestInProgressModal> = ({ open, infoText, warningText }) => (
  <Modal.Root open={open}>
    <Modal.Content css={inlineStyles.modal} onEscapeKeyDown={undefined} onPointerDownOutside={undefined}>
      <Flex direction="column" css={inlineStyles.loaderWrapper}>
        <Loader showRing size="large" spacing="medium" />
      </Flex>
      <Flex justify="center">
        <Text font="silka" textStyle="medium" css={inlineStyles.info}>
          {infoText || strings.general.modals.requestInProgress.info}
        </Text>
      </Flex>
      <Flex justify="center">
        <Text textStyle="medium" font="silka" css={inlineStyles.warning}>
          {warningText || strings.general.modals.requestInProgress.warning}
        </Text>
      </Flex>
    </Modal.Content>
  </Modal.Root>
);

export default RequestInProgressModal;
