import { FC } from "react";
import { Button, Heading, Tag, Text } from "@shawbrook/sds-component-library";

import { formatBalance } from "utils";
import strings from "assets/strings/strings.json";
import styles from "./ReinvestmentAccounts.module.scss";

interface IReinvestmentAccountsProps {
  accountDisplayName: string;
  accountIssueNumber: string | null;
  accountBalance: number;
  productDisplayName: string;
  productIssueNumber: string;
  handleEdit: () => void;
}

export const ReinvestmentAccounts: FC<IReinvestmentAccountsProps> = ({
  accountDisplayName,
  accountIssueNumber,
  accountBalance,
  productDisplayName,
  productIssueNumber,
  handleEdit
}) => {
  const { maturingAccountCard } = strings.reinvestment;
  const { selectedAccount } = strings.chooseReinvestment;

  return (
    <div className={styles.accountCard}>
      <div className={styles.accountCard__content}>
        <Heading as="h2" level="6" css={{ marginBottom: "0.625rem" }}>
          {maturingAccountCard.title}
        </Heading>
        <Tag isLarge css={{ padding: "$0-5 $1" }} className={styles.accountCard__content__tag}>
          <Text as="span" fontWeight="semiBold">
            {accountDisplayName}
          </Text>
          {` ${accountIssueNumber}`}
        </Tag>
        <Text color="grey" fontWeight="medium">
          {`${maturingAccountCard.balance} `}
          <Text as="span" fontWeight="semiBold">
            {formatBalance(accountBalance || 0)}
          </Text>
        </Text>
      </div>
      <div className={styles.accountCard__content}>
        <Heading as="h2" level="6" css={{ marginBottom: "0.625rem" }}>
          {selectedAccount}
        </Heading>
        <Tag isLarge css={{ padding: "$0-5 $1" }} className={styles.accountCard__content__tag}>
          <Text as="span" fontWeight="semiBold">
            {productDisplayName}
          </Text>
          {` ${productIssueNumber}`}
        </Tag>
        <div className={styles.accountCard__edit}>
          <Button variant="tertiary" onClick={handleEdit}>
            {strings.general.editButton}
          </Button>
        </div>
      </div>
    </div>
  );
};
