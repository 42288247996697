import { Alert, Box, ErrorIcon, Grid, Heading } from "@shawbrook/sds-component-library";
import { ArrowLeft } from "assets/images";
import strings from "assets/strings/strings.json";
import { IFRAMES } from "assets/strings/values";
import { FC, useEffect, useState } from "react";

import { AskAQuestion } from "components/AskAQuestion/AskAQuestion";
import IconLink from "components/IconLink/IconLink";
import LoadingSection from "components/LoadingSection/LoadingSection";
import useDocuments from "hooks/useDocuments";
import { useSearchParams } from "react-router-dom";
import { v4 as uuid } from "uuid";
import InlineStyles from "./Documents.styles";
import ArchiveMessage from "./components/ArchiveMessage";
import { FileList } from "./components/FileList";
import ShowMore from "./components/ShowMore";
import { GetContactMesssage, markVisibleDocumentsAsRead } from "./utils";

export const Documents: FC = () => {
  const [params] = useSearchParams();
  const limit = Number(params.get("limit")) || 10;
  const [pageLimit] = useState(limit);
  const { filesQuery, hasNextPage, loadNextPage } = useDocuments({ limit: pageLimit });
  const { documents, general } = strings;

  const handleShowMore = () => {
    loadNextPage();
  };

  useEffect(() => {
    if (filesQuery && filesQuery.data?.pages) {
      markVisibleDocumentsAsRead(filesQuery.data?.pages);
    }
  });

  useEffect(
    () => () => {
      const iframesToRemove = document.body.querySelectorAll(`[data-id="${IFRAMES.DOCUMENT_DOWNLOAD}"]`);
      iframesToRemove.forEach(iframeToRemove => iframeToRemove.remove());
    },
    []
  );

  return (
    <Box as="section" className="page__wrapper" css={InlineStyles.wrapper}>
      <Box as="header" css={InlineStyles.header}>
        <IconLink to="/" icon={<ArrowLeft />} text={general.back} />
        <Heading color="white">{documents.title}</Heading>
      </Box>

      <Grid css={InlineStyles.grid}>
        <Box css={InlineStyles.mainWrapper}>
          <>
            {filesQuery.error ? (
              <Alert
                type="inline"
                variant="error"
                css={InlineStyles.alert}
                icon={<ErrorIcon aria-hidden />}
                aria-live="assertive">
                <p>{GetContactMesssage}</p>
              </Alert>
            ) : null}

            {documents.archiveMessage ? (
              <ArchiveMessage
                message={documents.archiveMessage}
                link={{ url: documents.messageLink, text: documents.messageText }}
              />
            ) : null}

            <Heading level="3" as="h2">
              {documents.subtitle}
            </Heading>

            {filesQuery.data && filesQuery.status === "success" ? (
              <Box>
                {filesQuery.data?.pages && (
                  <FileList data={filesQuery.data?.pages} limit={pageLimit} key={uuid()}>
                    {hasNextPage ? (
                      <ShowMore onClick={handleShowMore} status={filesQuery.fetchStatus}>
                        Show {limit} more
                      </ShowMore>
                    ) : null}
                  </FileList>
                )}
              </Box>
            ) : (
              <LoadingSection />
            )}
          </>
        </Box>
        <Box as="aside">
          <AskAQuestion text={documents.messageText} link={documents.messageLink} />
        </Box>
      </Grid>
    </Box>
  );
};
