import { IAvailableProduct, ProductCategory } from "services/data/types";
import { isISAAccountCategory } from "utils";
import { FormattedAvailableProducts } from "utils/formatAvailableProducts/interfaces";
import { formatProductsGroup } from "./formatProductsGroup";
import { sortAvailableProducts } from "./sortAvailableProducts";

const formatAvailableProducts = (
  availableProducts: IAvailableProduct[],
  isISAAccount: boolean
): FormattedAvailableProducts => {
  // We need to filter out Notice ISA products, as they are not supposed to be offered.
  // In case Notice ISA products should be available (or when Notice ISAs have a correct
  // value of currentlyOffered flag), remove filtering.
  const filteredProducts = availableProducts.filter(el => el.category !== ProductCategory.NOTICE_ISA);
  const sortedProducts = sortAvailableProducts(filteredProducts);

  let formattedProducts: FormattedAvailableProducts = {};

  sortedProducts.forEach(productsGroup => {
    const { category } = productsGroup;
    if (
      category &&
      ((isISAAccount && isISAAccountCategory(category)) || (!isISAAccount && !isISAAccountCategory(category)))
    ) {
      const formattedProductsGroup = formatProductsGroup(productsGroup);
      const setName = productsGroup.products[0].title;

      formattedProducts = {
        ...formattedProducts,
        ...(formattedProductsGroup && { [setName]: formattedProductsGroup })
      };
    }
  });

  return formattedProducts;
};

export default formatAvailableProducts;
