import { Box, Button, Divider, Heading, TabsResponsive, Tag, Text } from "@shawbrook/sds-component-library";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import rreplace from "react-string-replace";

import { ArrowLeft } from "assets/images";
import { DATES } from "assets/strings/formats";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import { ErrorModal } from "components/ErrorModal";
import { IconButton } from "components/IconButton/IconButton";
import { LoadingSection } from "components/LoadingSection/LoadingSection";
import { PRODUCT_CARD_SECTION, ProductCard } from "components/ProductCard/ProductCard";
import { TermsExplained } from "components/TermsExplained/TermsExplained";
import WhyWeAskThis from "components/WhyWeAskThisLink";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import useAvailableProducts from "hooks/useAvailableProducts";
import useSrollToTop from "hooks/useScrollToTop";
import { ProductCategory } from "services/data/types";
import { INTEREST_FREQUENCY, useReinvestmentContext } from "state/reinvestmentState";
import {
  findInterestRate,
  formatBalance,
  formatToDisplayDate,
  getAvailableBalance,
  getInstructionDisplayDate,
  isISAAccountCategory
} from "utils";
import formatAvailableProducts from "utils/formatAvailableProducts";
import { formatProductsGroup } from "utils/formatAvailableProducts/formatProductsGroup";
import { FormattedAvailableProduct } from "utils/formatAvailableProducts/interfaces";
import { headerStyles } from "../HeaderStyles.styles";
import styles from "./SelectAccount.module.scss";
import { AvailableProductsSelectionArr, TABS_VALUES, generateSelection } from "./tabs";

export const SelectAccount = () => {
  const params = useParams();
  const accountId = params.id || "";
  useSrollToTop();
  const navigate = useNavigate();
  const { state, setState } = useReinvestmentContext();
  const [selection, setSelection] = useState<AvailableProductsSelectionArr>([]);
  const [isProductSelected, setIsProductSelected] = useState(false);
  const { accountDetailData, accountResolved, accountErrored } = useAccount({ accountId });
  const { availableProductsData, isAvailableProductsLoading, isAvailableProductsError } = useAvailableProducts({
    accountId
  });

  const maturityDate = accountDetailData?.maturity?.maturityDate || "";
  const formattedMaturityDate = formatToDisplayDate(maturityDate);
  const instructionMaturityDate = getInstructionDisplayDate(
    [DATES.isoWithTimezoneShort, DATES.isoWithLongTimezone],
    maturityDate
  );
  const interestRate = accountDetailData ? accountDetailData.product.interestRate : "";
  const productName = accountDetailData ? accountDetailData.product.title : "";
  const productCategory = accountDetailData?.product.category;
  const issueNumber = accountDetailData
    ? `${strings.general.issueNumber} ${accountDetailData.product.issueNumber}`
    : null;

  const { title } = strings.reinvestment;
  const {
    subtitle,
    description,
    alert,
    selectAccount,
    otherOptions,
    fixedRateBondAccName,
    cashISAAccName,
    disclaimer
  } = strings.selectAccount;
  const { maturingAccountCard } = strings.reinvestment;

  const balance = getAvailableBalance(accountDetailData);

  useEffect(() => {
    if (accountDetailData) {
      setState({
        accountId: accountDetailData.id,
        partialData: {
          maturingAccount: accountDetailData
        }
      });
    }
  }, [accountDetailData, setState]);

  useEffect(() => {
    if (accountDetailData && availableProductsData) {
      const availableProductSets = formatAvailableProducts(
        availableProductsData,
        isISAAccountCategory(accountDetailData.product.category)
      );
      const categories: Set<ProductCategory> = new Set();

      Object.keys(availableProductSets).forEach(setName => {
        categories.add(availableProductSets[setName].category);
      });

      setState({
        accountId: accountDetailData.id,
        partialData: {
          categories
        }
      });

      setSelection(generateSelection(availableProductSets, categories));
    }
  }, [availableProductsData, accountDetailData, setState]);

  useEffect(() => {
    if (
      isProductSelected &&
      accountDetailData &&
      typeof state[accountDetailData.id]?.selectedProductSetName !== "undefined"
    ) {
      navigate(`${links.maturityInstruction.link}/choose-reinvestment/${accountDetailData.id}`);
    }
  }, [state, accountDetailData, navigate, isProductSelected]);

  const handleProductSetSelection = (setName: string) => {
    const selectedProductGroup = availableProductsData?.find(
      productsGroup => productsGroup.products[0]?.title === setName
    );
    setState({
      accountId: accountDetailData?.id as string,
      partialData: {
        selectedProductSetName: setName,
        selectedProductSet: selectedProductGroup
          ? (formatProductsGroup(selectedProductGroup) as FormattedAvailableProduct)
          : undefined
      }
    });
    setIsProductSelected(true);
  };

  const handleWithdrawFull = () => {
    navigate(links.withdrawFull.link.replace(":id", accountId));
  };

  if (accountErrored || isAvailableProductsError) {
    return <ErrorModal open allPages />;
  }

  const accNameWeight = productCategory === ProductCategory.FIXED_RATE ? "medium" : "semiBold";
  const subtitleWithAccName = rreplace(subtitle, "{{ACC_NAME}}", (_, i) => (
    <Text as="span" fontWeight={accNameWeight} key={`ACC_NAME${i}`} css={{ fontSize: "$6", "@lg": { fontSize: "$7" } }}>
      {productCategory === ProductCategory.FIXED_RATE ? fixedRateBondAccName : cashISAAccName}
    </Text>
  ));
  const descriptionWithDate = rreplace(description, "{{DATE}}", (_, i) => (
    <Text as="span" textStyle="body" fontWeight="semiBold" key={`DATE${i}`}>
      {instructionMaturityDate}
    </Text>
  ));

  return (
    <div className={styles.title}>
      <Box as="header" css={headerStyles.header}>
        <IconButton onClick={() => navigate(-1)} icon={<ArrowLeft />} text={strings.general.back} />
        <Heading color="white">{title}</Heading>
      </Box>
      {(!accountResolved || isAvailableProductsLoading) && <LoadingSection />}

      {accountDetailData && availableProductsData && (
        <section className={styles.page__content}>
          <section>
            <div>
              <Heading level={2}>{subtitleWithAccName}</Heading>
              <Text textStyle="body" fontWeight="medium" className={styles.landingTitleHeadings}>
                {descriptionWithDate}
              </Text>
              <WhyWeAskThis
                label={alert.label}
                content={alert.text1
                  .replace("{{DATE}}", formattedMaturityDate)
                  .replace("{{GROSS/AER}}", interestRate?.toString())}
                subContent={alert.text2}
                alternativeFontStyle
              />
            </div>

            <Divider />

            <Heading css={{ marginBottom: "$4" }} level="4">
              {selectAccount}
            </Heading>

            {selection.length > 0 && (
              <div>
                <TabsResponsive
                  defaultValue={TABS_VALUES.allAccounts}
                  items={selection.map(el => ({
                    title: el.title,
                    value: el.value,
                    content: (
                      <>
                        {Object.entries(el.content).map(([setName, val]) => (
                          <ProductCard
                            key={setName}
                            displayName={val.title || ""}
                            variableProductType={val.category !== ProductCategory.FIXED_RATE}
                            interestRateAnnual={findInterestRate(val.products, INTEREST_FREQUENCY.annually)}
                            interestRateMonthly={findInterestRate(val.products, INTEREST_FREQUENCY.monthly)}
                            maxInterestRate={val.maxInterestRate}
                            maxBalanceSole={val.soleMaxBalance}
                            section={PRODUCT_CARD_SECTION.REINVESTMENT}
                            pdf={val.kpiLink}
                            onSelect={() => handleProductSetSelection(setName)}
                            maxBalanceJoint={val.jointMaxBalance}
                          />
                        ))}
                      </>
                    )
                  }))}
                  selectId="tabs-responsive-available-products"
                  tabStyleTrigger="widthAligned"
                  tabStyleContent="widthAligned"
                  backgroundColorContent="transparent"
                  backgroundColorTrigger="transparent"
                />
              </div>
            )}
            <Text className={styles.disclaimer}>{disclaimer}</Text>
          </section>
          <aside>
            <div className={styles.accountCard}>
              <div className={styles.accountCard__content}>
                <Heading level="6" css={{ marginBottom: "0.625rem" }}>
                  {maturingAccountCard.title}
                </Heading>
                <Tag isLarge css={{ padding: "$0-5 $1" }} className={styles.accountCard__content__tag}>
                  <Text as="span" fontWeight="semiBold">
                    {productName}
                  </Text>
                  {` ${issueNumber}`}
                </Tag>
                <div className={styles.accountCard__content__balance}>
                  <Text color="grey" fontWeight="medium">
                    {`${maturingAccountCard.balance} `}
                    <Text as="span" fontWeight="semiBold">
                      {formatBalance(balance)}
                    </Text>
                  </Text>
                </div>
                <div className={styles.accountCard__content__interest}>
                  <div className={styles.accountCard__content__interest__rate}>
                    <Text fontWeight="medium" color="grey">
                      {maturingAccountCard.interestRate}
                    </Text>
                    <Text fontWeight="semiBold">{interestRate}%</Text>
                  </div>
                  <div className={styles.accountCard__content__interest__rate}>
                    <Text fontWeight="medium" color="grey">
                      {maturingAccountCard.interestFrequency}
                    </Text>
                    <Text fontWeight="semiBold">
                      {accountDetailData.product.interestFrequency || accountDetailData.interest.interestFrequency}
                    </Text>
                  </div>
                </div>
                <div className={styles.accountCard__content__eot}>
                  <Text fontWeight="medium" color="grey">
                    {maturingAccountCard.endOfTerm}
                  </Text>
                  <Text fontWeight="semiBold">{formattedMaturityDate}</Text>
                </div>
              </div>
            </div>
            <div className={styles.otherOptions}>
              <Heading as="h3" level="6" className={styles.otherOptions__header}>
                {otherOptions.title}
              </Heading>
              <div className={styles.otherOptions__body}>
                <Button variant="tertiary" onClick={handleWithdrawFull}>
                  <Text as="span">{otherOptions.option}</Text>
                </Button>
              </div>
            </div>
            <AskAQuestion />
          </aside>
          <TermsExplained className={styles.termsExplained} />
        </section>
      )}
    </div>
  );
};

export default SelectAccount;
