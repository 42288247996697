/* eslint-disable no-console */
import { Button, Flex, Loader, OneTimePasswordInput } from "@shawbrook/sds-component-library";
import { useGenerateOtpForContact } from "hooks/useSendOTPProcess/useSendOTPProcess";
import { ApiAlerts } from "journeys/components/ApiAlerts";
import {
  SHOW_VERIFICATION_OTP_WARNING,
  VERIFICATION_OTP,
  VERIFICATION_OTP_ERROR,
  VERIFICATION_OTP_WARNING
} from "pages/BankfastUserMigration/OTPProcess/Selectors";
import { useRef, useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { getInlineServerErrorStatus } from "utils/getOTPRetryStatus";
import styles from "./otpVerification.module.scss";
import { IOtpVerificationProps } from "./types";

export const OtpVerification = ({ otpType, otpId, strings, setPostResult }: IOtpVerificationProps) => {
  const {
    control,
    setValue,
    clearErrors,
    getValues,
    getFieldState,
    formState: { errors }
  } = useFormContext();

  const { label, resend, editLabel } = strings;
  const navigate = useNavigate();
  const [callGenerateOtpForContactData, setCallGenerateOtpForContactData] = useState(false);
  const isResendPasscode = useRef(false);
  const handleResendPasscode = () => {
    clearErrors();
    setCallGenerateOtpForContactData(true);
    isResendPasscode.current = true;
  };

  const { generateOtpForContactFetching } = useGenerateOtpForContact({
    otpType,
    setPostResult,
    callGenerateOtpForContactData,
    setCallGenerateOtpForContactData,
    isResendPasscode: isResendPasscode.current
  });

  const handleEdit = () => {
    setValue(VERIFICATION_OTP(otpType), "");
    setValue(VERIFICATION_OTP_ERROR(otpType), false);
    navigate(-1);
  };

  const handleOtpChange = (e: string) => {
    setValue(VERIFICATION_OTP(otpType), e);
    setValue(VERIFICATION_OTP_ERROR(otpType), false);
  };

  const getInfoMessage = () =>
    getFieldState(VERIFICATION_OTP(otpType))?.error?.message || getInlineServerErrorStatus(errors)
      ? getFieldState(VERIFICATION_OTP(otpType))?.error?.message || errors.root?.serverError?.message
      : "";

  const getOtpFieldStatus = () =>
    getFieldState(VERIFICATION_OTP(otpType))?.error?.message || getInlineServerErrorStatus(errors)
      ? "error"
      : "default";

  return (
    <Flex gap="4" direction="column">
      {getValues(SHOW_VERIFICATION_OTP_WARNING(otpType)) === 1 && (
        <ApiAlerts errors={{ root: { serverWarning: { message: getValues(VERIFICATION_OTP_WARNING(otpType)) } } }} />
      )}
      <Controller
        name={VERIFICATION_OTP(otpType)}
        control={control}
        render={({ field: { ref, ...field } }) => (
          <OneTimePasswordInput
            {...field}
            id={otpId}
            name={otpType}
            labelText={{ main: label }}
            infoMessage={getInfoMessage()}
            status={getOtpFieldStatus()}
            onChange={handleOtpChange}
            autoFocus
          />
        )}
      />
      <Flex gap="4" direction="column" css={{ "@sm": { flexDirection: "row" } }}>
        <Button
          variant="secondary"
          disabled={generateOtpForContactFetching}
          size="medium"
          type="button"
          css={{ minWidth: "167px" }}
          onClick={handleResendPasscode}
          isSlim>
          {generateOtpForContactFetching ? <Loader size="small" /> : resend}
        </Button>
        <Button variant="plain" size="medium" type="button" onClick={handleEdit} className={styles.editEmail}>
          {editLabel}
        </Button>
      </Flex>
    </Flex>
  );
};

export default OtpVerification;
