import { useDataCleanseMock } from "__mocks__/dataCleanse";
import { API_LIMITS, SESSION_STORAGE_KEY, STATUS_CODE, SYSTEM_MESSAGE } from "assets/strings/api";
import axios, { AxiosError, AxiosResponse } from "axios";
import pca from "services/data/pca";
import getAccessToken from "utils/getAccessToken";
import setDefaultAuthHeader from "utils/setDefaultAuthHeader";
import sleep from "utils/sleep";

let ATTEMPT_INDEX = 0;

export const apiUrls = {
  customer: `${process.env.REACT_APP_APIM_URL || ""}/me`,
  accounts: `${process.env.REACT_APP_APIM_URL || ""}/me/accounts`,
  payments: `${process.env.REACT_APP_APIM_URL || ""}/me/accounts/:sourceAccountId/payments`,
  availableProducts: `${process.env.REACT_APP_APIM_URL || ""}/me/accounts/:sourceAccountId/available-products`,
  newAccountProducts: `${process.env.REACT_APP_APIM_URL || ""}/me/accounts/available-products`,
  setInstruction: `${process.env.REACT_APP_APIM_URL || ""}/me/accounts/:sourceAccountId/maturity/instruction-set`,
  linkedAccounts: `${process.env.REACT_APP_APIM_URL || ""}/me/accounts/linked-accounts/`,
  documents: `${process.env.REACT_APP_APIM_URL || ""}/me/documents`,
  document: `${process.env.REACT_APP_APIM_URL || ""}/me/documents/:documentId`,
  documentRead: `${process.env.REACT_APP_APIM_URL || ""}/me/documents/:documentId/read`,
  messagesUnread: `${process.env.REACT_APP_APIM_URL || ""}/me/messages/unread`,
  availableThreads: `${process.env.REACT_APP_APIM_URL || ""}/me/threads`,
  messages: `${process.env.REACT_APP_APIM_URL || ""}/me/threads/:threadId/messages`,
  profiles: `${process.env.REACT_APP_APIM_URL || ""}/me/profiles`,
  provider: `${process.env.REACT_APP_APIM_URL || ""}/isa/provider`,
  // Data cleans
  customers: `${process.env.REACT_APP_APIM_URL || ""}/me/update`,
  contact: `${process.env.REACT_APP_API_EMAIL_OTP_URL || ""}/auth/otp`,
  verifyContact: `${process.env.REACT_APP_API_EMAIL_OTP_URL || ""}/auth/otp/verify`
};

// Default config options
const defaultOptions = {
  headers: {
    "Content-Type": "application/json",
    Authorization: setDefaultAuthHeader(),
    "X-Correlation-Id": "",
    "X-Platform": ""
  }
};

// Create instance
const axiosInstance = axios.create(defaultOptions);

if (process.env.NODE_ENV !== "production" && process.env.REACT_APP_MOCK_DATA_CLEANSE === "true") {
  // eslint-disable-next-line no-console
  console.log(":: USING MOCK DATA ::");
  // eslint-disable-next-line react-hooks/rules-of-hooks
  useDataCleanseMock(axiosInstance);
}

axiosInstance.interceptors.request.use((config: any): any => {
  const accessToken = sessionStorage.getItem(SESSION_STORAGE_KEY.ACCESS_TOKEN);

  if (accessToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        authorization: `Bearer ${accessToken}`
      }
    };
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response: AxiosResponse) => {
    ATTEMPT_INDEX = 0;
    return response;
  },
  async (error: AxiosError) => {
    const originalRequest = error.config!;
    if (
      ATTEMPT_INDEX < API_LIMITS.RETRY_LIMIT &&
      (error.message === SYSTEM_MESSAGE.NETWORK_ERROR || error.response?.status === STATUS_CODE.UNAUTHORIZED)
    ) {
      await sleep(Math.min(API_LIMITS.RETRY_TIME_STEP * 2 ** ATTEMPT_INDEX, API_LIMITS.RETRY_MAX_TIME));
      ATTEMPT_INDEX += 1;
      await getAccessToken(pca);
      return axiosInstance(originalRequest);
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
