import { useMutation } from "@tanstack/react-query";
import { UseSubmitTransfer } from "hooks/useSubmitTransfer/interfaces";
import { submitTransfer } from "services/data/api";

const useSubmitTransfer = ({
  paymentParams = {},
  sourceAccountIdArg,
  destinationAccountIdArg,
  amountValueArg,
  useLinkedAccount = false,
  referenceArg
}: UseSubmitTransfer = {}) => {
  const sourceAccountId = sourceAccountIdArg ?? "";
  const destinationAccountId = localStorage.getItem("destinationAccountId") || (destinationAccountIdArg ?? "");
  const amountValue = amountValueArg ?? 0;
  const reference = useLinkedAccount && referenceArg == null ? "" : referenceArg;

  const {
    mutate: postTransfer,
    isError: isSubmitTransferError,
    error: submitTransferError,
    isSuccess: isSubmitTransferSuccess,
    isLoading: isSubmitTransferLoading,
    data: submitTransferData
  } = useMutation({
    mutationFn: () =>
      submitTransfer(
        sourceAccountId,
        {
          sourceAccount: {
            id: sourceAccountId
          },
          ...(!useLinkedAccount && {
            destinationAccount: {
              id: destinationAccountId
            }
          }),
          amount: {
            amount: amountValue,
            currency: "GBP"
          },
          ...(reference != null && { reference }),
          ...(useLinkedAccount && { useLinkedAccount })
        },
        paymentParams
      )
  });

  return {
    postTransfer,
    isSubmitTransferError,
    submitTransferError,
    isSubmitTransferSuccess,
    isSubmitTransferLoading,
    submitTransferData
  };
};

export default useSubmitTransfer;
