import { Flex, Heading, Space } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import { PRODUCT_CATEGORY_KEYS } from "config/productCategoryKeys";
import { FC } from "react";
import { ProductCategory } from "services/data/types";
import { inlineStyles } from "./ProsCons.styles";
import { ProsConsItem } from "./ProsConsItem";

export enum ProsConsItemType {
  PRO = "Pro",
  CON = "Con"
}

interface IProsCons {
  productCategory?: ProductCategory;
}

interface ICategoryProsCons {
  [key: string]: { pros: string[]; cons: string[]; title: string };
}

export const ProsCons: FC<IProsCons> = ({ productCategory }) => {
  if (!productCategory) return null;
  const { prosLabel, consLabel } = strings.prosCons;
  const prosConsStrings = strings.prosCons.categoryProsCons as unknown as ICategoryProsCons;
  const categoryProsCons = prosConsStrings[PRODUCT_CATEGORY_KEYS[productCategory]];
  const { title } = prosConsStrings[PRODUCT_CATEGORY_KEYS[productCategory]];
  if (!categoryProsCons) return null;
  return (
    <>
      <Space gap="5">
        <Heading as="h3" level={3} css={{ m: "2rem 0 0" }}>
          {title}
        </Heading>
      </Space>
      <Flex css={inlineStyles.lists} gap="5">
        <Flex direction="column" css={{ flex: "1" }}>
          <Heading as="h4" level={4}>
            {prosLabel}
          </Heading>
          {categoryProsCons.pros.map(el => (
            <ProsConsItem key={el} content={el} itemType={ProsConsItemType.PRO} />
          ))}
        </Flex>
        <Flex direction="column" css={{ flex: "1" }}>
          <Heading as="h4" level={4}>
            {consLabel}
          </Heading>
          {categoryProsCons.cons.map(el => (
            <ProsConsItem key={el} content={el} itemType={ProsConsItemType.CON} />
          ))}
        </Flex>
      </Flex>
    </>
  );
};
