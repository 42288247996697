import { FC } from "react";

import { Button, Flex, Heading, Loader, Modal, Text } from "@shawbrook/sds-component-library";
import strings from "assets/strings/strings.json";
import rreplace from "react-string-replace";
import styles from "./WarningModal.module.scss";

interface IWarningModal {
  handleClose: () => void;
  handleConfirm: () => void;
  maturityDate?: string;
  title: string;
  text?: string;
  description?: string;
  loading?: boolean;
  open?: boolean;
  onEscapeKeyDown?: boolean;
  zIndex?: number;
  onPointerDownOutside?: boolean;
  accept?: string;
  decline?: string;
}

const WarningModal: FC<IWarningModal> = ({
  handleConfirm,
  handleClose,
  title,
  text,
  open,
  description,
  loading,
  zIndex,
  onEscapeKeyDown = false,
  onPointerDownOutside = false,
  maturityDate = "",
  accept = strings.general.modals.warning.accept,
  decline = strings.general.modals.warning.decline
}) => {
  const descMarginTop = text ? "$6" : 0;
  const modalStyles = {
    ...{ padding: "$6", maxWidth: "786px", borderRadius: "8px" },
    ...(typeof zIndex !== "undefined" && { zIndex })
  };

  return (
    <Modal.Root open={open}>
      <Modal.Content
        css={modalStyles}
        onEscapeKeyDown={onEscapeKeyDown ? handleClose : undefined}
        onPointerDownOutside={onPointerDownOutside ? handleClose : undefined}>
        <Heading level={2} fontWeight="semiBold" id="modal-title" css={{ marginBottom: "$6" }}>
          {title}
        </Heading>
        {(text || description) && (
          <Flex direction="column" css={{ marginBottom: "$6", whiteSpace: "break-spaces" }}>
            {text && (
              <Text textStyle="medium">
                {rreplace(text, "{{DATE}}", () => (
                  <Text as="span" textStyle="medium" key={maturityDate} css={{ fontWeight: "$medium" }}>
                    {maturityDate}
                  </Text>
                ))}
              </Text>
            )}
            {description && (
              <Text textStyle="medium" css={{ marginTop: descMarginTop }}>
                {description}
              </Text>
            )}
          </Flex>
        )}
        <Flex className={styles.buttonConatiner} gap="4" justify="end">
          <Button
            variant="secondary"
            disabled={loading}
            onClick={handleClose}
            size="large"
            className={styles.buttonConatiner__buttons}
            css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
            {decline}
          </Button>
          <Button
            onClick={handleConfirm}
            disabled={loading}
            size="large"
            className={styles.buttonConatiner__buttons}
            css={{ paddingTop: "1.125rem", paddingBottom: "1.125rem" }}>
            {loading ? <Loader size="small" /> : accept}
          </Button>
        </Flex>
      </Modal.Content>
    </Modal.Root>
  );
};

export default WarningModal;
