/**
 * Replaces in input string {{YEAR_CURRENT}} and {{YEAR_NEXT}} to next walues:
 * if todays date is before 00:00 6th April (BST) then YEAR_CURRENT = prev. year and YEAR_NEXT current year
 * if todays date is after 00:00 6th April (BST) then YEAR_CURRENT = current year and YEAR_NEXT next year
 * @param {string} input - input string where placeholders will be replaced
 * @returns {string} - string with replaced placeholders
 */
export const formatCurrentTaxYearRange = (input: string): string => {
  const yearNow = new Date().getFullYear();
  const yearThresholdForBST = new Date(yearNow, 3, 5, 23, 0);
  const currentYear = new Date() < yearThresholdForBST ? yearNow - 1 : yearNow;

  return input
    .replace("{{YEAR_CURRENT}}", currentYear.toString())
    .replace("{{YEAR_NEXT}}", (currentYear + 1).toString());
};
