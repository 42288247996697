import { Button, Heading } from "@shawbrook/sds-component-library";
import { QUERY_PARAMS } from "assets/strings/queryParams";
import strings from "assets/strings/strings.json";
import WhiteBox from "components/WhiteBox/WhiteBox";
import { links } from "config/navLinks";
import useAccount from "hooks/useAccount";
import useMoveMoneyLanding from "hooks/useMoveMoneyLanding";
import { useSearchParams } from "react-router-dom";
import { useMoveMoneyContext } from "state/moveMoneyState";
import ErrorRequestView from "components/ErrorRequestView";
import MoveMoneyForm from "./components/MoveMoneyForm";
import SelectedAccounts from "./components/SelectedAccounts";
import styles from "./MoveMoney.module.scss";
import inlineStyles from "./MoveMoney.styles";
import MoveMoneyWrapper from "./MoveMoneyWrapper";

export const MoveMoney = () => {
  const [searchParams] = useSearchParams();
  const { state, setState } = useMoveMoneyContext();
  const accountId = searchParams.get(QUERY_PARAMS.FROM_ACCOUNT);
  const {
    isSingleAccount,
    accountResolved,
    accountErrored,
    accountDetailData,
    accountsData,
    accountError,
    accountsError
  } = useAccount({
    accountId,
    skipRequest: state?.fromAccount != null
  });
  const { fromAccountValid, isPageResolved, isPageErrored, moveMoneyFromHandler } = useMoveMoneyLanding({
    accountResolved,
    accountErrored,
    accountDetailData,
    accountsData,
    accountId,
    moveMoneyContext: { state, setState }
  });

  // show loader until page is loaded
  if (!isPageResolved) return <MoveMoneyWrapper isLoaded={isPageResolved} />;

  if (isPageErrored) {
    return <ErrorRequestView context={{ state, setState }} requestErrors={[accountsError, accountError]} />;
  }

  let backUrl = links.landing.link;

  if (fromAccountValid && !isSingleAccount && !accountId) {
    backUrl = state.toAccount ? links.moveMoneyTo.link : links.moveMoneyFrom.link;
  }

  return (
    <MoveMoneyWrapper isLoaded={isPageResolved} backUrl={backUrl}>
      {!fromAccountValid && (
        <section>
          <WhiteBox>
            <Heading level="5" className={styles.moveMoneyHeadings}>
              {strings.moveMoney.mmFrom}
            </Heading>
            <Button
              variant="tertiary"
              onClick={moveMoneyFromHandler}
              role="link"
              css={inlineStyles.chooseAccountBtn}
              className={styles["move-money__chooseAccount-button"]}>
              {strings.moveMoney.chooseAccount}
            </Button>
          </WhiteBox>
        </section>
      )}

      {fromAccountValid && (
        <section>
          <SelectedAccounts
            fromAccount={state.fromAccount!}
            toAccount={state.toAccount}
            isSingleAccount={isSingleAccount}
          />
          <MoveMoneyForm />
        </section>
      )}
    </MoveMoneyWrapper>
  );
};

export default MoveMoney;
