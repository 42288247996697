export default {
  tab: "tab",
  doc: "documents",
  statement: "statements",
  docLimit: "documents-limit",
  stateLimit: "statements-limit",
  noFiles: "No files to display"
};

export const CONTENT_TYPES = {
  pdf: "application/pdf",
  doc: "application/msword",
  docx: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  xls: "application/vnd.ms-excel",
  xlsx: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ppt: "application/vnd.ms-powerpoint",
  pptx: "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  jpg: "image/jpeg",
  jpeg: "image/jpeg",
  png: "image/png",
  gif: "image/gif",
  csv: "text/csv",
  txt: "text/plain",
  rtf: "application/rtf"
};
