import { FC, useReducer } from "react";
import { Outlet } from "react-router-dom";
import { Flex, Loader } from "@shawbrook/sds-component-library";

import IdleModal from "components/IdleModal";
import useNavigateToBankfastMigration from "hooks/useNavigateToBankfastMigration";
import usePageTrack from "hooks/usePageTrack";
import { useServiceTick } from "hooks/useServiceTick/useServiceTick";
import DefaultLayout from "layouts/DefaultLayout";
import { HighTraffic } from "pages/HighTraffic/HighTraffic";
import { CloseModal } from "pages/MoveMoney/components/Modals";
import { initialState, IState } from "state/moveMoneyState";

export const MoveMoneyLayout: FC = () => {
  usePageTrack();
  useServiceTick();
  const { isCustomerLoading, errorOccured } = useNavigateToBankfastMigration();

  // A simple reducer function that just has one function.
  const [state, setState] = useReducer(
    (data: IState, partialData: Partial<IState>) => ({
      ...data,
      ...partialData
    }),
    initialState
  );

  const renderLayout = () => {
    if (isCustomerLoading && !errorOccured) {
      return (
        <Flex justify="center" align="center" css={{ height: "100vh" }}>
          <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
        </Flex>
      );
    }

    if (state.isHighTraffic) {
      return <HighTraffic />;
    }

    return (
      <DefaultLayout className="layoutWrapper--landing">
        <>
          <IdleModal />
          <CloseModal
            show={state.displayLeaveModal}
            pathBoundaries="move-money"
            isReadyToShow={Boolean(state.fromAccount)}
          />
          <Outlet context={{ state, setState }} />
        </>
      </DefaultLayout>
    );
  };

  return renderLayout();
};

export default MoveMoneyLayout;
