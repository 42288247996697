/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-unused-vars */
import { AxiosError, AxiosResponse } from "axios";
import {
  BankfastUserMigrationFlow,
  TAllStepIdValues,
  IAllBankfastUserMigrationStepTypes
} from "pages/BankfastUserMigration/Steps";
import { ICustomer } from "services/data/types";
import { FieldValues, SubmitHandler, UseFormSetError } from "react-hook-form";
import { IBankfastUserMigrationAppContext, IBankfastUserMigrationState } from "state/bankfastUserMigrationState";
import { ObjectSchema } from "yup";

export interface IScreenProps {
  key?: string;
  currentState?: ComponentState;
  currentStepIndex?: number;
  setPostResult?: React.Dispatch<
    React.SetStateAction<
      | {
          result: APIReturnResult | null;
        }
      | undefined
    >
  >;
  setCurrentState?: React.Dispatch<React.SetStateAction<ComponentState>>;
  flowId?: BankfastUserMigrationFlow;
  apiErrors?: ApiError[] | null;
  onSubmit?: SubmitHandler<IBankfastUserMigrationState>;
  continueBtnLoading?: boolean;
  customerData?: ICustomer;
  setIsBtnHidden?: React.Dispatch<React.SetStateAction<boolean>>;
}

export type APICustomer = Customer;

export type APICustomerResponse = {
  success: boolean;
  message: string;
  errors: ApiError[];
};

export type APIContactResponse = {
  title: string;
  detail?: string;
  status: number;
  errors?: any;
};

export enum Groups {
  EMAIL = "email",
  MOBILE = "mobile",
  COMMUNICATION = "communication"
}

export type GroupsSteps = {
  [key in Groups]: Array<IAllBankfastUserMigrationStepTypes>;
};

export type CommsPreferences = {
  [key in Groups]: boolean;
};

export type PageSequence = Array<Groups>;

export type APIBankfastJourneyResponse = APICustomerResponse | APIContactResponse;

export type APICustomersReturn = AxiosError<APICustomerResponse> | AxiosResponse<APICustomerResponse>;
export type APIContactReturn = AxiosError<APIContactResponse> | AxiosResponse<APIContactResponse>;
export type APIVerifyContactReturn = AxiosError<APIContactResponse> | AxiosResponse<APIContactResponse>;

export type APIReturnResult = APICustomersReturn | APIContactReturn | APIVerifyContactReturn;

export type ApiError = {
  property: string;
  message: string;
  code: string;
};

export enum ComponentState {
  INITIAL,
  PRESUBMIT,
  SUBMITTED,
  DISPLAY_ERRORS,
  GO_TO_NEXT_STEP
}

export type ErrorMessage = {
  message: string;
  code: string;
};

export type AfterSubmitFunctionParams = (
  inputValue: string,
  context: IBankfastUserMigrationAppContext,
  currentStep: IStep<TAllStepIdValues>,
  setError: UseFormSetError<FieldValues>
) => void;

export type IStep<Type> = {
  id: Type;
  title: string;
  fields: string[];
  continueButtonLabel?: string;
  continueButtonLink?: string;
  validationSchema?: ObjectSchema<any>;
  ignoredErrorCodes?: string[];
  hideContinueButton?: boolean;
};

export type Customer = {
  contactDetails: {
    email: string;
    emailVerified: boolean;
    mobilePhoneNumber: string;
    mobilePhoneNumberVerified: boolean;
    commPreferencesProvided: boolean;
    secondaryPhoneNumber: string;
    commPreferences: {
      marketing: {
        email: boolean;
        sms: boolean;
      };
      voice: {
        translatorRequired: boolean;
        translationRequired: boolean;
      };
      document: {
        largePrint: boolean;
      };
    };
  };
};

export type OtpType = "email" | "mobile";
