import classnames from "classnames";
import { Heading, Text, Loader } from "@shawbrook/sds-component-library";
import { ArrowLeft } from "assets/images";
import strings from "assets/strings/strings.json";
import AskAQuestion from "components/AskAQuestion/AskAQuestion";
import IconLink from "components/IconLink/IconLink";
import WhiteBox from "components/WhiteBox/WhiteBox";
import React, { FC } from "react";
import styles from "./MoveMoney.module.scss";

interface IMoveMoneyWrapper {
  children?: React.ReactNode;
  className?: string;
  isLoaded?: boolean;
  description?: string;
  backUrl?: string;
}

export const MoveMoneyWrapper: FC<IMoveMoneyWrapper> = ({
  children,
  isLoaded = false,
  description,
  backUrl = "/",
  ...props
}) => {
  const cxWrapper = classnames(styles["move-money__wrapper"], {
    [styles["move-money__wrapper--with-description"]]: description
  });

  return (
    <div className={cxWrapper} {...props}>
      <header>
        <IconLink to={backUrl} icon={<ArrowLeft />} text={strings.general.back} />
        <Heading color="white">{strings.moveMoney.title}</Heading>
        {description && (
          <Text css={{ fontSize: "$4", marginTop: "$1" }} font="silka" fontWeight="medium" color="white">
            {description}
          </Text>
        )}
      </header>
      <section className={styles.page__content}>
        {!isLoaded && (
          <section className={styles.moveMoneyLoadingScreen}>
            <WhiteBox>
              <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
            </WhiteBox>
          </section>
        )}
        {isLoaded && children}
        <aside>
          <AskAQuestion />
        </aside>
      </section>
    </div>
  );
};
export default MoveMoneyWrapper;
