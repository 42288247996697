import { media } from "config/breakpoints";

const { md } = media;
export const inlineStyles = {
  continueButton: {
    button: {
      width: "100%",
      [md]: {
        width: "auto"
      }
    }
  }
};
