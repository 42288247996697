export default {
  bodyCard: {
    width: "100%",
    padding: "$3 $3 0",
    "@sm": {
      padding: "$4"
    }
  },
  submissionCard: {
    width: "100%",
    maxWidth: "var(--large-screen)",
    padding: "$3",
    borderRadius: 0,
    "@sm": {
      padding: "$5 $4 $4"
    }
  },
  interestHeading: {
    full: {
      fontWeight: "$medium",
      marginTop: "$8",
      marginBottom: "$5"
    },
    partial: {
      fontWeight: "$medium",
      marginTop: "$8",
      marginBottom: "$8"
    }
  },
  submissionButtons: {
    "@sm": {
      flexDirection: "row",
      justifyContent: "space-between"
    }
  },
  submitButton: {
    padding: "1.125rem $6 1.125rem",
    width: "auto",
    "@sm": {
      width: "fit-content"
    }
  },
  cancelButton: {
    borderBottom: "none",
    width: "100%",
    "@sm": {
      width: "fit-content"
    }
  },
  editButton: {
    borderBottom: 0,
    padding: "0 0 $1"
  },
  accountCardWrapper: {
    "@sm": {
      flexDirection: "row",
      gap: "$4"
    }
  }
};
