import { IMessage, SenderType, MessageDirection } from "services/data/types";

const getMessagesInfo = (messages: Array<IMessage>, messageWasJustSentByCustomer: boolean = false) => {
  const messagesLength = messages.length;
  const isThreadJustCreated =
    messagesLength === 1 &&
    messages[0].sender !== SenderType.AUTOMATED &&
    messages[0].direction === MessageDirection.INBOUND &&
    !messages[0].content;
  const isThreadResolved =
    messagesLength === 2 &&
    messages[messagesLength - 1].sender === SenderType.AUTOMATED &&
    messages[messagesLength - 1].direction === MessageDirection.OUTBOUND;
  const newMessageSent =
    messagesLength > 1 &&
    messages[messagesLength - 1].direction === MessageDirection.INBOUND &&
    messageWasJustSentByCustomer;
  const replyReceived =
    messagesLength > 2 &&
    messages[messagesLength - 1].direction === MessageDirection.OUTBOUND &&
    messages[messagesLength - 1].sender;

  return {
    messagesLength,
    isThreadJustCreated,
    isThreadResolved,
    newMessageSent,
    replyReceived
  };
};

export default getMessagesInfo;
