import { bankAccountInputsConfig } from "config/bankAccountInputsConfig";
import { NumberFormatValues } from "react-number-format";

export const formatSortCodeInput = (sortCode: string): string => {
  if (!sortCode) return "";
  return sortCode
    .slice(0, bankAccountInputsConfig.sortCodeMaxLength)
    .split("")
    .map((el, i) => (i % 2 === 1 && i < bankAccountInputsConfig.sortCodeMaxLength - 1 ? `${el}-` : el))
    .join("");
};

export const formatAccountNumberInput = (accountNumber: string): string =>
  accountNumber.slice(0, bankAccountInputsConfig.accountNumberMaxLength);

export const isAllowedSortCodeInputValidator = (values: NumberFormatValues): boolean =>
  values && values.formattedValue?.length <= bankAccountInputsConfig.sortCodeMaxLengthIncludingDashes;
