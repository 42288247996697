/* eslint-disable no-restricted-globals */
import { ITokenWorker } from "workers/tokenTimer/interfaces";

const tokenTimerFunction = () => {
  let timer: ReturnType<typeof setTimeout> | null = null;

  self.addEventListener("message", (e: MessageEvent<ITokenWorker>) => {
    const { type, exp, msToRefresh, isLoggedIn } = e.data;

    switch (type) {
      case "start":
        if (timer) {
          clearTimeout(timer);
        }

        if (isLoggedIn && exp && msToRefresh != null && msToRefresh > 0) {
          timer = setTimeout(() => {
            self.postMessage({ type: "refreshToken" });
          }, msToRefresh + 10);
        }
        break;
      default:
        break;
    }
  });
};

export default tokenTimerFunction;
