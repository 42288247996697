export default {
  container: {
    paddingLeft: 0,
    paddingRight: 0,
    "@xs": {
      paddingLeft: 0,
      paddingRight: 0
    }
  },
  entrance: {
    marginBottom: "$5"
  }
};
