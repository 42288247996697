export default {
  flex: { borderTop: "1px solid $grey2", p: "20px" },
  heading: { p: "1rem 0 1rem 1.25rem", fontSize: "var(--fontSizes-3)", fontWeight: "var(--fontWeights-semiBold)" },
  boxCircle: {
    borderRadius: "50%",
    width: "18px",
    height: "18px",
    p: 0,
    backgroundColor: "$grey2"
  },
  boxFirstRectangle: { width: "245px", height: "11px", p: 0, backgroundColor: "$grey2" },
  boxSecondRectangle: { width: "140px", height: "11px", p: 0, backgroundColor: "$grey2" },
  boxThirdRectangle: { width: "70px", height: "11px", p: 0, backgroundColor: "$grey2" }
};
