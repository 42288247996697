// export type AccessibilityOptions = {
//   localization: string;
//   supportNeeded?: "yes" | "no";
//   voice: {
//     usesTranslatorWhenContacting: boolean;
//     ukRelayService: boolean;
//     onlyCommunicatesInWriting: boolean;
//   };
//   document: {
//     largePrint: boolean;
//     translator: boolean;
//     writingOnly: boolean;
//     relayUKService: boolean;
//   };
// };

// record Name from: src/Application/Resources/CustomerDetails/Name.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/CustomerDetails/Name.cs&_a=contents
interface ICustomerName {
  fullName?: string;
  title?: string;
  givenNames?: Array<string>;
  familyName?: string;
  dateChanged?: string;
}

interface INationalInsuranceNumber {
  number?: string;
  missingReason?: string;
}

// record PersonalDetails from src/Application/Resources/CustomerDetails/PersonalDetails.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/CustomerDetails/PersonalDetails.cs

interface IPersonalDetails {
  gender?: string;
  dateOfBirth?: string;
  countryCodeOfBirth?: string;
  nationalInsuranceNumber?: INationalInsuranceNumber;
  nationality?: string;
}

// enum CustomerType from: src/Domain/Entities/CustomerEntity.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Domain/Entities/CustomerEntity.cs

export enum CustomerType {
  OTHER = "Other",
  PERSONAL = "Personal",
  BUSINESS = "Business"
}

// record Marketing from src/Application/Resources/CustomerDetails/Marketing.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/CustomerDetails/Marketing.cs

export interface ICustomerMarketing {
  sms?: boolean;
  email?: boolean;
  phoneCall?: boolean;
}

// record Accessibility from src/Application/Resources/CustomerDetails/Accessibility.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/CustomerDetails/Accessibility.cs
export interface ICustomerAccessibility {
  braille?: boolean;
  largePrint?: boolean;
  translator?: boolean;
  writingOnly?: boolean;
  relayUKService?: boolean;
}
// record CommPreferences from:src/Application/Resources/CustomerDetails/CommPreferences.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/CustomerDetails/CommPreferences.cs&version=GBepic/SDD-70-reinvestments&_a=contents
export interface ICustomerCommPreferences {
  marketing?: ICustomerMarketing;
  accessibility?: ICustomerAccessibility;
}

// record ContactDetails from: src/Application/Resources/CustomerDetails/ContactDetails.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/CustomerDetails/ContactDetails.cs
interface ICustomerContactDetails {
  email?: string;
  emailVerified?: boolean;
  mobilePhoneNumber?: string;
  mobilePhoneNumberVerified?: boolean;
  secondaryPhoneNumber?: string;
  secondaryPhoneNumberVerified?: boolean;
  commPreferencesProvided?: boolean;
  commPreferences?: ICustomerCommPreferences;
}

// record Address from: src/Application/Resources/CustomerDetails/Address.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/CustomerDetails/Address.cs
export interface ICustomerAddress {
  lines?: Array<string>;
  postalCode?: string;
  city?: string;
  county?: string;
  country?: string;
  moveInDate?: string;
  moveOutDate?: string;
}

// record Addresses from: src/Application/Resources/CustomerDetails/Addresses.cs
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/CustomerDetails/Addresses.cs
interface ICustomerAddresses {
  homeAddress?: ICustomerAddress;
  correspondanceAddress?: ICustomerAddress;
  pastAddresses?: Array<ICustomerAddress>;
  // Added based on figma for business details
  registeredAddress?: ICustomerAddress;
  tradingAddress?: ICustomerAddress;
}

// WIP: Added below interface to match the sample customer entity for business accounts, from the BE ticket SDD-8295
// https://shawbrook.atlassian.net/browse/SDD-8295

export enum TempBusinessType {
  SOLE_TRADER = "Sole Trader",
  LIMITED = "Limited",
  PARTNERSHIP = "Partnership"
}

interface ITempCustomerCorporateDetails {
  businessType?: TempBusinessType;
  businessName?: string;
  sicCode?: string;
  cRNnumber?: string;
  businessTurnover?: number;
  businessBalance?: number;
  numberOfEmployees?: number;
  // Added additional items to match the figma
  companyName?: string; // will default to using businessName for now, here for sake of completeness
  tradingName?: string;
  signatories?: string[];
}

// Below Customer entity based on the following file from the BE:
// https://dev.azure.com/shawbrookbank/Consumer-Savings/_git/cs_savings_customer_service?path=/src/Application/Resources/Customer.cs&_a=contents
// File src/Application/Resources/Customer.cs in cs_savings_customer_service repository

export interface ICustomer {
  id?: string;
  name?: ICustomerName;
  type?: CustomerType;
  status?: string;
  customerSince?: string;
  personalDetails?: IPersonalDetails;
  contactDetails?: ICustomerContactDetails;
  addresses?: ICustomerAddresses;
  previousNames?: Array<ICustomerName>;
  references?: {
    [key: string]: string;
  };
  // Added below items to match the sample customer entity for business accounts, from the BE ticket SDD-8295
  // https://shawbrook.atlassian.net/browse/SDD-8295
  corporateDetails?: ITempCustomerCorporateDetails;
}

export type InterestRate = {
  balanceBandFrom: number;
  balanceBandTo: number;
  effectiveDate: string;
  id: string;
  interestRate: number;
  productId: string;
};

export type AccountType = "Single" | "Joint";

export enum AccountStatus {
  CLOSED = "Closed",
  OPEN = "Open",
  UNFUNDED = "Unfunded",
  PENDING = "Pending",
  CREATEFAILED = "CreateFailed"
}

type Withdrawability = {
  enabled?: boolean;
  reason?: string;
};

export type IsaProviders = {
  id: string;
  companyName: string;
};

export type IsaDetails = {
  isaProvider: IsaProviders;
  isaProviderNameIfOther?: string;
  accountNumber?: string;
  sortCode: string;
  buildingSocietyRollNumber?: string;
  investmentType: string;
};

export interface INoticePeriod {
  hasNoticePeriod?: string;
  startTransfer?: string;
}

export interface IAccountSummary {
  id: string;
  sortCode: string;
  accountNumber: string;
  accountId: string;
  accountType: AccountType;
  withdrawability: {
    enabled: boolean;
    reason?: string;
  };
  outboundWithdrawability?: Withdrawability;
  openToFunding: {
    enabled: boolean;
    reason?: string;
  };
  product: {
    name: string;
    category: ProductCategory;
    displayName: string;
    issueNumber: number;
    interestRate: number;
    maxBalance: number;
    minBalance?: number;
    noticePeriodDays: number;
    title: string;
    interestRates: InterestRate[];
    interestFrequency: string;
    openToFunding: {
      enabled: boolean;
      reason?: string;
    };
    type: ProductType;
    earlyExitChargeDays?: number;
  };
  status: AccountStatus;
  balances: {
    availableBalance: {
      amount: number;
      currency: string;
    };
    clearedBalance: {
      amount: number;
      currency: string;
    };
    capitalBalance: {
      amount: number;
      currency: string;
    };
    bookedBalance: {
      amount: number;
      currency: string;
    };
    isaAllowance: {
      amount: number;
      currency: string;
    };
    isaAllowanceRemaining: {
      amount: number;
      currency: string;
    };
  };
  interest: {
    interestFrequency: string;
  };
  linkedAccount?: ILinkedAccount;
  associatedCustomers: Array<{
    savingsCustomerId: string;
    customerName: string;
    relationship: string;
  }>;
  references: {
    summitAccountNumber: string;
  };
  maturity?: {
    maturityDate?: string;
    preMaturing?: boolean;
    hasMatured?: boolean;
    commsSent?: boolean;
    instructionsSet?: boolean;
  };
  unauthorisedAccountCredit?: boolean;
}

export interface IIsaTransferEditSelection {
  transferISASavingsToCurrentAccount?: string;
  isaDetails?: IsaDetails;
  fullTransfer?: string;
  toppedUpAccountInCY?: string;
  transferAmount?: TransferAmount;
}

export type IAllAccounts = Array<IAccountSummary>;

export type InterestFrequency = "monthly" | "annually";

export type ProductType = "Business" | "Personal";

export enum ProductCategory {
  NOTICE = "Notice",
  FIXED_RATE = "Fixed Rate",
  EASY_ACCESS = "Easy Access",
  EASY_ACCESS_ISA = "Easy Access ISA",
  FIXED_RATE_ISA = "Fixed Rate ISA",
  NOTICE_ISA = "Notice ISA",
  MATURED_FUNDS = "Matured Funds",
  MATURED_FUNDS_ISA = "Matured Funds ISA"
}

export enum InterestPaymentOption {
  SAVINGS = "Compound into same account",
  LINKED = "BACS Transfer"
}

export interface IProduct {
  name: string;
  issueNumber: number;
  kpiLink: string;
  interestRate: number;
  interestRates: InterestRate[];
  interestRateBelowMinBal: number;
  interestFrequency: InterestFrequency;
  withdrawable: boolean;
  openToFunding: boolean;
  maxBalance: number;
  jointMaxBalance: number;
  minBalance: number;
  displayName: string;
  title: string;
  noticePeriodDays: number;
  type: string;
  variableProductType: boolean;
  reinvestmentPenalty: number;
  term?: Term;
  eligibility: {
    newCustomerOnly: boolean;
    endOfTermOnly: boolean;
    currentlyOffered: boolean;
  };
  category: ProductCategory;
  productGroup: string;
  id: string;
  references: {
    summitProductCode: string;
  };
}

export type Term = {
  periodIndicator: string;
  number: number;
};

export interface ILinkedAccount {
  id: string;
  bankName: string;
  accountNumber: string;
  verified: boolean;
  holderName: string;
  sortCode: string;
  unverifiedReason?: string;
}

export enum LinkedAccountUnverifiedReason {
  BANK_CHECK_IN_PROGRESS = "BANK_CHECK_IN_PROGRESS",
  BANK_CHECK_FAIL = "BANK_CHECK_FAIL"
}

export interface IAvailableProduct {
  issueNumber: number;
  interestRateBelowMinBal?: number;
  maxBalance?: number;
  jointMaxBalance?: number;
  minBalance?: number;
  type: string;
  category: ProductCategory;
  term?: Term;
  products: IProduct[];
  soleMaxBalance?: number;
  interestRates: Array<InterestRate>;
  title?: string;
  noticePeriodDays?: number;
  lowestInterestRate?: number;
  lowestRateUpperBound?: number;
}

export interface IAccountDetails extends IAccountSummary {
  nickname: string;
  holdCodes: Array<string>;
  inceptionDate: string;
  closureDate: string;
  fundedDate: string;
  fundingWindow: number;
  linkedAccount?: ILinkedAccount;
  product: IAccountSummary["product"] & {
    productId: string;
    type: string;
    isaDetails: {
      type: string;
    };
    references: {
      summitProductCode: string;
    };
    term: Term;
    jointMaxBalance: number;
  };
  balances: IAccountSummary["balances"] & {
    clearedBalance: {
      amount: number;
      currency: string;
    };
    outstandingBalance: {
      amount: number;
      currency: string;
    };
  };
  interest: IAccountSummary["interest"] & {
    totalInterestEarnt: number;
    predictedInterest: number;
    dateLastInterestRateChange: string;
    previousInterestRate: number;
    interestDueDate: string;
    interestPaymentMethod: string;
  };
  notice: {
    noticePending: boolean;
    noticeInstructions: Array<string>;
  };
  associatedCustomers: IAccountSummary["associatedCustomers"] &
    Array<{
      references: {
        summitCustomerNumber: string;
      };
    }>;
  references: {
    summitAccountNumber: string;
  };
}
export interface IPaymentsBody {
  amount: {
    amount: number;
    currency: string;
  };
  sourceAccount: {
    id: string;
  };
  useLinkedAccount?: boolean;
  destinationAccount?: {
    id: string;
  };
  reference?: string;
}

export enum TransferStatus {
  COMPLETE = "Complete",
  APPROVED = "Approved",
  FAILED = "Failed",
  REJECTED = "Rejected",
  MANUALLY_REJECTED = "ManuallyRejected",
  MANUAL_REVIEW = "ManualReview",
  PENDING = "Pending"
}

export enum TransactionPaymentMethod {
  OTHER = "Other",
  INTERNAL_TRANSFER = "InternalTransfer",
  OUTBOUND = "Outbound",
  ADJUSTMENT = "Adjustment",
  INBOUND = "Inbound"
}

export enum TransactionType {
  OTHER = "Other",
  WITHDRAWAL = "Withdrawal",
  INTEREST = "Interest",
  RECEIPT = "Receipt",
  ISA_TRANSFER_IN = "IsaTransferIn",
  ISA_TRANSFER_OUT = "IsaTransferOut",
  CLOSURE = "Closure"
}

export enum OperationType {
  CREDIT = "Credit",
  DEBIT = "Debit"
}

export interface IPaymentsResponse extends IPaymentsBody {
  id: string;
  sourceBalanceAfter: number;
  sourceBalanceBefore: number;
  destinationBalanceAfter?: number;
  destinationBalanceBefore?: number;
  reference?: string;
  status: {
    value: TransferStatus;
    reason?: string;
    dateChanged: string;
  };
  dateInitiated: string;
  dateProcessed?: string;
  transactionType?: TransactionType;
  transactionPaymentMethod?: TransactionPaymentMethod;
  operationType?: OperationType;
  sourceAccount: IPaymentsBody["sourceAccount"] & {
    sortCode: string;
    product: {
      id: string;
      displayName: string;
      issueNumber?: string;
      title?: string;
    };
    linkedAccount?: {
      id: string;
      bankName: string;
      accountNumber: string;
      verified: boolean;
      holderName: string;
      sortCode: string;
    };
  };
  destinationAccount?: IPaymentsBody["destinationAccount"] & {
    sortCode: string;
    product: {
      id: string;
      displayName: string;
      issueNumber?: string;
      title?: string;
    };
  };
}

export interface IPaymentsError {
  property: string;
  message: string;
  code: string;
}

export interface IPaymentsErrorResponse {
  errors: Array<IPaymentsError>;
}

type InstructionReason = "MATURITY";

export enum InstructionType {
  REINVEST_NEW = "REINVEST-NEW",
  WITHDRAW = "WITHDRAW"
}

type Amount = {
  amount: number;
  currency: string;
};

type ReferencesToAccount = {
  summitAccountNumber?: string;
  summitProductCode?: string;
};

type InstructionToAccount = {
  displayName?: string;
  title: string;
  id: string;
  references?: ReferencesToAccount;
};

type Eligibility = {
  currentlyOffered: boolean;
  endOfTermOnly?: boolean;
};

type InstructionToProduct = InstructionToAccount & {
  interestRate: number;
  interestPaymentMethod?: string;
  interestFrequency: string;
};

export type ReceivedInstructionProduct = InstructionToProduct & {
  type?: string;
  noticePeriodDays?: number;
  withdrawable?: boolean;
  openToFunding?: boolean;
  name?: string;
  issueNumber?: number;
  category?: string;
  productGroup?: string;
  eligibility?: Eligibility;
  term?: Term;
  jointMaxBalance?: number;
  maxBalance?: number;
  minBalance?: number;
  outboundWithdrawability?: Withdrawability;
  productId?: string;
  withdrawability?: Withdrawability;
};

export type Instruction = {
  id?: string;
  type: InstructionType;
  amount?: Amount;
  useRemainingBalance: boolean;
  priority: number;
  toAccount?: InstructionToAccount;
  toProduct?: InstructionToProduct | ReceivedInstructionProduct;
};

export type Valid = {
  canExecute: boolean;
  reason: string;
};

export interface IInstructionBody {
  instructionReason?: InstructionReason;
  executionDate?: string;
  instructions?: Instruction[];
}

export interface IMarketingPreferences {
  email: boolean;
  sms: boolean;
  phoneCall: boolean;
}

export interface IAccessibilityPreferences {
  translator: boolean;
  relayUKService: boolean;
  writingOnly: boolean;
  largePrint: boolean;
  braille: boolean;
}
export interface ICustomerGDPRAndA11yBody {
  contactDetails: {
    commPreferencesProvided: boolean;
    commPreferences: {
      marketing: ICustomerCommPreferences["marketing"];
      accessibility: ICustomerCommPreferences["accessibility"];
    };
  };
}

export interface ININumberBody {
  personalDetails: {
    nationalInsuranceNumber: {
      number: string;
    };
  };
}

type References = {
  summitCustomerNumber?: string;
  summitAccountNumber?: string;
  shawbrookUser?: string;
  summitProductCode: string;
};

export interface IMaturityInstructionResponse {
  id?: string;
  executionDate: string;
  instructionReason: string;
  instructions: Instruction[];
  valid?: Valid;
  references?: References;
}

/* Secure Messages */

export interface IMessagesUnread {
  count: number;
}

/* Secure Messages */
export type MessageDirectionType = "Inbound" | "Outbound";
export type MessageResponseType = "CHOICE" | "FREETEXT" | "NO-RESPONSE";
export type MessageResponse = {
  type: MessageResponseType;
  options: Array<string>;
};
export enum MessageDirection {
  INBOUND = "Inbound",
  OUTBOUND = "Outbound"
}
export enum SenderType {
  CREATE_THREAD = "create-thread",
  AUTOMATED = "Automated"
}
export interface IMessage {
  messageId: string;
  sentDate: string;
  hasBeenRead?: boolean;
  accountId?: string;
  subject?: string;
  readDate?: string;
  content?: string;
  sender?: string;
  attachments?: Array<string>;
  direction: MessageDirectionType;
  response?: MessageResponse;
}
export interface IMessageSummary {
  sentDate: string;
  sender?: string;
  content?: string;
}

// /threads
export interface IThreadSummary {
  threadId: string;
  subject: string;
  countOfUnreadMessages?: number;
  mostRecentMessage: IMessageSummary;
  isClosed?: boolean;
  accountId?: string;
}

export interface IAvailableThreads {
  [response: string]: Array<IThreadSummary>;
}

export interface CreateMessageRequest {
  content: string;
}

export interface CreateThreadRequest {
  sender?: string;
  content?: string;
  subject?: string;
  accountId?: string;
}
export interface ISubmitThreadResponse {}
export interface ISubmitMessagesResponse {}
export interface IThreadsError {
  property: string;
  message: string;
  code: string;
}

export interface IThreadsErrorResponse {
  errors: Array<IPaymentsError>;
}

export type AnnualSavings = {
  annualDeposit: number;
  sourceOfDeposit: string;
};

export interface ILinkedAccountResponse {
  accountNumber: string;
  sortCode: string;
  holderName: string;
  bankName: string;
}

export interface IAssociatedCustomer extends ININumberBody {
  id: string;
  relationship: string;
}

export interface INewAccountPayload {
  product: {
    id: string;
  };
  accountType: string;
  interest: {
    interestPaymentMethod: string;
    interestFrequency: string;
  };
  linkedAccount: {
    accountNumber: string;
    sortCode: string;
    holderName: string;
    bankName?: string;
  };
  initialDeposit: {
    estimatedDepositAmount: number;
    sourceOfDeposit: string;
  };
  associatedCustomers?: IAssociatedCustomer[];
  isaTransfer?: IIsaTransfer;
}

export type IsaAmount = {
  amount: number;
  currency: string;
};
export interface IIsaTransfer {
  sourceAccount: {
    sortCode: string;
    accountNumber: string;
    rollNumber: string;
  };
  investmentType: string;
  provider: {
    name: string;
  };
  fullBalance: boolean;
  hasCurrentYearSubscriptions: boolean;
  hasFundsFromPreviousTaxYears: boolean | null;
  transferAmount: IsaAmount | null;
  subscribedAmount: IsaAmount | null;
  hasNoticePeriod: boolean;
  immediateTransfer: boolean | null;
}

export interface ISubmitNewAccountResponse {
  status: string;
  accountId: string;
}

export type TransferAmount = {
  amountPaidToISA?: number;
  transferMoreMoney?: string;
  additionalAmount?: number;
  isaContainsSavingFromPY?: string;
};
