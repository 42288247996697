import classnames from "classnames";
import { StrictMode, useReducer } from "react";
import { Outlet, matchPath, useLocation, useNavigate } from "react-router-dom";

import { Flex, Loader } from "@shawbrook/sds-component-library";
import IdleModal from "components/IdleModal";
import FEATURE_FLAGS from "config/featureFlags";
import { links } from "config/navLinks";
import useProfileLoader from "hooks/UseProfileLoader";
import useNavigateToBankfastMigration from "hooks/useNavigateToBankfastMigration";
import usePageTrack from "hooks/usePageTrack";
import { useServiceTick } from "hooks/useServiceTick/useServiceTick";
import DefaultLayout from "layouts/DefaultLayout";
import { HighTraffic } from "pages/HighTraffic/HighTraffic";
import NotFound from "pages/NotFound";
import { useGlobalContext } from "state/globalContext";
import { IState, initialState } from "state/mainState";
import InfoLayout from "./InfoLayout/InfoLayout";

export const MainLayout = () => {
  usePageTrack();
  useServiceTick();
  const { isCustomerLoading, errorOccured } = useNavigateToBankfastMigration();
  const location = useLocation();
  const navigate = useNavigate();
  const globalState = useGlobalContext();
  const { customerProfileData, isCustomerProfileLoading, hasMultipleProfiles } = useProfileLoader();

  const [state, setState] = useReducer(
    (data: IState, partialData: Partial<IState>) => ({
      ...data,
      ...partialData
    }),
    initialState
  );

  const cxLayout = classnames("layoutWrapper--landing", {
    "layoutWrapper--landing--shortest":
      location.pathname === links.myProfile.link ||
      location.pathname === links.documents.link ||
      matchPath(`${links.closeAccount.link}`, location.pathname) ||
      matchPath(`${links.linkedAccountIssue.link}`, location.pathname),
    "layoutWrapper--landing--info":
      FEATURE_FLAGS.allowBusinessBanking && isCustomerProfileLoading && !customerProfileData
  });

  const renderLayout = () => {
    if (isCustomerLoading && !errorOccured) {
      return (
        <Flex justify="center" align="center" css={{ height: "100vh" }}>
          <Loader showRing size="medium" spacing="medium" text="Loading..." textColor="primary" />
        </Flex>
      );
    }

    if (state.is404Page) {
      return <NotFound withLayout />;
    }

    if (state.isHighTraffic) {
      return <HighTraffic />;
    }

    if (FEATURE_FLAGS.allowBusinessBanking) {
      if (isCustomerProfileLoading && !customerProfileData) {
        return (
          <InfoLayout className={cxLayout}>
            <Loader />
          </InfoLayout>
        );
      }

      if (!isCustomerProfileLoading && hasMultipleProfiles && globalState.selectedProfile === undefined) {
        navigate(links.profile.link);
      }
    }

    return (
      <DefaultLayout className={cxLayout}>
        <>
          <IdleModal />
          <Outlet context={{ state, setState }} />
        </>
      </DefaultLayout>
    );
  };

  return <StrictMode>{renderLayout()}</StrictMode>;
};

export default MainLayout;
