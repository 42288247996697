import { Heading } from "@shawbrook/sds-component-library";
import React, { FC } from "react";
import styles from "./index.module.scss";

interface IModalProps {
  title: string;
  children: React.ReactNode;
}

export const Modal: FC<IModalProps> = ({ children, title }) => (
  <dialog open aria-labelledby="modal-title" className={styles.modal} role="alertdialog" aria-modal="true">
    <div className={styles.modalElement}>
      <Heading level={3} fontWeight="semiBold" id="modal-title" css={{ marginBottom: "$3" }}>
        {title}
      </Heading>
      {children}
    </div>
  </dialog>
);

export default Modal;
