import { LabelPink, LogoPink } from "assets/images/index";
import strings from "assets/strings/strings.json";
import { FC } from "react";
import styles from "./header.module.scss";

export const Header: FC = () => (
  <header className={styles.bankfastUserMigrationHeader}>
    <LogoPink title={strings.general.logoTitle} style={{ width: "1.875rem", height: "3.0625rem" }} />
    <LabelPink aria-hidden="true" style={{ width: "10.0625rem", height: "1.5rem" }} />
  </header>
);

export default Header;
