export const CUSTOMER = `customer`;
export const CUSTOMER_CONTACT_DETAILS = `${CUSTOMER}.contactDetails`;
export const CUSTOMER_EMAIL = `${CUSTOMER_CONTACT_DETAILS}.email`;
export const CUSTOMER_EMAIL_VERIFIED = `${CUSTOMER_CONTACT_DETAILS}.emailVerified`;
export const CUSTOMER_MOBILE = `${CUSTOMER_CONTACT_DETAILS}.mobilePhoneNumber`;
export const CUSTOMER_MOBILE_VERIFIED = `${CUSTOMER_CONTACT_DETAILS}.mobilePhoneNumberVerified`;

export const MARKETING_PREFERENCES = `${CUSTOMER}.marketingPreferences`;
export const ACCESSIBILITY = `${CUSTOMER}.accessibilityOptions`;
export const ACCESSIBILITY_SUPPORT_NEEDED = `${ACCESSIBILITY}.supportNeeded`;
export const ACCESSIBILITY_DETAILS = `${ACCESSIBILITY}.details`;
export const GDPR_AND_A11Y_ERROR = `${CUSTOMER}.GDPRAndA11yError`;

export const EMAIL_VERIFICATION = "verification";
export const MOBILE_VERIFICATION = "verification";

export const EMAIL_VERIFICATION_CONTACT = `${EMAIL_VERIFICATION}.contact`;
export const EMAIL_VERIFICATION_VERIFIED_CONTACT = `${EMAIL_VERIFICATION}.verifiedContact`;
export const EMAIL_VERIFICATION_OTP = `${EMAIL_VERIFICATION}.otp`;
export const EMAIL_VERIFICATION_OTP_ERROR = `${EMAIL_VERIFICATION}.otpError`;
export const EMAIL_SHOW_VERIFICATION_OTP_WARNING = `${EMAIL_VERIFICATION}.showOtpWarningEmail`;
export const EMAIL_VERIFICATION_OTP_WARNING = `${EMAIL_VERIFICATION}.otpWarningEmail`;

export const MOBILE_VERIFICATION_CONTACT = `${MOBILE_VERIFICATION}.contact`;
export const MOBILE_VERIFICATION_VERIFIED_CONTACT = `${MOBILE_VERIFICATION}.verifiedContact`;
export const MOBILE_VERIFICATION_OTP = `${MOBILE_VERIFICATION}.otp`;
export const MOBILE_VERIFICATION_OTP_ERROR = `${MOBILE_VERIFICATION}.otpError`;
export const MOBILE_SHOW_VERIFICATION_OTP_WARNING = `${MOBILE_VERIFICATION}.showOtpWarningMobile`;
export const MOBILE_VERIFICATION_OTP_WARNING = `${MOBILE_VERIFICATION}.otpWarningMobile`;
export const MOBILE_VERIFICATION_SCREEN_MODE = `${MOBILE_VERIFICATION}.screenMode`;
